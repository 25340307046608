import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Accordion, Card } from 'react-bootstrap';
import { Bar } from "react-chartjs-2";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import * as myConstent from '../contexts/ConstContext';

const AssessmentHistory = () => {
  const { getRoadmapAssessmentHistory, logout } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
  const [history, setHistory] = useState([]);
  const [roadmap, setRoadmap] = useState({});
  
  const decoratedOnClick = (id) => {
    setIsActive(id);
    /*let eventKey = e.currentTarget.getAttribute('eventKey');*/

  };

  const navigateToResultPage = (e) => {
    navigate(`/check-assessment-result/self/${e.currentTarget.id}`);
  };
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const res = await getRoadmapAssessmentHistory({roadmap_id:id, user_id:user.id});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){
          if(res.data){
            setHistory(res.data.assessment_history);
            setRoadmap(res.data.roadmap);
          }
          setLoading(false);
      }else{
      }
    };
    
    fetchData();
  }, []);

    
  const barChartOptions = {
    indexAxis: "y", // Make the bars horizontal
    scales: {
        x: {
            display: true,
            scaleLabel: {
            display: true,
            labelString: 'Value'
            },
            ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
            suggestedMin: 0.5,
            suggestedMax: 5.0,
            callback: function(label, index, labels) {
                switch (label) {
                case 0:
                    return '';
                case 1:
                    return 'Novice';
                case 2:
                    return 'Advanced Beginner';
                case 3:
                    return 'Competent';
                case 4:
                    return 'Proficient';
                case 5:
                    return 'Expert';
                default:
                    return '';
                }
            }
            },
            gridLines: {
            display: false
            }
        }
    },
  };

  return (
    <div className="row h-full">
      <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
        <Container fluid>
          <Row>
            <div className="col-md-12">
              {!loading && (
            <>

            <center><h1>{roadmap.roadmap_name} Assessment History</h1></center>
            
              <div className="list-group history-list mt-4">
                <div className="list-group-item list-group-item-action flex-column align-items-start active">
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Date & Time</h5>
                  <h5>Level</h5> 
                  <h5>Action</h5> 
                </div>
                </div>
                <Accordion defaultActiveKey="0">
                {(history.length > 0) && (history.map((assessment, index) => ( 
                 <>
                 <Card>
                  <Card.Header>
                  <div eventKey={index} onClick={() => decoratedOnClick(index)}> 
                    <div className="list-group-item list-group-item-action flex-column align-items-start ">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1 mx-1">{Moment(assessment.created_at).format(myConstent.DATETIMEFORMAT)}</h5>
                      {(assessment.total_score >= 0 && assessment.total_score <= 0.99) && (<small className="me-1">Entry Level Developer</small>)}
                      {(assessment.total_score >= 1 && assessment.total_score <= 1.99) && (<small className="me-1">Junior Developer</small>)}
                      {(assessment.total_score >= 2 && assessment.total_score <= 2.99) && (<small className="me-1">Mid Level Developer</small>)}
                      {(assessment.total_score >= 3 && assessment.total_score <= 3.99) && (<small className="me-1">Senior Developer</small>)}
                      {(assessment.total_score >= 4 && assessment.total_score <= 5) && (<small className="me-1">Principal Developer</small>)}
                      <button id={assessment.id} className="btn btn-primary" onClick={navigateToResultPage}>Check Result</button>
                    </div>
                    </div>
                  </div>
                  </Card.Header>
                  <Accordion.Collapse className={isActive === index  ? 'show' : ''} eventKey={index}>
                    <Card.Body><div>
                        <Bar data={{
                            labels: assessment.skill_labels,
                            datasets: [
                            {
                                label: 'Roadmaps',
                                data: assessment.skill_data,
                                backgroundColor: "rgba(53, 162, 235, 0.5)"
                            }
                            ],
                        }} options={barChartOptions} 
                        height={"50%"}/>
                        </div></Card.Body>
                  </Accordion.Collapse>
                  </Card>
                </>
                )))}
                </Accordion>
              </div>
              
            </>
          )}
          </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AssessmentHistory;