import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { UserContext } from '../contexts/UserContext';

const LoginForm = () => {
  const navigate = useNavigate();
  const {loginUser, wait, loggedInCheck, showToast} = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    redirectToReferrer: false
  });
  const clientId = '869676454101-r04o2t5lkcj2f50r268da3fjic6k4bu3.apps.googleusercontent.com';

  const submitForm = async (e) => {
      e.preventDefault();
      setLoading(true);
      
      const data = await loginUser(formData);
      if(data.success){
          e.target.reset();
          setRedirect('Redirecting...');
          setLoading(false);
          await loggedInCheck();
          navigate("/profile");
      } else {
        showToast(data.message, true);
        setLoading(false);
      }
  };


  const handleGoogleLogin = async (res) => {
    console.log('Login Success: currentUser:', res.credential);
    formData['type'] = "GOOGLE";
    formData['credential'] = res.credential;
    const data = await loginUser(formData);
      if(data.success){
          setRedirect('Redirecting...');
          await loggedInCheck();
          navigate("/profile");
      }else {
        showToast(data.message, true);
        setLoading(false);
      }

    // refreshTokenSetup(res);
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "77vm3ukkm0s1re",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      handleLinkedInLogin(code);
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log(error);
      showToast(error.errorMessage, true);
    },
  });

  const handleLinkedInLogin = async (res) => {
    formData['type'] = "LINKEDIN";
    formData['credential'] = res;
    const data = await loginUser(formData);
    if(data.success){
        setRedirect('Redirecting...');
        await loggedInCheck();
        navigate("/profile");
    }else {
      showToast(data.message, true);
      setLoading(false);
    }
  };


  return (
    <div className="login-form padding100">
      <Container>
        <Row>
          <Col md={6}>
            <Image
              src="/img/about.jpg"
              alt="Login"
              fluid
              className="login-image"
            />
          </Col>
          <Col md={6}>
            <div className="login-form-container">
              <h2>Login</h2>
              <Form onSubmit={submitForm}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                
                <div className="forgot-link">
                  <a href="/forgot-password">Forgot Password?</a>
                </div>
                <br></br>
                <div className="col-xl-12 col-lg-12">
                  <div className="part-btn text-center">
                    {redirect ? redirect : <Button className="login-btn" type="submit" variant="primary" disabled={wait}>
                      Login {loading ? <i class="fa fa-spinner fa-spin"></i> : ''}
                    </Button>}
                  </div>
                </div>

              </Form>

              <div className="login-options">
              <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin
                  onSuccess={credentialResponse => handleGoogleLogin(credentialResponse)}
                  onError={() => {
                    alert('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>

            {/* <Button className="google-btn" variant="warning" onClick={handleGoogleLogin}>
                  Login with Google
                  {'  '}
                </Button> */}

                <Button className="linkedin-btn" variant="info" onClick={linkedInLogin} >
                  Login with LinkedIn
                </Button>
              </div>

              <div className="signup-link">
                <p>
                  Don't have an account? <a href="/signup">Sign Up</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default LoginForm;
