import React from 'react';
import { Link } from "react-router-dom";
import { Button, Dropdown, Container } from 'react-bootstrap';
import ProfileMenu from "./profilemenu";

const Process = () => {

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <div className="settings-card">
                <div className="row">
                  <div className="col-md-3">
                    <aside className="d-sm-none py-10 d-md-block border-end process-sidebar">
                      <div className="relative mr-2">
                        <span className="mb-2 d-flex align-items-center justify-content-between text-xs uppercase text-gray-400"><span>Choose Team</span></span>
                        <Dropdown data-bs-theme="dark">
                          <Dropdown.Toggle id="dropdown-button-dark-example1" className="d-flex align-items-center justify-content-between" variant="secondary">
                            <img src="https://roadmap.sh/images/default-avatar.png" alt="" className="h-4 w-4 rounded-full object-cover" /> <span className="ms-2">Test</span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="bg-dark">
                            <Dropdown.Item className="text-white" href="#/action-1">
                              Personal Account
                            </Dropdown.Item>
                            <Dropdown.Item className="text-white" href="#/action-2">Test </Dropdown.Item>
                            <Dropdown.Item className="text-white" href="#/action-3">Test Group</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-4"><a className="btn btn-light d-flex p-1" href="/team/new"><span>+</span><span>New Team</span></a></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <hr className="my-4" />
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <Link className="nav-link active" aria-current="page" href="#"><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="M3 3v18h18"></path><path d="m19 9-5 5-4-4-3 3"></path></svg>Progress</span></Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" href="#"><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"></polygon><line x1="9" x2="9" y1="3" y2="18"></line><line x1="15" x2="15" y1="6" y2="21"></line></svg>Roadmaps</span></Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" href="#"><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>Members</span></Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" href="#"><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="me-2 h-4 w-4"><path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"></path><path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>Settings</span></Link>
                        </li>
                      </ul>
                      <hr className="my-4" />
                      <Button variant="outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"></path></svg> Send Feedback</Button>
                    </aside>
                  </div>

                  <div className="col-md-9"></div>
                </div>
                    
              </div>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default Process;