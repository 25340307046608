import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';

const HiringAssessment = () => {
  const {logout, getRoadmapById, postAssessment, getSkills, getHiringDataUsingToken, showToast} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [id, setId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [teamType, setTeamType] = useState('');
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isQuestions, setIsQuestions] = useState(1);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [roadmap, setRoadmaps] = useState({});
  const [loadingRoadmap, setLoadingRoadmap] = useState(true);
  const [myQuestions, setMyQuestions] = useState([]);
  const [selectedQueData, setSelectedQueData] = useState({result:[]});
  const [selectedArrData, setSelectedArrData] = useState([]);
  
  const [assumeScore, setAssumeScore] = useState(-1);
  const [showModal, setShowModal] = useState(true);
  const [technicalLabels] = useState([{"key": "Entry Level Developer", "value": 1}, {"key": "Junior Developer", "value": 2}, {"key": "Mid Level Developer", "value": 3}, {"key": "Senior Developer", "value": 4}, {"key": "Principal Developer", "value": 5}]);
  const [nonTechnicalLabels] = useState([{"key": "Level-1", "value": 1}, {"key": "Level-2", "value": 2}, {"key": "Level-3", "value": 3}, {"key": "Level-4", "value": 4}, {"key": "Level-5", "value": 5}]);

  const handleRateChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAssumeScore(value);
      setShowModal(false);
    }
  };
  const closeModal = () => {
    navigate('/');
	}

  const handleSelectedAnswer = (question_id,skill_id, answer) => {

    let array = [];
    if(selectedQueData.result.length > 0){
        array = selectedQueData.result[0].questions;
        array.forEach((element, index) => {
          if(element.skill_question_id == question_id && element.skill_id == skill_id) {
            array.splice(index, 1);
            return true;
          }
        });
    }

    let queRes = (selectedQueData.result.length > 0) ? array : myQuestions;
    
    queRes.push({
      skill_question_id:question_id,
      answer:answer,
      skill_id:skill_id
    });
    let itemsres = [];
      itemsres.push({
         questions:queRes
    });
    let qData = {result:itemsres};
    setSelectedQueData(qData);

    
    let selectedArrData = [];
    (itemsres[0].questions).forEach(element1 => {
      selectedArrData.push(element1.skill_question_id);
    });
    setSelectedArrData(selectedArrData);
  };

  const fetchRoadMapData = async (id) => {
    setLoadingRoadmap(true);
    const resRoadmap = await getRoadmapById({id:id});
    if(resRoadmap.token_message){
      console.error(resRoadmap.token_message);
      logout();
    }else if(resRoadmap){
        setRoadmaps(resRoadmap.data);
        setLoadingRoadmap(false);
    }else{
    }
  };

  const handleSubmit = async (e) => {
    // Submit the user's answers
    // Display a message
    if(selectedQueData.result.length == 0){
      showToast('Please rate all skill questions', true); 
      return false;
    } else if(selectedQueData.result[0] != undefined && selectedQueData.result[0].questions.length != questionsCount){
      showToast('Please rate all skill questions', true); 
      return false;
    }

    let formData = {
      assume_score:assumeScore,
      user_id:(user != null) ? user.id : 0,
      roadmap_id:id,
      question_results:JSON.stringify(selectedQueData),
      feedback_id:0,
      in_process:0,
      assessment_id:0,
      admin_id:0,
      team_id:teamId,
      team_type:teamType,
      hiring_token:(token == undefined) ? '' : token
    };
    var result = await postAssessment(formData);
    if(result.status){
        alert("Assessment Complete - Thank You!");
        navigate('/');
    }
    else if(!result.status && result.message){
    }
    
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
    // Function to fetch data from the API
    const fetchData = async (id) => {
      setLoading(true);
      const response = await getSkills(id);
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else if(response.data){
          var result = response.data;
          setSkills(result);
          setIsQuestions(response.is_questions);
          setQuestionsCount(response.question_count);
          
          setLoading(false);
      }

      fetchRoadMapData(id); 
    };


    const fetchDataFromToken = async () => {
      setLoading(true);
      const response = await getHiringDataUsingToken(token);
      if(response.token_message){
        console.error(response.token_message);
        logout();
      } else if(!response.status){
        showToast('Assessment not found.', true);
      } else if(response.data){
          var result = response.data;
          if(result.status == 1) {
            showToast("Assessment is already completed.", false);
            navigate('/');
          } else {
            setTeamType('HIRING');
            setTeamId(result.hiring_id);
            setId(result.roadmap_id);
            fetchData(result.roadmap_id);            
          }
      } 
    };
    if(token == undefined) {
      setTeamType(location.state.teamType);
      setTeamId(location.state.teamId);
      setId(location.state.roadmapId);
      fetchData(location.state.roadmapId);
    } else {
      fetchDataFromToken();
    }
  }, []);

  return (

    <div className="padding100 h-full">
      <div className="container-fluid">
        {!loadingRoadmap && (
            <>
            <h1>{(roadmap.roadmap_name).split(' ').map(function(word) {return word[0].toUpperCase() + word.substr(1);}).join(' ')} Roadmap</h1>
        
        <p>{roadmap.roadmap_description}</p>

        {
          (assumeScore != -1) && (
          (loading) ? <h6 className="text-center">Loading...</h6> :
        (isQuestions == 0)  ? 
          <h6 className="text-center">Data not found</h6> 
          :
          <div className="table-responsive-sm table-responsive-md">
            <table className="table new_assessment_table align-middle table-sticky">
              <thead>
                <tr>
                  <th scope="col" className="tbl_header">Skill Name</th>
                  <th scope="col" className="tbl_header">Skill Question</th>
                  <th scope="col" className="tbl_header">Novice - I've never used or applied the skills and I'm completely new to the concept</th>
                  <th scope="col" className="tbl_header">Advanced Beginner - I've heard of this and understand what it is and when it's used. I might have started using and/or applying the skills, but I need a lot of hands-on assistance and guidance to do anything.</th>
                  <th scope="col" className="tbl_header">Competent -  I can access, use and apply the skill and I'm self-sufficient in its application, but I need time and access to reference materials or experts in some cases to apply the skill.</th>
                  <th scope="col" className="tbl_header">Proficient - I can do this independently and effectively with little to no dependence on experts or reference materials.</th>
                  <th scope="col" className="tbl_header">Expert - I am confident in this and can teach it effectively to others. I may be considered a SME or go-to expert and thought leader in this area</th>
                </tr>
              </thead>
              <tbody>
                {!loading && (skills.length > 0) && (
                  skills.map((skill, index) => (
                      skill.questionDetails.map((question, index) => (

                    <>
                    <tr>
                      { (index == 0) ?
                      <td scope="row" rowSpan={skill.questionDetails.length}>{skill.skill_name} - {skill.skill_definition}</td> :
                      '' }
                      
                    <td>
                        {question.skill_question}  
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="1" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,1)}     
                        className={(selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}   
                      />
                      
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="2" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,2)}     
                        className={(selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}   
                        />
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="3" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,3)} 
                        className={(selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}                                                
                        />
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="4" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,4)}                            
                        className={(selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}                                   
                        />
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="5" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,5)}      
                        className={(selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}                                                 
                        />
                    </td>
                  </tr>
                    </>
                  ))
                ))
                )}
              {!loading && (skills.length === 0) && (
                <>
                  <tr><td className="text-center" colspan="7">Data not found</td></tr>
                </>
              )}
              </tbody>
            </table>
            <div className="button-section justify-content-end">
              <button className="btn submit-button" onClick={handleSubmit}>Submit</button> 
            </div>
          </div>
          )
        }
      
      <Modal show={showModal} centered fullscreen className="assessmentModal">
        <Modal.Header closeButton onClick={closeModal}>
          <Modal.Title > Skill Assessment </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3 className="text-center">How would you rate yourself on {(roadmap.roadmap_name).split(' ').map(function(word) {return word[0].toUpperCase() + word.substr(1);}).join(' ')} Roadmap?</h3>
          <br/>
          <Row>
          {
            (roadmap.roadmap_type == "Technical") ?
            technicalLabels.map((level, index) => (
              <div className="col-sm-2 m-auto text-center">
                <Form.Check
                    inline
                    name="levels[]"
                    value={level.value}
                    type="radio"
                    id={"level_" + level.value}
                    onChange={
                      handleRateChange
                    }
                    label={level.key}
                /> 
            </div>
            ))
            :
            nonTechnicalLabels.map((level, index) => (
              <div className="col-sm-2 m-auto text-center">
                <Form.Check
                    inline
                    name="levels[]"
                    value={level.value}
                    type="radio"
                    id={"level_" + level.value}
                    onChange={
                      handleRateChange
                    }
                    label={(index == 0) ? level.key + " (Low)" : (index == 4) ? level.key + " (High)" : level.key}
                /> 
            </div>
            ))
          }
          </Row>
          <Row className="mt-5 mx-3">
            <Col md={2}></Col>
            <Col md={8}>
            <ul className="list-unstyled">
              <li className="mb-2 fw-bolder">Note : </li>
              <li className="mb-2">This assessment shouldn’t take more than 6 minutes</li>
              <li className="mb-2">At the end of the assessment, you can see your score and also a relative comparison with others who have taken this assessment.</li>
            </ul>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Modal.Body>
      </Modal>
      </>
      )}
      </div>
    </div>
  );
};

export default HiringAssessment;