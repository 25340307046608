import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DeleteModal = ({ show, deletTeam, handleCloseModal }) => {
	const [formData, setFormData] = useState('');
	const [btnDisable, setBtnDisable] = useState(true);
	const onChangeInput = (e) => {
    	setFormData(e.target.value);
    	if(e.target.value.toLowerCase() === 'delete'){
    		setBtnDisable(false);
    	}else{
    		setBtnDisable(true);
    	}
  	};
  	const closeModal = () => {
  		handleCloseModal();
  		setFormData('');
  	}
 
	return (
    <div>
    	<Modal show={show} centered>
	        <Modal.Header>
	          <Modal.Title>Delete Team</Modal.Title>
	        </Modal.Header>

	        <Modal.Body>
	          <p>This will permanently delete your team and all associated data.</p>
	          	<Form.Group controlId="delete-team" className="mb-3">
                  <Form.Label>Please type "delete" to confirm.</Form.Label>
                  <Form.Control
                    type="text"
                    name="delete-team"
                    value={formData}
                    onChange={onChangeInput}
                    placeholder="Type 'delete' to confirm"
                    
                  />
                </Form.Group>
	        </Modal.Body>

	        <Modal.Footer>
	          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
	          <Button variant="danger" disabled={btnDisable} onClick={deletTeam}>Confirm</Button>
	        </Modal.Footer>
      	</Modal>
    </div>
  );
};
export default DeleteModal;