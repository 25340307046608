import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import ProfileMenu from './profilemenu';
import DropdownMenu from './dropdownmenu';
import { UserContext } from '../contexts/UserContext';
import FilterButtons from './filterButtons';
const ProfileSkills = () => {
  const [roadmaps, setRoadmaps] = useState([]);
  const { getRoadmap, logout, getUserBookmark, user, postBookmark } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [menuSubItems, setMenuSubItems] = useState([]);
  const [item, setItem] = useState([]);
  const [bookmarkData, setBookmarkData] = useState([]);
  const [selectedRoadmapType, setSelectedRoadmapType] = useState('all');
  const [selectedRoadmapSubType, setSelectedRoadmapSubType] = useState('all');
  const [subTypeColors] = useState({
    "Technical@Role Based":"success",
    "Technical@Skill Based":"info",
    "Non Technical@Role Based":"dark",
    "Non Technical@Soft Skills":"danger",
    "Custom@Custom":"warning"
  });

 
  const filterItem = (e) => {
    const curcatItem = e.target.value;
    const newItem = [...new Set(item.filter(newVal => (curcatItem === 'all') ? (newVal.roadmap_type !== curcatItem) : (newVal.roadmap_type === curcatItem)).map(Val => (Val.roadmap_subtype)))];
    setMenuSubItems(newItem);
    setSelectedRoadmapType(e.target.value);
    filterRoadmaps('1',e.target.value);
    document.getElementById("roadmap_sub_type").selectedIndex = 'all';
  }; 

  const filterSubItem = (e) => {
    setSelectedRoadmapSubType(e.target.value);
    filterRoadmaps('2',e.target.value);
  }; 

  const filterSubTypeItem = (type,subtype) => {
    console.log(type,subtype);
    setSelectedRoadmapType(type);
    setSelectedRoadmapSubType(subtype);
    filterRoadmaps(type,subtype);
  };

  const filterRoadmaps = (type, type_value) => {
    let roadmapType = "";
    let roadmapSubType = "";
    if(type === "1") {
      roadmapType = type_value;
      roadmapSubType = 'all';
    } else if(type === "2") {
      roadmapType = selectedRoadmapType;
      roadmapSubType = type_value;
    } else if(type != "1" && type != "2") {
      roadmapType = type;
      roadmapSubType = type_value;
    } else {
      roadmapType = selectedRoadmapType;
      roadmapSubType = selectedRoadmapSubType;
    }

    const filter_type = item.filter((val) => {
        if(roadmapType === 'all'){
          return val.roadmap_type !== roadmapType;
        }else{
          return val.roadmap_type === roadmapType;
        }
    });

    const filter_sub_type = filter_type.filter((val) => {
        if(roadmapSubType === 'all'){
          return val.roadmap_subtype !== roadmapSubType;
        }else{
          return val.roadmap_subtype === roadmapSubType;
        }
    });
    setRoadmaps(filter_sub_type);
  };


  // Function to fetch data from the API
  const getBookmark = async () => {
    var user_id = user.id;
    const response = await getUserBookmark({user_id:user_id});
        if(response){
          if(response.token_message){
            console.error(response.token_message);
            logout();
          }else{
            setBookmarkData(response.data);
          }
        }
        else {
        }
  };

  // Function to post data on API
  const handlePostBookmark = async (e) => {
    var roadmap_id = e.currentTarget.getAttribute('data-id');
    var user_id = user.id;
    const response = await postBookmark({roadmap_id:roadmap_id, user_id:user_id});
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          getBookmark();
        }
      }
      else {
      }
  }; 

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    // Function to fetch data from the API
    const fetchData = async () => {

      setLoading(true);
      const response = await getRoadmap();
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          let result = response.data;
          setRoadmaps(result);
          var menuItem = [...new Set(result.map((Val) => Val.roadmap_type))];
          setMenuItems(menuItem);
          var menuSubItem = [...new Set(result.map((Val) => Val.roadmap_subtype))];
          setMenuSubItems(menuSubItem);
          setItem(result);
          setLoading(false);
        }
      }
      else {
      }
          
      getBookmark();
    };
    
    fetchData();
  }, []);

  return (
    <div className="row h-full">
      <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
      </div>
      <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
        <Container fluid>
          <Row>
            <Col>
              <h1>Plan Your Next Move!</h1>
              
            </Col>
          </Row>

          <div>
            <p>Your Favorite Roadmaps are Listed Below.</p>

          </div>
          <div className="mt-2">
          
            <FilterButtons 
              filterItem={filterItem}
              setItem={roadmaps}
              menuItems={menuItems}
              filterSubItem ={filterSubItem}
              menuSubItems = {menuSubItems}
              key="filter-btn"
            />

            <ul className="color_legend">
              <li onClick={() => filterSubTypeItem('all','all')}>
                <strong className="color_box bg-secondary">&nbsp;</strong>
                <span className="choice">All</span>
              </li>
              <li onClick={() => filterSubTypeItem('Technical','Role Based')}>
                <strong className="color_box bg-success">&nbsp;</strong>
                <span className="choice">Technical ( Role Based )</span>
              </li>
              <li onClick={() => filterSubTypeItem('Technical','Skill Based')}>
                <strong className="color_box bg-info">&nbsp;</strong>
                <span className="choice">Technical ( Skill Based )</span>
              </li>
              <li onClick={() => filterSubTypeItem('Non Technical','Role Based')}>
                <strong className="color_box bg-dark">&nbsp;</strong>
                <span className="choice">Non Technical ( Role Based )</span>
              </li>
              <li onClick={() => filterSubTypeItem('Non Technical','Soft Skills')}>
                <strong className="color_box bg-danger">&nbsp;</strong>
                <span className="choice">Non Technical ( Soft Skills )</span>
              </li>
              {/* <li onClick={() => filterSubTypeItem('Custom','Custom')}>
                <strong className="color_box bg-warning">&nbsp;</strong>
                <span className="choice">Custom</span>
              </li> */}
            </ul>
            
            {
              (loading) ? <h6 className="text-center">Loading...</h6> : ''
            }
            <Row>
            {bookmarkData.map((bookmark) => (
            roadmaps.map((roadmap) => (
                ((bookmark.roadmap_id === roadmap.roadmap_id) &&  (<Dropdown className="col-md-6 col-lg-4 col-xl-3 mt-3" key={roadmap.roadmap_id}>
                      <Dropdown.Toggle className="w-100" variant={subTypeColors[roadmap.roadmap_type+'@'+roadmap.roadmap_subtype]}>
                          {roadmap.roadmap_name}
                      </Dropdown.Toggle>
                      <DropdownMenu  roadmapId={roadmap.roadmap_id} roadmapName={roadmap.roadmap_name} roadmapSlug={roadmap.roadmap_slug} />
                      <div data-id={roadmap.roadmap_id} onClick={handlePostBookmark}  className="bookmarkRibbon bookmarked"></div>
                  </Dropdown>
                ))
              ))
            ))}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProfileSkills;