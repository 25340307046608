
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import { Container, Form, Modal, Button} from 'react-bootstrap';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const AccessRights = () => {
  const { userRoleList, logout, userRoleCreateUpdate, userRoleDetail, userRoleDelete, showToast } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const defaultModules = {
    teams: {
      view:true
    },
    history : {
      view:true
    },
    playbook : {
      view:true
    }
  }
  const [formData, setFormData] = useState({
    user_id:user.id,
    id: '',
    title: '',
    access_rights: defaultModules
  });


  const defaultModuleNames = ["teams","history","playbook"];

  const [modules, setModules] = useState(defaultModules);
    
  // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const roles_response = await userRoleList({'user_id' : user.id});
      if(roles_response){
        if(roles_response.token_message){
          console.error(roles_response.token_message);
          logout();
        }else{
          setLoading(false);
          setRoles(roles_response.data);
          if(roles_response.data.length > 0) {
            getAccessRightsData(roles_response.data[0]['id']);
          }
        }
      }
      else {
        console.error(roles_response.message);
      }
    };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const openRoleEditPopup = async (roleId = null) => {
      if(roleId != null && roleId != 0) {
          setRoleId(roleId);
          const response = await userRoleDetail(roleId);
          
          if(response.token_message){
            console.error(response.token_message);
            logout();
          }else if(response.status){
            setFormData(response.data);
            setShowModal(true);
          }
          else if(!response.status && response.message){
              showToast(response.message, true);
          }
      }else{
        setFormData({
          user_id:user.id,
          id: '',
          title: '',
          access_rights: defaultModules
        });
        setRoleId(0);
        setShowModal(true);
      }
      
  }

  const handleRoleDelete = async (id) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this role?"
    )
    if (confirmBox === true) {
      const response = await userRoleDelete(id);
      
      if(response.token_message){
          console.error(response.token_message);
          logout();
      }else if(response.status){
        setTimeout(
          () => {
            fetchData();
          }, 1000);
      }
      else if(!response.status && response.message){
          showToast(response.message, true);
      }
    }

  }

  const onChangeInput = (e) => {
      setFormData({
        ...formData,
        [e.target.name]:e.target.value
      });
      
  };
  const closeRoleModal = () => {
    setShowModal(false);
    setFormData({
      user_id:user.id,
      id: '',
      title: '',
      access_rights: defaultModules
    });
    setRoleId(0);
  }

  const saveRoleModal = async (e) => {
    e.preventDefault();
    if(formData.title == '') {
        showToast('Please enter Title!', true); return false;
      }
      const data = await userRoleCreateUpdate(formData);
      if(data.token_message){
          console.error(data.token_message);
          logout();
      }else if(data.status){
          showToast(data.message, false);
          setTimeout(
          () => {
            closeRoleModal();
            fetchData();
          }, 1000);

      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  }

  const handleCheck = (action,module_name, e) => {
    let newFormValues = modules;
      newFormValues[module_name][action] = e.target.checked;
      setModules(newFormValues);
      setFormData({
        ...formData,
        access_rights:newFormValues
      });
  }

  const saveRoleAccessRights = async () => {
    const data = await userRoleCreateUpdate(formData);
    if(data.token_message){
        console.error(data.token_message);
        logout();
    }else if(data.status){
        showToast(data.message, false);
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
  }

  const getAccessRightsData = async (role_id) => {
    const access_response = await userRoleDetail(role_id);
      if(access_response){
        if(access_response.token_message){
          console.error(access_response.token_message);
          logout();
        }else{
            var module_arr = defaultModules;
            if(access_response.data.access_rights != null){
              var module_data = access_response.data.access_rights; 
                module_arr = {teams:{
                  view:(module_data.teams.view == 1) ? true : false
                },
                history:{
                  view:(module_data.history.view == 1) ? true : false
                },
                playbook:{
                  view:(module_data.playbook.view == 1) ? true : false
                }
              };
          }
          setModules(module_arr);

          setFormData({
            ...formData,
            title:access_response.data.title,
            id:access_response.data.id,
            access_rights:module_arr
          });
        }
      }
      else {
          showToast(access_response.message, true);
      }
  }

  let handleChange = (e) => {
    getAccessRightsData(e.target.value);
  }
                
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <div className="settings-container-xl">
              <ul className="settings-nav settings-nav-borders nav nav-tabs" role="tablist">
                  <li className="nav-item">
                      <a className="settings-nav-link active ms-0" href="#role" data-bs-toggle="tab">Roles</a>
                  </li>
                  <li className="nav-item">
                      <a className="settings-nav-link" href="#access-rights" data-bs-toggle="tab">Access Rights</a>
                  </li>
              </ul>
              <hr className="settings-hr mt-0 mb-4"/>
              <div className="tab-content">
                <div className="tab-pane active" id="role">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="settings-card mb-4 mb-xl-0">
                        <div className="settings-card-header">Roles</div>
                        <div className="settings-card-body">
                          <div className="row settings-row mt-4 float-end">
                              <button className="settings-delete-btn settings-btn-primary" onClick={() => openRoleEditPopup(0)} type="button">Create</button>
                          </div>
                          <div className="p-0 pb-2 mt-4">
                            <table id="accessrights_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="40">Title</th>
                                  <th width="40">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {roles.map((role, index) => (
                                  <tr key="1">
                                    <td width="20">{index+1}</td>
                                    <td width="40">{role.title} {['ADMIN','MEMBER'].includes(role.code) ? ' ( Default ) ' : ''}</td>
                                    <td width="40">
                                      <button className={['ADMIN','MEMBER'].includes(role.code) ? 'btn btn-sm btn-success me-2 disable-div' : 'btn btn-sm btn-success me-2'}  onClick={() => openRoleEditPopup(role.id)} >
                                        Edit
                                      </button>
                                      <button className={['ADMIN','MEMBER'].includes(role.code) ? 'btn btn-sm btn-danger disable-div' : 'btn btn-sm btn-danger'} onClick={() => handleRoleDelete(role.id)} >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                  ))}
                                {(roles.length == 0) && (<tr className="text-center"><td colSpan="3">Data Not Found</td></tr>)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="access-rights">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="settings-card mb-4 mb-xl-0">
                        <div className="settings-card-header">Access Rights</div>
                        <div className="settings-card-body">
                          <div className="row">
                          {
                            ((roles.length != 0)) ? (
                            <div className="col-md-6">
                              <Form.Label>Role</Form.Label>
                              <Form.Select aria-label="All" id="roadmap_type" onChange={e => handleChange(e)}>
                                {roles.map((role, index) => (
                                  <option value={role.id} >{role.title}</option>
                                ))}
                              </Form.Select>
                            </div>
                            ) : <p className='text-center' style={{color:'red'}}>Please add role.</p>
                          }
                          </div>
                          {!loading && (
                          <>
                          {
                            ((roles.length != 0)) && (
                              <div className="p-0 pb-2 mt-4">

                                <table id="accessrights_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                                  <thead>
                                    <tr>
                                      <th>Module</th>
                                      <th>View</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(Object.values(modules)).map((row, index) => (
                                      <tr key={index}>
                                        <td>{(defaultModuleNames[index] == 'playbook') ? 'Onboard Playbook' : 
                                        (defaultModuleNames[index]).charAt(0).toUpperCase() + (defaultModuleNames[index]).slice(1)}</td>
                                        <td><Form.Check // prettier-ignore
                                            type='checkbox'
                                            id={`default-checkbox`}
                                            name='view'
                                            checked={(row.view) ? true : false}
                                            value={row.view}
                                            onChange={(e) => handleCheck('view',defaultModuleNames[index],e)}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="row settings-row mt-4">
                                    <button className="settings-delete-btn settings-btn-primary" onClick={saveRoleAccessRights} type="button">Save</button>
                                </div>
                              </div>
                            )
                          }
                          </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={showModal} centered>
              <Modal.Header>
                <Modal.Title>{roleId == 0 ? 'Create' : 'Edit'} Role</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Label>Role Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={onChangeInput}
                    placeholder="Enter Title"
                    
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button type='button' variant="outline-secondary" onClick={closeRoleModal}>Cancel</Button>
                </div>
                <div className="btn-right">
                  <Button type='button' variant="dark" onClick={saveRoleModal}>Save</Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
  );
};

export default AccessRights;
