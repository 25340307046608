// Import ChartJS correctly
import ChartJS from 'chart.js/auto'; 
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Bar, getElementAtEvent   } from "react-chartjs-2";
import ProfileMenu from "./profilemenu";
import {UserContext} from '../contexts/UserContext';

const UserRoadmapProgress = () => {
  
  const navigate = useNavigate();
  const { user, getRoadmapAssessmentHistory,getRoadmapById, getChartDataByUser, getInnerLevelChartDataByUser, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(true);

  const [thirdChartLoading, setThirdChartLoading] = useState(true);
  var tempThirdChartSkillData = [];
  tempThirdChartSkillData['label'] = [];
  tempThirdChartSkillData['data'] = [];
  const [thirdChartSkillData, setThirdChartSkillData] = useState(tempThirdChartSkillData);

  const [roadmap, setRoadmap] = useState({});
  const { roadmap_id } = useParams();

  const { user_id } = useParams();

  var staticInnerSkillData = [];
  staticInnerSkillData['label'] = [];
  staticInnerSkillData['data'] = [];
  const [innerSkillData, setInnerSkillData] = useState(staticInnerSkillData);

  var staticUserSkillData = [];
  staticUserSkillData['label'] = [];
  staticUserSkillData['data'] = [];
  const [userSkillData, setUserSkillData] = useState(staticUserSkillData);

  const [customSkillLabels, setCustomSkillLabels] = useState(["Entry Level Developer","Junior Developer","Mid Level Developer","Senior Developer","Principal Developer"]);


  const chartRef = useRef();
  const innerChartRef = useRef();


  const fetchInnerLevelData = async (name) => {
    setInnerLoading(true);
    setThirdChartLoading(true);
    const res = await getInnerLevelChartDataByUser({name:name, user_id:(user_id ? user_id : user.id)});
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){ 
      
      if(res.data.skill_level.length > 0) {  
        setCustomSkillLabels(res.data.skill_level);
      } else {
        setCustomSkillLabels(customSkillLabels);
      }

      var tempData = [];
      tempData['label'] = res.data.labels;
      tempData['data'] = Object.values(res.data.data);
      setInnerSkillData(tempData);
      setRoadmap(res.data.roadmap);


      setInnerLoading(false);
    }
  };

  const fetchThirdChartData = async (date) => {
    setThirdChartLoading(true);
    const res = await getRoadmapAssessmentHistory({roadmap_id:roadmap.roadmap_id, user_id:(user_id ? user_id : user.id), date:date});
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){ 
      var tempData = [];
      tempData['label'] = res.data.assessment_history.skill_labels;
      tempData['data'] = Object.values(res.data.assessment_history.skill_data);
      setThirdChartSkillData(tempData);
      setThirdChartLoading(false);
    }
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
     // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const res = await getChartDataByUser({user_id:(user_id ? user_id : user.id)});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){ 
        var tempStaticUserSkillData = [];
        tempStaticUserSkillData['label'] = res.data.labels;
        tempStaticUserSkillData['data'] = res.data.data;
        setUserSkillData(tempStaticUserSkillData);
          setLoading(false);
      }else{
      }


      if(roadmap_id !== undefined && roadmap_id !== 'undefined') {
        const res1 = await getRoadmapById({id:roadmap_id});
        if(res1.token_message){
          console.error(res1.token_message);
          logout();
        }else if(res1){ 
          setRoadmap(res1.data);
          setTimeout(() => {
            fetchInnerLevelData(res1.data.roadmap_name)
          }, 1000);
        }
      }
      
      
    };
    fetchData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleProceed = (event) => {
    var index = getElementAtEvent(chartRef.current, event);
    if(index.length > 0) {
      index = index[0].index;  
      fetchInnerLevelData(userSkillData['label'][index]);
    }
  };

  const handleInnerChartProceed = (event) => {
    var index = getElementAtEvent(innerChartRef.current, event);
    if(index.length > 0) {
      index = index[0].index;  
      fetchThirdChartData(innerSkillData['label'][index]);
    }
  };


  // Sample data for the horizontal bar chart
  const barChartData = {
    labels: userSkillData['label'],
    datasets: [
      {
        label: 'Roadmaps',
        data: userSkillData['data'],
        backgroundColor: "rgba(53, 162, 235, 0.5)"
      }
    ],
  };
  
  const barChartOptions = {
    indexAxis: "x", // Make the bars horizontal
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  // Sample data for the horizontal bar chart
  const levelBarChartData = {
    labels: innerSkillData['label'],
    datasets: [
      {
        label: customSkillLabels[0],
        data: innerSkillData['data'][0],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: customSkillLabels[1],
        data: innerSkillData['data'][1],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: customSkillLabels[2],
        data: innerSkillData['data'][2],
        backgroundColor: 'rgba(255, 99, 71, 0.5)',
      },
      {
        label: customSkillLabels[3],
        data: innerSkillData['data'][3],
        backgroundColor: 'rgba(205, 174, 71, 0.5)',
      },
      {
        label: customSkillLabels[4],
        data: innerSkillData['data'][4],
        backgroundColor: 'rgba(205, 174, 187, 0.5)',
      },
    ],
  };
  
  const levelBarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
    scales: {
       y: {
         display: true,
         scaleLabel: {
           display: true,
           labelString: 'Value'
         },
         ticks: {
           min: 0,
           max: 5,
           stepSize: 1,
           suggestedMin: 0.5,
           suggestedMax: 5.0,
           callback: function(label, index, labels) {
             switch (label) {
               case 0:
                 return '';
               case 1:
                 return customSkillLabels[0];
               case 2:
                 return customSkillLabels[1];
               case 3:
                 return customSkillLabels[2];
               case 4:
                 return customSkillLabels[3];
               case 5:
                 return customSkillLabels[4];
                 default:
                  return '';
             }
           }
         },
         gridLines: {
           display: false
         }
       }
     },
  };

  
  const thirdBarChartOptions = {
    indexAxis: "y", // Make the bars horizontal
    scales: {
        x: {
            display: true,
            scaleLabel: {
            display: true,
            labelString: 'Value'
            },
            ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
            suggestedMin: 0.5,
            suggestedMax: 5.0,
            callback: function(label, index, labels) {
                switch (label) {
                case 0:
                    return '';
                case 1:
                    return 'Novice';
                case 2:
                    return 'Advanced Beginner';
                case 3:
                    return 'Competent';
                case 4:
                    return 'Proficient';
                case 5:
                    return 'Expert';
               default:
                  return '';
                }
            }
            },
            gridLines: {
            display: false
            }
        }
    },
  };

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <h1>Track Progress</h1>
            {!loading && (
                <div className="row">
                  <Bar ref={chartRef} data={barChartData} options={barChartOptions} onClick={handleProceed} />
        
                  {!innerLoading && (
                  <>
                    <h2>Skills by Levels</h2>
                    <Bar ref={innerChartRef} data={levelBarChartData} options={levelBarChartOptions} onClick={handleInnerChartProceed} />
                    </>
                  )}
        
                  {!thirdChartLoading && (
                  <>
                    <h2>History of Skills</h2>
                    
                      <Bar data={{
                        labels: thirdChartSkillData.label,
                        datasets: [
                        {
                            label: 'Skills',
                            data: thirdChartSkillData.data,
                            backgroundColor: "rgba(53, 162, 235, 0.5)"
                        }
                        ],
                    }} options={thirdBarChartOptions} />
                    </>
                  )}
                </div>
            )}
          </Container>
        </div>
      </div>
  );
};

export default UserRoadmapProgress;