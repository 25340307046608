import React, {useContext, useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Container, Row } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamGrpSidebar from "./teamGrpSidebar";
import DeleteModal from "./deleteModal";
const SettingStdGrp = () => {

  const { getStudyTeamDetails, logout, updateStudyGroup, deleteStudyGroup, showToast} = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    group_name:'',
    group_description:''
  });
  const [showModal, setShowModal] = useState(false);

  

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  const fetchTeamData = async () => {
    const team_response = await getStudyTeamDetails(id);
    if(team_response){
      if(team_response.token_message){
        console.error(team_response.token_message);
        logout();
      }else{
        setFormData({
            group_id:id,
            group_name:team_response.data.group_name,
            group_description:team_response.data.group_description
        });
      }
    }
    else {
      showToast(team_response.message, true);
    }
  };
  
  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.group_name === "") {
        showToast("Please enter group name", true); return false;
      }

      const data = await updateStudyGroup(formData);
      if(data.status){
          e.target.reset();
          fetchTeamData();
          showToast("Updated successfully!", false);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };
  const openDeleteModal = async (e) => {
    setShowModal(true);
  }
  const deletTeam = async (e) => {

    const response = await deleteStudyGroup(id);
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          console.log(response);
           navigate("/profile-teams");
        }
      }
      else {
          showToast(response.message, true);
      }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    const fetchData = async () => {
      const team_response = await getStudyTeamDetails(id);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setFormData({
              group_id:id,
              group_name:team_response.data.group_name,
              group_description:team_response.data.group_description
          });
        }
      }
      else {
        showToast(team_response.message, true);
      }
    };

    fetchData();
  }, []);
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamGrpSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      <div className="settings-card">
                        <div className="settings-card-header">Update Study Group Information</div>
                        <div className="settings-card-body">
                        <Form onSubmit={handleSubmit} >
                          <Form.Group controlId="group_name" className="mb-3">
                            <Form.Label>Group Name*</Form.Label>
                            <Form.Control
                              type="text"
                              name="group_name"
                              value={formData.group_name}
                              placeholder="Group Name"
                              onChange={onChangeInput}
                            />
                          </Form.Group>

                          <Form.Group controlId="group_description" className="mb-3">
                            <Form.Label>Group Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="group_description"
                              value={formData.group_description}
                              placeholder="Group Description"
                              onChange={onChangeInput}
                            />
                          </Form.Group>
                          <div className="button-section">
                              <button className="button submit" type="submit">Save</button>
                          </div>

                          <div className="row settings-row">
                              <hr/>
                              <h2>Delete Team</h2>
                              <p>Permanently delete this team and all of its resources.</p>
                              <button className="settings-delete-btn settings-btn-primary" onClick={openDeleteModal} type="button">Delete Team</button>
                          </div>
                        </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteModal show={showModal} deletTeam={deletTeam} handleCloseModal={handleCloseModal} />
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default SettingStdGrp;