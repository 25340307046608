import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Radar } from 'react-chartjs-2';
import { Row, Col, Form, Container } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamGrpSidebar from "./teamGrpSidebar";
const ProgressGroup = () => {
  const {logout, getStudyTeamDetails, getLoggedInUser, getTeamsRoadmaps, getRoadmapsSpiderChartData} = useContext(UserContext);
  const [errMsg, setErrMsg] = useState('');
  const [theLoginUser, setLoginUser] = useState({email:""});
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedMembers, setAcceptedMembers] = useState([]);
  const [roles, setRoles] = useState([]);

  
  const [chartLoading, setChartLoading] = useState(false);
  const [roadmaps, setRoadmaps] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  
  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: '',
        data: chartData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const handleHrefRedirect = (href,id) => {
    navigate(href+id);
  };
  
  const fetchSpiderData = async (roadmap_id) => {
    const res = await getRoadmapsSpiderChartData({roadmap_id:roadmap_id,team_id:id,type:"STUDYGROUP"});
    if(res){
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else{
        let labels = res.data.labels;
        let data = res.data.data;
        setChartLabels(labels);
        setChartData(data);
        setChartLoading(false);
      }
    }
    else {
        setErrMsg(res.message);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    const fetchData = async () => {
      const user_response = await getLoggedInUser();
      setLoginUser(user_response);
  
      //
      const team_response = await getStudyTeamDetails(id);
      console.log(team_response);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setAcceptedMembers(team_response.data.accepted_members_results);
          setRoles(team_response.roles);
          setLoading(false);
        }
      }
      else {
          setErrMsg(team_response.message);
      }
      

      const res = await getTeamsRoadmaps({team_id:id,type:"STUDYGROUP"});
      if(res){
        if(res.token_message){
          console.error(res.token_message);
          logout();
        }else{
          setRoadmaps(res.data);
          if(res.data.length > 0) {
            fetchSpiderData(res.data[0].roadmap_id);
          }
          setChartLoading(false);
        }
      }
      else {
          setErrMsg(res.message);
      }
    };

    fetchData();
  }, []);

  let handleChange = (e) => {
    fetchSpiderData(e.target.value);
  }

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamGrpSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      
                        <div className="settings-card">
                          
                          {
                          (!chartLoading) ? 
                          <div>
                            <div className="settings-card-header">Team Memeber Progress</div>
                            <div className="settings-card-body">
                            <Form.Label>Select Roadmap</Form.Label>
                            <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleChange(e)}>
                              {roadmaps.map((roadmap) => (
                                <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                              ))}
                            </Form.Select>

                            {
                              (chartLabels.length > 0) ? 
                              (chartData.reduce((a, b) => a + b, 0) != 0) ? 
                              <div style={{height:"800px",width:"800px",margin:"auto"}} className="proccessChart"><Radar data={data} /></div>
                              : <div style={{textAlign: "center", marginTop: "50px"}}>Reporting will be done when users take the skill assessment.</div>
                              : <div style={{textAlign: "center", marginTop: "50px"}}>No skill found</div>
                            }
                            
                            </div>
                          </div>
                          : ''
                          }

                          {
                          (!loading) ? 
                          <div>
                            {/* <div className="settings-card-header">Team Memeber Progress</div> */}
                            <div className="settings-card-body">
                                <Row>
                                    {acceptedMembers.map((element, index) => (
                                    <Col sm={12} md={12} lg={4}>
                                        <div className="h-full min-h-270 overflow-hidden rounded-3 border">
                                          <div className="position-relative d-flex gap-3 border-bottom p-3">
                                            <img src="/img/default-avatar.png" alt="Dev" className="h-8 min-h-32 w-8 min-w-32 rounded-circle"/>
                                            <div className="inline-grid w-100 profile">
                                              <div className="d-flex justify-content-between items-center ">
                                                <h3 className="truncate font-medium">{element.fname + " " + element.lname}</h3>
                                                {
                                                  (element.email  ===  theLoginUser.email) ? <span className="rounded-3 bg-danger px-1 py-0.5 text-xs text-white">You</span> : ''
                                                }
                                              </div>
                                              <p className="truncate fs-6">{element.email}</p>
                                            </div>
                                          </div>
                                          <div className="position-relative d-flex grow flex-column p-3">
                                          roadmaps
                                          {element.roadmaps.map((roadmap, roadmap_index) => (
                                            <button className="group position-relative overflow-hidden rounded-3 border p-2 hover:border-gray-300 hover:text-black focus:outline-none btn btn-outline-secondary" onClick={() => handleHrefRedirect("/user-roadmap-progress/" , roadmap.roadmap_id + "/" + element.user_id)}>
                                              <span className="position-relative z-10 d-flex align-items-center justify-content-between text-sm">
                                                <span className="inline-grid">
                                                  <span className="truncate">{roadmap.roadmap_name}</span>
                                                </span>
                                                <span className="ml-1.5 shrink-0 text-xs text-gray-400 fn-12">{roadmap.score} / 5</span>
                                              </span>
                                              <span className="position-absolute inset-0 bg-gray-100 group-hover:bg-gray-200" style={{ width: '0%' }}></span>
                                            </button>
                                          ))}
                                          {/* <button className="text-sm text-gray-400 border-0 text-decoration-underline bg-transparent">+ 3 more</button> */}
                                          </div>
                                        </div>
                                    </Col>          
                                    ))}
                                </Row>
                            </div>
                          </div>
                          : ''
                          }

                          {
                            (successMsg) ?  
                            <div>
                              <div className="settings-card-header">Team Created</div>
                              <div className="settings-card-body">
                                <div className="mb-1 flex flex-col items-center">
                                  <p className="text-sm text-gray-700 add_member_success_text">Your team has been updated. Happy learning!</p>
                                </div>
                              </div>
                            </div>
                            : ''
                          }

                      </div>
                    </div>
                  </div>
                      
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default ProgressGroup;