import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ReactQuill from 'react-quill'

export const PlaybookArticleUpdateModal = ({ show, handleCloseModal, fetchData, playbookModalId }) => {
  const {onboardingPlaybookArticleCreateUpdate, logout, onboardingPlaybookArticleDetail} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [errModalMsg, setErrModalMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errFileMsg, setErrFileMsg] = useState('');
  const [checkFileExt, setCheckFileExt] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [formData, setFormData] = useState({
    id:0,
    onboarding_playbook_id:'',
    title:'',
    content:'',
    url_code:'',
    publish:0,
    mark_as_feature:0,
    status:0,
    file:'',
    parents_article:'',
    tags:'',
    seo:0,
    seo_title:'',
    seo_description:'',
    selected_parent_article:'',
    parent_articles_for_dropdown:[]
  });

  // Use useEffect to fetch data when the component mounts
  useEffect( async () => {
    const response = await onboardingPlaybookArticleDetail(playbookModalId);
      if(response){
          if(response.token_message){
              console.error(response.token_message);
              logout();
          }else{
              setFormData(response.data);
              var file_url = response.data.file;
              var file_name = file_url.split('/').pop()
              setFileName(file_name);
               var file_ext = file_url.split('.').pop();
              var fileExt = ['jpg','png','gif','jpeg'];
              if( fileExt.indexOf( file_ext.toLowerCase()) > -1 ) {
                 setCheckFileExt(true);
              }
              else{
                 setCheckFileExt(false);
              }
          }
      }
      else {
          setErrModalMsg(response.message);
      }
  }, []);

  const closeModal = () => {
    setSuccessMsg('');
    setErrModalMsg('');
    fetchData(playbookModalId);
    handleCloseModal();
  }

  const onChangeInput = (e) => {
    if(e.target.name == 'publish' || e.target.name == 'mark_as_feature' || e.target.name == 'seo') {
        const { checked } = e.target;
        if (checked) {
            setFormData({
                ...formData,
                [e.target.name]:1
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]:0
            });
        }
    } else {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        });
    }
    
  };

  const checkFileExtens = (file) => {
    var fileExt = ['jpg','png','gif','jpeg','svg'];
      var dt = new DataTransfer();
      var get_ext = file.name.split('.');
      get_ext = get_ext.reverse();
      if( fileExt.indexOf( get_ext[0].toLowerCase()) > -1 ) {
          setFileUrl(URL.createObjectURL(file));
          return true;
      }
      else{
          setFileUrl('');
          return false;
      }
  }
  const onChangeInputFile = (e) => {
    if (e.target.files) {
        var file = e.target.files[0];
        var checkFileExt = checkFileExtens(file);
        var fileExt = ['jpg','png','gif','jpeg','svg','pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'html', 'css', 'js', 'zip'];
          var get_ext = file.name.split('.');
          get_ext = get_ext.reverse();
          var file_size = file.size;
          var fileSize = Math.round((file_size / 1024));
          if( fileExt.indexOf( get_ext[0].toLowerCase()) > -1 ) {
              if(fileSize > 2048){
                  setErrFileMsg("File too Big, please select a file less than 2MB");
                  setFormData({
                    ...formData,
                    [e.target.name]:''
                  });
                  return false;
              }
              setFileName('');
              setErrFileMsg('');
              setFormData({
                ...formData,
                [e.target.name]:e.target.files[0]
              });
              setCheckFileExt(checkFileExt);
          } else{
              setFileName('');
              setFormData({
                ...formData,
                [e.target.name]:''
              });
              setErrFileMsg("You're only allowed to upload jpg,png,gif,jpeg,svg or pdf,doc,docx,xls,xlsx,txt,html,css,js,zip files.");
          }
    }
  }

  const saveModal = async (e) => {
    e.preventDefault();
 
    if(formData.title == '' || formData.url_code == '') {
      setErrModalMsg('Please enter all mandatory fields!'); return false;
    }else {
      setErrModalMsg(false);
    }
    setSuccessMsg('');
    setErrModalMsg('');

    formData.last_updated_by = user.id;
   
    let data = await onboardingPlaybookArticleCreateUpdate(formData);
    if(data.status){
        setSuccessMsg(data.message);
        setErrModalMsg(false);
        
        setTimeout(
            () => {
              closeModal();
        },1000);
    }
    else if(!data.status && data.message){
      setErrModalMsg(data.message);
    }
  }

    var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "align", "size",
  ];
  const handleProcedureContentChange = (content) => {
      setFormData({
        ...formData,
        ['content']:content
      });
  };
	return (
    <div>
    	<Modal show={show} size={'lg'} className="come-from-modal right" id="largeModal">
          <Modal.Header>
              <button type="button" className="btn-close close" data-dismiss="modal" aria-hidden="true" onClick={closeModal}>&times;</button>
              <Modal.Title id="myModalLabel" className="modal-title">Edit Article</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
             <div className="col-md-8 mb-5 pb-3">
                <div className="form-group">
                   <input id="title" placeholder="Title" type="text" name="title" className="form-control" value={formData.title} onChange={onChangeInput}/> 
                </div>
                <div className="form-group">
                   {/*<textarea id="description" placeholder="Article content" name="content" className="form-control article_description"></textarea>*/}
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="Article content"
                        onChange={handleProcedureContentChange}
                        style={{ height: "200px" }}
                        value={formData.content}
                    >
                    </ReactQuill>
                </div>
             </div>
             <div className="col-md-4">
                <div className="form-group">
                   <label htmlFor="header_backgraound" className="form__label">
                   Url Slug<sup style={{color:'red'}}>*</sup>
                   </label> <input id="code" placeholder="Url Slug" type="text" name="url_code" className="form-control" value={formData.url_code} onChange={onChangeInput} readOnly/>
                </div>
                <div className="form-group form-check">
                   <div className="checkbox-inline"><input id="exampleCheck1" name="publish" type="checkbox" true-value="1" false-value="0" className="form-check-input" checked={formData.publish == 1 ? true : false } onChange={onChangeInput} /> 
                    <label htmlFor="exampleCheck1" className="form-check-label">
                      Published
                    </label>
                   </div>
                </div>
                <div className="form-group form-check">
                   <div className="checkbox-inline">
                    <input id="exampleCheck2" name="mark_as_feature" type="checkbox" true-value="1" false-value="0" className="form-check-input" checked={formData.mark_as_feature == 1 ? true : false } onChange={onChangeInput}/> 
                    <label htmlFor="exampleCheck2" className="form-check-label">
                      Mark as Featured
                    </label>
                   </div>
                   <div className="form-input__description">
                      Mark the article as featured (display a star beside article)
                   </div>
                </div>
                <div className="form-group">
                    <div className="flw-attach__list">
                        { (formData.file != '') && (
                        <div className="flw-icon-with-text flw-portlet flw-portlet--light flw-attach__item" style={{cursor: "pointer"}}>
                            {/* <div className="flw-attach__item-actions" >
                                <a  target="_blank" className="flw-attach__action-icon margin-right-10">
                                    <i className="fa fa-download"></i>
                                </a>
                            </div>  */}
                            {/*<div className="flw-icon-with-text__icon flw-attach__item-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg> 
                            </div>*/}
                            {checkFileExt && (
                              <div className="flw-icon-with-text__icon flw-attach__item-icon">
                                  <img src={(fileUrl != '') ? fileUrl : formData.file} className="img-fluid" />
                              </div>
                            )}
                            {!checkFileExt && (
                              <div className="flw-icon-with-text__icon flw-attach__item-icon" style={{backgroundImage: "url(/img/icon/file.svg)" }}>
                              </div>
                            )}
                            <div className="flw-icon-with-text__content text-overflow">
                                <div className="flw-icon-with-text__content-title flw-attach__item-title text-overflow">
                                {(fileName != '') ? fileName : formData.file.name}
                                </div> 
                                <div className="progress flw-attach-progress" style={{opacity: "0"}}>
                                    <div className="progress-bar flw-attach-progress-bar" style={{width: "0px"}}></div>
                                </div>
                            </div>
                            <div style={{display: "none"}}></div>
                        </div>
                        )}
                        <div className="flw-icon-with-text flw-portlet flw-portlet--light flw-attach__item flw-attach-btn">
                            <label htmlFor="fileupload">       
                                <div className="flw-attach-btn__text"><i className="material-icons md-24 notranslate fa fa-paperclip" style={{marginRight: "8px"}}></i> 
                                    Attach File
                                </div> 
                                <input type="file" name="file" style={{display: "none"}} id="fileupload" onChange={onChangeInputFile}  />
                            </label>
                            <div className="flex--spacer"></div> 
                            
                        </div>
                    </div>
                     <span id="articleFile_error" className="text-danger">{errFileMsg}</span>
                </div>
                <div className="form-group">
                   <label htmlFor="header_backgraound" className="form__label">
                   Parent Articles
                   </label> 
                   <Form.Select className="form-control parent-article"  id="parent_article" name="parent_article" onChange={onChangeInput} >
                   <option value="">Select </option>
                    {
                        formData.parent_articles_for_dropdown.map((row,index) => (
                          <option value={row.id} selected={(row.id == formData.selected_parent_article) ? true : false}>{row.title} </option>
                        ))
                    }
                   </Form.Select>
                   
                </div>
                <div className="form-group">
                   <div className="checkbox-inline">
                        <input id="exampleCheck3" name="seo" type="checkbox" value="1" className="form-check-input" checked={formData.seo == 1 ? true : false } onChange={onChangeInput}/>
                        <label htmlFor="exampleCheck3" className="form-check-label">SEO</label> 
                   </div>
                </div>

                {
                  (formData.seo == 1) && (
                      <div className="seo-section">
                          <div className="form-group">
                              <label className="form__label" htmlFor="seo_title">
                                  Title HTML-document
                              </label>
                              <input className="form-control" placeholder="Title HTML-document" type="text" name="seo_title"  value={formData.seo_title}
                          onChange={onChangeInput} />
                          </div>
                          <div className="form-group">
                              <label className="form__label" htmlFor="seo_description">
                                  Meta Description
                              </label>
                              <textarea className="form-control"  placeholder="Meta Description" name="seo_description" value={formData.seo_description}
                          onChange={onChangeInput} >
                              </textarea>
                          </div>
                      </div>
                  )
              }
             </div>
            </div>
            
            {successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
            {errModalMsg && <h4 className="err-msg text-danger text-center">{errModalMsg}</h4>}
          </Modal.Body>
          

          <Modal.Footer className="justify-content-between">
            <div className="btn-left">
              <Button variant="outline-secondary" onClick={closeModal}>Cancel</Button>
            </div>
            <div className="btn-right" onClick={saveModal}>
              <Button variant="dark">Save</Button>
              {/* <Button variant="outline-secondary mx-2">Save as placeholder</Button>
              <Button variant="dark">Continue to Editor</Button> */}
            </div>
          </Modal.Footer>
        </Modal>
    </div>
  );
};