
import React, { useState, useEffect } from "react";

//import { Navigation } from "../components/navigation";
import { Term } from "../components/terms";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import {Helmet} from "react-helmet";
import JsonMetaData from "../data/metaData.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
 
const Terms = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [pageMetaData, setPageMetaData] = useState(JsonMetaData);
    useEffect(() => {
      setLandingPageData(JsonData);
      //  const script = document.createElement("script");
      //   script.src = "../js/metaTag.js";
      //   script.async = true;
      //   document.body.appendChild(script);
    }, []);
  
    return (
        <div>
            <Helmet>
              <title>{pageMetaData.terms.title}</title>
              <meta name="description" content={pageMetaData.terms.description} />
              <meta name="keywords" content={pageMetaData.terms.keywords} />
              <meta name="author" content={pageMetaData.terms.author} />
              <meta property="og:title" content={pageMetaData.terms.og_title} />
              <meta property="og:description" content={pageMetaData.terms.og_description} />
              <meta property="og:image" content={pageMetaData.terms.og_image} />
              <meta property="og:url" content={pageMetaData.terms.og_url} />
            </Helmet>
            <Term data={landingPageData.About} />
      </div>
    );
};
 
export default Terms;

