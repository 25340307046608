import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu
} from "react-pro-sidebar";
import { Dropdown } from "react-bootstrap";
import { FaHistory, FaRegEdit, FaRegHeart, FaUsers, FaChartLine, FaEnvelope, FaUserTie, FaBookReader, FaWrench } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle, FiShare2 } from "react-icons/fi";
import { BiCog } from "react-icons/bi";
import {UserContext} from '../contexts/UserContext';
import "react-pro-sidebar/dist/css/styles.css";


const ProfileMenu = () => {

  const [menuCollapse, setMenuCollapse] = useState(false);

  const {logout, getSingleUserDetail, updateSingleUserDetail, showToast } = useContext(UserContext); 
  const user =  JSON.parse(localStorage.getItem('userData')); 

  const location = useLocation();


  const userTeamData =  (localStorage.getItem('userTeamData') != null) ? JSON.parse(localStorage.getItem('userTeamData')) : [];

  const [currentTeams, setCurrentTeams] = useState({});
  const [teamSwitchLoading, setTeamSwitchLoading] = useState(false);
  const [formTeamData, setTeamFormData] = useState({
      id:'',
      last_login_team_id:0
  });

  const [teams] = useState(userTeamData);


  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  const navigate = useNavigate();

  const handleHrefRedirect = (href) => {
    if(href === 'support' ) {

      if(user != null && user.login_user_type === 'ADMIN'){

        href = '/queries'; // Navigate to the "history" page

      }else{

        href = '/support'; // Navigate to the "history" page
      }

    } 
    
    navigate(href);
  };


  const handleLogout = async () => {
    const res = await logout({user_id:user.id});
  }


  const getSingleUserDetailData = async(last_login_team_id_updated = 0) => {
    const res = await getSingleUserDetail(user.id);
      if(res.token_message){
          console.error(res.token_message);
          logout();
      }else if(res){
          setTeamFormData(res.data);
          res.data.login_user_type = "USER";
          localStorage.setItem('userData', JSON.stringify(res.data));
          let current_team = (teams).filter((val) => {
              if(res.data.last_login_team_id == val.id){
                return val;
              }
          });
          setCurrentTeams(current_team[0]);

          if(last_login_team_id_updated == 1) {
            setTimeout(() => {
              navigate("/profile");
            },1000);
          }
      }else{
      }
  }

  const saveUser = async (formTeamData, last_login_team_id_updated = 0) => {
      setTeamSwitchLoading(true);
      let data = await updateSingleUserDetail(formTeamData);
      if(data.status){
          setTeamSwitchLoading(false);
          showToast("Profile switched successfully", false);
          getSingleUserDetailData(last_login_team_id_updated);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  }

  const onChangeInput = async (e, team_id, last_login_team_id_updated = 0) => {
      setTeamFormData({
        ...formTeamData,
        last_login_team_id:team_id
      });
      formTeamData.last_login_team_id = team_id;
      saveUser(formTeamData, last_login_team_id_updated);
  };

   

  useEffect(() => {
    if((user != null) && (user.login_user_type !== 'ADMIN')) {
      getSingleUserDetailData(0);
    }
  }, []);


  return (
    <div id="header" className={`sidebar_profile ${menuCollapse ? "collapsed" : '' } `}>
      <ProSidebar collapsed={menuCollapse}>
        <SidebarHeader>
          <div className="logotext web-text">
            { (user != null) ? (!menuCollapse ? <h1>{user.fname} {user.lname}</h1> : <h1>{(user.fname).substring(0, 1)}{(user.lname).substring(0, 1)}</h1>) : '' }
          </div>
           <div className="logotext responsive-text">
            { (user != null) ? <h1>{user.fname} {user.lname}</h1> : '' }
          </div>
          <div className="closemenu" onClick={menuIconClick}>
            {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
          </div>
        </SidebarHeader>
        <SidebarContent>
            
          {(user != null) && (user.login_user_type !== 'ADMIN') && (
            <>
              <div style={{textAlign: 'center'}}>
              {
                (teamSwitchLoading) ?
                (<Dropdown data-bs-theme="primary" className="header_dropdown">
                  <Dropdown.Toggle variant="primary">
                    <span className="team_role">Loading...</span>
                  </Dropdown.Toggle>
                </Dropdown>) 
                :
                  (teams.length > 0) &&
                  (<Dropdown data-bs-theme="primary" className="header_dropdown">
                    <Dropdown.Toggle variant="primary">
                      {(currentTeams !== undefined) && <> <span className="team_role">{currentTeams.name}</span> </> }
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu className="bg-primary">
                        {teams.map((team, index) => (
                          <a className=" border-bottom text-white dropdown-item" href="javascript:void(0);" onClick={e => onChangeInput(e, team.id, 1)} data-rr-ui-dropdown-item=""><h6><strong>{team.name}</strong></h6> <span className="team_role">({team.parent_name}) 
                          <br/>({team.role_title})</span></a>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>) 
                }
              </div>
              <Menu iconShape="circle">
                <MenuItem icon={<FiHome />} onClick={() => handleHrefRedirect('/profile')} active={(location.pathname == '/profile' ? true : false)}> Home</MenuItem>
                <MenuItem icon={<FiShare2 />} onClick={() => handleHrefRedirect('/profile-skills')} active={(location.pathname == '/profile-skills' ? true : false)}>Roadmaps</MenuItem>
                <MenuItem icon={<FaUserTie />} onClick={() => handleHrefRedirect('/profile-teams')} active={(location.pathname == '/profile-teams' ? true : false)}>Teams</MenuItem>
                
                <MenuItem icon={<FaBookReader />} onClick={() => handleHrefRedirect('/onboard-playbook')} active={(location.pathname == '/onboard-playbook' ? true : false)}>Onboarding Playbook</MenuItem>
                
                
                {/* <MenuItem icon={<FaUsers />} onClick={navigateToTeamsProcess}>Team Process</MenuItem> */}
                {/*
                <MenuItem icon={<FaRegEdit />} onClick={navigateToOrganization} active={(location.pathname == '/profile-organization' ? true : false)}>Organization <br/>Progress</MenuItem> */}
                <MenuItem icon={<FaChartLine />} onClick={() => handleHrefRedirect('/track-progress')} active={(location.pathname == '/track-progress' ? true : false)}>Track Progress</MenuItem>
                {/* <SubMenu
                    title="Progress"
                    defaultOpen={((location.pathname == '/track-progress' || location.pathname == '/profile-organization') ? true : false)}
                    active={((location.pathname == '/track-progress' || location.pathname == '/profile-organization') ? true : false)}
                    icon={<FaRegEdit />}
                >
                  <MenuItem onClick={navigateToTrackProgress} active={(location.pathname == '/track-progress' ? true : false)}>Track Progress</MenuItem>
                  <MenuItem onClick={navigateToOrganization} active={(location.pathname == '/profile-organization' ? true : false)}>Organization <br/>Progress</MenuItem>
                </SubMenu> */}
                <MenuItem icon={<FaRegHeart />}  onClick={() => handleHrefRedirect('/profile-favorite')} active={(location.pathname == '/profile-favorite' ? true : false)}>Favorite</MenuItem>
                <MenuItem icon={<FaHistory />} onClick={() => handleHrefRedirect('/profile-history')} active={(location.pathname == '/profile-history' ? true : false)}>History</MenuItem>
                
                
                <MenuItem icon={<FaEnvelope />} onClick={() => handleHrefRedirect('/support')} active={(location.pathname == '/support' ? true : false)}>Support</MenuItem>
                {/* <MenuItem icon={<BiCog />} onClick={navigateToSettings} active={(location.pathname == '/profile-settings' ? true : false)}>Settings</MenuItem> */}


                <SubMenu
                    title="Settings"
                    defaultOpen={((location.pathname == '/profile-settings' || location.pathname == '/accessrights') ? true : false)}
                    active={((location.pathname == '/profile-settings' || location.pathname == '/accessrights') ? true : false)}
                    icon={<BiCog />}
                >
                  <MenuItem onClick={() => handleHrefRedirect('/profile-settings')} active={(location.pathname == '/profile-settings' ? true : false)}>Settings</MenuItem>
                  <MenuItem onClick={() => handleHrefRedirect('/accessrights')} active={(location.pathname == '/accessrights' ? true : false)}>Access Rights</MenuItem>
                </SubMenu>
              </Menu>
            </>
          )}
          {(user != null) && (user.login_user_type === 'ADMIN') && (
            <Menu iconShape="circle">
              <MenuItem icon={<FiHome />} onClick={() => handleHrefRedirect('/profile')} active={(location.pathname == '/profile' ? true : false)}> Home</MenuItem>
              <MenuItem icon={<FaEnvelope />} onClick={() => handleHrefRedirect('/queries')} active={(location.pathname == '/queries' ? true : false)}>Support Tickets</MenuItem>
              <MenuItem icon={<FaUsers />} onClick={() => handleHrefRedirect('/users')} active={(location.pathname == '/users' ? true : false)}>Users</MenuItem>
              <MenuItem icon={<FaUserTie />} onClick={() => handleHrefRedirect('/organization')} active={(location.pathname == '/organization' ? true : false)}>Organization</MenuItem>
              <MenuItem icon={<FiShare2 />} onClick={() => handleHrefRedirect('/roadmaps')} active={(location.pathname == '/roadmaps' ? true : false)}>Roadmaps</MenuItem>
              <MenuItem icon={<FaBookReader />} onClick={() => handleHrefRedirect('/onboard-playbook')} active={(location.pathname == '/onboard-playbook' ? true : false)}>Onboarding Playbook</MenuItem>
              {/* <SubMenu
                title="Settings"
                defaultOpen={((location.pathname == '/accessrights') ? true : false)}
                active={((location.pathname == '/accessrights') ? true : false)}
                icon={<FaWrench />}
            >
              <MenuItem onClick={navigateToAccessRights} active={(location.pathname == '/accessrights' ? true : false)}>Access Rights</MenuItem>
            </SubMenu> */}
            </Menu>
            )}
        </SidebarContent>
        
        <SidebarFooter>
          <Menu iconShape="circle">
            <MenuItem icon={<FiLogOut />} onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default ProfileMenu;