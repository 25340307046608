
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import { Container} from 'react-bootstrap';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const ProfileOrganization = () => {
  const { user, getOrganization, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState([]);
  const navigate = useNavigate();
  
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const res = await getOrganization();
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){
          setOrganization(res.data);
          setLoading(false);
      }else{
      }
      setTimeout(() => { 
        $("#organization_table").DataTable({
          pagingType: "full_numbers",
          pageLength: myConstent.DATATABLERECORDLIMIT,
          processing: true,
          stateSave: true,
          bDestroy: true,
          scrollX: true,
          dom: "Bfrtip",
          select: {
            style: "single",
          },
          order: [[4, 'desc']],
          buttons: [
            {
              extend: "pageLength",
              className: "btn btn-secondary bg-secondary",
            }
          ],
          lengthMenu: [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ]
        });
      },1000);
    };

    fetchData();
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <h1>Organization</h1>
            {!loading && (
            <>
              <div className="p-0 pb-2">

                <table id="organization_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                  <thead>
                    <tr>
                      <th>Team Name</th>
                      <th>Team Size</th>
                      <th>Team Website</th>
                      <th>Admin Name</th>
                      <th>Created Date</th>
                      {/* <th>Action</th>  */}
                    </tr>
                  </thead>
                  <tbody>
                    {organization.map((row, index) => (
                      <tr key={index}>
                        <td>{row.organization_name}</td>
                        <td>{row.organization_size}</td>
                        <td>{row.organization_website}</td>
                        <td>{row.fname +' '+ row.lname}</td>
                        <td>{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</td>
                        {/* <td>
                            <button className='btn btn-sm btn-success btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleAssessmentHistorySkills((row.stakeholder_name == 'Self' ? 'self/' : 'team-admin/')+row.id)}>
                              View
                            </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
            )}
          </Container>
        </div>
      </div>
  );
};

export default ProfileOrganization;
