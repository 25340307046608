import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Radar } from 'react-chartjs-2';
import { Row, Form, Container } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";

const ChartProgressOrganization = () => {
  const {logout, getTeamsRoadmaps, getRoadmapsSpiderChartData, showToast} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [roadmaps, setRoadmaps] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  
  const fetchSpiderData = async (roadmap_id) => {
     
    const res = await getRoadmapsSpiderChartData({roadmap_id:roadmap_id,team_id:id});
    if(res){
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else{
        let labels = res.data.labels;
        let data = res.data.data;
        if(res.data.labels.length == 2) {
          labels = [""].concat(labels);
          data = [""].concat(data);
        }
        setChartLabels(labels);
        setChartData(data);
        setLoading(false);
      }
    }
    else {
        showToast(res.message, true);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    const fetchData = async () => {
     
      const res = await getTeamsRoadmaps({team_id:id});
      if(res){
        if(res.token_message){
          console.error(res.token_message);
          logout();
        }else{
          setRoadmaps(res.data);
          if(res.data.length > 0) {
            fetchSpiderData(res.data[0].roadmap_id);
          }
          setLoading(false);
        }
      }
      else {
          showToast(res.message, true);
      }
    };

    fetchData();
  }, []);

  let handleChange = (e) => {
    fetchSpiderData(e.target.value);
  }
  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: '',
        data: chartData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      
                        <div className="settings-card">
                          
                          {
                          (!loading) ? 
                          <div>
                            <div className="settings-card-header">Progress</div>
                            <div className="settings-card-body">
                            <Form.Label>Select Roadmap</Form.Label>
                            <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleChange(e)}>
                              {roadmaps.map((roadmap) => (
                                <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                              ))}
                            </Form.Select>

                            {
                              (chartLabels.length > 0) ? 
                              <div style={{height:"600px"}} ><Radar data={data} /></div> : <div style={{textAlign: "center", marginTop: "100px"}}>No skill found</div>
                            }
                            
                            </div>
                          </div>
                          : ''
                          }

                      </div>
                    </div>
                  </div>
                      
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default ChartProgressOrganization;