
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import { Container, Dropdown} from 'react-bootstrap';
import { PlaybookUpdateModal } from "./playbookUpdateModal";
import { PlaybookArticleCreateModal } from "./playbookArticleCreateModal";
import { PlaybookArticleUpdateModal } from "./playbookArticleUpdateModal";

import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const PlaybookArticleDetails = () => {
  const { onboardingPlaybookArticleDetail, logout , onboardingPlaybookDelete, onboardingPlaybookArticleDelete, onboardingPlaybookArticleUpdateSingleField} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const [mainPost, setMainPost] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showEditArticleModal, setShowEditArticleModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isOpenId, setIsOpenId] = useState(0);
  const [checkFileExt, setCheckFileExt] = useState(false);
  const [fileName, setFileName] = useState('');
  const { id } = useParams();
  const [reffId, setReffId] = useState(id);
  
  const openEditPlaybookModal = async (e) => {
    setShowEditModal(true);
  }

  const openCreateModal = async (e) => {
   setShowModal(true);
 }

 const openUpdateModal = async (e) => {
   setShowEditArticleModal(true);
 }

 const handleUpdateCloseModal = () => {
   setShowEditArticleModal(false);
 };
  
  const handleEditCloseModal = () => {
    setShowEditModal(false);
  };
  const handleCloseModal  = () => {
    setShowModal(false);
  };


   const fetchData = async (reff_id = null) => {
      if(reff_id != null) {
         setReffId(reff_id);
      }
      setLoading(true);
      const response = await onboardingPlaybookArticleDetail((reff_id != null) ? reff_id : reffId);
      if(response){
         if(response.token_message){
            console.error(response.token_message);
            logout();
         }else{
            if (response.data.parent_article.user_can_edit == 1 || response.data.parent_article.user_id == user.id || user.login_user_type == 'ADMIN') {
               response.data.is_editable = 1;
            } else {
               response.data.is_editable = 0;
            }


            setMainPost(response.data);
             var file_url = response.data.file;
              var file_name = file_url.split('/').pop()
              setFileName(file_name);
               var file_ext = file_url.split('.').pop();
              var fileExt = ['jpg','png','gif','jpeg'];
              if( fileExt.indexOf( file_ext.toLowerCase()) > -1 ) {
                 setCheckFileExt(true);
              }
              else{
                 setCheckFileExt(false);
              }
            setLoading(false);
         }
      }
      else {
         console.error(response.message);
      }
   }

   // Use useEffect to fetch data when the component mounts
   useEffect(() => {
      fetchData();
   }, []);

   const handleDelete = async (id) => {
      const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
         const response = await onboardingPlaybookDelete(id);
         if(response){
             if(response.token_message){
                 console.error(response.token_message);
                 logout();
             }else{
               navigate(`/onboard-playbook`);   
             }
         }
         else {
            console.error(response.message);
         }
      }
    }

    const handleArticleDelete = async (main_id) => {
      const confirmBox = window.confirm(
      "Are you sure you want to delete this article?"
      )
      if (confirmBox === true) {
         const response = await onboardingPlaybookArticleDelete(id);
         if(response){
             if(response.token_message){
                 console.error(response.token_message);
                 logout();
             }else{
               navigate(`/onboard-playbook/article/${main_id}`);
             }
         }
         else {
            console.error(response.message);
         }
      }
    }

    const handleArticleChange = async (name,value) => {

      console.log({
         id:id,
         name:name,
         value:value
      });

      const response = await onboardingPlaybookArticleUpdateSingleField({
         id:id,
         name:name,
         value:value
      });
      if(response){
          if(response.token_message){
              console.error(response.token_message);
              logout();
          }else{
            fetchData();
          }
      }
      else {
         console.error(response.message);
      }
    }
    

    
    

   const toggleOpen = (id) => {
      console.log(id);
      setIsOpenId(id);
   } 

   const navigateToArticalDetail = (id) => {
      navigate(`/onboard-playbook/article/details/${id}`);
      setTimeout(
          () => {
            fetchData(id);
      },1000);
   }
  
  
                        
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 responsive-content">
        {
               (loading) ?
               <h6 className="text-center">Loading...</h6>
               :
               (
               <Container fluid>
                  <div className="row">
                  <div className="col-xs-12">
                     <div className="box mh-600px">
                        <div className="box-header d-flex">
                        <a href="javascript:void(0);" className="page-bar-back-url-btn" onClick={() => navigate(-1)}><i className="fa fa-long-arrow-left"></i></a> 
                        <h3 className="box-title">{mainPost.parent_article.name}</h3>
                        {(mainPost.is_editable == 1) && (
                        <div className="btn-group pull-right d-flex justify-content-center">
                           {
                              (mainPost.parent_article.user_id == user.id || user.login_user_type == 'ADMIN') && (
                                 <a data-target="#largeModal" data-toggle="modal" href="javascript:void(0)" className="btn btn-primary" onClick={openCreateModal}><i className="fa fa-plus me-2"></i> Create Article</a> 
                              )
                           }
                           <div className="btn-group header__more-btn d-flex ms-2">
                              <Dropdown>
                              <Dropdown.Toggle variant='Secondary' className="btn icon-button btn__lead-link d-flex align-items-center" style={{fontSize:"20px"}} id="dropdown-basic">
                                 <i className="fa fa-ellipsis-v"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="dropdown-menu bottom">
                                 <Dropdown.Item className="header__more-item" href="javascript:void(0);" onClick={openEditPlaybookModal}><i translate="no" className="fa fa-pencil me-2"></i> Edit</Dropdown.Item>
                                 {
                                    (mainPost.parent_article.user_id == user.id || user.login_user_type == 'ADMIN') && (
                                       <Dropdown.Item className="header__more-item menu-item-remove font-red-thunderbird" href="#" onClick={() => handleDelete(mainPost.parent_article.id)}><i translate="no" className="fa fa-trash me-2"></i>Delete </Dropdown.Item>
                                    )
                                 }
                              </Dropdown.Menu>
                              </Dropdown>
                           </div>
                        </div>
                        )}
                        </div>
                        <div className="box-body">
                        <div className="row h-full">
                           <div id="sideleft" className="side-active sideleft_grey col-md-3">
                              <div id="knowledgebase-sidebar" className="flw-sideleft-wrapper has-border">
                                 <div id="knowledgebase_section__tree" className="tree-view">
                                    <div className="flw-knb-menu">
                                    <ul id="favouriteArticles" className="sortable-exception flw-knb-list">
                                       <li className="flw-knb-item" onClick={() => toggleOpen('fav_id')}>
                                          <div data-toggle="collapse" data-target="#favouriteArticles-list" aria-expanded={(isOpenId == 'fav_id') ? true : false} className={`collapse-trigger ${(isOpenId == 'fav_id') ? 'collapsed' : ''} d-flex align-items-center justify-content-between`}>
                                             <div className="flw-knb-handle margin-right-10 flw-knb-first-lvl flex d-flex align-items-center">
                                                <span id="ui-63ef56c5109675-34247950" className="margin-right-12 d-flex align-items-center">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#8899AE" viewBox="0 0 16 16" className="svgFillColor">
                                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                   </svg>
                                                </span>
                                                <span>Favourites</span>
                                             </div>
                                             {(mainPost.favorite_articles.length > 0) && (
                                                <i className="fas fa-angle-down rotate-icon"></i>
                                             )}
                                          </div>
                                          {
                                             (mainPost.favorite_articles.length > 0) && (
                                                <ul aria-expanded={(isOpenId == 'fav_id') ? true : false} id="36-childs" className={`flw-knb-list collapse-are collapse ${(isOpenId == 'fav_id') ? 'show in' : ''} row_position1 ui-sortable`}>
                                                   {
                                                      mainPost.favorite_articles.map((favorite,favindex) => (
                                                         <li id="44" data-order="1" data-id="44" data-parent-article-id="36" className="flw-knb-item js-knb-menu-item ui-sortable-handle">
                                                            <div data-toggle="collapse" data-target="#36-44-childs" aria-expanded="false" className="collapse-trigger collapsed d-flex align-items-start" >
                                                               <div data-action="drop" className="flw-knb-handle"><a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(favorite.id)} tabIndex="-1" data-sideview-url="false" data-id_article="44" data-id_parent_tree="0" data-toggle="sidecenter">
                                                                  {favorite.title}</a>
                                                               </div>
                                                            </div>
                                                         </li>
                                                      ))
                                                   }
                                                </ul>
                                             )
                                          }
                                       </li>
                                      
                                    </ul> 
                                       
                                       <ul className="flw-knb-list row_position ui-sortable">
                                       {
                                          mainPost.menu_articles.map((row,index) => (
                                          <li id="36" data-order="1" data-id="36" data-parent-article-id="0" className="flw-knb-item js-knb-menu-item" onClick={() => toggleOpen(row.id)}>
                                             <div data-toggle="collapse" data-target="#36-childs" aria-expanded={(isOpenId == row.id) ? true : false} className={`collapse-trigger ${(isOpenId == row.id) ? 'collapsed' : ''} d-flex align-items-center justify-content-between`} >
                                                <div data-action="drop" className="flw-knb-handle flw-knb-first-lvl">
                                                   <div>
                                                      <span id="ui-63ef56c510c7e6-70955023 d-flex align-items-center">
                                                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#91A2B6" viewBox="0 0 16 16" className="bi bi-file-earmark-text margin-right-10">
                                                            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                                            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                                                         </svg>
                                                      </span>
                                                   </div>
                                                   <a href="javascript:void(0);" tabIndex="-1" data-sideview-url="false"  data-toggle="sidecenter"  onClick={() => navigateToArticalDetail(row.id)}>
                                                   {row.title} 
                                                   </a>
                                                </div>
                                                {(row.childs.length > 0) && (
                                                <i className="fas fa-angle-down rotate-icon"></i>
                                                )}
                                             </div>
                                             {
                                                (row.childs.length > 0) && (
                                                   <ul aria-expanded={(isOpenId == row.id) ? true : false} id="36-childs" className={`flw-knb-list collapse-are collapse ${(isOpenId == row.id) ? 'show in' : ''} row_position1 ui-sortable`}>
                                                      {
                                                         row.childs.map((child,childindex) => (
                                                            <li id="44" data-order="1" data-id="44" data-parent-article-id="36" className="flw-knb-item js-knb-menu-item ui-sortable-handle">
                                                               <div data-toggle="collapse" data-target="#36-44-childs" aria-expanded="false" className="collapse-trigger collapsed d-flex align-items-start" >
                                                                  <div data-action="drop" className="flw-knb-handle"><a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(child.id)} tabIndex="-1" data-sideview-url="false" data-id_article="44" data-id_parent_tree="0" data-toggle="sidecenter">
                                                                     {child.title}</a>
                                                                  </div>
                                                               </div>
                                                            </li>
                                                         ))
                                                      }
                                                   </ul>
                                                )
                                             }
                                             
                                          </li>
                                          ))
                                       }

                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div id="sidecenter" className="col-md-9">
                              <div className="page__content-wrapper ">
                              <div className="kb-article sidecenter-content-wrapper">
                                 <div className="flw-kb-main-header-content">
                                    <div className="flw-kb-main-header-content-head">
                                       <div className="flw-kb-main-header-content-head_left">
                                          
                                          {
                                          (mainPost.publish == 1) ? 
                                          <><i className="material-icons md-18 font-green-jungle margin-right-5 notranslate fa fa-check" translate="no"></i>
                                          <div className="margin-right-10 color_text_light vcenter">
                                                Published                            
                                          </div>
                                          </>
                                          :
                                          <div className="margin-right-10 color_text_light vcenter">
                                                Draft                           
                                          </div>   
                                          }                   
                                          <span>  </span>
                                       </div>
                                       <div className="flw-kb-main-header-content-head_right flex flex-row">
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-md-12">
                                          <h1 className="article-name margin-bottom-20">
                                             {(mainPost.is_editable == 1) && (
                                                <div className="btn-group pull-right d-flex align-items-center">
                                                   <button className="flw-mi-btn tooltips me-3">

                                                      <span id="ui-63f2fec2be3229-43306657" className="flw-mi-btn btn__lead-link d-flex align-items-center" 
                                                      onClick={() => handleArticleChange('mark_as_feature', (mainPost.mark_as_feature == 1) ? 0 : 1)}>
                                                            <svg className={`${(mainPost.mark_as_feature == 1) ? 'svgFillColor' : ''} bi bi-star-fill`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" id="addFavoriteColor" viewBox="0 0 16 16" data-type="article">
                                                               <path data-type="article" d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                                            </svg>
                                                      </span>

                                                   </button>
      
                                                   <a href="javascript:void(0);" onClick={openUpdateModal} className="tooltips flw-mi-btn me-3" data-original-title="Edit" data-placement="bottom">

                                                      <span id="ui-63f2fec2be6094-18886288" className="flw-mi-btn btn__lead-link d-flex align-items-center">
                                                   
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                               <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                                            </svg>
                                                      </span>

                                                   </a>
                                                   <Dropdown className="flw-mi-btn">
                                                      <Dropdown.Toggle variant='Secondary' className="tooltips flw-mi-btn" id="dropdown-basic">
                                                         <span id="ui-63f2fec2be81a7-36946243" className="flw-mi-btn btn__lead-link d-flex align-items-center">
                                                   
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                               <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                            </svg>
                                                      </span>
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu className="dropdown-menu bottom">
                                                         {
                                                            (mainPost.publish == 0) ?
                                                            <Dropdown.Item className="remove-item-knowledgebase font-red-thunderbird" href="#" onClick={() => handleArticleChange('publish', 1)}><i translate="no" className="fa fa-check me-2"></i>Publish </Dropdown.Item>
                                                            :
                                                            <Dropdown.Item className="edit-item-knowledgebase" href="#" onClick={() => handleArticleChange('publish', 0)}><i translate="no" className="fa fa-check me-2"></i> Unpublish</Dropdown.Item>
                                                         }

                                                         {
                                                            (mainPost.mark_as_feature == 0) ?
                                                            <Dropdown.Item className="remove-item-knowledgebase font-red-thunderbird" href="#" onClick={() => handleArticleChange('mark_as_feature', 1)}><i className="fa-regular fa-flag me-2"></i> Мark as featured </Dropdown.Item>
                                                            :
                                                            <Dropdown.Item className="remove-item-knowledgebase font-red-thunderbird" href="#" onClick={() => handleArticleChange('mark_as_feature', 0)}><i className="fa-regular fa-flag me-2"></i> Unmark as featured </Dropdown.Item>
                                                         }
                                                         <Dropdown.Divider />
                                                         {
                                                            (mainPost.parent_article.user_id == user.id || user.login_user_type == 'ADMIN') && (
                                                               <Dropdown.Item className="remove-item-knowledgebase font-red-thunderbird" href="#" onClick={() => handleArticleDelete(mainPost.parent_article.id)}><i translate="no" className="fa fa-trash me-2"></i> Remove </Dropdown.Item> 
                                                            )
                                                         }
                                                         
                                                      </Dropdown.Menu>
                                                   </Dropdown>
                                                </div>
                                             )}
                                             {mainPost.title}            
                                          </h1>
                                       </div>
                                    </div>
                                    <div className="row margin-bottom-20">
                                       <div className="col-md-12 knb-word-wrap article-content">
                                          <div dangerouslySetInnerHTML={ { __html: mainPost.content}} />
                                       </div>
                                    </div>
                                    <div className="row margin-bottom-20">
                                    <div className="col-md-12 font-md">
                                       {
                                          (mainPost.file != '') && 
                                          (
                                             <div className="flw-attach__list">
                                                <div className="">
                                                <div>
                                                   <div className="flw-icon-with-text flw-portlet flw-portlet--light flw-attach__item" style={{cursor: "pointer"}}>
                                                      <div className="flw-attach__item-actions">
                                                            <a href={mainPost.file} target="_blank" className="flw-attach__action-icon margin-right-10">
                                                               <i className="fa fa-download"></i>
                                                            </a> 
                                                      </div>
                                                      {checkFileExt && (
                                                      <div className="flw-icon-with-text__icon flw-attach__item-icon" 
                                                         style={{backgroundImage: "url("+mainPost.file+")" }}>
                                                      </div>
                                                      )} 
                                                      {!checkFileExt && (
                                                      <div className="flw-icon-with-text__icon flw-attach__item-icon" 
                                                            style={{backgroundImage: "url(/img/icon/file.svg)" }}>
                                                      </div> 
                                                      )} 
                                                      <div className="flw-icon-with-text__content text-overflow">
                                                         <div className="flw-icon-with-text__content-title flw-attach__item-title text-overflow">{fileName}</div> 
                                                         <div className="flw-icon-with-text__content-subtitle flw-attach__item-sub text-muted uppercase"></div> 
                                                      </div>
                                                   </div>
                                                </div> 
                                                </div>
                                             </div>
                                          )
                                       }
                                        
                                       <div className="article__navigation">

                                          {
                                             (mainPost.previous_article != null) && 
                                             (
                                                <div className="article__navigation-item">
                                                      <a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(mainPost.previous_article.id)} className="article__navigation-item-link" data-toggle="sidecenter" data-panel-info="in_side_panel" >
                                                         <span className="article__navigation-icon article__navigation-icon--prev">
                                                            <i className="fa fa-long-arrow-left"></i>
                                                         </span>
      
                                                         <span className="article__navigation-item-title">
                                                            <span className="article__navigation-item-label color_text_light">
                                                                  Previous                                    
                                                            </span>
                                                            {mainPost.previous_article.title}                          
                                                         </span>
      
                                                      </a>
                                                </div>
                                             )
                                          }

                                          {
                                             (mainPost.next_article != null) && 
                                             (
                                                <div className="article__navigation-item">
                                                   <a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(mainPost.next_article.id)} className="article__navigation-item-link article__navigation-item-link-next" data-toggle="sidecenter" data-panel-info="in_side_panel">
                                                      <span className="article__navigation-item-title">
                                                         <span className="article__navigation-item-label color_text_light">
                                                               Next                                    
                                                         </span>
                                                         {mainPost.next_article.title}                               
                                                      </span>

                                                      <span className="article__navigation-icon article__navigation-icon--next">
                                                         
                                                      <i className="fa fa-long-arrow-right"></i>
                                                      </span>
                                                   </a>
                                             </div>
                                             )
                                          }
                                    
                                          
                                       </div>
                                    </div>
                                    </div>
                                 </div>
                              </div>
                              </div>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>
                  </div>
                  <PlaybookUpdateModal show={showEditModal} handleCloseModal={handleEditCloseModal} fetchData={fetchData} playbookModalId={mainPost.parent_article.id} />
                  <PlaybookArticleUpdateModal show={showEditArticleModal} handleCloseModal={handleUpdateCloseModal} fetchData={fetchData} playbookModalId={reffId} />
                  <PlaybookArticleCreateModal show={showModal} handleCloseModal={handleCloseModal} fetchData={fetchData} playbookModalId={mainPost.parent_article.id} playbookCurrentId={id} /> 
               </Container>
               )
         }
        </div>
      </div>
  );
};
export default PlaybookArticleDetails;