import React, {useContext, useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Container, Row } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamHiringSidebar from "./teamHiringSidebar";
import DeleteModal from "./deleteModal";
const SettingHiring = () => {

  const { getHiringTeamDetails, logout, updateHiring, deleteHiring} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [errMsg, setErrMsg] = useState('');
   const [successMsg, setSuccessMsg] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    hiring_role_name:'',
    hiring_description:''
  });
  const [showModal, setShowModal] = useState(false);

  

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  const fetchTeamData = async () => {
    const team_response = await getHiringTeamDetails(id);
    if(team_response){
      if(team_response.token_message){
        console.error(team_response.token_message);
        logout();
      }else{
        setFormData({
            hiring_id:id,
            hiring_role_name:team_response.data.hiring_role_name,
            hiring_description:team_response.data.hiring_description
        });
      }
    }
    else {
      setErrMsg(team_response.message);
    }
  };
  
  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.hiring_role_name === "") {
        setErrMsg("Please enter hiring role name"); return false;
      } 
       else {
        setErrMsg("");
      }

      const data = await updateHiring(formData);
      if(data.status){
          e.target.reset();
          fetchTeamData();
          setErrMsg(false);
          setSuccessMsg("Updated successfully!");
      }
      else if(!data.status && data.message){
          setErrMsg(data.message);
      }
  };
  const openDeleteModal = async (e) => {
    setShowModal(true);
  }
  const deletTeam = async (e) => {

    const response = await deleteHiring(id);
      if(response){
        if(response.token_message){
          setSuccessMsg(false);
          console.error(response.token_message);
          logout();
        }else{
          console.log(response);
           navigate("/profile-teams");
        }
      }
      else {
          setSuccessMsg(false);
          setErrMsg(response.message);
      }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const checkProfessionalEmail = async () => {
    // List of commonly used professional email providers
    const professionalProviders = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'protonmail.com',
      'yopmail.com'
      // Add more professional email providers as needed
    ];

    // Extract domain from email address
    const domain = (user.email).split('@')[1];

    // Check if the domain is a commonly used professional email provider
    if (professionalProviders.includes(domain)) {
      alert('To unlock Hiring feature, please register using your official company email address.');
      navigate(`/profile-teams`);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    const fetchData = async () => {
    // checkProfessionalEmail();
      const team_response = await getHiringTeamDetails(id);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        } else if(team_response.status == false) {
          alert(team_response.message);
          navigate(`/profile-teams`);
        } else{
          setFormData({
              hiring_id:id,
              hiring_role_name:team_response.data.hiring_role_name,
              hiring_description:team_response.data.hiring_description
          });
        }
      }
      else {
        setErrMsg(team_response.message);
      }
    };

    fetchData();
  }, []);
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamHiringSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      <div className="settings-card">
                        <div className="settings-card-header">Update Hiring Information</div>
                        <div className="settings-card-body">
                        <Form onSubmit={handleSubmit} >
                          <Form.Group controlId="hiring_role_name" className="mb-3">
                            <Form.Label>Hiring Role Name*</Form.Label>
                            <Form.Control
                              type="text"
                              name="hiring_role_name"
                              value={formData.hiring_role_name}
                              placeholder="Hiring Role Name"
                              onChange={onChangeInput}
                            />
                          </Form.Group>

                          <Form.Group controlId="hiring_description" className="mb-3">
                            <Form.Label>Hiring Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="hiring_description"
                              value={formData.hiring_description}
                              placeholder="Hiring Description"
                              onChange={onChangeInput}
                            />
                          </Form.Group>
                          
                          {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
                          <div className="button-section">
                              <button className="button submit" type="submit">Save</button>
                          </div>
                          {successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}

                          <div className="row settings-row">
                              <hr/>
                              <h2>Delete Team</h2>
                              <p>Permanently delete this team and all of its resources.</p>
                              <button className="settings-delete-btn settings-btn-primary" onClick={openDeleteModal} type="button">Delete Team</button>
                          </div>
                        </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteModal show={showModal} deletTeam={deletTeam} handleCloseModal={handleCloseModal} />
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default SettingHiring;