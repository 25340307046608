import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import React from "react";
import Data from '../data/data';
import {Helmet} from "react-helmet";
import JsonMetaData from "../data/metaData.json";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [contactData, setContactData] = useState({});
  const [{ name, email, message }, setState] = useState(initialState);
  const [pageMetaData, setPageMetaData] = useState(JsonMetaData);

  useEffect(() => {
    setContactData(Data.Contact);
    //  const script = document.createElement("script");
    //     script.src = "../js/metaTag.js";
    //     script.async = true;
    //     document.body.appendChild(script);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    
    <div>
      <Helmet>
        <title>{pageMetaData.contact.title}</title>
        <meta name="description" content={pageMetaData.contact.description} />
        <meta name="keywords" content={pageMetaData.contact.keywords} />
        <meta name="author" content={pageMetaData.contact.author} />
        <meta property="og:title" content={pageMetaData.contact.og_title} />
        <meta property="og:description" content={pageMetaData.contact.og_description} />
        <meta property="og:image" content={pageMetaData.contact.og_image} />
        <meta property="og:url" content={pageMetaData.contact.og_url} />
      </Helmet>
      <div id="contact" className="h-full">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
            <div className="col-md-3 offset-md-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p className="d-none">
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                 {contactData.address || "loading"}
                </p>
              </div>
              <div className="contact-item d-none">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {contactData.phone ? contactData.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {contactData.email ? contactData.email : "loading"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
