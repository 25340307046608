import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Form, Container } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";
import Moment from 'moment';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const HistoryOrganization = () => {
  const {logout, getTeamMembersAssessmentHistory} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    const fetchData = async () => {
      const team_response = await getTeamMembersAssessmentHistory({team_id:id});
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setHistoryData(team_response.data);
        }
      }
      else {
        console.error(team_response.message);
      }

      setTimeout(
        () => {
      $("#history_organization_table").DataTable({
        pagingType: "full_numbers",
        pageLength: myConstent.DATATABLERECORDLIMIT,
        processing: true,
        scrollX: true,
        dom: "Bfrtip",
        select: {
          style: "single",
        },
        order: [[1, 'desc']],
        buttons: [
          {
            extend: "pageLength",
            className: "btn btn-secondary bg-secondary",
          },
          {
            extend: "copy",
            className: "btn btn-secondary bg-secondary",
          },
          {
            extend: "csv",
            className: "btn btn-secondary bg-secondary",
          },
          {
            extend: "print",
            customize: function (win) {
              $(win.document.body).css("font-size", "10pt");
              $(win.document.body)
                .find("table")
                .addClass("compact")
                .css("font-size", "inherit");
            },
            className: "btn btn-secondary bg-secondary",
          },
        ],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
    },2000);
      
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      
                        <div className="settings-card">
                          
                          {
                          (!loading) ? 
                          <div>
                            <div className="settings-card-header">Team Memeber History</div>
                            <div className="settings-card-body">
                              <div className="pt-4 pb-2">
                                <table id="history_organization_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                                  <thead>
                                    <tr>
                                      <th>User Name</th>
                                      <th>User Role</th>
                                      <th>Email</th>
                                      <th>Roadmap Name</th>
                                      <th>Score</th>
                                      <th>Assessment Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  
                                  {historyData.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.fname +' '+ row.lname}</td>
                                      <td>{row.role_title}</td>
                                      <td>{row.user_email}</td>
                                      <td>{row.roadmap_name}</td>
                                      <td>{row.total_score}</td>
                                      <td>{Moment(row.assessment_created_at).format(myConstent.DATETIMEFORMAT)}</td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                          : ''
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default HistoryOrganization;