import React from 'react';
import { Row, Container } from 'react-bootstrap';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";

const OrganizationDashboard = () => {
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      <div className="settings-card">
                        <div className="settings-card-header">Dashboard</div>
                        <div className="settings-card-body">
                            <center><h3>Welcome</h3></center>
                        </div>
                      </div>
                    </div>
                  </div>
                      
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default OrganizationDashboard;