import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';

export const PlaybookUpdateModal = ({ show, handleCloseModal, fetchData, playbookModalId, userId}) => {
    const {onboardingPlaybookCreate, logout, onboardingPlaybookDetail} = useContext(UserContext);
    const [errModalMsg, setErrModalMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [socialLinks] = useState(["facebook","gmail","instagram","twitter","youtube"]);
    const [showTab, setShowTab] = useState('tab-1');
    const [formData, setFormData] = useState({
        id:0,
        name:'',
        description:'',
        title_color:'#000000',
        custom_css:'',
        social_media_links_on_footer:'',
        header_background:'',
        header_logo:'',
        footer_logo:'',
        favicon:'',
        preview:'',
        hide_header:0,
        external_site_name:'',
        external_site_url:'',
        footer_logo_href:'',
        footer_logo_text:'',
        seo:0,
        seo_title:'',
        seo_description:'',
        social_links:[],
        last_updated_by:userId

    });
    const [formValues, setFormValues] = useState([{
        name: 'facebook',
        link:''
      },
      {
        name: 'gmail',
        link:''
      },
      {
        name: 'instagram',
        link:''
      },
      {
        name: 'twitter',
        link:''
      },
      {
        name: 'youtube',
        link:''
      }
    ]);
    const fetchModalData = async () => {
        const response = await onboardingPlaybookDetail(playbookModalId);
        if(response){
            if(response.token_message){
                console.error(response.token_message);
                logout();
            }else{
                response.data.social_links = formValues;
                if(response.data.social_media_links_on_footer != null){
                var links = JSON.parse(response.data.social_media_links_on_footer); 
                    if(links.length > 0) {
                        var social_links_arr = [{
                            name: 'facebook',
                            link:links.facebook.link
                        },
                        {
                            name: 'gmail',
                            link:links.gmail.link
                        },
                        {
                            name: 'instagram',
                            link:links.instagram.link
                        },
                        {
                            name: 'twitter',
                            link:links.twitter.link
                        },
                        {
                            name: 'youtube',
                            link:links.youtube.link
                        }
                        ];
                        setFormValues(social_links_arr);
                        response.data.social_links = social_links_arr;
                    } 
                }
                setFormData(response.data);
            }
        }
        else {
            setErrModalMsg(response.message);
        }
    }
    // Use useEffect to fetch data when the component mounts
    useEffect( async () => {
        fetchModalData();
    }, []);
  
    const closeModal = () => {
        setShowTab('tab-1');
        setSuccessMsg('');
        setErrModalMsg('');
        setFormValues([{
            name: 'facebook',
            link:''
          },
          {
            name: 'gmail',
            link:''
          },
          {
            name: 'instagram',
            link:''
          },
          {
            name: 'twitter',
            link:''
          },
          {
            name: 'youtube',
            link:''
          }
        ]);
        fetchData();
        fetchModalData();
        handleCloseModal();
    }

    const activeTab = (tab) => {
        setShowTab(tab);
    }

    const onChangeInput = (e) => {
        if(e.target.name == 'hide_header' || e.target.name == 'seo') {
            const { checked } = e.target;
            if (checked) {
                setFormData({
                    ...formData,
                    [e.target.name]:1
                });
            } else {
                setFormData({
                    ...formData,
                    [e.target.name]:0
                });
            }
        } else {
            setFormData({
                ...formData,
                [e.target.name]:e.target.value
            });
        }
        
    };

    let handleChange = (i, value, e) => {
      let newFormValues = [...formValues];
      newFormValues[i]['link'] = e.target.value;
      newFormValues[i]['name'] = value;
      setFormValues(newFormValues);
      setFormData({
        ...formData,
        social_links:newFormValues
      });
    }

    const onChangeInputFile = (e) => {
        if (e.target.files) {
            setFormData({
                ...formData,
                [e.target.name]:e.target.files[0]
              });
        }
      }

    const isValidUrl = (string) => {
        try {
          if(string != '') {
            new URL(string);
            return true;
          } else {
            return true;
          }
        } catch (err) {
          return false;
        }
      }

    const saveModal = async (e) => {
        e.preventDefault();
    
        if(formData.name == '' || formData.description == '') {
          setErrModalMsg('Please enter all mandatory fields!'); return false;
        }else {
          setErrModalMsg(false);
        }

        var temp_social_plateform_name = '';
        (formData.social_links).forEach(element => {
        var res = isValidUrl(element.link);
        if(res === false) {
            temp_social_plateform_name = element.name; 
            return true;            
        }
        });
  
        if(temp_social_plateform_name != '') {
          setErrModalMsg('Please enter valid url in '+temp_social_plateform_name+'!');return false; 
        }
        
        setSuccessMsg('');
        setErrModalMsg('');
       
        let data = await onboardingPlaybookCreate(formData);
        if(data.status){
            setSuccessMsg(data.message);
            setErrModalMsg(false);
            
            setTimeout(
                () => {
                closeModal();
            },1000);
        }
        else if(!data.status && data.message){
          setErrModalMsg(data.message);
        }
      }
 
	return (
    <div>
    	<Modal show={show} size={'lg'} className="come-from-modal right" id="largeModal">
          <Modal.Header>
              <button type="button" className="btn-close close" data-dismiss="modal" aria-hidden="true" onClick={closeModal}>&times;</button>
              <Modal.Title>{playbookModalId == null ? 'New' : 'Update'} Onboarding Playbook</Modal.Title>
          </Modal.Header>

          <Modal.Body className="knowledge_modal">
            <ul className="tabs">
                <li className={"tab tab-1 " + (showTab == 'tab-1' ? 'current' : '') } data-tab="tab-1" onClick={() => activeTab('tab-1')}>Main</li>
                <li className={"tab tab-2 d-none" + (showTab == 'tab-2' ? 'current' : '') } data-tab="tab-2" onClick={() => activeTab('tab-2')}>External</li>
            </ul>
            <div id="tab-1" className={"tab-content " + (showTab == 'tab-1' ? 'current' : '') }>
            
                <div className="form-group">
                    <label htmlFor="name" className="form__label">Name<sup style={{color:'red'}}>*</sup></label>
                    <input type="text" name="name" className="form-control" id="name" placeholder="Name"  
                      value={formData.name}
                      onChange={onChangeInput} />
                   
                </div>
                <div className="form-group">
                    <label htmlFor="description" className="form__label">Description<sup style={{color:'red'}}>*</sup></label>
                    <textarea className="form-control" name="description" id="description" rows="4"  
                    value={formData.description}
                    onChange={onChangeInput} placeholder="Description"></textarea>
                    
                </div>
                <div className="form-group">
                    <h4 className="section-title">Design</h4>
                </div>
                <div className="form-group">
                    <label htmlFor="header_backgraound" className="form__label">Header Background</label>
                    <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                    <div className="input-file__inner">
                        <label htmlFor="fileUpload" className="fileupload-new thumbnail ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>
                            </label>

                            <div className="input-file__image-wrap">
                                <div className="input-file__image-origin hidden"></div>
                                <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                {
                                    (formData.header_background != '' && formData.header_background.name == undefined) && 
                                    <img src={formData.header_background} />  
                                }
                                </div>
                            </div>
                            <span className="input-file__action--upload">
                                {
                                    (formData.header_background != '' && formData.header_background.name != '') ? 
                                    <span className="fileupload-exists">
                                        {formData.header_background.name}                        
                                    </span>
                                    :
                                    <span className="fileupload-new">
                                        Add                        
                                    </span>
                                }
                            </span>
                            <input type="file" name="header_background" className="form-control" id="header_background" placeholder="Add"  accept="image/*" onChange={onChangeInputFile} />
                            
                        </div>
                    </div>
                </div>
                <div className="form-group color-picker">
                    <label htmlFor="header_backgraound" className="form__label">Title Font Color</label>
                    <input type="color" className="form-control" name="title_color" value={formData.title_color}
                    onChange={onChangeInput} />
                </div>
                
            </div>
            <div id="tab-2" className={"tab-content d-none" + (showTab == 'tab-2' ? 'current' : '') }>
                <div className="form-group">
                    <h4 className="section-title">Template Design</h4>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="header_backgraound" className="form__label">Header Logo</label>
                        <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                            <div className="input-file__inner">
                                <label htmlFor="fileUpload" className="fileupload-new thumbnail ">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>

                                </label>

                                <div className="input-file__image-wrap">
                                    <div className="input-file__image-origin hidden"></div>
                                    <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                    {
                                        (formData.header_logo != '' && formData.header_logo.name == undefined) && 
                                        <img src={formData.header_logo} />  
                                    }
                                    </div>
                                </div>
                                <span className="input-file__action--upload">
                                {
                                    (formData.header_logo != '' && formData.header_logo.name != '') ? 
                                    <span className="fileupload-exists">
                                        {formData.header_logo.name}                        
                                    </span>
                                    :
                                    <span className="fileupload-new">
                                        Add                        
                                    </span>
                                }
                                </span>
                                <input type="file" className="form-control" id="header_logo" name='header_logo' placeholder="Add" accept="image/*" onChange={onChangeInputFile} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="header_backgraound" className="form__label">Footer Logo</label>
                        <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                            <div className="input-file__inner">
                                <label htmlFor="fileUpload" className="fileupload-new thumbnail ">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>

                                </label>

                                <div className="input-file__image-wrap">
                                    <div className="input-file__image-origin hidden"></div>
                                    <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                    {
                                        (formData.footer_logo != '' && formData.footer_logo.name == undefined) && 
                                        <img src={formData.footer_logo} />  
                                    }
                                    </div>
                                </div>
                                <span className="input-file__action--upload">
                                {
                                    (formData.footer_logo != '' && formData.footer_logo.name != '') ? 
                                    <span className="fileupload-exists">
                                        {formData.footer_logo.name}                        
                                    </span>
                                    :
                                    <span className="fileupload-new">
                                        Add                        
                                    </span>
                                }
                                </span>
                                <input type="file" className="form-control" id="footer_logo" name='footer_logo' placeholder="Add" accept="image/*" onChange={onChangeInputFile} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="header_backgraound" className="form__label">Favicon</label>
                        <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                            <div className="input-file__inner">
                                <label htmlFor="fileUpload" className="fileupload-new thumbnail ">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>

                                </label>

                                <div className="input-file__image-wrap">
                                    <div className="input-file__image-origin hidden"></div>
                                    <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                    {
                                        (formData.favicon != '' && formData.favicon.name == undefined) && 
                                        <img src={formData.favicon} />  
                                    }
                                    </div>
                                </div>
                                <span className="input-file__action--upload">
                                {
                                    (formData.favicon != '' && formData.favicon.name != '') ? 
                                    <span className="fileupload-exists">
                                        {formData.favicon.name}                        
                                    </span>
                                    :
                                    <span className="fileupload-new">
                                        Add                        
                                    </span>
                                }
                                </span>
                                <input type="file" className="form-control" id="favicon" name='favicon' placeholder="Add" accept="image/*" onChange={onChangeInputFile} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="header_backgraound" className="form__label">Preview</label>
                        <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                            <div className="input-file__inner">
                                <label htmlFor="fileUpload" className="fileupload-new thumbnail input-file__default">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>
                                </label>

                                <div className="input-file__image-wrap">
                                    <div className="input-file__image-origin hidden"></div>
                                    <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                    {
                                        (formData.preview != '' && formData.preview.name == undefined) && 
                                        <img src={formData.preview} />  
                                    }
                                    </div>
                                </div>
                                <span className="input-file__action--upload">
                                {
                                    (formData.preview != '' && formData.preview.name != '') ? 
                                    <span className="fileupload-exists">
                                        {formData.preview.name}                        
                                    </span>
                                    :
                                    <span className="fileupload-new">
                                        Add                        
                                    </span>
                                }
                                </span>
                                <input type="file" className="form-control" id="preview" name='preview' accept="image/*" onChange={onChangeInputFile} placeholder="Add" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group form-check">
                    <div className="checkbox-inline">
                        <input name="hide_header" type="checkbox" className="form-check-input" id="hide_header" value={formData.hide_header}
                    checked={formData.hide_header == 1 ? true : false } onChange={onChangeInput} />
                        <label className="form-check-label" htmlFor="hide_header">Hide Header</label>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="external_site_name" className="form__label">The name of your website</label>
                    <input className="form-control" id="external_site_name" placeholder="The name of your website" type="text" name="external_site_name"  value={formData.external_site_name}
                    onChange={onChangeInput} />
                </div>
                <div className="form-group">
                    <label htmlFor="external_site_url" className="form__label">Your website address</label>
                    <input className="form-control" id="external_site_url" placeholder="Your website address" type="text" name="external_site_url"  value={formData.external_site_url}
                    onChange={onChangeInput}  />
                </div>
                <div className="form-group">
                    <label htmlFor="footer_logo_href" className="form__label">Footer: Logo Link</label>
                    <input className="form-control" id="footer_logo_href" placeholder="Footer: Logo Link" type="text" name="footer_logo_href"   
                    value={formData.footer_logo_href}
                    onChange={onChangeInput}/>
                </div>
                <div className="form-group">
                    <label htmlFor="footer_logo_text" className="form__label">Footer: Logo Text</label>
                    <input className="form-control" id="footer_logo_text" placeholder="Footer: Logo Text" type="text" name="footer_logo_text" 
                    value={formData.footer_logo_text}
                    onChange={onChangeInput} />
                </div>

                <div className="form-group">
                    <div className="checkbox-inline">
                        <input className="form-check-input" id="exampleCheck3" name="seo" type="checkbox"  value={formData.seo}
                    checked={formData.seo == 1 ? true : false } onChange={onChangeInput} >
                        </input>
                        <label className="form-check-label" htmlFor="exampleCheck3">
                            SEO
                        </label>
                    </div>
                </div>
                {
                    (formData.seo == 1) && (
                        <div className="seo-section">
                            <div className="form-group">
                                <label className="form__label" htmlFor="seo_title">
                                    Title HTML-document
                                </label>
                                <input className="form-control" placeholder="Title HTML-document" type="text" name="seo_title"  value={formData.seo_title}
                            onChange={onChangeInput} />
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="seo_description">
                                    Meta Description
                                </label>
                                <textarea className="form-control"  placeholder="Meta Description" name="seo_description" value={formData.seo_description}
                            onChange={onChangeInput} >
                                </textarea>
                            </div>
                        </div>
                    )
                }
                
                <div className="form__divider"></div>
                <label class="form__label" for="">Social Media Links on Footer</label>
                <div class="form__input--fragment">
                  <div id="social-links-container" class="social-links">
                      <div class="social-links__item" data-social-link-id="0">
                        <div className="row">
                          {
                           formValues.map((element,index) => (
                          <>
                          <div className="col-md-3 mb-1 mt-1">
                            <div class="social-links__item-name">
                                <input type="text" class="form-control" placeholder="Item Name" id={`social_links_${element.name}`} value={element.name} readonly />
                            </div>
                          </div>
                          <div className="col-md-8 mb-1 mt-1">
                            <div class="social-links__item-url">
                                <input type="text" class="form-control" placeholder="Item URL" id={`social_links_${element.name}`} name="link" value={element.link}  onChange={e => handleChange(index, element.name, e)}/>
                            </div>
                          </div>
                          <div className="col-md-1 mt-1">
                            <div class="social-links__item-icon">
                                <div data-provides="fileupload" class="fileupload  fileupload-new ">
                                    <div class="" data-container=".sidepanel-wrapper">
                                        <div class="btn-group d-flex">
                                            <div class="social-links__item-icon--preview fileupload-new thumbnail">
                                                <img src={`/knowledge-asstes/image/${element.name}.png`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          </>
                          ))}
                        </div>
                      </div>
                      <br />
                  </div>
                </div>
                <div className="form-group">
                    <label htmlFor="css" className="form__label">Custom CSS</label>
                    <textarea className="form-control"  placeholder="Custom CSS" rows="4" name="custom_css" value={formData.custom_css}
                            onChange={onChangeInput}></textarea>
                </div>
                {/* <div className="form-group">
                    <div className="checkbox-inline">
                        <input className="form-check-input" id="exampleCheck4" type="checkbox" value="1" />
                        <label className="form-check-label" htmlFor="exampleCheck4">
                        Change the domain name
                        </label>
                        
                    </div>
                </div>
                <div>
                    <div className="form-group">
                        <label htmlFor="custom_domain_name" className="form__label">Your website address</label>
                        <input className="form-control" id="custom_domain_name" placeholder="Your website address" type="text" name="custom_domain_name"  />
                    </div>
                </div> */}
            </div>
            
            {successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
            {errModalMsg && <h4 className="err-msg text-danger text-center">{errModalMsg}</h4>}
          </Modal.Body>
          

          <Modal.Footer className="justify-content-between">
            <div className="btn-left">
              <Button variant="outline-secondary" onClick={closeModal}>Cancel</Button>
            </div>
            <div className="btn-right" onClick={saveModal}>
              <Button variant="dark">Save</Button>
              {/* <Button variant="outline-secondary mx-2">Save as placeholder</Button>
              <Button variant="dark">Continue to Editor</Button> */}
            </div>
          </Modal.Footer>
        </Modal>
    </div>
  );
};