import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Bar, Radar, Line } from 'react-chartjs-2';
import { Row, Col, Form, Modal, Container } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";

const ProgressOrganization = () => {
  const {logout, getTeamBoards, getTeamDetails, getLoggedInUser, getTeamsRoadmaps, getRoadmapsSpiderChartData, getTeamBoardDetails} = useContext(UserContext);
  const [errMsg, setErrMsg] = useState('');
  const [theLoginUser, setLoginUser] = useState({email:""});
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedMembers, setAcceptedMembers] = useState([]);
  const [onlyMembers, setOnlyMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  
  const [chartLoading, setChartLoading] = useState(false);
  const [roadmaps, setRoadmaps] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [userChartLabels, setUserChartLabels] = useState([]);
  const [userChartData, setUserChartData] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [singleBoard, setSingleBoard] = useState(null);
  const [modalBackgroundImage, setModalBackgroundImage] = useState({
    backgroundImage: null
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [tabIndex] = useState((location.state == null )  ? 1 : 2);
  const predefineBoards = [
    {
      id: "0",
      name: "To Do", 
      items: []
    },
    {
      id: "1",
      name: "In Progress",
      items: []
    },
    {
      id: "2",
      name: "Done",
      items: []
    },
  ]

  const [stores, setStores] = useState(predefineBoards);
  
  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: '',
        data: chartData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const handleHrefRedirect = (href,id) => {
    navigate(href+id);
  };

  const random_rgba = () => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
  };
  
  const fetchSpiderData = async (roadmap_id) => {
    let res = await getRoadmapsSpiderChartData({roadmap_id:roadmap_id,team_id:id});
    if(res){
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else{
        let labels = res.data.labels;
        let data = res.data.data;
        setChartLabels(labels);
        setChartData(data);

        let tdata = [];
        let colors = ['rgb(60, 179, 113)','rgb(255, 165, 0)','rgb(238, 130, 238)','rgb(106, 90, 205)','rgb(255, 0, 0)'];
        let index = 0;
        (res.data.user_skill_scores).forEach(element => {
          let color_code = (colors[index] != undefined) ? colors[index] : random_rgba();
          tdata.push({
            label: element.name,
            data: element.score,
            borderColor: color_code,
            backgroundColor: color_code
          })
          index++;
        });

        console.log(tdata);

        setUserChartLabels(res.data.bar_labels);
        setUserChartData(tdata);

        setChartLoading(false);
      }
    }
    else {
        setErrMsg(res.message);
    }

  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    const fetchData = async () => {
      const user_response = await getLoggedInUser();
      setLoginUser(user_response);
  
      //
      const team_response = await getTeamDetails(id);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setAcceptedMembers(team_response.data.accepted_members_results);
          setOnlyMembers(team_response.accepted_members);
          setRoles(team_response.roles);
          if(team_response.accepted_members.length > 0)
          {
            fetchTeamBoardsData(team_response.accepted_members[0].user_id);
          }
          setLoading(false);
        }
      }
      else {
          setErrMsg(team_response.message);
      }

      const res = await getTeamsRoadmaps({team_id:id});
      if(res){
        if(res.token_message){
          console.error(res.token_message);
          logout();
        }else{
          setRoadmaps(res.data);
          if(res.data.length > 0) {
            fetchSpiderData(res.data[0].roadmap_id);
          }
          setChartLoading(false);
        }
      }
      else {
          setErrMsg(res.message);
      }
    };

    fetchData();
  }, []);

  let handleChange = (e) => {
    fetchSpiderData(e.target.value);
  }

  let handleUserChange = (e) => {
    console.log(e.target.value);
    fetchTeamBoardsData(e.target.value);
  }



  const fetchTeamBoardsData = async (user_id) => {
    const response1 = await getTeamBoards(id,user_id,'ORGANIZATION');
    if(response1){
      if(response1.token_message){
        console.error(response1.token_message);
        logout();
      }else{
        let temp = predefineBoards;
        temp[0].items = response1.data.todo;
        temp[1].items = response1.data.inprogress;
        temp[2].items = response1.data.done;
        console.log(temp);
        setStores(temp);
      }
    }
    else {
      console.error(response1.message);
    }
  }


  const options = {
    plugins: {
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };
  
  const labels = userChartLabels;
  
  const data1 = {
    labels,
    datasets: userChartData,
  };

  const handleEdit = async (board_id) => {
    let data = await getTeamBoardDetails(board_id, 'ORGANIZATION');
    if(data.status){
      console.log(data.data)
      setSingleBoard(data.data);
      setModalBackgroundImage({
        backgroundImage: "url(" +data.data.header_image+ ")",
        backgroundSize: "cover"
      });
        setModalShow(true);
    }
    else if(!data.status && data.message){
      console.log(data.message);
    }
	}

  const closeModal = () => {
		setModalShow(false);
	}
  
  const StoreList = ({ name, items, id }) => {
    return (
      <Col sm={12} lg={6} xl={4}>
          <div>
              <div className="list-wrapper">
                  <div className="list-header">
                    <div className="list-text">
                      <h2 className="list-name">{name}</h2>
                    </div>
                    <div className="list-header-bottom"></div>
                  </div>
                  <ul className="list-cards">
                    {items.map((item, index) => (
                        <li className="list-card" style={{marginTop:'10px'}} onClick={() => handleEdit(item.id)}>
                          <div className="list-card-box">
                            <div className="list-card-item">
                              <b><p className="card-name">{item.title}</p></b>
                              {/* <p className="card-name">{(item.description.length > 90) ? (item.description).slice(0, 90)+'...' : item.description}</p> */}
                              <div className="card-front-badges">
                                {
                                  (item.description !== '' && item.description !== null) &&
                                  (
                                    <span className="badge-card badge-card-description">
                                        <span className="icon description-icon">
                                          <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10C21 9.44772 20.5523 9 20 9H4ZM3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H4C3.44772 15 3 14.5523 3 14ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H4Z" fill="currentColor"></path></svg>
                                        </span>
                                    </span>
                                  )
                                }

                                {
                                  (item.attachments.length > 0) &&
                                  (
                                    <span className="badge-card badge-card-description" style={{float:'right'}}>
                                        <span className="icon description-icon">
                                          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path>
                                          </svg>
                                        </span>
                                      <span className="badge-card-text checklist-text">{item.attachments.length}</span>
                                    </span> 
                                  )
                                }
                                  
                                  {/* <span className="badge-card checklist-badge">
                                    <span className="icon checklist-icon">
                                      <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V18H6V6L16 6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H6ZM8.73534 10.3223C8.36105 9.91618 7.72841 9.89038 7.3223 10.2647C6.91619 10.639 6.89039 11.2716 7.26467 11.6777L10.8768 15.597C11.4143 16.1231 12.2145 16.1231 12.7111 15.6264L13.0754 15.2683C13.3699 14.9785 13.6981 14.6556 14.0516 14.3075C15.0614 13.313 16.0713 12.3169 17.014 11.3848L17.0543 11.3449C18.7291 9.68869 20.0004 8.42365 20.712 7.70223C21.0998 7.30904 21.0954 6.67589 20.7022 6.28805C20.309 5.90022 19.6759 5.90457 19.2881 6.29777C18.5843 7.01131 17.3169 8.27244 15.648 9.92281L15.6077 9.96263C14.6662 10.8937 13.6572 11.8889 12.6483 12.8825L11.8329 13.6851L8.73534 10.3223Z" fill="currentColor"></path></svg>
                                    </span>
                                    <span className="badge-card-text checklist-text">0/4</span>
                                  </span> */}
                              </div>
                            </div>
                          </div>
                        </li>
                  ))}
                </ul>
              </div>
          </div>
      </Col>
    );
  }
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">

                      {
                          (successMsg) ?  
                          <div>
                            <div className="settings-card-header">Team Created</div>
                            <div className="settings-card-body">
                              <div className="mb-1 flex flex-col items-center">
                                <p className="text-sm text-gray-700 add_member_success_text">Your team has been updated. Happy learning!</p>
                              </div>
                            </div>
                          </div>
                          : ''
                        }

                        <div className="settings-card">
                        <div className="settings-card-header">Team Memeber Progress</div>
                          <div className="settings-card-body">
                            <div className="tract-process-container-xl">
                              <ul className="settings-nav settings-nav-borders nav nav-tabs" role="tablist">
                                  <li className="nav-item">
                                      <a className={`settings-nav-link ${tabIndex == 1 ? 'active' : ''} ms-0`} href="#roadmaps" data-bs-toggle="tab">Roadmaps</a>
                                  </li>
                                  <li className="nav-item">
                                      <a className={`settings-nav-link ${tabIndex == 2 ? 'active' : ''} `} href="#onboarding" data-bs-toggle="tab">Onboarding</a>
                                  </li>
                              </ul>
                              <hr className="settings-hr mt-0 mb-4"/>
                              <div className="tab-content">
                                <div className={`tab-pane ${tabIndex == 1 ? 'active' : ''}`} id="roadmaps">
                                  { (!chartLoading) &&  
                                    (<div>
                                      <Form.Label>Select Roadmap</Form.Label>
                                      <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleChange(e)}>
                                        {roadmaps.map((roadmap) => (
                                          <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                                        ))}
                                      </Form.Select>

                                      {
                                        (chartLabels.length > 0) ? 
                                        (chartData.reduce((a, b) => a + b, 0) != 0) ? 
                                        <div><div style={{height:"800px",width:"800px",margin:"auto"}} className="proccessChart" ><Radar data={data} /></div> <Line options={options} data={data1} /></div>
                                      : <div style={{textAlign: "center", marginTop: "50px"}}>Reporting will be done when users take the skill assessment.</div> : <div style={{textAlign: "center", marginTop: "50px"}}>No skill found</div>
                                      }
                                      
                                      <hr></hr>
                                    </div>)
                                  }

                                  {
                                  (!loading) ? 
                                  <div>
                                    {/* <div className="settings-card-header">Team Memeber Progress</div> */}
                                    <div className="settings-card-body">
                                        <Row>
                                            {acceptedMembers.map((element, index) => (
                                            <Col sm={12} md={12} lg={4} className="mb-3">
                                                <div className="h-full min-h-270 overflow-hidden rounded-3 border">
                                                  <div className="position-relative d-flex gap-3 border-bottom p-3">
                                                    <img src="/img/default-avatar.png" alt="Dev" className="h-8 min-h-32 w-8 min-w-32 rounded-circle"/>
                                                    <div className="inline-grid w-100 profile">
                                                      <div className="d-flex justify-content-between items-center ">
                                                        <h3 className="truncate font-medium">{element.fname + " " + element.lname}</h3>
                                                        {
                                                          (element.email  ===  theLoginUser.email) ? <span className="rounded-3 bg-danger px-1 py-0.5 text-xs text-white">You</span> : ''
                                                        }
                                                      </div>
                                                      <p className="truncate fs-6">{element.email}</p>
                                                    </div>
                                                  </div>
                                                  <div className="position-relative d-flex grow flex-column p-3">
                                                  roadmaps
                                                  {element.roadmaps.map((roadmap, roadmap_index) => (
                                                    <button className="group position-relative overflow-hidden rounded-3 border p-2 hover:border-gray-300 hover:text-black focus:outline-none btn btn-outline-secondary" onClick={() => handleHrefRedirect("/user-roadmap-progress/" , roadmap.roadmap_id + "/" + element.user_id)}>
                                                      <span className="position-relative z-10 d-flex align-items-center justify-content-between text-sm">
                                                        <span className="inline-grid">
                                                          <span className="truncate">{roadmap.roadmap_name}</span>
                                                        </span>
                                                        <span className="ml-1.5 shrink-0 text-xs text-gray-400 fn-12">{roadmap.score} / 5</span>
                                                      </span>
                                                      <span className="position-absolute inset-0 bg-gray-100 group-hover:bg-gray-200" style={{ width: '0%' }}></span>
                                                    </button>
                                                  ))}
                                                  {/* <button className="text-sm text-gray-400 border-0 text-decoration-underline bg-transparent">+ 3 more</button> */}
                                                  </div>
                                                </div>
                                            </Col>          
                                            ))}
                                        </Row>
                                    </div>
                                  </div>
                                  : ''
                                  }
                                </div>
                                <div className={`tab-pane ${tabIndex == 2 ? 'active' : ''}`} id="onboarding">
                                { (!loading) &&  
                                    (<div>
                                      <Form.Label>Select User</Form.Label>
                                      <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleUserChange(e)}>
                                        {onlyMembers.filter(newVal => (newVal.is_admin == 0)).map((element, index) => (
                                          <option value={element.user_id} >{element.name} ({element.email})</option>
                                        ))}
                                      </Form.Select>

                                      <br/>
                                      <br/>

                                      {
                                        (stores.length > 0) && (
                                          <Row>
                                            {stores.map((store, index) => (
                                              StoreList(store)
                                            ))}
                                          </Row>
                                        )
                                      }
                                      
                                      
                                      <hr></hr>
                                    </div>)
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                </div>
                    
              </Row>
            </main>
          </Container>
          <Modal show={modalShow} size="lg" centered className="card-detail-modal">
          {
            
            (singleBoard != null) && (
              <>
              <Modal.Header className="card-cover" closeButton onClick={closeModal} style={modalBackgroundImage}
              >
              </Modal.Header>
              <Modal.Body>
                  <div className="card-detail-header">
                    <div className="d-flex align-items-center">
                      <span className="card-header-icon"><i className="fa solid fa-credit-card"></i></span>
                      <div className="modal-title">
                        <h2 className="card-detail-title">{singleBoard.title}</h2>
                      </div>
                    </div>
                    <div className="card-detail-inline-content">
                      {/* <p>in list </p> */}
                    </div>
                  </div>
                  <Row>
                    <Col lg="9">
                      <div className="card-detail-header">
                        <div className="d-flex align-items-center">
                          <span className="card-header-icon"><i className="fa solid fa-align-justify"></i></span>
                          <div className="modal-title">
                            <h4 className="card-detail-title card-detail-desc-title">Description</h4>
                          </div>
                        </div>
                        <div className="card-detail-inline-content">
                          <div dangerouslySetInnerHTML={ { __html: singleBoard.description}} />
                        </div>
                      </div>
                      {
                        (singleBoard.attachments.length > 0) && (
                          <div className="card-detail-header">
                            <div className="d-flex align-items-center">
                              <span className="card-header-icon"><i className="fa solid fa-paperclip"></i></span>
                              <div className="modal-title">
                                <h4 className="card-detail-title card-detail-desc-title">Attachments</h4>
                              </div>
                            </div>
                            <div className="card-detail-inline-content">
                                <div className="attachment-list">
                                  {
                                  singleBoard.attachments.map((row,index) => (
                                    <div className="attachment-thumbnail">
                                        <img src={row.src} className="attachment-thumbnail-preview"/>
                                        <p className="attachment-thumbnail-details">
                                            <span className="attachment-thumbnail-name">{row.name}</span>
                                            {/* <span className="attachment-thumbnail-details-title-options">Added <span className="date"></span></span> */}
                                        </p>
                                    </div>
                                  ))
                                  }
                                </div>
                            </div>
                          </div>
                        )
                      }
                    </Col>
                    {/* <Col lg="3">
                      <div className="card-sidebar">
                          <div className="card-action-box">
                            <h4 className="action-title">Actions</h4>
                            <div className="action-button">
                              <button className="btn w-100 btn-secondary"><i className="fa fa-share-alt"></i> Share</button>
                            </div>
                          </div>
                      </div>
                    </Col> */}
                  </Row>
                  
                
              </Modal.Body>
              </>
            )
          }
          </Modal>
        </div>
      </div>
  );
};

export default ProgressOrganization;