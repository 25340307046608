import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Row, Form, Container } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";

const AddMembersOrganization = () => {

  const {logout, addMembersToOrganization, getTeamDetails, getLoggedInUser, showToast} = useContext(UserContext);
  const [theLoginUser, setLoginUser] = useState({email:""});
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([{
    user_name: '',
    email:'',
    role_id:''
  }]);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  

  const isValidEmail = (email) => {
    const errors = {}

    if (!email) {
      errors.message = 'Please add email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.message = 'Invalid email address'
    }

    return errors
  }


  const handleSubmit = async (e) => {
      e.preventDefault();

      let tempvalidate = false;
      formValues.forEach(element => {
        let isValid = isValidEmail(element.email);
        if(Object.keys(isValid).length > 0){
          showToast(isValid.message, true);
          tempvalidate = true;
          return false;
        }
      });

      if(tempvalidate) {
        return false;        
      }
      let data = await addMembersToOrganization({team_id:id,emails:(JSON.stringify(formValues)), name:(JSON.stringify(formValues))});
      if(data.status){
          e.target.reset();
          showToast('Your team has been updated. Happy learning!', false);
          navigate(`/createorg/add-members/${id}`);
          setTimeout(
          () => {
            fetchData();
          },500);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };

  let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      console.log(newFormValues);
      setFormValues(newFormValues);
  }
      
  let addFormFields = () => {
      setFormValues([...formValues, {
        user_name:'',
        email:'',
        role_id:''
      }])
  }

  let removeFormFields = (i) => {
     const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
      }
  }

  const fetchData = async () => {
      setLoading(true);
      const user_response = await getLoggedInUser();
      setLoginUser(user_response);
  
      //
      const team_response = await getTeamDetails(id);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          if(team_response.team_members.length  ===  0) {
            team_response.team_members[0] = {
              user_name:'',
              email:'',
              role_id:''
            };
          }
          setFormValues(team_response.team_members);
          setRoles(team_response.roles);
          setLoading(false);
        }
      }
      else {
          showToast(team_response.message, true);
      }
    };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      
                        <div className="settings-card">
                          
                          {
                          (!loading) ? 
                          <div>
                            <div className="settings-card-header">Invite your Team</div>
                            <div className="settings-card-body">
                                <form  onSubmit={handleSubmit}>
                                  
                                    <Row>
                                    {formValues.map((element, index) => (
                                      <div className={"form-inline d-flex justify-content-between align-items-center" + ( (element.email  ===  theLoginUser.email) ? "no_click" : "")} key={index}>
                                        <Form.Control
                                          type="text"
                                          name="user_name"
                                          className="addmember_name"
                                          value={element.user_name || ""}
                                          placeholder="Full Name"
                                          onChange={e => handleChange(index, e)}
                                        />
                                        <Form.Control
                                          type="email"
                                          name="email"
                                          className="addmember_email"
                                          value={element.email || ""}
                                          placeholder="Email"
                                          onChange={e => handleChange(index, e)}
                                        />
                                        <Form.Select name="role_id" className="addmember_role form-control" onChange={e => handleChange(index, e)}>
                                          {roles.map((role, index) => (
                                            <option value={role.id} selected={(element.role_id ? (element.role_id  ===  role.id) : (role.title === 'Member') )}>{role.title}</option>
                                          ))}

                                        </Form.Select>
                                        <button type="button"  className="btn btn-danger pull-right remove addmember_email_remove" onClick={() => removeFormFields(index)}><i class="fa fa-remove"></i></button>                                    
                                      </div>
                                    ))}
                                    </Row>


                                    <Row>
                                    <div className="button-section">
                                        <button className="button add" type="button" onClick={() => addFormFields()}>+ Add another</button>
                                        <button className="button submit" type="submit">Save</button>
                                    </div>
                                    </Row>
                                </form>
                            </div>
                          </div>
                          : ''
                          }

                      </div>
                    </div>
                  </div>
                      
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default AddMembersOrganization;