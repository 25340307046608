import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Accordion, Card } from 'react-bootstrap';
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import * as myConstent from '../contexts/ConstContext';

const UserAssessmentHistory = () => {
  const { user, getRoadmapsOfAssessmentsById, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
  const [history, setHistory] = useState([]);
  const [roadmap, setRoadmap] = useState({});
  
  const decoratedOnClick = (id) => {
    setIsActive(id);
    /*let eventKey = e.currentTarget.getAttribute('eventKey');*/

  };

  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const res = await getRoadmapsOfAssessmentsById({roadmap_id:id, user_id:user.id});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){
          if(res.data){
            setHistory(res.data.roadmap_history);
            setRoadmap(res.data.roadmap);
          }
          setLoading(false);
      }else{
      }
    };
    
    fetchData();
  }, []);

  return (
    <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
        <Container fluid>
          <Row>
            <div className="col-md-12">
              {!loading && (
            <>

            <center><h1>{roadmap.roadmap_name} User Assessment History</h1></center>
            
            <div className="table-responsive pt-4 pb-2">
              <table id="history_table" className="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Score</th>
                    <th>Reviewer Name</th>
                    <th>Assessment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((row, index) => (
                    <tr key={index}>
                      {(row.user_id === user.id) ? <td>{user.fname} {user.lname}</td> : <td>{row.users.fname} {row.users.lname}</td>}
                      <td>{row.total_score}</td> 
                      <td> {(row.reviewer_user != null) ? row.reviewer_user.fname + ' ' + row.reviewer_user.lname : ''}</td>
                      <td>{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </div>
              
            </>
          )}
          </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default UserAssessmentHistory;