export const BASEURL = 'https://algence.com/backend/api';
export const LOGINURL = 'login';
export const LOGOUTURL = 'logout';
export const FORGOTPASSWORDURL = 'forgot_password';
export const RESETPASSWORDURL = 'reset_password';
export const REGISTERURL = 'register';
export const VERIFYACCOUNTURL = 'account_verification';
export const ROADMAPURL = 'getRoadmap';
export const SKILLURL = 'getSkill';
export const ROADMAPBYIDURL = 'getRoadmapById';
export const POSTASSESSMENTURL = 'postAssessment';
export const GETASSESSMENTURL = 'getAssessment';
export const GETROADMAPASSESSMENTHISTORYURL = 'getRoadmapAssessmentHistory';
export const GETROADMAPOFASSESSMENTURL = 'getRoadmapsOfAssessments';
export const GETCHARTDATABYUSERURL = 'getChartDataByUser';
export const GETINNERLEVELCHARTDATABYUSERURL = 'getInnerLevelChartDataByUser';
export const SKILLQUESTIONURL = 'getSkillQuestion';
export const GETUSERBOOKMARKURL = 'getUserBookmark';
export const POSTUSERBOOKMARK = 'postUserBookmark';
export const DELETEUSERACCOUNT = 'deleteUserAccount';
export const CREATEORGANIZATION = 'createOrganization';
export const ADDROADMAPSTOORGANIZATION = 'addRoadmapsToOrganization';
export const ADDMEMBERSTOORGANIZATION = 'addMembersToOrganization';
export const REMOVEMEMBERSTOORGANIZATION = 'removeMemberToOrganization';
export const CREATESTUDYGROUP = 'createStudyGroup';
export const ADDROADMAPSTOGROUP = 'addRoadmapsToGroup';
export const ADDMEMBERSTOGROUP = 'addMembersToGroup';
export const GETTEAMSOFORGANIZATION = 'getTeamsOfOrganization';
export const ADDTEAMINORGANIZATION = 'addTeamInOrganization';
export const GETTEAMSOFORGANIZATIONBYTEAMID = 'getTeamsOfOrganizationByTeamId';
export const GETTEAMDETAILS = 'getTeamDetails';
export const GETSTUDYTEAMDETAILS = 'getStudyTeamDetails';
export const GETSTUDYTEAMS = 'getStudyTeams';
export const UPDATESTUDYGROUP = 'updateStudyGroup';
export const DELETESTUDYGROUP = 'deleteStudyGroup';
export const UPDATETEAM = 'updateTeam';
export const DELETETEAM = 'deleteTeam';
export const GETROLES = 'getRoles';
export const GETTEAMBYTOKEN = 'getTeamByToken';
export const UPDATETEAMBYTOKEN = 'updateTeamByToken';
export const GETSTUDYGROUPBYTOKEN = 'getStudyGroupByToken';
export const UPDATESTUDYGROUPBYTOKEN = 'updateStudyGroupByToken';
export const CAREATEROADMAPANDADDROADMAPTOORGANIZATIONORSTUDYGROUP = 'createRoadmapAndAddToOrganizationOrStudyGroup';
export const GETROADMAPBYTEAMID = 'getRoadmapByTeamId';
export const DELETEROADMAP = 'deleteRoadmap';
export const SAVEROADMAPSKILLANDQUESTION = 'saveRoadmapSkillAndQuestions';
export const GETTEAMSROADMAPS = 'getTeamsRoadmaps';
export const GETROADMAPSSPIDERCHARTDATA = 'getRoadmapsSpiderChartData';
export const SENDFEEDBACK = 'sendFeedback';
export const GETFEEDBACKBYTOKEN = 'getFeedbackByToken';
export const GETSKILLLEVELS = 'getSkillLevels'; 
export const GETROADMAPOFASSESSMENTBYIDURL = 'getRoadmapsOfAssessmentsById';
export const GETPENDINGFEEDBACKASSESSMENTDEATILBYUSERIDURL = 'getPendingFeedbackAssessmentDetailByUserId';
export const ADDUPDATEUSERSKILLQUESTIONSTATUSURL = 'addUpdateUserSkillQuestionStatus';
export const GETUSERTEAMSDATAURL = 'getUserTeamsData';
export const GETTRACKPROGRESSSELFDATAURL = 'getTrackProgressSelfData';
export const GETTRACKPROGRESSTEAMDATAURL = 'getTrackProgressTeamData';
export const GETTEAMMEMBERSASSESSMENTHISTORYURL = 'getTeamMembersAssessmentHistory';
export const GETALLASSESSMENTHISTORYOFUSERURL = 'getAllAssessmentHistoryOfUser';
export const GETSINGLEASSESSMENTDATAURL = 'getSingleAssessmentData';
export const GETSTAKEHOLDERUSERLISTWITHROADMAPSCHARTDATAURL = 'getStakeholderUserListWithRoadmapsChartData';
export const GETBOARDS = 'getBoards';
export const ADDBOARDSTOORGANIZATION = 'addBoardsToOrganization';
export const GETTEAMBOARDS = 'getTeamBoards';
export const CREATEUPDATETEAMBOARD = 'createUpdateTeamBoard';
export const UPDATEBOARDSTATUS = 'updateBoardStatus';
export const GETTEAMBOARDDETAILS = 'getTeamBoardDetails';
export const UPDATETEAMBOARD = 'updateTeamBoard';
export const DELETETEAMBOARD = 'deleteTeamBoard';
export const DELETETEAMBOARDATTACHMENT = 'deleteTeamBoardAttachment';
export const SUPPORTURL = 'saveSupportTicket';
export const GETSUPPORTTICKETURL = 'getSupportTickets';
export const GETSINGLESUPPORTTICKETURL = 'getSingleSupportTicket';
export const SAVEREPLYTOSUPPPORTTICKTEURL = 'saveReplyToSupportTicket';
export const GETALLTEAMSROADMAPSBYUSERIDURL = 'getAllTeamsRoadmapsByUserId';
export const GETROADMAPSSPIDERCHARTDATAURL = 'getOrganizationRoadmapsSpiderChartData';
export const GETUSERSURL = 'getUsers';
export const GETORGANIZATIONURL = 'getOrganization';
export const GETROADMAPSWITHPERCENTILEDATAURL = 'getRoadmapsWithPercentileData';
export const CAREATEUPDATEROADMAPS = 'createUpdateRoadmap';
export const GETSINGLEROADMAPURL = 'getSingleRoadmap';
export const GETSINGLEUSERDETAILURL = 'getSingleUserDetail';
export const UPDATESINGLEUSERDETAILURL = 'updateSingleUserDetail';
export const CHANGEUSERPASSWORDURL = 'changeUserPassword';
export const GETDASHBOARDCOUNTSURL = 'getDashboardCounts';
export const BULKROADMAPCHANGEURL = 'bulkRoadmapChange';
export const BULKADDEDITROADMAPSKILLSANDQUESTIONSURL = 'bulkAddEditRoadmapSkillsAndQuestions';
export const ROADMAPIMPORTURL = 'importRoadmaps';
export const ONBOARDINGPLAYBOOKCREATEURL = 'onboardingPlaybookCreate';
export const ONBOARDINGPLAYBOOKLISTURL = 'onboardingPlaybookList';
export const ONBOARDINGPLAYBOOKDETAILURL = 'onboardingPlaybookDetail';
export const ONBOARDINGPLAYBOOKDELETEURL = 'onboardingPlaybookDelete';
export const ONBOARDINGPLAYBOOKARTICLECREATEUPDATEURL = 'onboardingPlaybookArticleCreateUpdate';
export const ONBOARDINGPLAYBOOKARTICLEDELETEURL = 'onboardingPlaybookArticleDelete';
export const ONBOARDINGPLAYBOOKARTICLEDETAILURL = 'onboardingPlaybookArticleDetail';
export const ONBOARDINGPLAYBOOKARTICLEUPDATESINGLEFIELDURL = 'onboardingPlaybookArticleUpdateSingleField';
export const COPYCUSTOMROADMAPURL = 'copyCustomRoadmap';
export const GETPLAYBOOKARTICLEBYKEYWORD = 'getPlaybookArticlesByKeyword';
export const GETHIRINGTEAMDETAILS = 'getHiringTeamDetails';
export const CREATEHIRINGURL = 'createHiring';
export const ADDROADMAPSTOHIRING = 'addRoadmapsToHiring';
export const ADDMEMBERSTOHIRING = 'addMembersToHiring';
export const GETHIRINGTEAMS = 'getHiringTeams';
export const GETHIRINGBYTOKENURL = 'getHiringByToken';
export const UPDATEHIRINGBYTOKENURL = 'updateHiringByToken';
export const UPDATEHIRINGNURL = 'updateHiring';
export const DELETEHIRINGNURL = 'deleteHiring';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKCREATEUPDATEURL = 'organizationTeamOnboardingPlaybookCreateUpdate';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKLISTURL = 'organizationTeamOnboardingPlaybookList';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKDETAILURL = 'organizationTeamOnboardingPlaybookDetail';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKDELETEURL = 'organizationTeamOnboardingPlaybookDelete';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKARTICLECREATEUPDATEURL = 'organizationTeamOnboardingPlaybookArticleCreateUpdate';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKARTICLEDELETEURL = 'organizationTeamOnboardingPlaybookArticleDelete';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKARTICLEDETAILURL = 'organizationTeamOnboardingPlaybookArticleDetail';
export const ORGANIZATIONTEAMONBOARDINGPLAYBOOKARTICLEUPDATESINGLEFIELDURL = 'organizationTeamOnboardingPlaybookArticleUpdateSingleField';
export const ORGANIZATIONTEAMGETPLAYBOOKARTICLEBYKEYWORD = 'organizationTeamGetPlaybookArticlesByKeyword';
export const GETMASTERUSERTEAMSDATAURL = 'getMasterUserTeamsData';
export const POSTACCESSRIGHTSURL = 'postAccessRights';
export const GETACCESSRIGHTSURL = 'getAccessRights';
export const GETALLTEAMSOFUSERURL = 'getAllTeamsOfUSer';
export const SHAREHIRINGURL = 'shareHiring';
export const GETHIRINGDATAUSINGTOKENURL = 'getHiringDataUsingToken';
export const GETPENDINGHIRINGASSESSMENTDEATILBYUSERIDURL = 'getPendingHiringAssessmentDetailByUserId';
export const USERROLELIST = 'userRoleList';
export const USERROLECREATEUPDATE = 'userRoleCreateUpdate';
export const USERROLEDETAIL = 'userRoleDetail';
export const USERROLEDELETE = 'userRoleDelete';
export const DATEFORMAT = 'DD-MM-YYYY';
export const DATETIMEFORMAT = 'YYYY-MM-DD hh:mm A';
export const DATATABLERECORDLIMIT = 5;
export const TOASTTIMELIMIT = 1000;