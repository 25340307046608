
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import { CSVLink } from "react-csv";
import { Row, Form, Modal, Container, Button, Col} from 'react-bootstrap';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const Support = () => {
  const { user, getSupportTickets, saveSupportTicket, saveReplyToSupportTicket, getSingleSupportTicket, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [singleData, setSingleData] = useState(null);
  const [errMsg, setErrMsg] = useState('');
  const [sucMsg, setSucMsg] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    subject:'',
    message:'',
    user_id:user.id
  });

  const [replyFormData, setReplyFormData] = useState({
    ticket_no:'',
    reply:'',
    sender:user.id
  });

  // Function to fetch data from the API
  const fetchData = async (is_first_load = 1) => {
    const res = await getSupportTickets(user.id);
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){
        setRecords(res.data);
        setLoading(false);
    }else{
    }

    setTimeout(() => {
        /*if(is_first_load == 1) {*/
        //     $("#history_table").DataTable().clear().draw();
        //     $("#history_table").DataTable().destroy();
        // }
        $("#support_table").DataTable({
          pagingType: "full_numbers",
          pageLength: myConstent.DATATABLERECORDLIMIT,
          dom: "Bfrtip",
          destroy: true,
          scrollX: true,
          select: {
              style: "single",
          },
          buttons: [
              {
              extend: "pageLength",
              className: "btn btn-secondary bg-secondary",
              }
          ],
          lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
          ],
          columnDefs: [
            {
              targets: 0,
              render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
              }
            }
          ]
        });
      /*}*/
    },2000);
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  
	const onChangeInput = (e) => {
      setFormData({
          ...formData,
          [e.target.name]:e.target.value
      });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.subject === "") {
          setErrMsg("Please enter subject"); return false;
      } else if(formData.message === "") {
          setErrMsg("Please enter message"); return false;
      } 
      else {
          setErrMsg("");
      }

      const data = await saveSupportTicket(formData);
      if(data.status){
          e.target.reset();
          setFormData({
              user_id:user.id,
              subject:'',
              message:''
          });
          setErrMsg(false);
          setSucMsg(data.message);
           $('#support_table').DataTable().clear().destroy();
           setRecords([]);
          setTimeout(() => {
              fetchData(0);
          },1000);
      }
      else if(!data.status && data.message){
          setErrMsg(data.message);
      }
  };

	const onChangeReplyInput = (e) => {
      setReplyFormData({
        ...replyFormData,
        [e.target.name]:e.target.value
      });
    };

    const handleCheck = async (ticket_id) => {
      const data = await getSingleSupportTicket(ticket_id);
        if(data.status){
          setSingleData(data.data);
          setReplyFormData({
            ticket_no:ticket_id,
            reply:'',
            sender:user.id
          });
          console.log(replyFormData);
          setErrMsg(false);
          setSucMsg(false);
        }
        else if(!data.status && data.message){
            setErrMsg(data.message);
        }
        setModalShow(true);
    };
    const closeModal = () => {
        setModalShow(false);
    }

    const handleReply = async (e) => {
         e.preventDefault();
         if(replyFormData.reply === "") {
            setErrMsg("Please enter message"); return false;
        } 
        else {
            setErrMsg("");
        }
        var ticket_no = replyFormData.ticket_no;
        const data = await saveReplyToSupportTicket(replyFormData);
        if(data.status){
            setReplyFormData({
            ticket_no:ticket_no,
            reply:'',
            sender:user.id
            });
            setErrMsg(false);
            setSucMsg(data.message);

            setTimeout(() => {
              handleCheck(ticket_no);
            },1000);
        }
        else if(!data.status && data.message){
            setErrMsg(data.message);
        }
    }

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <h1>Support Ticket</h1>
              <div className="p-0 pb-2">
                
              <Row>
              <Form onSubmit={handleSubmit} >
              <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Control
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={onChangeInput}
                    placeholder="Enter Subject"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      value={formData.message}
                      placeholder="Enter Message"
                      onChange={onChangeInput}
                      required
                    />
                </Form.Group>
                  {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
                  {sucMsg && <h4 className="err-msg text-success text-center">{sucMsg}</h4>}
                  
                  <div className="button-section">
                      <button className="button submit" type="submit">Send</button>
                  </div>
                </Form>
              </Row>
                {
                    (loading) ? <h6 className="text-center">Loading...</h6> :
                    (records.length > 0) && (
                        <table id="support_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ticket No</th>
                                <th>Subject</th>
                                <th>Message</th>
                                <th>Created Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((row, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{row.ticket_no}</td>
                                <td>{row.subject}</td>
                                <td>{row.message}</td>
                                <td>{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</td>
                                <td><button className='btn btn-sm btn-primary btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleCheck(row.ticket_no)}>
                                    Check
                                </button></td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    ) 
                }
              </div>
          </Container>

          
          <Modal show={modalShow} size="lg">
            <Modal.Header closeButton onClick={closeModal}>
            <Modal.Title>View</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {(singleData != null) && (
                <section>
                    <div className="container py-5">
                        <div className="card">
                          <div className="card-body">
                            <div className="parent-card-box">
                                <div className="d-flex flex-start align-items-center">
                                  {/* <h4 className="rounded-circle shadow-1-strong me-3 py-2 px-2 mb-0 text-white bg-secondary">A</h4> */}
                                  <div>
                                    <h6 className="fw-bold text-primary mb-1 ">{singleData.fname +' '+ singleData.lname}</h6>
                                    <p className="text-muted small mb-0">
                                      {Moment(singleData.created_at).format(myConstent.DATETIMEFORMAT)}
                                    </p>
                                  </div>
                                </div>

                                <p className="mt-3 mb-4 pb-2">
                                  {singleData.message}
                                </p>
                            </div>
                            {(singleData.replys).map((element) => ( 
                              (element.sender == "Admin") ?
                                <div className="child-card-box">
                                  <div className="d-flex flex-start align-items-center">
                                    {/* <h4 className="rounded-circle shadow-1-strong me-3 py-2 px-2 mb-0 text-white bg-secondary">A</h4> */}
                                    <div>
                                      <h6 className="fw-bold text-primary mb-1 ">Admin</h6>
                                      <p className="text-muted small mb-0">
                                        {Moment(element.created_at).format(myConstent.DATETIMEFORMAT)}
                                      </p>
                                    </div>
                                  </div>

                                  <p className="mt-3 mb-4 pb-2">
                                    {element.reply}
                                  </p>
                                </div>
                                :
                                <div className="parent-card-box">
                                  <div className="d-flex flex-start align-items-center">
                                    {/* <h4 className="rounded-circle shadow-1-strong me-3 py-2 px-2 mb-0 text-white bg-secondary">A</h4> */}
                                    <div>
                                      <h6 className="fw-bold text-primary mb-1 ">{singleData.fname +' '+ singleData.lname}</h6>
                                      <p className="text-muted small mb-0">
                                        {Moment(element.created_at).format(myConstent.DATETIMEFORMAT)}
                                      </p>
                                    </div>
                                  </div>

                                  <p className="mt-3 mb-4 pb-2">
                                    {element.reply}
                                  </p>
                              </div>
                             )
                            )}


                            
                          </div>
                          <div className="card-footer py-3 border-0" style={{backgroundColor: '#f8f9fa'}}>
                              <Row>
                                <Col lg="12">
                                <div className="d-flex flex-start w-100">
                                  <div className="form-outline w-100">
                                    <Form.Control
                                      as="textarea"
                                      rows={4}
                                      name="reply"
                                      value={replyFormData.reply}
                                      onChange={onChangeReplyInput}
                                      className="form-control"
                                      required
                                    />
                                  </div>
                                </div>
                                </Col>
                                <Col lg="12">
                                  {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
                                  {sucMsg && <h4 className="err-msg text-success text-center">{sucMsg}</h4>}
                                </Col>
                              </Row>
                            <div className="float-end mt-2 pt-1">
                              <button type="button" className="btn btn-primary btn-sm" onClick={handleReply}>Reply</button>
                            </div>
                          </div>
                        </div>
                    </div>
                </section>
                )}
            </Modal.Body>
           </Modal>
        </div>
      </div>
  );
};

export default Support;
