import React, {useContext, useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Container, Row } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";
import DeleteModal from "./deleteModal";

const TeamSettings = () => {

  const { getTeamDetails, logout, updateTeam, deleteTeam, postAccessRights, getAccessRights, showToast} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [isMasterAdmin, setMasterAdmin] = useState(0);
  const [roles, setRoles] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [formData, setFormData] = useState({
    team_id:id,
    team_name:''
  });
   const [rightsFormData, setRightsFormData] = useState({
    team_id:id,
    role_id:0,
  });
  const defaultModules = {
    teams: {
      view:true,
      add:true,
      delete:true
    },
    history : {
      view:true,
      add:true,
      delete:true
    },
    playbook : {
      view:true,
      add:true,
      delete:true
    }
  }
  const defaultModuleNames = ["teams","history","playbook"];

  const [modules, setModules] = useState(defaultModules);

  const fetchTeamData = async () => {
    //
    const team_response = await getTeamDetails(id);
    if(team_response){
      if(team_response.token_message){
        console.error(team_response.token_message);
        logout();
      }else{
        setFormData({
            team_id:id,
            team_name:team_response.data.team_name
        });
      }
    }
    else {
        showToast(team_response.message, true);
    }
  };

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.team_name === "") {
        showToast("Please enter team name", true); return false;
      }

      const data = await updateTeam(formData);
      if(data.status){
          e.target.reset();
          fetchTeamData();
          showToast("Updated successfully!", false);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };

  const openDeleteModal = async (e) => {
    setShowModal(true);
  }

  const deletTeam = async (e) => {
    const response = await deleteTeam(id);
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
           navigate("/profile-teams");
        }
      }
      else {
          showToast(response.message, true);
      }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

   let handleChange = (e) => {
    getAccessRightsData(e.target.value);
  }
  const handleCheck = (action,module_name, e) => {
    let newFormValues = modules;
      newFormValues[module_name][action] = e.target.checked;
      setModules(newFormValues);
      setRightsFormData({
        ...rightsFormData,
        accessright:newFormValues
      });
  }

  const saveAccessRights = async () => {
     
      const data = await postAccessRights(rightsFormData);
      if(data.status){
          showToast(data.message, false);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  }


  const getAccessRightsData = async (role_id) => {
    const access_response = await getAccessRights(id, role_id);
      if(access_response){
        if(access_response.token_message){
          console.error(access_response.token_message);
          logout();
        }else{

            var module_arr = defaultModules;
            if(access_response.data.access_rights !== null){
             var module_data = JSON.parse(access_response.data.access_rights); 
                module_arr = {teams:{
                  view:(module_data.teams.view === 1) ? true : false,
                  add:(module_data.teams.add === 1) ? true : false,
                  delete:(module_data.teams.delete === 1) ? true : false
                },
                history:{
                  view:(module_data.history.view === 1) ? true : false,
                  add:(module_data.history.add === 1) ? true : false,
                  delete:(module_data.history.delete === 1) ? true : false
                },
                playbook:{
                  view:(module_data.playbook.view === 1) ? true : false,
                  add:(module_data.playbook.add === 1) ? true : false,
                  delete:(module_data.playbook.delete === 1) ? true : false
                }
                };
          }
          setModules(module_arr);

          setRightsFormData({
            ...rightsFormData,
            role_id:access_response.data.role_id,
            accessright:module_arr
          });
        }
      }
      else {
          showToast(access_response.message, true);
      }
  }
   const fetchData = async () => {
      //
      const team_response = await getTeamDetails(id, (user === null ? "" : user.id));
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setFormData({
              team_id:id,
              team_name:team_response.data.team_name
          });
          setMasterAdmin(team_response.is_master_admin);
          setRoles(team_response.roles);
        }
      }
      else {
          showToast(team_response.message, true);
      }
    };
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
    // if (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.teams.view == 1){
    //     setIsAuthorized(true);
    // }
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      <div className="settings-card">
                        <div className="settings-card-header">Update Team Information</div>
                        <div className="settings-card-body">
                          <div className="tract-process-container-xl">

                          <Form onSubmit={handleSubmit} >
                            <Form.Group controlId="team_name" className="mb-3">
                              <Form.Label>Team Name*</Form.Label>
                              <Form.Control
                                type="text"
                                name="team_name"
                                value={formData.team_name}
                                placeholder="Team Name"
                                onChange={onChangeInput}
                              />
                            </Form.Group>

                            <div className="button-section">
                                <button className="button submit" type="submit">Save</button>
                            </div>

                            {/* {
                              (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.teams.delete == 1) && ( */}

                              <div className="row settings-row">
                                  <hr/>
                                  <h2>Delete Team</h2>
                                  <p>Permanently delete this team and all of its resources.</p>
                                  <button className="settings-delete-btn settings-btn-primary" onClick={openDeleteModal} type="button">Delete Team</button>
                              </div>
                            {/* )
                          } */}
                          </Form>
                              
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeleteModal show={showModal} deletTeam={deletTeam} handleCloseModal={handleCloseModal} />
                </div>
              </Row>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default TeamSettings;