import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import ProfileMenu from './profilemenu';
import DropdownMenu from './dropdownmenu';
import { UserContext } from '../contexts/UserContext';
import FilterButtons from './filterButtons';

const ProfileSkills = () => {
  const [roadmaps, setRoadmaps] = useState([]);
  const { getRoadmap, logout, postBookmark, user, getUserBookmark } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [menuSubItems, setMenuSubItems] = useState([]);
  const [bookmarkData, setBookmarkData] = useState([]);
  const [item, setItem] = useState([]);
  const [selectedRoadmapType, setSelectedRoadmapType] = useState('all');
  const [selectedRoadmapSubType, setSelectedRoadmapSubType] = useState('all');
  const [searchInput, setSearchInput] = useState("");
  const [subTypeColors] = useState({
    "Technical@Role Based":"success",
    "Technical@Skill Based":"info",
    "Non Technical@Role Based":"dark",
    "Non Technical@Soft Skills":"danger",
    "Custom@Custom":"warning"
  });

 
  const filterItem = (e) => {
    const curcatItem = e.target.value;
    const newItem = [...new Set(item.filter(newVal => (curcatItem === 'all') ? (newVal.roadmap_type !== curcatItem) : (newVal.roadmap_type === curcatItem)).map(Val => (Val.roadmap_subtype)))];
    setMenuSubItems(newItem);
    setSelectedRoadmapType(e.target.value);
    filterRoadmaps('1',e.target.value);
    document.getElementById("roadmap_sub_type").selectedIndex = 'all';
  }; 

  const filterSubItem = (e) => {
    setSelectedRoadmapSubType(e.target.value);
    filterRoadmaps('2',e.target.value);
  };

  const filterSubTypeItem = (type,subtype) => {
    console.log(type,subtype);
    setSelectedRoadmapType(type);
    setSelectedRoadmapSubType(subtype);
    filterRoadmaps(type,subtype);
  };

  const filterRoadmaps = (type, type_value) => {
    let roadmapType = "";
    let roadmapSubType = "";
    if(type === "1") {
      roadmapType = type_value;
      roadmapSubType = 'all';
    } else if(type === "2") {
      roadmapType = selectedRoadmapType;
      roadmapSubType = type_value;
    } else if(type != "1" && type != "2") {
      roadmapType = type;
      roadmapSubType = type_value;
    } else {
      roadmapType = selectedRoadmapType;
      roadmapSubType = selectedRoadmapSubType;
    }

    const filter_type = item.filter((val) => {
        if(roadmapType === 'all'){
          return val.roadmap_type !== roadmapType;
        }else{
          return val.roadmap_type === roadmapType;
        }
    });

    const filter_sub_type = filter_type.filter((val) => {
        if(roadmapSubType === 'all'){
          return val.roadmap_subtype !== roadmapSubType;
        }else{
          return val.roadmap_subtype === roadmapSubType;
        }
    });
    setRoadmaps(filter_sub_type);
  };

  // Function to post data on API
  const handlePostBookmark = async (e) => {
    var roadmap_id = e.currentTarget.getAttribute('data-id');
    var user_id = user.id;
    const response = await postBookmark({roadmap_id:roadmap_id, user_id:user_id});
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          getBookmark();
        }
      }
      else {
      }
  }; 

  // Function to fetch data from the API
  const getBookmark = async () => {
    var user_id = user.id;
    const response = await getUserBookmark({user_id:user_id});
        if(response){
          if(response.token_message){
            console.error(response.token_message);
            logout();
          }else{
            var bookmarakResult = [...new Set(response.data.map((Val) => Val.roadmap_id))];
            setBookmarkData(bookmarakResult);
          }
        }
        else {
        }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {

      setLoading(true);
      const response = await getRoadmap();
        if(response){
          if(response.token_message){
            console.error(response.token_message);
            logout();
          }else{
            setLoading(false);
            let result = response.data;
            setRoadmaps(result);
            var menuItem = [...new Set(result.map((Val) => Val.roadmap_type))];
            setMenuItems(menuItem);
            var menuSubItem = [...new Set(result.map((Val) => Val.roadmap_subtype))];
            setMenuSubItems(menuSubItem);
            setItem(result);
          }
        }
        else {
        } 
      getBookmark();
    };
    fetchData();
  }, []);

  return (
    <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
        <Container fluid>
          <Row>
            <Col>
              <h1>Welcome To Your Skill Rating Assessment!</h1>
              
            </Col>
          </Row>

          <div>
            <p>Select Roadmap Type Based on Your Choice</p>

          </div>
          <div className="mt-2">
            <input
             type="search"
             placeholder="Search here"
             onChange={handleChange}
             value={searchInput}
             className="form-control mb-3"
            />
            <FilterButtons 
              filterItem={filterItem}
              setItem={roadmaps}
              menuItems={menuItems}
              filterSubItem ={filterSubItem}
              menuSubItems = {menuSubItems}
              key="filter-btn"
            />
            <ul className="color_legend">
              <li onClick={() => filterSubTypeItem('all','all')}>
                <strong className="color_box bg-secondary">&nbsp;</strong>
                <span className="choice">All</span>
              </li>
              <li onClick={() => filterSubTypeItem('Technical','Role Based')}>
                <strong className="color_box bg-success">&nbsp;</strong>
                <span className="choice">Technical ( Role Based )</span>
              </li>
              <li onClick={() => filterSubTypeItem('Technical','Skill Based')}>
                <strong className="color_box bg-info">&nbsp;</strong>
                <span className="choice">Technical ( Skill Based )</span>
              </li>
              <li onClick={() => filterSubTypeItem('Non Technical','Role Based')}>
                <strong className="color_box bg-dark">&nbsp;</strong>
                <span className="choice">Non Technical ( Role Based )</span>
              </li>
              <li onClick={() => filterSubTypeItem('Non Technical','Soft Skills')}>
                <strong className="color_box bg-danger">&nbsp;</strong>
                <span className="choice">Non Technical ( Soft Skills )</span>
              </li>
              {/* <li onClick={() => filterSubTypeItem('Custom','Custom')}>
                <strong className="color_box bg-warning">&nbsp;</strong>
                <span className="choice">Custom</span>
              </li> */}
            </ul>
            {
              (loading) ? <h6 className="text-center">Loading...</h6> : 
              <Row>
              {
              roadmaps.filter(roadmap => {
                if (searchInput === '') {
                  return roadmap;
                } else if (roadmap.roadmap_name.toLowerCase().includes(searchInput.toLowerCase())) {
                  return roadmap;
                }
              }).map((roadmap, index) => (
                <Dropdown className="col-md-6 col-lg-4 col-xl-3 mt-3" key={roadmap.roadmap_id}>
                    <Dropdown.Toggle className="w-100" variant={subTypeColors[roadmap.roadmap_type+'@'+roadmap.roadmap_subtype]}>
                        {roadmap.roadmap_name}
                        <span className="coming_soon_txt">{(roadmap.skill_data == 0) ? ' (Coming Soon)' : ''}</span>
                    </Dropdown.Toggle>
                    <DropdownMenu  roadmapId={roadmap.roadmap_id} roadmapName={roadmap.roadmap_name} roadmapSlug={roadmap.roadmap_slug} />
                    <div data-id={roadmap.roadmap_id} onClick={handlePostBookmark} className={`bookmarkRibbon ${(bookmarkData.indexOf(roadmap.roadmap_id) !== -1) ? 'bookmarked' : ''}`}></div>
                    
                </Dropdown>
              ))}
              </Row>
            }
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProfileSkills;
