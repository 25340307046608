
import React, { useState, useEffect } from "react";

import { AboutUs } from "../components/aboutus";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";


export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
 
const About = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
  
    return (
        <div>
            <AboutUs data={landingPageData.About} />
      </div>
    );
};
 
export default AboutUs;

