import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';

const Fullstack = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [question, setQuestions] = useState([]);
  const [questionAll, setQuestionsAll] = useState([]);
  const [backBtnDisable, setBackBtnDisable] = useState(true);
  const [nextBtnDisable, setNextBtnDisable] = useState(false);
   const [submitBtnDisable, setSubmitBtnDisable] = useState(true);
   const navigate = useNavigate();
  const updateProgressBar = (questionInd) => {
    const progress = (questionInd + 1) / questionAll.length * 100;
    const progressBar = document.querySelector(".progress-bar");
    progressBar.style.width = progress + "%";
  };
  const location = useLocation();

  const {getSkills, logout, getRoadmapById, postAssessment, user} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [loadingRoadmap, setLoadingRoadmap] = useState(true);
  const [roadmap, setRoadmaps] = useState({});
   const [myItems, setMyItems] = useState([]);
   const [selectedQueData, setSelectedQueData] = useState({result:[]});
   const id = location.state.roadmapId;

  const handleNextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
    setQuestions(questionAll[questionIndex + 1]);
    updateProgressBar(questionIndex);
    setBackBtnDisable(false);

    var arr = questionAll.length;
    var idx = questionIndex + 1;
    if(selectedQueData.result.length > 0){
      if(selectedQueData.result[idx] === undefined){
        setSelectedAnswer(0);
      }else{
        setSelectedAnswer(selectedQueData.result[idx].questions[0].answer);
      }
    }else{
      setSelectedAnswer(0);
    }
    idx = idx % arr;
    if(idx === (arr - 1)){
      setNextBtnDisable(true);
      setSubmitBtnDisable(false);
      setTimeout(() => {
        updateProgressBar(idx);
      }, 500);
    }

  };
  
  const handleBackQuestion = () => {
    setQuestionIndex(questionIndex - 1);
    setQuestions(questionAll[questionIndex - 1]);
    updateProgressBar(questionIndex);
    setSelectedAnswer(selectedAnswer);
    setNextBtnDisable(false);
    setSubmitBtnDisable(true);
    var arr = questionAll.length;
    var idx = questionIndex;

    if (idx === 0) {
        idx = arr - 1;
    } else {
        idx = idx -1;
    }
    if(idx === 0){
      setBackBtnDisable(true);
      setTimeout(() => {
        updateProgressBar(-1);
      }, 500);
    }
    
    if(selectedQueData.result.length > 0){
      if(selectedQueData.result[idx] === undefined){
        setSelectedAnswer(0);
      }else{
        setSelectedAnswer(selectedQueData.result[idx].questions[0].answer);
      }
    }else{
        setSelectedAnswer(0);
    }

  };

  const handleSelectedAnswer = (answer) => {
    setSelectedAnswer(answer);
    const array = selectedQueData.result;
    if(array){
      const index = array.indexOf(selectedQueData.result[questionIndex]);

      if (index > -1) {
        array.splice(index, 1);
      }
      let qData1 = {result:array};
      setSelectedQueData(qData1);
    }

    let queRes = [];
    let total = 0;
    question.questionDetails.map((questionDetail, index) => (
        queRes.push({
          skill_question_id:questionDetail.skills_question_id,
          answer:answer
        })
      
    ));
    question.questionDetails.map((questionDetail, index) => (
       total += answer
    ));
    let itemsres = myItems;
      itemsres.push({
         skill_id: question.skill_id,
         questions:queRes,
         totalAnswer: total
    });
    let qData = {result:itemsres};
    setSelectedQueData(qData);

  };

  const handleReset = () => {
    setQuestionIndex(0);
    setQuestions(questionAll[0]);
    updateProgressBar(-1);
    setSelectedAnswer(0);
    setBackBtnDisable(true);
    setNextBtnDisable(false);
    setSubmitBtnDisable(true);
    setMyItems([]);
    var result = {result:[]};
    setSelectedQueData(result);
  };

  const handleSubmit = async (e) => {
    // Submit the user's answers
    // Display a message
    let formData = {
      user_id:user.id,
      roadmap_id:id,
      question_results:JSON.stringify(selectedQueData)
    };
    
    var result = await postAssessment(formData);
    if(result.status){
        alert(result.message);

        navigate(`/fullstack/result/${result.id}`);
    }
    else if(!result.status && result.message){
    }
    
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const response = await getSkills(id);
          if(response.token_message){
            console.error(response.token_message);
            logout();
          }else if(response.data){
              var result = response.data;
              setQuestions(result[questionIndex]);
              setQuestionsAll(result);
              setLoading(false);
              if(result.length === 0){
                setNextBtnDisable(true);
                setSubmitBtnDisable(true);
              }
          }else{
          }

    };

    const fetchRoadMapData = async () => {
      setLoadingRoadmap(true);
      const resRoadmap = await getRoadmapById({id:id});
          if(resRoadmap.token_message){
            console.error(resRoadmap.token_message);
            logout();
          }else if(resRoadmap){
              setRoadmaps(resRoadmap.data);
              setLoadingRoadmap(false);
          }else{
          }

    };
    fetchData();
    fetchRoadMapData();
  }, []);

  return (

    <div className="padding100 skills-rating h-full">
      <div className="container">
        {!loadingRoadmap && (
            <><h1>{(roadmap.roadmap_name).split(' ').map(function(word) {return word[0].toUpperCase() + word.substr(1);}).join(' ')} Roadmap</h1></>
        )}
        <p>{roadmap.roadmap_description}</p>
        <h3>Let's rate your skills using the below scale.</h3>
        <div className="progress">
           <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        
        <div className="question-and-answer-section">
          {
            (loading) ? <h6 className="text-center">Loading...</h6> : ''
          }
          {!loading && (questionAll.length > 0) && (
            <>
          <h3>Knowledge of {question.skill_name} - {question.skill_definition} </h3>
          <h4>Skills Include:</h4>
          <ul>
           
            {question.questionDetails.map((questionDetail, index) => (

              <li key={index}>{questionDetail.skill_question}</li>
            ))}
          </ul>
          
          <div className="answers-section">
            <div className="row row-cols-sm-auto row-cols-md-3 row-cols-lg-5 col-sm col-md col">
              <div className={selectedAnswer === 1 ? "active item" : 'item'}>
                <label htmlFor="answer-1">
                  <div className="box box-1">
                      <p className="description unselectable">I've never used or applied the skills and I'm completely new to the concept</p>
                  </div>
                  <div className="custom-radios">
                      <input type="radio" id="answer-1" name="answer" value="1" checked={selectedAnswer === 1}  onChange={() => handleSelectedAnswer(1)} />
                      <span><img src="/img/icon/check-icn.svg" alt="Checked Icon" /></span>
                      <p className="title">Novice</p>
                  </div>
                </label>
              </div>
              <div className={selectedAnswer === 2 ? "active item" : 'item'}>
                <label htmlFor="answer-2">
                  <div className="box box-2">
                      <p className="description unselectable">I've heard of this and understand what it is and when it's used. I might have started using and/or applying the skills, but I need a lot of hands-on assistance and guidance to do anything.</p>
                  </div>
                  <div className="custom-radios">
                      <input type="radio" id="answer-2" name="answer" value="2" checked={selectedAnswer === 2}  onChange={() => handleSelectedAnswer(2)}/>                 
                      <span><img src="/img/icon/check-icn.svg" alt="Checked Icon" /></span>
                      <p className="title">Advanced Beginner</p>
                  </div>
                </label>
              </div>
              <div className={selectedAnswer === 3 ? "active item" : 'item'}>
                <label htmlFor="answer-3">
                  <div className="box box-3">
                      <p className="description unselectable"> I can access, use and apply the skill and I'm self-sufficient in its application, but I need time and access to reference materials or experts in some cases to apply the skill.</p>
                  </div>
                  <div className="custom-radios">
                      <input type="radio" id="answer-3" name="answer" value="3" checked={selectedAnswer === 3} onChange={() => handleSelectedAnswer(3)} />   
                      <span><img src="/img/icon/check-icn.svg" alt="Checked Icon" /></span>
                      <p className="title">Competent</p>
                  </div>
                </label>
             </div>
              <div className={selectedAnswer === 4 ? "active item" : 'item'}>
                <label htmlFor="answer-4">
                  <div className="box box-4">
                      <p className="description unselectable">I can do this independently and effectively with little to no dependence on experts or reference materials.</p>
                  </div>
                  <div className="custom-radios">
                      <input type="radio" id="answer-4" name="answer" value="4" checked={selectedAnswer === 4} onChange={() => handleSelectedAnswer(4)} />
                      <span><img src="/img/icon/check-icn.svg" alt="Checked Icon" /></span>
                      <p className="title">Proficient</p>
                  </div>
                </label>
              </div>
              <div className={selectedAnswer === 5 ? "active item" : 'item'}>
                <label htmlFor="answer-5">
                  <div className="box box-5">
                      <p className="description unselectable">I am confident in this and can teach it effectively to others. I may be considered a SME or go-to expert and thought leader in this area</p>
                  </div>
                  <div className="custom-radios">
                      <input type="radio" id="answer-5" name="answer" value="5" checked={selectedAnswer === 5} onChange={() => handleSelectedAnswer(5)} />
                    
                      <span><img src="/img/icon/check-icn.svg" alt="Checked Icon" /></span>
                      <p className="title">Expert</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          </>
          )}
          {(questionAll.length === 0) && (
            <>
              <h4>Data not found</h4>
            </>
          )}
        </div>
        <div className="button-section">
            <button onClick={handleReset} className="reset-button">Reset</button>  
            <button onClick={handleSubmit} disabled={submitBtnDisable} className="btn submit-button">Submit</button> 
            <button onClick={handleBackQuestion} disabled={backBtnDisable} className="btn back-button">Back</button>
            <button onClick={handleNextQuestion} disabled={nextBtnDisable} className="btn next-button">Next</button>
          </div>
      </div>
    </div>
  );
};

export default Fullstack;