// Import ChartJS correctly
import ChartJS from 'chart.js/auto'; 
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Bar , Radar  } from "react-chartjs-2";
import ReactSpeedometer from "react-d3-speedometer";
import ProfileMenu from "./profilemenu";
import {UserContext} from '../contexts/UserContext';

const CheckAssessmentResult = () => {
  const { getSingleAssessmentData, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  var temp = [];
  temp['label'] = [];
  temp['data'] = [];
  const [chartSkillData, setChartSkillData] = useState(temp);

  
  var staticInnerSkillData = [];
  staticInnerSkillData['label'] = [];
  staticInnerSkillData['data'] = [];
  const [innerSkillData, setInnerSkillData] = useState(staticInnerSkillData);
  const [customSkillLabels, setCustomSkillLabels] = useState(["Entry Level Developer","Junior Developer","Mid Level Developer","Senior Developer","Principal Developer"]);


  const [assessment, setAssessment] = useState({});
  const [technicalLabels] = useState(["Entry Level Developer", "Junior Developer", "Mid Level Developer", "Senior Developer", "Principal Developer"]);
  const [nonTechnicalLabels] = useState(["Level-1", "Level-2", "Level-3", "Level-4", "Level-5"]);

  const { type } = useParams();
  const { id } = useParams();
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getSingleAssessmentData({id:id, type:type});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){ 
        setAssessment(res.data);
        var temp = [];
        temp['label'] = res.data.level_label;
        temp['data'] = Object.values(res.data.level_data);
        setInnerSkillData(temp);
        setCustomSkillLabels(res.data.level_skill_levels);

        
        var temp = [];
        temp['label'] = res.data.skill_labels;
        temp['data'] = res.data.skill_data;
        setChartSkillData(temp);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Sample data for the horizontal bar chart
  const barChartData = {
    labels: chartSkillData['label'],
    datasets: [
      {
        label: 'Skills',
        data: chartSkillData['data'],
        backgroundColor: "rgba(53, 162, 235, 0.5)"
      }
    ],
  };
  
  const barChartOptions = {
    indexAxis: "y", // Make the bars horizontal
    scales: {
      x: {
        display: true,
        scaleLabel: {
        display: true,
        labelString: 'Value'
        },
        ticks: {
        min: 0,
        max: 5,
        stepSize: 1,
        suggestedMin: 0.5,
        suggestedMax: 5.0,
        callback: function(label, index, labels) {
            switch (label) {
            case 0:
              return '';
            case 1:
                return customSkillLabels[0];
            case 2:
                return customSkillLabels[1];
            case 3:
                return customSkillLabels[2];
            case 4:
                return customSkillLabels[3];
            case 5:
                return customSkillLabels[4];
           default:
              return '';
            }
        }
        },
        gridLines: {
        display: false
        }
    },
      y: {
        beginAtZero: true,
      },
    },
  };

  // Sample data for the horizontal bar chart
  const levelBarChartData = {
    labels: innerSkillData['label'],
    datasets: [
      {
        label: customSkillLabels[0],
        data: innerSkillData['data'][0],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: customSkillLabels[1],
        data: innerSkillData['data'][1],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: customSkillLabels[2],
        data: innerSkillData['data'][2],
        backgroundColor: 'rgba(255, 99, 71, 0.5)',
      },
      {
        label: customSkillLabels[3],
        data: innerSkillData['data'][3],
        backgroundColor: 'rgba(205, 174, 71, 0.5)',
      },
      {
        label: customSkillLabels[4],
        data: innerSkillData['data'][4],
        backgroundColor: 'rgba(205, 174, 187, 0.5)',
      },
    ],
  };
  
  const levelBarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
    },
    scales: {
       y: {
         display: true,
         scaleLabel: {
           display: true,
           labelString: 'Value'
         },
         ticks: {
           min: 0,
           max: 5,
           stepSize: 1,
           suggestedMin: 0.5,
           suggestedMax: 5.0,
           callback: function(label, index, labels) {
             switch (label) {
               case 0:
                 return '';
               case 1:
                 return customSkillLabels[0];
               case 2:
                 return customSkillLabels[1];
               case 3:
                 return customSkillLabels[2];
               case 4:
                 return customSkillLabels[3];
               case 5:
                 return customSkillLabels[4];
                 default:
                  return '';
             }
           }
         },
         gridLines: {
           display: false
         }
       }
     },
  };

  const teamSpiderdata = {
    labels: chartSkillData['label'],
    datasets: [
      {
        label: '',
        data: chartSkillData['data'],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const horizontalBarChartLabels = ["Post Skill Assessment Rating","Pre Skill Assessment Rating"];
  const total_score_arr = [0,0];
  let total_score = Math.ceil(assessment.total_score);
  for (let i = 0; i <= 5; i++) {
      if(i == total_score) {
          total_score_arr[0] = assessment.total_score;
           break;
        }
  }

  const assume_score_arr = [0,0];
  let assume_score = assessment.assume_score;
  for (let i = 1; i <= 5; i++) {
      if(i == assume_score) {
          assume_score_arr[1] = assume_score;
           break;
        }
  }

  const horizontalBarChartOptions = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        x: {
            display: true,
            scaleLabel: {
            display: true,
            labelString: 'Value'
            },
            ticks: {
              min: 0,
              max: 5,
              stepSize: 1,
              callback: function(label, index, labels) {
                  switch (label) {
                  case 0:
                    return '';
                  case 1:
                      return (assessment.roadmap_type == "Technical") ? technicalLabels[0] : nonTechnicalLabels[0];
                  case 2:
                      return (assessment.roadmap_type == "Technical") ? technicalLabels[1] : nonTechnicalLabels[1];
                  case 3:
                      return (assessment.roadmap_type == "Technical") ? technicalLabels[2] : nonTechnicalLabels[2];
                  case 4:
                      return (assessment.roadmap_type == "Technical") ? technicalLabels[3] : nonTechnicalLabels[3];
                  case 5:
                      return (assessment.roadmap_type == "Technical") ? technicalLabels[4] : nonTechnicalLabels[4];
                default:
                    return '';
                  }
              }
            },
            gridLines: {
              display: true
            }
        }
    },
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          labels: {
              font: {
                  size: window.innerWidth > 1024 ? 14 : window.innerWidth > 768 ? 9 : 6
              },
              boxWidth: 10,
              boxHeight: 10

          }
        },
        title: {
          display: false,
          text: '',
        },
      },
  };
  
  const horizontalBarChartData = {
      labels: horizontalBarChartLabels,
      datasets: [
        {
          label: 'Post Skill Assessment Rating',
          data: total_score_arr,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: 'Pre Skill Assessment Rating',
          data: assume_score_arr,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    };

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <div className="button-section">
                <button onClick={() => navigate(-1)} className="button" title="back"><i className="fa fa-chevron-left"></i> Back</button>
            </div>
            <h1>Assessment Skills Score</h1>
            {!loading && (
            <div className="row">
              
                <div className="col-md-12 mt-4 mb-4">
                  <div className="chart-container">
                    <Bar options={horizontalBarChartOptions} data={horizontalBarChartData} />
                  </div>
                </div>

              <Bar data={levelBarChartData} options={levelBarChartOptions} />
             
              {
                (chartSkillData['data'].length > 0 && type == 'team-admin') ? 
                <div style={{height:"600px"}} ><Radar data={teamSpiderdata} /></div> : <Bar data={barChartData} options={barChartOptions} />
              }
            </div>
            )}
          </Container>
        </div>
      </div>
  );
};

export default CheckAssessmentResult;