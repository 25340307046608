
import React, { useState, useEffect } from "react";

//import { Navigation } from "../components/navigation";
import { Policy } from "../components/privacyPolicy";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import {Helmet} from "react-helmet";
import JsonMetaData from "../data/metaData.json";


export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
 
const PrivacyPolicy = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [pageMetaData, setPageMetaData] = useState(JsonMetaData);
    useEffect(() => {
      setLandingPageData(JsonData);
      //  const script = document.createElement("script");
      //   script.src = "../js/metaTag.js";
      //   script.async = true;
      //   document.body.appendChild(script);
    }, []);
  
    return (
        <div>
            <Helmet>
              <title>{pageMetaData.privacy.title}</title>
              <meta name="description" content={pageMetaData.privacy.description} />
              <meta name="keywords" content={pageMetaData.privacy.keywords} />
              <meta name="author" content={pageMetaData.privacy.author} />
              <meta property="og:title" content={pageMetaData.privacy.og_title} />
              <meta property="og:description" content={pageMetaData.privacy.og_description} />
              <meta property="og:image" content={pageMetaData.privacy.og_image} />
              <meta property="og:url" content={pageMetaData.privacy.og_url} />
            </Helmet>
            <Policy data={landingPageData.About} />
      </div>
    );
};
 
export default PrivacyPolicy;

