
import React, { useState, useEffect } from "react";

//import { Navigation } from "../components/navigation";
import { Plans } from "../components/pricing";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import {Helmet} from "react-helmet";
import JsonMetaData from "../data/metaData.json";
export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
 
const Pricing = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [pageMetaData, setPageMetaData] = useState(JsonMetaData);
    useEffect(() => {
      setLandingPageData(JsonData);
      // const script = document.createElement("script");
      // script.src = "../js/metaTag.js";
      // script.async = true;
      // document.body.appendChild(script);
    }, []);
  
    return (
        <div>
        <Helmet>
          <title>{pageMetaData.pricing.title}</title>
          <meta name="description" content={pageMetaData.pricing.description} />
          <meta name="keywords" content={pageMetaData.pricing.keywords} />
          <meta name="author" content={pageMetaData.pricing.author} />
          <meta property="og:title" content={pageMetaData.pricing.og_title} />
          <meta property="og:description" content={pageMetaData.pricing.og_description} />
          <meta property="og:image" content={pageMetaData.pricing.og_image} />
          <meta property="og:url" content={pageMetaData.pricing.og_url} />
      </Helmet>
        <Plans data={landingPageData.Pricing} />
      </div>
    );
};
 
export default Pricing;

