
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import { Container, Dropdown} from 'react-bootstrap';

import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const OnboardingArticleDetails = () => {
   const { onboardingPlaybookArticleDetail, logout } = useContext(UserContext);
   const [loading, setLoading] = useState(true);
   const [mainPost, setMainPost] = useState(null);
   const navigate = useNavigate();
   const [isOpenId, setIsOpenId] = useState(0);
   const location = useLocation();
   const { url_code } = useParams();
   const [id] = useState(location.state.current_id);
   const [parent_id, setParentId] = useState(location.state.parent_id);
   const [parent_url_code, setParentUrlCode] = useState('');
   const [parent_title, setParentTitle] = useState(location.state.parent_title);
   const [checkFileExt, setCheckFileExt] = useState(false);
  const [fileName, setFileName] = useState('');

   const fetchData = async (id) => {
      setLoading(true);
      const response = await onboardingPlaybookArticleDetail(id,1);
      if(response){
         if(response.token_message){
            console.error(response.token_message);
            logout();
         }else{
            setMainPost(response.data);
            if(response.data.selected_parent_article != ''){
               var parent_url_code = response.data.parent_articles_for_dropdown.filter(newVal => (newVal.id === response.data.selected_parent_article)).map((Val, id) => {
                      return Val;
                });
               setParentUrlCode(parent_url_code[0]['url_code']);
               setParentId(parent_url_code[0]['id']);
               setParentTitle(parent_url_code[0]['title']);
            }else{
                setParentUrlCode('');
                setParentId('');
               setParentTitle('');
            }
            
            var file_url = response.data.file;
            var file_name = file_url.split('/').pop()
            setFileName(file_name);
            var file_ext = file_url.split('.').pop();
            var fileExt = ['jpg','png','gif','jpeg'];
            if( fileExt.indexOf( file_ext.toLowerCase()) > -1 ) {
               setCheckFileExt(true);
            }
            else{
               setCheckFileExt(false);
            }
            setLoading(false);
         }
      }
      else {
         console.error(response.message);
      }
   }

   const toggleOpen = (id) => {
      setIsOpenId(id);
   } 


  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData(id);
  }, []);

  const navigateToArtical = (id) => {
    navigate(`/onboarding-playbook/article/${id}`);
  }
   const navigateToArticalDetail = (urlCode,id,par_title,par_id, parent_url_code= '') => {
      if(parent_url_code != ''){
        urlCode = parent_url_code;
      }
      navigate(`/onboarding-playbook/article/details/${urlCode}`, {
        state: {
            current_id : id,
            parent_id : par_id,
            parent_title: par_title
        }
     });
      setTimeout(
          () => {
            fetchData(id); 
            /*setParentId(par_id);
            setParentTitle(par_title);*/
      },1000);
   }
                        
  return (
      <div className="h-full front-view">
          <Container fluid>
            <div className="row">
              <div className="col-xs-12">
                <div className="box">
                  <div className="box-body">
                  {
                     (loading) ?
                     <h6 className="text-center">Loading...</h6>
                     :
                     (
                       <div className="row">
                         <div id="sideleft" className="side-active sideleft_grey col-md-3 col-lg-2">
                           <div id="knowledgebase-sidebar" className="flw-sideleft-wrapper has-border">
                             <div id="knowledgebase_section__tree" className="tree-view">
                               <div className="flw-knb-menu">
                                   <ul id="favouriteArticles" className="sortable-exception flw-knb-list">
                                       <li className="flw-knb-item" onClick={() => toggleOpen('fav_id')}>
                                          <div data-toggle="collapse" data-target="#favouriteArticles-list" aria-expanded={(isOpenId == 'fav_id') ? true : false} className={`collapse-trigger ${(isOpenId == 'fav_id') ? 'collapsed' : ''} d-flex align-items-center justify-content-between`}>
                                             <div className="flw-knb-handle margin-right-10 flw-knb-first-lvl flex d-flex align-items-center">
                                                <span id="ui-63ef56c5109675-34247950" className="margin-right-12 d-flex align-items-center">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#8899AE" viewBox="0 0 16 16" className="svgFillColor">
                                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                   </svg>
                                                </span>
                                                <span>Favourites</span>
                                             </div>
                                             {(mainPost.favorite_articles.length > 0) && (
                                                <i className="fas fa-angle-down rotate-icon"></i>
                                             )}
                                          </div>
                                          {
                                             (mainPost.favorite_articles.length > 0) && (
                                                <ul aria-expanded={(isOpenId == 'fav_id') ? true : false} id="36-childs" className={`flw-knb-list collapse-are collapse ${(isOpenId == 'fav_id') ? 'show in' : ''} row_position1 ui-sortable`}>
                                                   {
                                                      mainPost.favorite_articles.map((favorite,favindex) => (
                                                         <li id="44" data-order="1" data-id="44" data-parent-article-id="36" className="flw-knb-item js-knb-menu-item ui-sortable-handle">
                                                            <div data-toggle="collapse" data-target="#36-44-childs" aria-expanded="false" className="collapse-trigger collapsed d-flex align-items-start" >
                                                               <div data-action="drop" className="flw-knb-handle"><a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(favorite.url_code, favorite.id, parent_title, parent_id )} tabIndex="-1" data-sideview-url="false" data-id_article="44" data-id_parent_tree="0" data-toggle="sidecenter">
                                                                  {favorite.title}</a>
                                                               </div>
                                                            </div>
                                                         </li>
                                                      ))
                                                   }
                                                </ul>
                                             )
                                          }
                                        </li>
                                      
                                    </ul> 
                                  <ul className="flw-knb-list row_position ui-sortable">
                                    {
                                       mainPost.menu_articles.map((row,index) => (
                                     <li id="36" data-order="1" data-id="36" data-parent-article-id="0" className="flw-knb-item js-knb-menu-item" onClick={() => toggleOpen(row.id)}>
                                        <div data-toggle="collapse" data-target="#36-childs" aria-expanded={(isOpenId == row.id) ? true : false} className={`collapse-trigger ${(isOpenId == row.id) ? 'collapsed' : ''} d-flex align-items-center justify-content-between`}>
                                           <div data-action="drop" className="flw-knb-handle flw-knb-first-lvl">
                                              <div>
                                                 <span id="ui-63ef56c510c7e6-70955023 d-flex align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#91A2B6" viewBox="0 0 16 16" className="bi bi-file-earmark-text margin-right-10">
                                                       <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                                       <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                                                    </svg>
                                                 </span>
                                              </div>
                                              <a href="javascript:void(0);" tabIndex="-1" data-sideview-url="false" data-id_article="86" data-id_parent_tree="0" data-toggle="sidecenter" onClick={() => navigateToArticalDetail(row.url_code, row.id,'', 0)}>
                                              {row.title} 
                                              </a>
                                           </div>
                                            {(row.childs.length > 0) && (<i className="fas fa-angle-down rotate-icon"></i>)}
                                        </div>
                                        {
                                          (row.childs.length > 0) && (
                                           <ul aria-expanded={(isOpenId == row.id) ? true : false} id="36-childs" className={`flw-knb-list collapse-are collapse ${(isOpenId == row.id) ? 'show in' : ''} row_position1 ui-sortable`}>
                                              {
                                                   row.childs.map((child,childindex) => (
                                                      <li id="44" data-order="1" data-id="44" data-parent-article-id="36" className="flw-knb-item js-knb-menu-item ui-sortable-handle">
                                                         <div data-toggle="collapse" data-target="#36-44-childs" aria-expanded="false" className="collapse-trigger collapsed d-flex align-items-start" >
                                                            <div data-action="drop" className="flw-knb-handle"><a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(child.url_code, child.id, row.title, row.id)} tabIndex="-1" data-sideview-url="false" data-id_article="44" data-id_parent_tree="0" data-toggle="sidecenter">
                                                               {child.title}</a>
                                                            </div>
                                                         </div>
                                                      </li>
                                                   ))
                                                }
                                           </ul>
                                        )}
                                     </li>
                                     ))}
                                  </ul>
                               </div>
                             </div>
                           </div>
                         </div>
                         <div id="sidecenter" className="col-md-9 col-lg-10 mt-5 h-full">
                           <div className="page__content-wrapper ">
                             <div className="kb-article sidecenter-content-wrapper">
                               <div className="flw-kb-main-header-content">
                                 <div className="flw-kb-main-header-content-head">
                                     <ol className="breadcrumb">
                                       {
                                          (mainPost.parent_article != null) && 
                                          (
                                             <li><a href="javascript:void(0)" onClick={() => navigateToArtical(mainPost.parent_article.id)} className="margin-right-10">{mainPost.parent_article.name}</a><i className="fa fa-angle-right ms-2 margin-right-10"></i></li> 
                                          )
                                       }
                                       {
                                          (parent_title != '') && (parent_id != 0) &&
                                          (
                                             <li><a href="javascript:void(0)" onClick={() => navigateToArticalDetail(url_code, parent_id,'', 0, parent_url_code)} className="margin-right-10">{parent_title}</a><i className="fa fa-angle-right ms-2 margin-right-10"></i></li> 
                                          )
                                       }        
                                       <li className="active">{mainPost.title}</li>
                                    </ol>
                                 </div>
                                 <div className="row">
                                     <div className="col-md-12">
                                         <h1 className="article-name margin-bottom-20">
                                            {mainPost.title}             
                                         </h1>
                                         <hr style={{borderColor: "rgb(230, 233, 242) !important"}} />
                                     </div>
                                 </div>
                                 <div className="row margin-bottom-20">
                                     <div className="col-md-12 knb-word-wrap article-content">
                                        <div dangerouslySetInnerHTML={ { __html: mainPost.content}} />
                                     </div>
                                 </div>
                                 
                                 <div className="row margin-bottom-20">
                                   <div className="col-md-12 font-md">
                                    {
                                       (mainPost.file != '') && 
                                          (
                                           <div className="flw-attach__list">
                                             <div className="">
                                               <div>
                                                 <div className="flw-icon-with-text flw-portlet flw-portlet--light flw-attach__item" style={{cursor: "pointer"}}>
                                                     <div className="flw-attach__item-actions">
                                                         <a href={mainPost.file} target="_blank" className="flw-attach__action-icon margin-right-10">
                                                             <i className="fa fa-download"></i>
                                                         </a> 
                                                     </div>
                                                     
                                                     {checkFileExt && (
                                                      <div className="flw-icon-with-text__icon flw-attach__item-icon" 
                                                         style={{backgroundImage: "url("+mainPost.file+")" }}>
                                                      </div>
                                                      )} 
                                                      {!checkFileExt && (
                                                      <div className="flw-icon-with-text__icon flw-attach__item-icon" 
                                                            style={{backgroundImage: "url(/img/icon/file.svg)" }}>
                                                      </div> 
                                                      )}   
                                                     <div className="flw-icon-with-text__content text-overflow">
                                                       <div className="flw-icon-with-text__content-title flw-attach__item-title text-overflow">{fileName}</div> 
                                                       <div className="flw-icon-with-text__content-subtitle flw-attach__item-sub text-muted uppercase"></div> 
                                                     </div>
                                                 </div>
                                               </div> 
                                             </div>
                                           </div> 
                                          )}
                                     <div className="article__navigation">
                                       {
                                          (mainPost.previous_article != null) && 
                                             (
                                               <div className="article__navigation-item">
                                                   <a href="javascript:void(0);" onClick={() => navigateToArticalDetail(mainPost.previous_article.url_code, mainPost.previous_article.id, '', 0)} className="article__navigation-item-link" data-toggle="sidecenter" data-panel-info="in_side_panel" data-article-id="86" data-href="/_module/knowledgebase/view/item_article/86" data-article-path="86">
                                                       <span className="article__navigation-icon article__navigation-icon--prev">
                                                           <i className="fa fa-long-arrow-left"></i>
                                                       </span>

                                                       <span className="article__navigation-item-title">
                                                           <span className="article__navigation-item-label color_text_light">
                                                               Previous                                    
                                                           </span>
                                                          {mainPost.previous_article.title}                            
                                                       </span>
                                                    </a>
                                               </div>
                                             )
                                       }
                                       {
                                          (mainPost.next_article != null) && 
                                             (
                                               <div className="article__navigation-item">
                                                   <a href="javascript:void(0);" onClick={() => navigateToArticalDetail(mainPost.next_article.url_code, mainPost.next_article.id,'',0)} className="article__navigation-item-link article__navigation-item-link-next" data-toggle="sidecenter" data-panel-info="in_side_panel" data-article-id="99" data-href="/_module/knowledgebase/view/item_article/86-99" data-article-path="86-99">
                                                       <span className="article__navigation-item-title">
                                                           <span className="article__navigation-item-label color_text_light">
                                                               Next                                    
                                                           </span>
                                                           {mainPost.next_article.title}                               
                                                       </span>

                                                       <span className="article__navigation-icon article__navigation-icon--next">
                                                           
                                                       <i className="fa fa-long-arrow-right"></i>
                                                       </span>
                                                   </a>
                                               </div>
                                             )
                                       }
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     )
                  }
                  </div>
                </div>
              </div>
            </div>
          </Container>
      </div>
  );
};
export default OnboardingArticleDetails;