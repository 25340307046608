
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import { Container, Dropdown} from 'react-bootstrap';

import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const OnboardingPlaybookArticle = () => {
  const { onboardingPlaybookDetail, logout, getPlaybookArticlesByKeyword } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [searchData, setSearhData] = useState([]);
  const [mainPost, setMainPost] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  
  const navigateToArticalDetail = (url_code, id,parent_title,parent_id,playbookId) => {

    navigate(`/onboarding-playbook/article/details/${url_code}`, {
        state: {
            current_id : id,
            parent_id : parent_id,
            parent_title: parent_title,
            playbook_id:playbookId
        },
    });
  }

  const fetchData = async () => {
      setLoading(true);
      const response = await onboardingPlaybookDetail(id,1);
      if(response){
         if(response.token_message){
            console.error(response.token_message);
            logout();
         }else{
            console.log(response.data);
            setMainPost(response.data);
            setLoading(false);
         }
      }
      else {
         console.error(response.message);
      }
   }

   const searchInputChange = async (e) => {
      if (e.code == "ArrowUp" || e.code == "ArrowDown")
          return;
       if (e.code == "Enter")
          return;
       setIsShowSpinner(true);
       let keyword = e.target.value;
      const response = await getPlaybookArticlesByKeyword({onboarding_playbook_id: id,keyword:keyword.toLowerCase(),is_publish:1});
      if(response){
         if(response.token_message){
            console.error(response.token_message);
            logout();
         }else{
            if(response.status){
               setIsShowSpinner(false);
               console.log(response.data);
               setSearhData(response.data);

            }else{
               setIsShowSpinner(false);
               setSearhData([]);
            }
         }
      }
      else {
         console.error(response.message);
      }
  }

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);
  
  
                        
  return (
      <div className="h-full front-view">
         <div className="row">
            <div className="col-xs-12">
              <div className="box">
                  <div className="box-body">
                      <div className="row">
                      { (loading) ?
                        <h6 className="text-center">Loading...</h6>
                        :
                        (
                        <div id="sidecenter">
                          <div className="page__content-wrapper">
                             <div className="main-header header-bg text-center" style={{background: (mainPost.header_background != '') ? "url("+mainPost.header_background+")" : "#0d6efd"}}>
                                <div className="flw-kb-main-header-content">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <a href="javascript:void(0);" className="page-bar-back-url-btn me-3" onClick={() => navigate('/onboarding-playbook')} style={{color: (mainPost.title_color != '') ? mainPost.title_color : '#000000'}}><i className="fa fa-long-arrow-left"></i></a>
                                      <h1 className="knowledgebase__base-title text-left" style={{color: (mainPost.title_color != '') ? mainPost.title_color : '#000000'}}>
                                         {mainPost.name}
                                      </h1>
                                    </div>
                                   <h4 className="text-left margin-bottom-20 flw-kb-main-banner-description" style={{color: (mainPost.title_color != '') ? mainPost.title_color : '#000000'}}>
                                      <div>{mainPost.description}</div>
                                   </h4>
                                   <div className="search-page search-content-1 position-relative">
                                      <div id="searchBox" className="input-group input-group-lg text-left">
                                         <span className="input-group-btn">
                                            <button id="btn_knowledgebase_search" type="button" className="btn flw-search-addon-btn no-space rounded-4">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" fill="#91a2b6" viewBox="0 0 16 16" className="bi bi-search">
                                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                               </svg>
                                            </button>
                                         </span>
                                         <input id="tableSearchInput" placeholder=" Search" type="text" className="form-control" onKeyUp={searchInputChange} /> { (isShowSpinner) && (<i className="fa fa-circle-o-notch fa-spin"></i>)}
                                      </div>
                                      {(searchData.length > 0) && (
                                           <div className="autocomplete">
                                             {searchData.map((row,index) => (
                                                <ul  key={index} className="autocomplete-results" >
                                                        <li  className="knb-search-item"><a className="dropdown-item knb-search-link" href="javascript:void(0)" onClick={() => navigateToArticalDetail(row.url_code, row.id,row.parent_article_title,row.parent_article,mainPost.id)}><h6 className="">{row.title}</h6><div className="no-margin knb-search-content" dangerouslySetInnerHTML={ { __html: row.content}} /></a>  
                                                    </li>
                                                </ul>
                                             ))}
                                         
                                            </div>
                                       )} 
                                   </div>
                                </div>
                             </div>
                             <div className="container-fixed">
                                <div className="item-base-content">
                                {
                                    mainPost.articles.map((row,index) => (
                                   <div>
                                      <a href="javascript:void(0);" className="knowledgebase-articles-news" onClick={() => navigateToArticalDetail(row.url_code, row.id,'', 0, mainPost.id)}>
                                         <h4>
                                            {row.title}
                                         </h4>
                                      </a>
                                      {
                                       (row.childs.length > 0) && (
                                         <ul className="childs-list-container list-unstyled margin-top-10">
                                             {
                                             row.childs.map((child,childindex) => (
                                               <li className="childs-list-item margin-bottom-5 padding-5 position-relative">
                                                  <a href="javascript:void(0);" onClick={() => navigateToArticalDetail(child.url_code, child.id,row.title, row.id, mainPost.id)} className="childs-list-item-link">
                                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#91A2B6" viewBox="0 0 16 16" className="bi bi-file-earmark-text margin-right-12">
                                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                                                     </svg>
                                                     {child.title} 
                                                  </a>
                                               </li>
                                             ))
                                          }
                                         </ul>
                                      )}
                                   </div>
                                    ))
                                 }
                                </div>
                             </div>
                          </div>
                        </div>
                        )}
                      </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
  );
};
export default OnboardingPlaybookArticle;