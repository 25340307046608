import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center padding80">
      <div className="container">
        <div className="section-title">
          <h2>Features</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-4 col-lg-2 mx-sm-0 mx-lg-auto">
                  {" "}
                  <i className={d.icon}></i>
                  <h3 className="feature-title">{d.title}</h3>
                  <p className="feature-content">{d.text}</p>
                </div>
              ))
            : "Loading..."}

            
            <div className="col-md-12 mt-3">
              <div className="sqs-block button-block sqs-block-button text-center">
                  <a href="/login" className="btn btn-custom btn-lg">
                      Get started
                  </a>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};
