import React, {useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container } from 'react-bootstrap';
import Moment from 'moment';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {UserContext} from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import * as myConstent from '../contexts/ConstContext';

const ProfileTeams = () => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();
  const { getTeamsOfOrganizationByTeamId, logout, getStudyTeams, getHiringTeams } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [teams, setTeams] = useState([]);
  const [grpTeams, setGrpTeams] = useState([]);
  const [hiringData, setHiringData] = useState([]);
  const [inviteOrgResults, setInviteOrgResults] = useState([]);
  const [inviteStuGrpResults, setInviteStuGrpResults] = useState([]);
  const [inviteHiringResults, setInviteHiringResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isProfessional, setIsProfessional] = useState(true);

  

  //check module createorg
  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
    if(team === 'Organization'){
      navigate(`/createorg/${team.split(' ').join('-').toLowerCase()}`);
    }else if(team === 'Hiring') {
      if(isProfessional == false) {
        return false;
      }
      navigate(`/createhiring/${team.split(' ').join('-').toLowerCase()}`);
    }else{
      navigate(`/createstdgrp/${team.split(' ').join('-').toLowerCase()}`);
    }
  };

  const handleShowAddTeam = () => {
    setShowAddForm(true);
  };

  const handleShowManageTeam = () => {
    setShowAddForm(false);
  };

  const handleRedirect = (id,team) => {
      if(team === 'orgTeams'){
        navigate(`/createorg/chooseroadmaps/${id}`);
      } else if(team === 'hiring'){
        navigate(`/createhiring/add-hiring-members/${id}`);
      } else{
        navigate(`/createstdgrp/chooseroadmaps/${id}`);
      }
  };

  const handleInviteRedirect = (invite_token, team) => {
    if(team === 'orgTeams'){
      navigate(`/createorg/respond-invite/${invite_token}`);
    } else if(team === 'hiring'){
      navigate(`/createhiring/respond-invite/${invite_token}`);
    } else{
      navigate(`/createstdgrp/respond-invite/${invite_token}`);
    }
  };

  const checkProfessionalEmail = async () => {
    // List of commonly used professional email providers
    const professionalProviders = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'protonmail.com',
      'yopmail.com'
      // Add more professional email providers as needed
    ];

    // Extract domain from email address
    const domain = (user.email).split('@')[1];

    // Check if the domain is a commonly used professional email provider
    if (professionalProviders.includes(domain)) {
      setIsProfessional(false);
      return false;
    } else {
      setIsProfessional(true);
      return true;
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      
      let isProfessionalEmail = await checkProfessionalEmail();

      setLoading(true);
      let temp_teams, temp_stugrp_teams, temp_stugrp_invite_results, temp_org_invite_results, temp_hiring_invite_results, temp_hiring_results = [];
  
      let response = await getTeamsOfOrganizationByTeamId('undefined', user.id, 1);
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          setTeams(response.data);
          setInviteOrgResults(response.invite_results);
          temp_teams = response.data;
          temp_org_invite_results = response.invite_results;
        }
      }
      else {
        console.error(response);
      }
      
      let user_id = user.id;
      let response2 = await getStudyTeams(user_id);
      if(response2){
        if(response2.token_message){
          console.error(response2.token_message);
          logout();
        }else{
          setGrpTeams(response2.data);
          setInviteStuGrpResults(response2.invite_results);
          temp_stugrp_teams = response2.data;
          temp_stugrp_invite_results = response2.invite_results;
        }
      }
      else {
        console.error(response2);
      }

      let response3 = await getHiringTeams(user.id);
      if(response3){
        if(response3.token_message){
          console.error(response3.token_message);
          logout();
        } else if(response3.status == false) {
          alert(response3.message);
          navigate(`/profile`);
        } else{
          setHiringData(response3.data);
          // setInviteHiringResults(response3.invite_results);
          temp_hiring_results = response3.data; 
          // temp_hiring_invite_results = response3.invite_results;
        }
      }
      else {
        console.error(response3);
      }
  
      if(temp_teams.length === 0 && temp_org_invite_results.length === 0 && temp_stugrp_teams.length === 0 && temp_stugrp_invite_results.length === 0
        && temp_hiring_results.length === 0) {
        setShowAddForm(true);
      }
      setLoading(false);
  
      $("#team_table").DataTable({
        pagingType: "full_numbers",
        pageLength: myConstent.DATATABLERECORDLIMIT,
        processing: true,
        scrollX: true,
        dom: "Bfrtip",
        select: {
          style: "single",
        },
        order: [[2, 'desc']],
        buttons: [
          {
            extend: "pageLength",
            className: "btn btn-secondary bg-secondary",
          },
          {
            extend: "copy",
            className: "btn btn-secondary bg-secondary",
          },
          {
            extend: "csv",
            className: "btn btn-secondary bg-secondary",
          },
          {
            extend: "print",
            customize: function (win) {
              $(win.document.body).css("font-size", "10pt");
              $(win.document.body)
                .find("table")
                .addClass("compact")
                .css("font-size", "inherit");
            },
            className: "btn btn-secondary bg-secondary",
          },
        ],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
    };
    
    fetchData();
    // if (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.teams.view == 1){
         
    // }else{
    //   navigate('/unauthorized');
    // }
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main className="mt-4">
            {showAddForm && (
            <>
            <Row>
              
              <Col md={6} lg={8}>
                <h1>Create Team(s)</h1>
                <h4>Please Select From The Below! </h4>
              </Col>
              <Col md={6} lg={4}>
                <div className="button-section team-button-section">
                    <button className="button submit" type="buuton" onClick={() => handleShowManageTeam()}>Manage Team</button>
                </div>
              </Col>
              
              <Col md={12} lg={12}>
               {(isProfessional == false) && (
                <p style={{color:'red'}}>To unlock Hiring feature, please register using your official company email address.</p>
               )}
              </Col>
              <form onSubmit={(e) => e.preventDefault()}>
                <fieldset>
               
                  <div className="teams row" id="teams">
                    <div className="col-md-6 col-lg-3 d-flex">
                      <div
                        className={`team d-flex flex-grow-1 flex-column align-items-center rounded-lg bg-gray-100 ${selectedTeam === "Organization" ? "selected" : ""}`}
                        onClick={() => handleTeamSelect("Organization")}
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mb-3 h-12 w-12 opacity-100"><path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"></path><path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path><path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"></path><path d="M10 6h4"></path><path d="M10 10h4"></path><path d="M10 14h4"></path><path d="M10 18h4"></path></svg>
                      <span className="mb-2 block text-2xl fw-bold">Organization</span>
                      <span className="text-sm text-gray-500">Track the skills and learning progress of the tech team at your company</span>
                      </div>

                    </div>
                    <div className="col-md-6 col-lg-3 d-flex">
                      <div
                        className={`team d-flex flex-grow-1 flex-column align-items-center rounded-lg bg-gray-100 ${selectedTeam === "Study Group" ? "selected" : ""}`}
                        onClick={() => handleTeamSelect("Study Group")}
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mb-3 h-12 w-12 opacity-100"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        <span className="mb-2 block text-2xl fw-bold">Study Group</span>
                        <span className="text-sm text-gray-500">Invite your friends or course-mates and track your learning progress together</span>
                      </div>
                    </div>
                    <div className={isProfessional == false ? "col-md-6 col-lg-3 d-flex disable-div" : "col-md-6 col-lg-3 d-flex"} title={isProfessional == false ? 'To unlock this feature, please register using your official company email address.' : ''}>
                      <div
                        className={`team d-flex flex-grow-1 flex-column align-items-center rounded-lg bg-gray-100 ${selectedTeam === "Hiring" ? "selected" : ""}`}
                        onClick={() => handleTeamSelect("Hiring")}
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 640 512"><path d="M272.2 64.6l-51.1 51.1c-15.3 4.2-29.5 11.9-41.5 22.5L153 161.9C142.8 171 129.5 176 115.8 176H96V304c20.4 .6 39.8 8.9 54.3 23.4l35.6 35.6 7 7 0 0L219.9 397c6.2 6.2 16.4 6.2 22.6 0c1.7-1.7 3-3.7 3.7-5.8c2.8-7.7 9.3-13.5 17.3-15.3s16.4 .6 22.2 6.5L296.5 393c11.6 11.6 30.4 11.6 41.9 0c5.4-5.4 8.3-12.3 8.6-19.4c.4-8.8 5.6-16.6 13.6-20.4s17.3-3 24.4 2.1c9.4 6.7 22.5 5.8 30.9-2.6c9.4-9.4 9.4-24.6 0-33.9L340.1 243l-35.8 33c-27.3 25.2-69.2 25.6-97 .9c-31.7-28.2-32.4-77.4-1.6-106.5l70.1-66.2C303.2 78.4 339.4 64 377.1 64c36.1 0 71 13.3 97.9 37.2L505.1 128H544h40 40c8.8 0 16 7.2 16 16V352c0 17.7-14.3 32-32 32H576c-11.8 0-22.2-6.4-27.7-16H463.4c-3.4 6.7-7.9 13.1-13.5 18.7c-17.1 17.1-40.8 23.8-63 20.1c-3.6 7.3-8.5 14.1-14.6 20.2c-27.3 27.3-70 30-100.4 8.1c-25.1 20.8-62.5 19.5-86-4.1L159 404l-7-7-35.6-35.6c-5.5-5.5-12.7-8.7-20.4-9.3C96 369.7 81.6 384 64 384H32c-17.7 0-32-14.3-32-32V144c0-8.8 7.2-16 16-16H56 96h19.8c2 0 3.9-.7 5.3-2l26.5-23.6C175.5 77.7 211.4 64 248.7 64H259c4.4 0 8.9 .2 13.2 .6zM544 320V176H496c-5.9 0-11.6-2.2-15.9-6.1l-36.9-32.8c-18.2-16.2-41.7-25.1-66.1-25.1c-25.4 0-49.8 9.7-68.3 27.1l-70.1 66.2c-10.3 9.8-10.1 26.3 .5 35.7c9.3 8.3 23.4 8.1 32.5-.3l71.9-66.4c9.7-9 24.9-8.4 33.9 1.4s8.4 24.9-1.4 33.9l-.8 .8 74.4 74.4c10 10 16.5 22.3 19.4 35.1H544zM64 336a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm528 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"/></svg>
                        <span className="mb-2 block text-2xl fw-bold">Hiring</span>
                        <span className="text-sm text-gray-500">Make smarter hiring decisions by collecting skills rating for potential hires.</span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
              
            </Row>
              </>
            )}
              
              {(!showAddForm && !loading) && (
            <>
            <Row>  
              <Col md={6} lg={8}>
                <h1>Manage Team(s)</h1>
              </Col>
              <Col md={6} lg={4}>
              <div className="button-section team-button-section">
                  <button className="button submit" type="buuton" onClick={() => handleShowAddTeam()}>Get Started</button>
              </div></Col>
              <div className="settings-card">
                <div className="row">
                  <div className="col-md-12">
                  {(teams.length > 0 || grpTeams.length > 0 || inviteOrgResults.length > 0 || inviteStuGrpResults.length > 0 || hiringData.length > 0) && (
                      <>
                        <div className="p-0 pb-2">
                          <table id="team_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                              <thead>
                                  <tr>
                                    <th>Team Name</th>
                                    <th>Type</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                    </tr>
                              </thead>

                              <tbody>
                              {inviteOrgResults.map((row, index) => (
                                <tr key={index}>
                                <td>{row.team_name}  <span className={row.invite_status === "0" ? 'pending_text' : ''}>{row.invite_status === "0" ? ' (Pending) ' : ''}</span> </td>
                                <td>{row.organization_name}</td>
                                <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                                <td>
                                {
                                  row.invite_status === "0" ? 
                                  <button className='btn btn-sm btn-success' onClick={() => handleInviteRedirect(row.invite_token, 'orgTeams')} >
                                  Check
                                  </button> : 
                                  ''
                                }
                                </td>
                                </tr>
                            ))}

                            {inviteStuGrpResults.map((row, index) => (
                                <tr key={index}>
                                <td>{row.group_name}  <span className={row.invite_status === "0" ? 'pending_text' : ''}>{row.invite_status === "0" ? ' (Pending) ' : ''}</span> </td>
                                <td>Study Group</td>
                                <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                                <td>
                                {
                                  row.invite_status === "0" ? 
                                  <button className='btn btn-sm btn-success' onClick={() => handleInviteRedirect(row.invite_token, 'grpTeams')} >
                                  Check
                                  </button> : 
                                  ''
                                }
                                </td>
                                </tr>
                            ))}

                            {/* {inviteHiringResults.map((row, index) => (    
                                <tr key={index}>
                                <td>{row.hiring_role_name}  <span className={row.invite_status === "0" ? 'pending_text' : ''}>{row.invite_status === "0" ? ' (Pending) ' : ''}</span> </td>
                                <td>Hiring</td>
                                <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                                <td>
                                {
                                  row.invite_status === "0" ? 
                                  <button className='btn btn-sm btn-success' onClick={() => handleInviteRedirect(row.invite_token, 'hiring')} >
                                  Check
                                  </button> : 
                                  ''
                                }
                                </td>
                                </tr>
                            ))} */}

                            {teams.map((row, index) => (
                                <tr key={index}>
                                <td>{row.team_name} <span className={row.invite_status === "1" ? 'accepted_text' : ''}>{row.invite_status === "1" ? ' (Accepted) ' : ''}</span></td>
                                <td>{row.organization_name}</td>
                                <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                                <td>
                                    <button className='btn btn-sm btn-primary' onClick={() => handleRedirect(row.id,'orgTeams')} >
                                    Manage
                                    </button>
                                </td>
                                </tr>
                            ))}
                            {grpTeams.map((row, index) => (
                                <tr key={index}>
                                <td>{row.group_name} <span className={row.invite_status === "1" ? 'accepted_text' : ''}>{row.invite_status === "1" ? ' (Accepted) ' : ''}</span></td>
                                <td>Study Group</td>
                                <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                                <td>
                                    <button className='btn btn-sm btn-primary' onClick={() => handleRedirect(row.group_id,'grpTeams')} >
                                    Manage
                                    </button>
                                </td>
                                </tr>
                            ))}
                            {hiringData.map((row, index) => (
                                <tr key={index}>
                                <td>{row.hiring_role_name} <span className={row.invite_status === "1" ? 'accepted_text' : ''}>{row.invite_status === "1" ? ' (Accepted) ' : ''}</span></td>
                                <td>Hiring</td>
                                <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                                <td>
                                    <button className='btn btn-sm btn-primary' onClick={() => handleRedirect(row.hiring_id,'hiring')} >
                                    Manage
                                    </button>
                                </td>
                                </tr>
                            ))}
                              </tbody>
                          </table>
                        </div>
                      </>
                      )}
                  </div>
                </div>
                    
              </div>
              </Row>
              </>
            )}
            </main>
          </Container>
          
        </div>
      </div>
  );
};

export default ProfileTeams;