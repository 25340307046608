import React, { useState, useContext } from 'react';
import { Dropdown, Modal, Button, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';
import {v4 as uuidv4} from 'uuid';

const DropdownMenu = ({ show, roadmapId, roadmapName, roadmapSlug }) => {
  const [showModal, setShowModal] = useState(false);
  const {postBookmark, user, sendFeedback, showToast} = useContext(UserContext);
  
  const location = useLocation();
  const navigate = useNavigate();
   const [redirect, setRedirect] = useState(false);
  const [formData, setFormData] = useState({
    user_id:(user != null ? user.id : ''),
    email:'',
    subject: '',
    feedback_text: '',
    roadmap_id:roadmapId
  });
  const handleSendFeedbackClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleProceed = (e) => {
    navigate(`/assessment/${roadmapSlug}`, {
        state: {
            roadmapId: roadmapId,
        },
    });
  };

  /*const handleProceed2 = (e) => {
    navigate(`/new-assessment/${roadmapSlug}`, {
        state: {
            roadmapId: roadmapId,
        },
    });
  };*/

  const handleFlowchartClick = (e) => {
    navigate(`/roadmap-flowchart/${roadmapSlug}`, {
        state: {
            roadmapId: roadmapId,
            path:location.pathname
        },
    });
  };

  const handleBookmark = async (e) => {
    let formData = {
      user_id:user.id,
      roadmap_id:roadmapId
    };
    
    var result = await postBookmark(formData);
    if(result.status){
        alert(result.message);
    }
    else if(!result.status && result.message){
    }
    
  };

  const handleLoginClick = (e) => {
    navigate(`/login`);
  };

  const handleSendAction =  async (e) => {
    e.preventDefault();
    if(formData['email'] === '') {
      showToast("Please enter email", true); return false;
    }

    if(formData['subject'] === '') {
      showToast("Please enter subject" , true); return false;
    }
    if(formData['feedback_text'] === '') {
      showToast("Please enter Feedback", true); return false;
    }
    var token = uuidv4();
    var url = `${window.location.origin}/reviewer-assessment/${token}`;
    formData.temp_url = url;
    formData.url_code = token;

    setRedirect('Loading...');
    const data = await sendFeedback(formData);
    if(data.status){
        showToast('Feedback save successfully', false);

        setTimeout(
        () => {

         setShowModal(false);
         setFormData({
              user_id:(user != null ? user.id : ''),
              email:'',
              subject: '',
              feedback_text: '',
              roadmap_id:roadmapId
        });
       },1000);

    }else{
      setRedirect(false);
      showToast(data.message, true);
    }
    
  }
  return (
    <>
      <Dropdown.Menu show={show} className="w-100">
        {(user != null) ? <Dropdown.Item onClick={handleBookmark}>Bookmark</Dropdown.Item> : <Dropdown.Item onClick={handleLoginClick}>Bookmark</Dropdown.Item>}
        {(user != null) ? <Dropdown.Item onClick={handleProceed} >Start Assessment</Dropdown.Item> : <Dropdown.Item onClick={handleLoginClick} >Start Assessment</Dropdown.Item>}
        {/* {(user != null) ? <Dropdown.Item onClick={handleProceed2} >Start New Assessment</Dropdown.Item> : <Dropdown.Item onClick={handleLoginClick} >Start Assessment</Dropdown.Item>} */}
        {(user != null) ? <Dropdown.Item onClick={handleSendFeedbackClick}>Send For Feedback</Dropdown.Item> : <Dropdown.Item onClick={handleLoginClick}>Send For Feedback</Dropdown.Item>}
        {(user != null) ? <Dropdown.Item onClick={handleFlowchartClick}>Skills Flow</Dropdown.Item> : <Dropdown.Item onClick={handleLoginClick}>Skills Flow</Dropdown.Item>}
        
      </Dropdown.Menu>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="Email" value={formData.email} onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    } />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Subject</Form.Label>
              <Form.Control type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={(e) =>
                      setFormData({ ...formData, subject: e.target.value })
                    } />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Feedback Text</Form.Label>
              <Form.Control as="textarea" name="feedback_text" rows={3} placeholder="Enter your text here" value={formData.feedback_text}  onChange={(e) =>
                      setFormData({ ...formData, feedback_text: e.target.value })
                    }/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendAction}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DropdownMenu;
