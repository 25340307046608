import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import {UserContext} from '../contexts/UserContext';
import "react-pro-sidebar/dist/css/styles.css";

const TeamAcceptRequest = () => {
  const navigate = useNavigate();
  const { invite_token } = useParams();
  const [loading, setLoading] = useState(true);
  const { getTeamByToken, updateTeamByToken, logout } = useContext(UserContext);
  
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');


  
  const submitInviteResult = async (status) => {
    const data = await updateTeamByToken({invite_token:invite_token, status:status});
    if(data.status){
      setErrMsg(false);
      setSuccessMsg(data.message);

      setTimeout(
        () => {
          if(status === 1) {
            navigate(`/createorg/chooseroadmaps/${data.team_id}`);
          } else if(status === 2) {
            navigate(-1)
          }
        }
        , 2000);

      
    }
    else if(!data.status && data.message){
      setErrMsg(data.message);
    }
  };


  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getTeamByToken(invite_token);
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          if(response.status) {
            if(response.data.status === "0") {
              setLoading(false);
            } else {
              setErrMsg("Link is expired!");
            }
          } else {
            setErrMsg(response.message);
          }
        } 
      }
      else {
        console.error(response);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="row h-full">
      <aside className="padding80 py-10 d-md-block border-end process-sidebar">
        <Container fluid>
          <div className="relative mr-2">
          {!loading && (
              <>
            <div className="container text-center">
              <h2 className="mb-1 text-2xl font-bold">Join Team</h2>
              <p className="mb-3 text-base leading-6 text-gray-600">You have been invited to join the team.</p>
              <div className="mx-auto w-full max-w-md">
                <div className="flex w-full items-center gap-2">
                  <button type="button" className="btn btn-success mx-2" onClick={() => submitInviteResult(1)}>Accept</button>
                  <button type="button" className="btn btn-danger ms-2" onClick={() => submitInviteResult(2)}>Reject</button>
                </div>

                <hr/>

                
              <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>Back to Teams</button>
              </div>
            </div>
            </>
          )}
          
          {successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
          {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
          </div>
        </Container>
      </aside>
    </div>
  );
};

export default TeamAcceptRequest;