import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeContext } from './contexts/theme-context';
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import Pricing from './pages/pricing';
import Home from './pages/home';
import Skills from './components/skills';
import Login from './pages/login';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import "./App.css";
import Fullstack from "./pages/fullstack";
import AssessmentResult from "./components/assessmentresult";
import AssessmentHistory from "./components/assessmenthistory";
import TrackProgressPage from "./components/trackprogress";
import UserRoadmapProgress from "./components/userRoadmapProgress";  
import ProfileSkills from "./components/profileskills";
import ProfileHistory from "./components/profilehistory";
import Support from "./components/profilesupport";
import Queries from "./components/profilequeries";
import { ProSidebar } from 'react-pro-sidebar';
import ProfileFavorite from "./components/profilefavorite";
import ProfileSettings from "./components/profilesettings";
import ProfileOrganization from "./components/profileorganization";
import CreateFlowchart from "./components/createflowchart";
import CreateRoadmapChart from "./components/createRoadmapChart";
import ProfileHome from "./components/profilehome";
import SignUp from "./components/signup";
import VerifyAccount from './pages/verifyAccount';
import {UserContext} from './contexts/UserContext';
import Footer from "./components/footer";
import Assessment from "./pages/assessment";
import ProfileTeams from "./components/profileteams";
import CreateOrg from "./components/createorg";
import ChooseRoadmaps from "./components/chooseRoadmaps";
import AddMembersOrganization from "./components/addMembersOrganization";
import CreateStdGrp from "./components/createStdGrp";
import AddMembersGroup from "./components/addMembersGroup";
import Process from "./components/process";
import AddTeams from "./components/addTeams";
import TeamSettings from "./components/teamSettings";
import ChooseGroupRoadmaps from "./components/chooseGroupRoadmaps";
import SettingStdGrp from "./components/settingStdGrp";
import SettingHiring from "./components/settingHiring";
import DrawRoadmapFlowchart from "./components/drawRoadmapFlowchart";
import TeamBoard from "./components/teamBoard";

import TeamAcceptRequest from "./components/teamAcceptRequest";
import StudyGroupAcceptRequest from "./components/studyGroupAcceptRequest";
import HiringAcceptRequest from "./components/hiringAcceptRequest";


import OrganizationDashboard from "./components/organizationDashboard";

import ProgressOrganization from "./components/progressOrganization";
import ChartProgressOrganization from "./components/chartprogressOrganization";
// import UserOnboardPlaybook from "./components/userOnboardPlaybook";
// import OnboardPlaybookArticleOrganization from "./components/onboardPlaybookArticleOrganization";
// import OnboardPlaybookArticleDetailOrganization from "./components/onboardPlaybookArticleDetailOrganization";

import ProgressGroup from "./components/progressGroup";
import ChartProgressGroup from "./components/chartprogressGroup";


import ProgressHiring from "./components/progressHiring";

import { LinkedInCallback } from "react-linkedin-login-oauth2";

import Terms from "./pages/terms";
import AboutUs from "./pages/aboutus";
import PrivacyPolicy from "./pages/privacyPolicy";
import NewAssessment from "./pages/newAssessment";
import HiringAssessment from "./pages/hiringAssessment";
import TeamAssessment from "./pages/teamAssessment";
import ReviewerAssessment from "./pages/reviewerAssessment";
import OnboardingPlaybook from "./pages/onboardingPlaybook";
import UserAssessmentHistory from "./components/userAssessmenthistory";
import SkillsFlow from "./components/skillsFlow";
import SkillsFlowGrp from "./components/skillsFlowGrp";
import HistoryOrganization from "./components/historyOrganization";
import CheckAssessmentResult from "./components/checkAssessmentResult";
import AutoLogin from "./components/autoLogin";

import ProfileUsers from "./components/profileusers";
import ProfileRoadmaps from "./components/profileroadmaps";
import DrawProfileRoadmapFlowchart from "./components/drawProfileRoadmapFlowchart";
import PageEditor from "./components/pageEditor";
import OnboardPlaybook from "./components/onboardPlaybook";
import PlaybookArticle from "./components/playbookArticle";
import PlaybookArticleDetails from "./components/playbookArticleDetails";
import OnboardingPlaybookArticle from "./components/onboardingPlaybookArticle";
import OnboardingArticleDetails from "./components/onboardingArticleDetails";
import CreateHiring from "./components/createHiring";
import ChooseHiringRoadmaps from "./components/chooseHiringRoadmaps";
import AddMembersHiring from "./components/addMembersHiring";
import AccessRights from "./components/accessRights";
import UnAuthorized from "./components/unAuthorized";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true
});

const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const isBrowserDefaulDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem('default-theme');
    const browserDefault = isBrowserDefaulDark() ? 'dark' : 'light';
    return localStorageTheme || browserDefault;
  };

  const [theme, setTheme] = useState(getDefaultTheme());


   // Function to simulate a successful login
   const handleLoginSuccess = () => {
    setLoggedIn(true);
  };

  const {user} = useContext(UserContext); 
  const pathname = window.location.pathname;
  const curntUrl = pathname.split('/');
  const reviewer_assessment = curntUrl[1];

  const history = createBrowserHistory();


  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme} main`}>
        <BrowserRouter>
          <Navigation />
          <div className="content-wrapper h-full">
            {loggedIn && 
              <ProSidebar>

              </ProSidebar>
            }

            <Routes history={history}>
              <Route exact path='/' element={<Home />} />
              <Route path='/auto-login/:id/:token' element={<AutoLogin />} />
              <Route exact path='/pricing' element={<Pricing />} />
              <Route exact path='/terms' element={<Terms />} />
              <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route exact path='/about-us' element={<AboutUs />} />
              <Route exact path="/skills" element={<Skills />} />
              <Route path="/fullstack/:title" element={<Fullstack/>}/>
              
              <Route path="/createorg/dashboard/:id" element={<OrganizationDashboard/>}/>

              <Route path="/createorg/chooseroadmaps/:id" element={<ChooseRoadmaps/>}/>
              <Route path="/createorg/add-members/:id" element={<AddMembersOrganization/>}/>
              <Route path="/createorg/team-settings/:id" element={<TeamSettings/>}/>
              <Route path="/organization/onboard/:id" element={<TeamBoard/>}/>
              <Route path="/createstdgrp/chooseroadmaps/:id" element={<ChooseGroupRoadmaps/>}/>
              <Route path="/createstdgrp/add-group-members/:id" element={<AddMembersGroup/>}/>
              <Route path="/createstdgrp/settings/:id" element={<SettingStdGrp/>}/>
              
              <Route path="/createorg/respond-invite/:invite_token" element={<TeamAcceptRequest/>}/>
              <Route path="/createstdgrp/respond-invite/:invite_token" element={<StudyGroupAcceptRequest/>}/>
              <Route path="/gropup-skills-flow/:title/:id" element={<SkillsFlowGrp/>}/>

              <Route path="/roadmap/draw/:title" element={<DrawRoadmapFlowchart/>}/>
              <Route path="/reviewer-assessment/:token" element={<ReviewerAssessment/>}/>
              
              <Route path="/hiring-token-assessment/:token" element={<HiringAssessment/>}/>
              <Route path="/skills-flow/:title/:id" element={<SkillsFlow/>}/>
              <Route path="/check-assessment-result/:type/:id" element={<CheckAssessmentResult/>}/>
              
              <Route exact path="/contact" element={<Contact/>}/>
              <Route path="/page" element={<PageEditor/>}/>
              <Route exact path="/onboarding-playbook" element={<OnboardingPlaybook/>}/>
              <Route path="/onboarding-playbook/article/:id" element={<OnboardingPlaybookArticle/>}/>
              <Route path="/onboarding-playbook/article/details/:title" element={<OnboardingArticleDetails/>}/>

              <Route path="/createhiring/chooseroadmaps/:id" element={<ChooseHiringRoadmaps/>}/>
              <Route path="/createhiring/add-hiring-members/:id" element={<AddMembersHiring/>}/>
              <Route path="/createhiring/respond-invite/:invite_token" element={<HiringAcceptRequest/>}/>
              <Route path="/createhiring/settings/:id" element={<SettingHiring/>}/>

              {!user && (
                <>
                <Route path="/login" element={<Login onLoginSuccess={() => handleLoginSuccess()} />} />
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/reset-password/:id/:token" element={<ResetPassword/>}/>
                <Route path="/verify-account/:id/:token" element={<VerifyAccount/>}/>
                </>
              )}
              <Route path="*" element={<Navigate to={user ? '/profile':'/login'} />} />
              { user && ( 
              <> 
              <Route path="/profile" element={<ProfileHome/>}/>
              <Route path="/profile-skills" element={<ProfileSkills/>}/>
              <Route path="/profile-history" element={<ProfileHistory/>}/>
              <Route path="/support" element={<Support/>}/>
               <Route path="/queries" element={<Queries/>}/>
              <Route path="/profile-favorite" element={<ProfileFavorite/>}/>
              <Route path="/profile-settings" element={<ProfileSettings/>}/>
              <Route path="/organization" element={<ProfileOrganization/>}/>
              <Route path="/track-progress" element={<TrackProgressPage />} />
              <Route path="/track-progress/:roadmap_id" element={<TrackProgressPage />} />
              <Route path="/user-roadmap-progress/:roadmap_id/:user_id" element={<UserRoadmapProgress />} />
              <Route path="/assessment/result/:id" element={<AssessmentResult/>}/>
              <Route path="/assessment/history/:id" element={<AssessmentHistory/>}/>
              <Route path="/profile-teams" element={<ProfileTeams/>}/>
              <Route path="/createstdgrp/:title" element={<CreateStdGrp/>}/>
              
              <Route path="/team/process" element={<Process/>}/>
              
              
              <Route path="/createorg/:title" element={<CreateOrg/>}/>
              <Route path="/createorg/addteams/:id" element={<AddTeams/>}/>
              <Route path="/organization/progress/:id" element={<ProgressOrganization/>}/>
              <Route path="/organization/chart_progress/:id" element={<ChartProgressOrganization/>}/>
              <Route path="/organization/history/:id" element={<HistoryOrganization/>}/>
              
              <Route path="/createstdgrp/progress/:id" element={<ProgressGroup/>}/>
              <Route path="/createstdgrp/chart_progress/:id" element={<ChartProgressGroup/>}/>

              <Route path="/new-assessment/:title" element={<NewAssessment/>}/>
              {/* <Route path="/assessment/:title" element={<Assessment/>}/> */}
              <Route path="/assessment/:title" element={<NewAssessment/>}/>
              <Route path="/roadmap-flowchart/:id" element={<CreateRoadmapChart/>}/>
              <Route path="/user-assessment-history/:id" element={<UserAssessmentHistory/>}/>

              
              <Route path="/users" element={<ProfileUsers/>}/>
              <Route path="/roadmaps" element={<ProfileRoadmaps/>}/>
              <Route path="/roadmaps/draw/:title" element={<DrawProfileRoadmapFlowchart/>}/>
              <Route path="/onboard-playbook" element={<OnboardPlaybook/>}/>
              <Route path="/view-onboard-playbook/article/:id" element={<PlaybookArticle/>}/>
              <Route path="/onboard-playbook/article/:id" element={<PlaybookArticle/>}/>
              <Route path="/onboard-playbook/article/details/:id" element={<PlaybookArticleDetails/>}/>

              <Route path="/createhiring/:title" element={<CreateHiring/>}/>

              <Route path="/hiring/progress/:id" element={<ProgressHiring/>}/>
              <Route path="/accessrights" element={<AccessRights/>}/>
              <Route path="/unauthorized" element={<UnAuthorized/>}/>

{/*               
              <Route path="/user-onboard-playbook" element={<UserOnboardPlaybook/>}/>
              <Route path="/user-onboard-playbook/article/:id/:playbook_id" element={<OnboardPlaybookArticleOrganization/>}/>
              <Route path="/user-onboard-playbook/article/details/:id/:article_id" element={<OnboardPlaybookArticleDetailOrganization/>}/> */}
              
              </> 
              )}
              <Route path="/team-assessment/:title" element={<TeamAssessment/>}/>
              <Route path="/hiring-assessment/:title" element={<HiringAssessment/>}/>
              
              { <Route path="/flowchart" element={<CreateFlowchart/>}/> }
              

              <Route exact path="/linkedin" element={<LinkedInCallback/>} />
            </Routes>
            <Footer data={landingPageData.footer} />
          </div>
        </BrowserRouter>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;