import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap';
import { Bar, getElementAtEvent   } from "react-chartjs-2";
import ReactSpeedometer from "react-d3-speedometer";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';

const AssessmentResult = () => {
  const { getAssessment, getRoadmapsWithPercentileData, getInnerLevelChartDataByUser, getRoadmapAssessmentHistory, logout } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const [roadmap, setRoadmap] = useState({});
  const [assessment, setAssessment] = useState({});
  const [assessmentToalScore, setAssessmentToalScore] = useState(5);

  const [customSegmentLabels] = useState([
    {
      text: "0 To 20",
      position: "INSIDE",
      color: "#fff",
    },
    {
      text: "20 To 40",
      position: "INSIDE",
      color: "#fff",
    },
    {
      text: "40 To 60",
      position: "INSIDE",
      color: "#fff",
      fontSize: "16px",
    },
    {
      text: "60 To 80",
      position: "INSIDE",
      color: "#fff",
    },
    {
      text: "80 To 100",
      position: "INSIDE",
      color: "#fff",
    },
  ]);

  const [technicalLabels] = useState(["Entry Level Developer", "Junior Developer", "Mid Level Developer", "Senior Developer", "Principal Developer"]);
  const [nonTechnicalLabels] = useState(["Level-1", "Level-2", "Level-3", "Level-4", "Level-5"]);

  const [SelfToalScore, setSelftToalScore] = useState(5);
  const innerChartRef = useRef();

  
  const [innerLoading, setInnerLoading] = useState(true);
  var staticInnerSkillData = [];
  staticInnerSkillData['label'] = [];
  staticInnerSkillData['data'] = [];
  const [innerSkillData, setInnerSkillData] = useState(staticInnerSkillData);

  var staticUserSkillData = [];
  staticUserSkillData['label'] = [];
  staticUserSkillData['data'] = [];
  const [userSkillData, setUserSkillData] = useState(staticUserSkillData);

  const [customSkillLabels, setCustomSkillLabels] = useState(["Entry Level Developer","Junior Developer","Mid Level Developer","Senior Developer","Principal Developer"]);

  const [thirdChartLoading, setThirdChartLoading] = useState(true);
  var tempThirdChartSkillData = [];
  tempThirdChartSkillData['label'] = [];
  tempThirdChartSkillData['data'] = [];
  const [thirdChartSkillData, setThirdChartSkillData] = useState(tempThirdChartSkillData);
  
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {

    window.scrollTo(0, 0);  
    // Function to fetch data from the API
    const fetchData = async () => {

      setLoading(true);
      const resSelf = await getRoadmapsWithPercentileData(user.id);
      if(resSelf.token_message){
        console.error(resSelf.token_message);
        logout();
      }else if(resSelf){
        const res = await getAssessment({id:id});
        if(res.token_message){
          console.error(res.token_message);
          logout();
        }else if(res){
          if(res.data){
            setAssessment(res.data);
            (resSelf.data.roadmap_labels).forEach(element => {
              if(element.roadmap_id == res.data.roadmap_id) {
                setSelftToalScore(element.percentile_rank);
                fetchInnerLevelData(element.roadmap_name)
                return false;
              }
            });

          }
        }

      }
      setLoading(false);
    };
    
    fetchData();
  }, []);

  const navigateToHistoryPage = () => {
    navigate(`/assessment/history/${assessment.roadmap_id}`);
  };

  const fetchInnerLevelData = async (name) => {
    setInnerLoading(true);
    setThirdChartLoading(true);
    const res = await getInnerLevelChartDataByUser({name:name, user_id:user.id});
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){ 
      if(res.data.skill_level.length > 0) {  
        setCustomSkillLabels(res.data.skill_level);
      } else {
        setCustomSkillLabels(customSkillLabels);
      }

      var tempData = [];
      tempData['label'] = res.data.labels;
      tempData['data'] = Object.values(res.data.data);
      setInnerSkillData(tempData);
      setRoadmap(res.data.roadmap);
      setInnerLoading(false);
    }
  };

  const handleInnerChartProceed = (event) => {
    var index = getElementAtEvent(innerChartRef.current, event);
    if(index.length > 0) {
      index = index[0].index;  
      fetchThirdChartData(innerSkillData['label'][index]);
    }
  };

  const fetchThirdChartData = async (date) => {
    setThirdChartLoading(true);
    const res = await getRoadmapAssessmentHistory({roadmap_id:roadmap.roadmap_id, user_id:user.id, date:date});
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){ 
      var tempData = [];
      tempData['label'] = res.data.assessment_history.skill_labels;
      tempData['data'] = Object.values(res.data.assessment_history.skill_data);
      setThirdChartSkillData(tempData);
      setThirdChartLoading(false);
    }
  };

  // Sample data for the horizontal bar chart
  const levelBarChartData = {
    labels: innerSkillData['label'],
    datasets: [
      {
        label: customSkillLabels[0],
        data: innerSkillData['data'][0],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: customSkillLabels[1],
        data: innerSkillData['data'][1],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: customSkillLabels[2],
        data: innerSkillData['data'][2],
        backgroundColor: 'rgba(255, 99, 71, 0.5)',
      },
      {
        label: customSkillLabels[3],
        data: innerSkillData['data'][3],
        backgroundColor: 'rgba(205, 174, 71, 0.5)',
      },
      {
        label: customSkillLabels[4],
        data: innerSkillData['data'][4],
        backgroundColor: 'rgba(205, 174, 187, 0.5)',
      },
    ],
  };
  
  const levelBarChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Click on individual bars to expand'
      }
    },
    scales: {
       y: {
         display: true,
         scaleLabel: {
           display: true,
           labelString: 'Value'
         },
         ticks: {
           min: 0,
           max: 5,
           stepSize: 1,
           suggestedMin: 0.5,
           suggestedMax: 5.0,
           callback: function(label, index, labels) {
             switch (label) {
               case 0:
                 return '';
               case 1:
                 return customSkillLabels[0];
               case 2:
                 return customSkillLabels[1];
               case 3:
                 return customSkillLabels[2];
               case 4:
                 return customSkillLabels[3];
               case 5:
                 return customSkillLabels[4];
                 default:
                  return '';
             }
           }
         },
         gridLines: {
           display: false
         }
       }
     },
  };

  const thirdBarChartOptions = {
    indexAxis: "y", // Make the bars horizontal
    scales: {
        x: {
            display: true,
            scaleLabel: {
            display: true,
            labelString: 'Value'
            },
            ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
            suggestedMin: 0.5,
            suggestedMax: 5.0,
            callback: function(label, index, labels) {
                switch (label) {
                case 0:
                  return '';
                case 1:
                    return customSkillLabels[0];
                case 2:
                    return customSkillLabels[1];
                case 3:
                    return customSkillLabels[2];
                case 4:
                    return customSkillLabels[3];
                case 5:
                    return customSkillLabels[4];
               default:
                  return '';
                }
            }
            },
            gridLines: {
            display: false
            }
        }
    },
  };

  const horizontalBarChartLabels = ["Post Skill Assessment Rating","Pre Skill Assessment Rating"];
  const total_score_arr = [0,0];
  let total_score = Math.ceil(assessment.total_score);
  for (let i = 0; i <= 5; i++) {
      if(i == total_score) {
          total_score_arr[0] = assessment.total_score;
           break;
        }
  }

  const assume_score_arr = [0,0];
  let assume_score = assessment.assume_score;
  for (let i = 1; i <= 5; i++) {
      if(i == assume_score) {
        assume_score_arr[1] = assume_score;
           break;
        }
  }

  const horizontalBarChartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
          display: true,
          scaleLabel: {
          display: true,
          labelString: 'Value'
          },
          ticks: {
          min: 0,
          max: 5,
          stepSize: 1,
          suggestedMin: 0.5,
          suggestedMax: 5.0,
          callback: function(label, index, labels) {
              switch (label) {
              case 0:
                return '';
              case 1:
                  return (assessment.roadmap_type == "Technical") ? technicalLabels[0] : nonTechnicalLabels[0];
              case 2:
                  return (assessment.roadmap_type == "Technical") ? technicalLabels[1] : nonTechnicalLabels[1];
              case 3:
                  return (assessment.roadmap_type == "Technical") ? technicalLabels[2] : nonTechnicalLabels[2];
              case 4:
                  return (assessment.roadmap_type == "Technical") ? technicalLabels[3] : nonTechnicalLabels[3];
              case 5:
                  return (assessment.roadmap_type == "Technical") ? technicalLabels[4] : nonTechnicalLabels[4];
             default:
                return '';
              }
          }
          },
          gridLines: {
          display: false
          }
      }
    },
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          labels: {
              font: {
                  size: window.innerWidth > 1024 ? 14 : window.innerWidth > 768 ? 9 : 6
              },
              boxWidth: 10,
              boxHeight: 10

            }
        },
        title: {
          display: false,
          text: '',
        },
      },
  };
  
  const horizontalBarChartData = {
      labels: horizontalBarChartLabels,
      datasets: [
        {
          label: 'Post Skill Assessment Rating',
          data: total_score_arr,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: 'Pre Skill Assessment Rating',
          data: assume_score_arr,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    };
    
  return (
    <div className="row h-full">
      <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
        <Container fluid>
          <Row>
              {!loading && (
            <> 
            
            <div className="col-md-12">
              <button type='button' onClick={navigateToHistoryPage} className='btn btn-primary history_link'>Check Historical Data</button>
            </div>

            <div className="row">

            {
              (assessment.assume_score != 0) && (
                <>
                <div className="col-md-12 mt-4 mb-4">
                  <div className="chart-container">
                    <Bar options={horizontalBarChartOptions} data={horizontalBarChartData}/>
                  </div>
                </div>
                </>
              )
            }

              <Col md={12}>
                  <div style={{height:"500px",width:"800px",margin:"auto"}} className="proccessChart" >
                  <ReactSpeedometer
                    fluidWidth={true}
                    minValue={0}
                    maxValue={100}
                    value={SelfToalScore} 
                    currentValueText={"Percentile Rank - " + SelfToalScore}
                    needleColor="steelblue"
                    segmentColors={[
                      "rgb(0, 192, 192)",
                      "rgb(0, 179, 143)",
                      "rgb(0, 166, 100)",
                      "rgb(0, 153, 61)",
                      "rgb(0, 141, 28)",
                    ]}
                    customSegmentLabels={customSegmentLabels}
                  />
                  </div>
              </Col>

              <h2>Skills by Levels</h2>
              <Bar ref={innerChartRef} data={levelBarChartData} options={levelBarChartOptions} onClick={handleInnerChartProceed} />
             

            {!thirdChartLoading && (
            <>
              <h2>History of Skills</h2>
              
                <Bar data={{
                  labels: thirdChartSkillData.label,
                  datasets: [
                  {
                      label: 'Skills',
                      data: thirdChartSkillData.data,
                      backgroundColor: "rgba(53, 162, 235, 0.5)"
                  }
                  ],
              }} options={thirdBarChartOptions} />
              </>
            )}
            </div>
            </>
          )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AssessmentResult;