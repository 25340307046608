
import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Container, Modal, Button, Form} from 'react-bootstrap';
import Moment from 'moment';
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const ProfileRoadmaps = () => {
  const { getRoadmap, logout, deleteRoadmap, getSkillLevels, createUpdateRoadmap, getSingleRoadmap, bulkRoadmapChange, importRoadmaps, getSkills, bulkAddEditRoadmapSkillsAndQuestions } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [allroadmaps, setAllroadmaps] = useState([]);
  const [newRoadmaps, setNewRoadmaps] = useState([]);
  const [bulkRoadmaps, setBulkRoadmaps] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [showRoadmapUploadModal, setShowRoadmapUploadModal] = useState(false);
   const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [successBullMsg, setSuccessBullMsg] = useState('');
  const [errModalMsg, setErrModalMsg] = useState('');
  const [roadmapTypes, setRoadmapTypes] = useState([]);
  const [skillLevel, setSkillLevel] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    roadmap_id:0,
    roadmap_title:'',
    roadmap_description:'',
    roadmap_type:'',
    roadmap_subtype:''

  });
  const [file, setFile] = useState(null);
  const [skills, setSkills] = useState([]);
  const [selectedRoadmapIdForBulkEdit, setSelectedRoadmapIdForBulkEdit] = useState(null);
  
  // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const res = await getRoadmap();
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){
          setAllroadmaps(res.data);
          setBulkRoadmaps(res.data);
          setLoading(false);
      }else{
      }

      setLoading(true);
      let response2 = await getSkillLevels(0);
      if(response2){
        if(response2.token_message){
          console.error(response2.token_message);
          logout();
        }else{
          let temp = [];
          let arr = [];
          (response2.data).forEach(element => {
            if(!arr.includes(element.roadmap_type)) {
              arr.push(element.roadmap_type);
              temp.push(element);
            }
          });
          setRoadmapTypes(temp);

          setSkillLevel(response2.data);
          setErrMsg(false);
          setLoading(false);
        }
      }
      else {
          setErrMsg(response2.message);
      }

      setTimeout(() => { 
        $("#roadmap_table").DataTable({
          pagingType: "full_numbers",
          pageLength: myConstent.DATATABLERECORDLIMIT,
          processing: true,
          stateSave: true,
          bDestroy: true,
          scrollX: true,
          dom: "Bfrtip",
          select: {
            style: "single",
          },
          order: [[3, 'desc']],
          buttons: [
            {
              extend: "pageLength",
              className: "btn btn-secondary bg-secondary",
            }
          ],
          lengthMenu: [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ]
        });
      },1000);

      
    };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteRoadmap = async (id) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
      let data = await deleteRoadmap(id);
      if(data.status){
          setErrMsg(false);
          fetchData();
      }
      else if(!data.status && data.message){
          setErrMsg(data.message);
      }
    }
    
  };

  const handleEditRoadmap = async (id) => {
      let data = await getSingleRoadmap(id);
      if(data.status){
          setModalType('Update');
          setErrMsg(false);
          setFormData({
            roadmap_id:data.data.roadmap_id,
            roadmap_title:data.data.roadmap_name,
            roadmap_description:data.data.roadmap_description,
            roadmap_type:data.data.roadmap_type,
            roadmap_subtype:data.data.roadmap_subtype,
          });
          setShowModal(true);
      }
      else if(!data.status && data.message){
          alert(data.message);
      }
  };
  

  const openRoadmapModal = async (e) => {
    setModalType('Create');
    setShowModal(true);
  }
  const openRoadmapBulkModal = async (e) => {
    setShowBulkModal(true);
  }

  const openRoadmapUploadModal = async (e) => {
    setShowRoadmapUploadModal(true);
  }

  const handleCloseModal = () => {
    setModalType('');
    setShowModal(false);
    setFormData({
      roadmap_id:0,
      roadmap_title:'',
      roadmap_description:'',
      roadmap_type:'',
      roadmap_subtype:'',
    });
  };

  const handleCloseBulkModal = () => {
    setShowBulkModal(false);
  };

  const handleUploadRoadmapCloseModal = () => {
    setFile(null);
    setShowRoadmapUploadModal(false);
  }

  const onChangeInput = (e) => {
      setFormData({
        ...formData,
        [e.target.name]:e.target.value
      });
  };

  const saveModal = async (e) => {
    e.preventDefault();

    if(formData.roadmap_title == '' || formData.roadmap_description == '' || formData.roadmap_type == '' || formData.roadmap_subtype == '') {
      setErrModalMsg('Please enter all fields!'); return false;
    }else {
      setErrModalMsg(false);
    }
   
    let data = await createUpdateRoadmap(formData);
    if(data.status){
        setErrModalMsg(false);
        setFormData({
          roadmap_id:0,
          roadmap_title:'',
          roadmap_description:'',
          roadmap_type:'',
          roadmap_subtype:'',
        });
        handleCloseModal();
        fetchData();
    }
    else if(!data.status && data.message){
      setErrModalMsg(data.message);
    }
  }
  
  const handleDrawRoadmap = async (id, roadmap_slug) => {
    navigate(`/roadmaps/draw/${roadmap_slug}`, {
      state: {
          roadmapId: id,
          path: ''
      },
    });
  };
  
  const onChangeRoadmapInput = (e) => {
      var roadmap_id = e.target.attributes['data-roadmap_id'].value;

      const nextShapes = bulkRoadmaps.map(ele => {
        if (ele.roadmap_id === roadmap_id) {
          return {
            ...ele,
            [e.target.name]: e.target.value,
          };
        } else {
          return ele;
        }
      });
      setBulkRoadmaps(nextShapes);

  };

  const onChangeNewRoadmapInput = (e) => {
    var main_index = e.target.attributes['data-index'].value;
    const nextRows = newRoadmaps.map((ele,index) => {
      if (index == main_index) {
        return {
          ...ele,
          [e.target.name]: e.target.value,
        };
      } else {
        return ele;
      }
    });
    setNewRoadmaps(nextRows);
  };

  const addRow = () => { 
      var temp = newRoadmaps;
      //take the existing state and concat the new data and set the state again   
      setNewRoadmaps([...temp, {
        roadmap_id:'',
        roadmap_name:'',
        roadmap_description:'',
        roadmap_type:'',
        roadmap_subtype:''
      }]);   
  };

  const deleteNewAddedRow = (main_index) => {
    setNewRoadmaps(newRoadmaps.length > 1 ? newRoadmaps.slice(main_index) : []);
  };

  const saveBulkModal = async (e) => {
    e.preventDefault();

    setErrModalMsg(false);
    let data = await bulkRoadmapChange(
      {
        update_roadmaps:bulkRoadmaps,
        new_roadmaps:newRoadmaps
      }
    );
    if(data.status){
        setSuccessBullMsg(data.message);
        setNewRoadmaps([]);
        fetchData();
    }
    else if(!data.status && data.message){
      setErrModalMsg(data.message);
    }
  }
  const onChangeInputFile = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }
  const uploadRoadmapFile = async (e) => {
    e.preventDefault();
    setErrModalMsg('');
    setSuccessBullMsg('');
    if (file != null) {
      const formFileData = new FormData();
      formFileData.append("file", file);
      let data = await importRoadmaps(
        {
          file : formFileData
        }
      );
      if(data.status){
          setSuccessBullMsg(data.message);
          setFile(null);
          setTimeout(
          () => {
            setShowRoadmapUploadModal(false);
            setErrModalMsg('');
            fetchData();
          },1000);

      }
      else if(!data.status && data.message){
        setErrModalMsg(data.message);
      }
    } else {
      setErrModalMsg('Please choose file!');
      return false;
    }
  }

  const handleBulkEdit = async (id) => {
    setShowBulkEditModal(true);
    setSelectedRoadmapIdForBulkEdit(id);

    setSuccessBullMsg(false);
    setErrModalMsg(false);

    const response = await getSkills(id);
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else if(response.data){
          var result = response.data;
          var skillResult = result.map((ele,index) => {
            if (ele.questionDetails.length == 0) {
              var questions = ele.questionDetails;
              questions.push({
                skills_question_id: 0,
                skill_question:''
              });
              return {
                ...ele,
                questionDetails: questions
              };
            }else{
              return ele;
            } 
          });
          setSkills(skillResult);
      }
  };

  const handleCloseBulkEditModal = (e) => {
      setShowBulkEditModal(false);
      setSkills([]);
  }

  const saveBulkEditModal = async (e) => {
    e.preventDefault();
    
    
    setSuccessBullMsg(false);
    setErrModalMsg(false);
    let data = await bulkAddEditRoadmapSkillsAndQuestions(
      {
        skills:skills,
        roadmap_id:selectedRoadmapIdForBulkEdit
      }
    );
    if(data.status){
        setSuccessBullMsg(data.message);
        setTimeout(() => {
          setSkills([]);
          handleBulkEdit(selectedRoadmapIdForBulkEdit);
        },2000);
    }
    else if(!data.status && data.message){
      setErrModalMsg(data.message);
    }
  }

  const onChangeSkillInput = (e) => {
      var skill_id = e.target.attributes['data-skill_id'].value;

      const nextShapes = skills.map((ele,index) => {
        if (ele.skill_id === skill_id) {
          return {
            ...ele,
            [e.target.name]: e.target.value
          };
        } else if ('NEW@'+index === skill_id) {
          return {
            ...ele,
            [e.target.name]: e.target.value
          };
        } else {
          return ele;
        }
      });
      setSkills(nextShapes);
  };

  const onChangeSkillQuestionInput = (e) => {
      var skill_id = e.target.attributes['data-skill_id'].value;
      var question_id = e.target.attributes['data-question_id'].value;

      const nextShapes = skills.map((ele,index) => {
        if (ele.skill_id === skill_id) {
          var questions = ele.questionDetails.map((que,qindex) => {
            if (que.skills_question_id === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else if ('NEW@'+qindex === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else {
              return que;
            }
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else if ('NEW@'+index === skill_id) {
          var questions = ele.questionDetails.map((que,qindex) => {
            if (que.skills_question_id === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else if ('NEW@'+qindex === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else {
              return que;
            }
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else {
          return ele;
        }
      });
      setSkills(nextShapes);
  };

  const addSkillRow = () => { 
      var temp = skills;
      setSkills([...temp, {
        skill_id:0,
        skill_name:'',
        questionDetails:[{
          skills_question_id: 0,
          skill_question:''
        }]
      }]);
  };

  const deleteNewAddedSkillRow = (main_index) => {
    if(skills.length > 1) {
      var next = [];
      skills.map((ele,index) => {
        if (index != main_index) {
          next.push(ele);
        } 
      });
      setSkills(next);
    } else {
      setSkills([]);
    }
    
  };

  const addQuestionRow = (skill_id) => {

      const nextShapes = skills.map((ele,index) => {
        if (ele.skill_id === skill_id) {
          var questions = ele.questionDetails;
          questions.push({
            skills_question_id: 0,
            skill_question:''
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else if ('NEW@'+index === skill_id) {
          var questions = ele.questionDetails;
          questions.push({
            skills_question_id: 0,
            skill_question:''
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else {
          return ele;
        }
      });
      setSkills(nextShapes);
  }
  const removeQuestionRow = (skill_id, question_id) => {
    const nextShapes = skills.map((ele,index) => {
      if (ele.skill_id === skill_id) {
        var questions = [];
        ele.questionDetails.map((que,qindex) => {
          if (que.skills_question_id != question_id && ('NEW@'+qindex) != question_id) {
            questions.push(que);
          } 
        });
        return {
          ...ele,
          questionDetails: questions
        };
      } else if ('NEW@'+index === skill_id) {
        var questions = [];
        ele.questionDetails.map((que,qindex) => {
          if (que.skills_question_id != question_id && ('NEW@'+qindex) != question_id) {
            questions.push(que);
          } 
        });
        return {
          ...ele,
          questionDetails: questions
        };
      } else {
        return ele;
      }
    });
    setSkills(nextShapes);
}

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <Row>
              <Col md={12} lg={7}>
                <h1>Roadmaps</h1>
              </Col>
              <Col md={12} lg={5} className="btn-area">
              <button className="btn btn-warning" style={{marginRight:'2px'}} type="buuton" onClick={openRoadmapUploadModal}>Import Roadmaps</button>
              <button className="btn btn-success" style={{marginRight:'2px'}} type="buuton" onClick={openRoadmapBulkModal}>Bulk Roadmaps</button>
              <button className="btn btn-dark" type="buuton" onClick={openRoadmapModal}>Create Roadmap</button>
              </Col>
               {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
            </Row>
            {!loading && (
            <>
              <div className="p-0 pb-2">

                <table id="roadmap_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                  <thead>
                    <tr>
                      <th>Roadmap Name</th>
                      {/* <th>Roadmap Description</th> */}
                      <th>Roadmap Type</th>
                      <th>Roadmap Subtype</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allroadmaps.map((row, index) => (
                      <tr key={index}>
                        <td>{row.roadmap_name}</td>
                        {/* <td>{row.roadmap_description}</td> */}
                        <td>{row.roadmap_type}</td>
                        <td>{row.roadmap_subtype}</td>
                        <td>{Moment(row.create_timestamp).format(myConstent.DATETIMEFORMAT)}</td>
                        <td>
                            <button className='btn btn-sm btn-info btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleBulkEdit(row.roadmap_id)}>
                              Bulk Text Edit
                            </button>
                            <button className='btn btn-sm btn-success btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleEditRoadmap(row.roadmap_id)}>
                              Edit
                            </button>
                            <button className='btn btn-sm btn-warning mx-1' style={{'marginBottom':'2px'}} onClick={() => handleDrawRoadmap(row.roadmap_id, row.roadmap_slug)}>
                              Draw
                            </button>
                            <button className='btn btn-sm btn-danger mx-1' style={{'marginBottom':'2px'}} onClick={() => handleDeleteRoadmap(row.roadmap_id)}>
                              Delete
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
            )}

            <Modal show={showModal} centered>
              <Modal.Header>
                <Modal.Title>{modalType} Roadmap</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <Form.Group controlId="delete-team" className="mb-3">
                    <Form.Label>Roadmap Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="roadmap_title"
                      value={formData.roadmap_title}
                      onChange={onChangeInput}
                      placeholder="Enter Title"
                      
                    />
                  </Form.Group>
                  <Form.Group controlId="delete-team" className="mb-3">
                    <Form.Label>Roadmap Type</Form.Label>
                    <Form.Select name="roadmap_type" aria-label="Default select example" onChange={onChangeInput}>
                      <option value="">Choose Option</option>
                      {roadmapTypes.filter(newVal => (newVal.roadmap_type != 'Custom')).map((level) => (
                        <option value={level.roadmap_type} selected={formData.roadmap_type == level.roadmap_type}>{level.roadmap_type}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="delete-team" className="mb-3">
                    <Form.Label>Roadmap Subtype</Form.Label>
                    <Form.Select name="roadmap_subtype" aria-label="Default select example" onChange={onChangeInput}>
                      <option value="">Choose Option</option>
                      {skillLevel.filter(newVal => (formData.roadmap_type == newVal.roadmap_type && newVal.roadmap_subtype != 'Custom')).map((level) => (
                        <option value={level.roadmap_subtype} selected={formData.roadmap_subtype == level.roadmap_subtype} >{level.roadmap_subtype}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="delete-team" className="mb-3">
                    <Form.Label>Roadmap Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="roadmap_description"
                        value={formData.roadmap_description}
                        placeholder="Enter Description"
                        onChange={onChangeInput}
                      />
                  </Form.Group>


                  {errModalMsg && <h4 className="err-msg text-danger text-center">{errModalMsg}</h4>}
              </Modal.Body>
              

              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button variant="outline-secondary" onClick={handleCloseModal}>Cancel</Button>
                </div>
                <div className="btn-right">
                  <Button variant="dark" onClick={saveModal}>Save</Button>
                  {/* <Button variant="outline-secondary mx-2">Save as placeholder</Button>
                  <Button variant="dark">Continue to Editor</Button> */}
                </div>
              </Modal.Footer>
            </Modal>

            <Modal show={showBulkModal} fullscreen className="bulkModal" centered onHide={handleCloseBulkModal}>
              <Modal.Header closeButton>
                <Modal.Title>Bulk Roadmap</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  {/* <Table roadmapdata={allroadmaps} /> */}
                  <table className="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th >Roadmap Name</th>
                      <th >Roadmap Descripion</th>
                      <th >Roadmap Type</th>
                      <th >Roadmap Subtype</th>
                      <td >Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    
                  {/* <Table roadmapdata={allroadmaps} /> */}
                    {bulkRoadmaps.map((row, index) => (
                      <tr key={index}>
                        <td><input name='roadmap_name' data-roadmap_id={row.roadmap_id}  type='text' value={row.roadmap_name} onChange={onChangeRoadmapInput} /></td>
                        <td><input name='roadmap_description' data-roadmap_id={row.roadmap_id} type='text' value={row.roadmap_description} onChange={onChangeRoadmapInput}/></td>
                        <td>
                          <select name='roadmap_type' data-roadmap_id={row.roadmap_id} aria-label="Default select example" onChange={onChangeRoadmapInput}>
                          {roadmapTypes.filter(newVal => (newVal.roadmap_type != 'Custom')).map((level) => (
                            <option value={level.roadmap_type} selected={row.roadmap_type == level.roadmap_type}>{level.roadmap_type}</option>
                          ))}
                          </select>
                        </td>
                        <td>
                          <select name='roadmap_subtype' data-roadmap_id={row.roadmap_id} aria-label="Default select example" onChange={onChangeRoadmapInput}>
                            {skillLevel.filter(newVal => (row.roadmap_type == newVal.roadmap_type && newVal.roadmap_subtype != 'Custom')).map((level) => (
                              <option value={level.roadmap_subtype} selected={row.roadmap_subtype == level.roadmap_subtype} >{level.roadmap_subtype}</option>
                            ))}
                          </select>
                        </td>
                        <td></td>
                      </tr>
                    ))}

                    {newRoadmaps.map((row, index) => (
                      <tr key={index}>
                        <td><input name='roadmap_name' data-index={index}  type='text' value={row.roadmap_name} onChange={onChangeNewRoadmapInput} /></td>
                        <td><input name='roadmap_description' data-index={index} type='text' value={row.roadmap_description} onChange={onChangeNewRoadmapInput}/></td>
                        <td>
                          <select name='roadmap_type' data-index={index} aria-label="Default select example" onChange={onChangeNewRoadmapInput}>
                          <option value="">Choose Option</option>
                          {roadmapTypes.filter(newVal => (newVal.roadmap_type != 'Custom')).map((level) => (
                            <option value={level.roadmap_type} selected={row.roadmap_type == level.roadmap_type}>{level.roadmap_type}</option>
                          ))}
                          </select>
                        </td>
                        <td>
                          <select name='roadmap_subtype' data-index={index} aria-label="Default select example" onChange={onChangeNewRoadmapInput}>
                          <option value="">Choose Option</option>
                            {skillLevel.filter(newVal => (row.roadmap_type == newVal.roadmap_type && newVal.roadmap_subtype != 'Custom')).map((level) => (
                              <option value={level.roadmap_subtype} selected={row.roadmap_subtype == level.roadmap_subtype} >{level.roadmap_subtype}</option>
                            ))}
                          </select>
                        </td>
                        <td><button className='btn btn-danger' onClick={() => deleteNewAddedRow(index)}>Delete</button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className='btn btn-success pull-right' style={{marginTop:'10px'}} onClick={addRow}>ADD</button>
                  {successBullMsg && <h4 className="err-msg text-success text-center">{successBullMsg}</h4>}
                  {errModalMsg && <h4 className="err-msg text-danger text-center">{errModalMsg}</h4>}
                  
                
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button variant="outline-secondary" onClick={handleCloseBulkModal}>Cancel</Button>
                </div>
                <div className="btn-right">
                  <Button variant="dark" onClick={saveBulkModal}>Save</Button>
                </div>
              </Modal.Footer>
            </Modal>

            <Modal show={showBulkEditModal} size='lg' className="bulkEditModal" centered onHide={handleCloseBulkEditModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add/Edit Skills</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <table className="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th style={{width:'50px'}}></th>
                      <th >Skill Name</th>
                      <th >Skill Questions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (skills.length > 0) && (
                      skills.map((skill, sindex) => (
                        skill.questionDetails.map((question, qindex) => (
                          <>
                          <tr key={qindex}>
                              {(qindex == 0) &&
                                (<>
                                <td style={{width:'50px'}} scope="row" rowSpan={skill.questionDetails.length}><div className="question_box">
                                    <button className='btn btn-danger rounded-circle' onClick={() => deleteNewAddedSkillRow(sindex)}><i className="fa fa-remove"></i></button></div>
                                </td>
                                <td  scope="row" rowSpan={skill.questionDetails.length}>
                                  <input name='skill_name' data-skill_id={(skill.skill_id == 0) ? ('NEW@'+sindex) : skill.skill_id} data-question_id={(question.skills_question_id == 0) ? ('NEW@'+qindex) : question.skills_question_id}  type='text' value={skill.skill_name} onChange={onChangeSkillInput} />
                                </td></>)
                              }
                            
                            <td className='question_box'>
                              <input name='skill_question' data-skill_id={(skill.skill_id == 0) ? ('NEW@'+sindex) : skill.skill_id}  data-question_id={(question.skills_question_id == 0) ? ('NEW@'+qindex) : question.skills_question_id} type='text' value={question.skill_question} onChange={onChangeSkillQuestionInput}/>
                                {
                                  (qindex != 0) && (
                                    <div className="question_box">
                                      <button className='btn btn-danger pull-right rounded-circle' style={{marginLeft:'10px'}} onClick={() => removeQuestionRow((skill.skill_id == 0) ? ('NEW@'+sindex) : skill.skill_id, (question.skills_question_id == 0) ? ('NEW@'+qindex) : question.skills_question_id)}><i className="fa fa-remove"></i></button>
                                    </div>
                                  )
                                }

                                {
                                  (skill.questionDetails.length == (qindex + 1)) && (
                                    <div className="question_box">
                                      <button className='btn btn-success pull-right rounded-circle' style={{marginLeft:'10px'}} onClick={() => addQuestionRow((skill.skill_id == 0) ? ('NEW@'+sindex) : skill.skill_id)}><i className="fa fa-plus"></i></button>
                                    </div>
                                  )
                                }
                            </td>
                          </tr>
                          </>
                        ))
                      ))
                    )        
                    }
                    
                  </tbody>
                </table>
                <button className='btn btn-success pull-right' style={{marginTop:'10px'}} onClick={addSkillRow}>ADD SKILL</button>
                  {successBullMsg && <h4 className="err-msg text-success text-center">{successBullMsg}</h4>}
                  {errModalMsg && <h4 className="err-msg text-danger text-center">{errModalMsg}</h4>}
                  
                
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button variant="outline-secondary" onClick={handleCloseBulkEditModal}>Cancel</Button>
                </div>
                <div className="btn-right">
                  <Button variant="dark" onClick={saveBulkEditModal}>Save</Button>
                </div>
              </Modal.Footer>
            </Modal>

            <Modal show={showRoadmapUploadModal} centered>
              <Modal.Header>
                <Modal.Title>Bulk Upload</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <Form.Group controlId="delete-team" className="mb-3">
                    <Form.Control
                      type="file"
                      name="roadmap_file"
                      onChange={onChangeInputFile}
                      
                    />
                  </Form.Group>
                  <p><a href="/import_sample/roadmap-demo-file.csv"><strong>Download Sample File</strong></a></p>
                  {successBullMsg && <h4 className="err-msg text-success text-center">{successBullMsg}</h4>}
                  {errModalMsg && <h4 className="err-msg text-danger text-center">{errModalMsg}</h4>}
              </Modal.Body>
              

              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button variant="outline-secondary" onClick={handleUploadRoadmapCloseModal}>Cancel</Button>
                </div>
                <div className="btn-right">
                  <Button variant="dark" onClick={uploadRoadmapFile}>Upload</Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
  );
};

export default ProfileRoadmaps;
