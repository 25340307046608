import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileMenu from "./profilemenu";
import { Form, Row, Container } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';

const ProfileSettings = () => {
    const { getSingleUserDetail, changeUserPassword, updateSingleUserDetail, deleteUserAccount, logout , getAllTeamsOfUSer} = useContext(UserContext);
    const user =  JSON.parse(localStorage.getItem('userData'));
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [errPasswordMsg, setPasswordErrMsg] = useState('');
    const [successPasswordMsg, setPasswordSuccessMsg] = useState('');
    const [profileUser, setProfileUser] = useState([]);
    const navigate = useNavigate();

    const [teams, setTeams] = useState([]);
    const [formData, setFormData] = useState({
        id:'',
        username:'', 
        fname: '',
        lname:'',
        email:'',
        organization_name:'',
        location:'',
        job_title: '',
        job_industry: '',
        newsletter_subscribe: 0,
        enable_notifications: 0,
        last_login_team_id:0
      });

    const [changePasswordFormData, setChangePasswordFormData] = useState({
        id:user.id,
        password:'',
        cpassword: ''
    });

    const onPasswordChangeInput = (e) => {
        setChangePasswordFormData({
          ...changePasswordFormData,
          [e.target.name]:e.target.value
        });
    };
      
    const onChangeInput = (e) => {
        setFormData({
          ...formData,
          [e.target.name]:e.target.value
        });
    };

    const handleChange = (e) => {
        const { value, name, checked } = e.target;
        if (checked) {
            setFormData({
                ...formData,
                [name]:1
            });
        }
        else {
            setFormData({
                ...formData,
                [name]:0
            });
        }
    };
      
    // Function to fetch data from the API
    const fetchData = async (last_login_team_id_updated = 0) => {
        const res1 = await getAllTeamsOfUSer(user.id);
        if(res1.token_message){
            console.error(res1.token_message);
            logout();
        }else if(res1){
            setTeams(res1.data);
        }else{
        }

        const res = await getSingleUserDetail(user.id);
        if(res.token_message){
            console.error(res.token_message);
            logout();
        }else if(res){
            setFormData(res.data);
            res.data.login_user_type = "USER";
            localStorage.setItem('userData', JSON.stringify(res.data));

            if(last_login_team_id_updated == 1) {
                setTimeout(() => {
                    navigate("/profile");
                },1000);
            }
        }else{
        }
    };
    
    // Use useEffect to fetch data when the component mounts
    useEffect(() => {
        setLoading(true);
        fetchData();
        setLoading(false);
    }, []);

    
    const saveUser = async (last_login_team_id_updated = 0) => {
        setSuccessMsg('');
        setErrMsg('');
        let data = await updateSingleUserDetail(formData);
        if(data.status){
            if(last_login_team_id_updated == 1) {
                setSuccessMsg("Profile switched successfully");
            } else {
                setSuccessMsg(data.message);
            }
            setErrMsg(false);
            setTimeout(() => {
                fetchData(last_login_team_id_updated);
            },2000);
        }
        else if(!data.status && data.message){
            setErrMsg(data.message);
            setSuccessMsg('');
        }
    }

    const saveUserPassword = async (e) => {
        e.preventDefault();
        setPasswordSuccessMsg('');
        setPasswordErrMsg('');
        if(changePasswordFormData.password == '' || changePasswordFormData.cpassword == '') {
            setPasswordErrMsg('Please enter password & confirm password');
            setPasswordSuccessMsg('');
            return false;
        }else if(changePasswordFormData.password != changePasswordFormData.cpassword) {
            setPasswordErrMsg('Password & confirm password does not match.');
            setPasswordSuccessMsg('');
            return false;
        }

        let data = await changeUserPassword(changePasswordFormData);
        if(data.status){
            setPasswordSuccessMsg(data.message);
            setPasswordErrMsg(false);
            setChangePasswordFormData({
                id:user.id,
                password:'',
                cpassword: ''
            });
        }
        else if(!data.status && data.message){
            setPasswordErrMsg(data.message);
            setPasswordSuccessMsg('');
        }
    }

    const deleteAccount = async () => {
        const confirmBox = window.confirm(
            "Are you sure you want to delete an account?"
        )
        if (confirmBox === true) {
            var user_id = user.id;
            const response = await deleteUserAccount(user_id);
            if(response){
              if(response.token_message){
                console.error(response.token_message);
                logout();
              }else{
                console.log(response);
                logout();
              }
            }
            else {
            }
        }
    };
    return (
        <div className="row h-full">
            <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
                <ProfileMenu />
            </div>
            <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
                <Container fluid>
                {!loading ? (
                    <>
                    <div className="settings-container-xl">
                        {/* Account page navigation */}
                        <ul className="settings-nav settings-nav-borders nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="settings-nav-link active ms-0" href="#profile" data-bs-toggle="tab">Profile</a>
                            </li>
                            <li className="nav-item">
                                <a className="settings-nav-link" href="#scurity" data-bs-toggle="tab">Security</a>
                            </li>
                            <li className="nav-item">
                                <a className="settings-nav-link" href="#notifications" data-bs-toggle="tab">Notifications</a>
                            </li>
                            <li className="nav-item">
                                <a className="settings-nav-link" href="#switchTeam" data-bs-toggle="tab">Team Setting</a>
                            </li>
                        </ul>
                        <hr className="settings-hr mt-0 mb-4"/>
                        <div className="tab-content">
                            <div className="tab-pane active" id="profile">
                                <div className="row">
                                    {/* <div className="col-xl-4">
                                        <div className="settings-card mb-4 mb-xl-0">
                                            <div className="settings-card-header">Profile Picture</div>
                                            <div className="settings-card-body text-center">
                                                <img className="settings-img-account-profile rounded-circle mb-2" src="http://bootdey.com/img/Content/avatar/avatar1.png" alt=""/>
                                                <div className="settings-small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                                                <button className="settings-btn settings-btn-primary" type="button">Upload new image</button>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12">
                                        {/* Account details card */}
                                        <div className="settings-card mb-4">
                                            <div className="settings-card-header">Account Details</div>
                                            <div className="settings-card-body">
                                                <form>
                                                    {/* Form Group (username) */}
                                                    <div className="settings-form-group mb-3">
                                                        <label className="settings-small mb-1" htmlFor="inputUsername">Username (how your name will appear to other users on the site)</label>
                                                        <input name="username" className="settings-form-control form-control form-control" id="inputUsername" type="text" placeholder="Enter your username" value={formData.username} onChange={onChangeInput}/>
                                                    </div>
                                                    {/* Form Row */}
                                                    <div className="row settings-row gx-3 mb-3">
                                                        {/* Form Group (first name) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputFirstName">First name</label>
                                                            <input className="settings-form-control form-control" name="fname" id="inputFirstName" type="text" placeholder="Enter your first name" value={formData.fname} onChange={onChangeInput} />
                                                        </div>
                                                        {/* Form Group (last name) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputLastName">Last name</label>
                                                            <input className="settings-form-control form-control" name="lname" id="inputLastName" type="text" placeholder="Enter your last name" value={formData.lname} onChange={onChangeInput} />
                                                        </div>
                                                    </div>
                                                    {/* Form Row */}
                                                    <div className="row settings-row gx-3 mb-3">
                                                        {/* Form Group (organization name) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputOrgName">Organization name</label>
                                                            <input className="settings-form-control form-control" name="organization_name" id="inputOrgName" type="text" placeholder="Enter your organization name" value={formData.organization_name} onChange={onChangeInput}/>
                                                        </div>
                                                        {/* Form Group (location) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputLocation">Location</label>
                                                            <input className="settings-form-control form-control" name="location" id="inputLocation" type="text" placeholder="Enter your location" value={formData.location} onChange={onChangeInput}/>
                                                        </div>
                                                    </div>
                                                    {/* Form Row */}
                                                    <div className="row settings-row gx-3 mb-3">
                                                        {/* Form Group (job title) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputJobTitle">Job Title</label>
                                                            <input className="settings-form-control form-control" name="job_title" id="inputJobTitle" type="text" placeholder="Enter your job title" value={formData.job_title} onChange={onChangeInput}/>
                                                        </div>
                                                        {/* Form Group (job industry) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputJobIndustry">Job Industry</label>
                                                            <input className="settings-form-control form-control" name="job_industry" id="inputJobIndustry" type="text" placeholder="Enter your job industry" value={formData.job_industry} onChange={onChangeInput}/>
                                                        </div>
                                                    </div>
                                                    {/* Form Group (email address) */}
                                                    <div className="settings-form-group mb-3">
                                                        <label className="settings-small mb-1" htmlFor="inputEmailAddress">Email address</label>
                                                        <input className="settings-form-control form-control" id="inputEmailAddress" type="email" name="email" value={formData.email} placeholder="Enter your email address" disabled/>
                                                    </div>
                                                    {/* Save changes button */}
                                                    <button className="settings-btn settings-btn-primary" type="button" onClick={saveUser}>Save changes</button>
                                                    {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
                                                    {successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="scurity">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {/* Password change card */}
                                        <div className="settings-card mb-4 mb-xl-0">
                                            <div className="settings-card-header">Change Password</div>
                                            <div className="settings-card-body">
                                                <form>
                                                    {/* Form Row */}
                                                    <div className="row settings-row gx-3 mb-3">
                                                        {/* Form Group (password) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputPassword">Password</label>
                                                            <input className="settings-form-control form-control" name="password" id="inputPassword" type="password" placeholder="Enter your password" value={changePasswordFormData.password} onChange={onPasswordChangeInput}/>
                                                        </div>
                                                        {/* Form Group (confirm password) */}
                                                        <div className="col-md-6">
                                                            <label className="settings-small mb-1" htmlFor="inputConfirmPassword">Confirm Password</label>
                                                            <input className="settings-form-control form-control" name="cpassword" id="inputConfirmPassword" type="password" placeholder="Enter your confirm password" value={changePasswordFormData.cpassword}  onChange={onPasswordChangeInput}/>
                                                        </div>
                                                    </div>
                                                    
                                                    {/* Save changes button */}
                                                    <button className="settings-btn settings-btn-primary" type="button" onClick={saveUserPassword}>Save changes</button>

                                                    {errPasswordMsg && <h4 className="err-msg text-danger text-center">{errPasswordMsg}</h4>}
                                                    {successPasswordMsg && <h4 className="err-msg text-success text-center">{successPasswordMsg}</h4>}

                                                    <br/><br/>
                                                    
                                                    <div className="row settings-row">
                                                        <hr/>
                                                        <h2>Delete Account</h2>
                                                        <p>Permanently remove your account? This cannot be undone and all your progress and data will be lost.</p>
                                                        <button className="settings-delete-btn settings-btn-primary" onClick={deleteAccount} type="button">Delete Account</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="notifications">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {/* Password change card */}
                                        <div className="settings-card mb-4 mb-xl-0">
                                            <div className="settings-card-header">Notifications</div>
                                            <div className="settings-card-body">
                                                <form>
                                                    {/* Form Row */}
                                                    <div className="row settings-row gx-3 mb-3">
                                                        {/* Form Group (newsletter subscribe) */}
                                                        <div className="col-md-6">
                                                            <input className="settings-form-control form-control" name="newsletter_subscribe" id="inputNewsletterSubscribe" type="checkbox" checked={formData.newsletter_subscribe == 1 ? 'checked' : ''} onChange={handleChange}/> <label for="inputNewsletterSubscribe">Newsletter Subscribe</label>
                                                        </div>
                                                        {/* Form Group (enable notifications) */}
                                                        <div className="col-md-6">
                                                            <input className="settings-form-control form-control" name="enable_notifications" id="inputEnableNotifications" type="checkbox" checked={formData.enable_notifications == 1 ? 'checked' : ''} onChange={handleChange}/>  <label for="inputEnableNotifications">Enable Notifications</label>
                                                        </div>
                                                    </div>
                                                    
                                                    {/* Save changes button */}
                                                    <button className="settings-btn settings-btn-primary" type="button" onClick={saveUser}>Save changes</button>
                                                    
                                                    {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}{successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="switchTeam">
                                <div className="row">
                                    <div className="col-xl-12">
                                        {/* Password change card */}
                                        <div className="settings-card mb-4 mb-xl-0">
                                            <div className="settings-card-header">Team Setting</div>
                                            <div className="settings-card-body">
                                                <form>
                                                    {/* Form Row */}
                                                    <div className="row settings-row gx-3 mb-3">
                                                        {/* Form Group (newsletter subscribe) */}
                                                        <div className="col-md-6">
                                                            <Row>
                                                            <Form.Label>Select Team For Switch Account </Form.Label>
                                                            <Form.Select name="last_login_team_id" className="last_login_team_id" onChange={e => onChangeInput(e)}>
                                                                {(teams.length > 0) && teams.map((team, index) => (
                                                                <option value={team.id}  selected={(formData.last_login_team_id == team.id) ? true : false}>{team.name} ({team.parent_name}) ({team.role_title})</option>
                                                                ))}
                                                            </Form.Select>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    
                                                    <button className="settings-btn settings-btn-primary" type="button" onClick={() => saveUser(1)}>Save changes</button>
                                                    
                                                    {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}{successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    ) : <h6 className="text-center">Loading...</h6>
                }
                </Container>
            </div>
        </div>
    );
};

export default ProfileSettings;
