
import React, { useState, useEffect } from "react";

import { OnboardingPlaybookPage } from "../components/onboardingPlaybook";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";


export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
 
const OnboardingPlaybook = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
  
    return (
        <div>
            <OnboardingPlaybookPage data={landingPageData.About} />
        </div>
    );
};
 
export default OnboardingPlaybook;

