import React from "react";

export const Term = (props) => {
  return (
    <div id="terms" className="h-full">
      <div className="container">
        <div className="section-title text-center">
          <h2>Terms & conditions</h2>
          <p>
            
          </p>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="text-area">
            <section>
              <p>This Agreement, sometimes referred to as 'Terms of Use', comprises the terms governing your use of our websites ('Website'). By continuing to use the Website, you accept and agree to the following terms and conditions, as the same may be revised from time to time:</p>
              <ol className="list-groups">
                <li className="list-groups-item">'We', 'us', 'our' and 'Algence' refer to the Quantum Ventures LLC and its affiliates, that is entities which are controlled by, control, or are under common control with, Quantum Ventures LLC Company. For purposes of this Section, &ldquo;control&rdquo; means possessing, directly or indirectly, formally or de facto, the power to direct or substantially affect the direction of the management, policies and operations of an entity, whether through ownership of voting securities, by contract, by position of authority, through financing, marketing, product supply, or otherwise. However, this Agreement does not have the effect of making you, as a customer of one of our companies, a customer of any other such company.</li>
                <li className="list-groups-item">You agree to use the Website in a manner consistent with these Terms of Use.</li>
                <li className="list-groups-item">You may not transfer, sell, or otherwise assign your rights or obligations under this Agreement.</li>
                <li className="list-groups-item">We may terminate your access to and use of the Website at any time.</li>
                <li className="list-groups-item">This Agreement incorporates the Privacy Statement set forth on this Website as the same may be modified from time to time.</li>
                <li className="list-groups-item">You acknowledge that Algence or its licensors own and hold all intellectual property and proprietary rights, title and interest in the Website, and that your use of the same does not transfer any rights in the same to you. You agree:
              
                <ul className="list-groups">
                    <li className="list-groups-item">not to copy, reproduce, distribute, publish, display, perform, modify, or create derivative works from the Website. You may only download material from this Website for your own use as follows: you may make one machine readable copy and/or one print copy that is limited to occasional items of individual interest only. You may not remove or amend any trademark, copyright or other proprietary notice. Without limiting the generality of the foregoing, you may not distribute any part of this Website over any network, nor sell or offer it for sale. You may not copy, decompile, reverse-engineer, disassemble, modify, or create derivative works of the Website. Open Source software used in the Website is provided in accordance with the terms of the applicable Open Source Software license, and Algence is not a party to the same. We reserve all rights that are not specifically granted to you.</li>
                    <li className="list-groups-item">That you are expressly prohibited from presenting the Website in a setting created by you or a third party (e.g., through framing), particularly (but not exclusively) where that setting includes advertising or other materials that we have not authorized to be displayed with the Website.</li>
                    <li className="list-groups-item">That any of your questions, comments, or suggestions for improvements regarding the Website or the services that you email, post, or otherwise transmit to us (your "Communications") will become the property of Algence. You hereby relinquish and assign to Algence the worldwide, royalty free, right and title to run, use, re-use, implement, copy, display, and create derivative works from, those Communications, including any ideas, concepts, know-how, or techniques contained in your Communications for any purpose.</li>
                </ul>
              </li>
              
                <li className="list-groups-item">All content and functionality displayed, provided, reproduced, or distributed by us on the Website are for information purposes only and are not intended as, and are not a substitute for advice, including, without limitation, a professional's advice on legal, tax, or investment matters.</li>
                <li className="list-groups-item">Where we provide or enable links to third party sites, we do so for information purposes only and we accept no liability for your use of the same.</li>
                <li className="list-groups-item">We reserve the right to monitor all Communications and to remove any which we consider in our absolute discretion to be offensive, defamatory, or otherwise in breach of these Terms of Use. You agree that we have no obligation to monitor Postings by you or others, however.</li>
                <li className="list-groups-item">You will be responsible for all charges and obligations incurred, and liabilities for harm caused, and for all actions taken, through your use of this Website.</li>
                <li className="list-groups-item">You agree to indemnify and hold us harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from use of this Website, including, but not limited to, any violation of this agreement, or the failure to fulfill any obligations relating to use of the Website.</li>
                <li className="list-groups-item">You warrant that: you will not use any electronic communication feature of the Website for any purpose that is abusive, tortious, intrusive on another's privacy, harassing, libelous, defamatory, obscene, threatening, hateful, or otherwise unlawful; you will not post any materials that infringe on rights of third parties; and, you will not upload or transmit any virus or other malware.</li>
                <li className="list-groups-item">The Website, any information provided from it and these Terms of Use are given and made in Wisconsin, U.S.A. This agreement is subject to Wisconsin law, without regard to its choice of law provisions, and suit, if any, must be brought exclusively within the courts of the State of Wisconsin, Dane County. You agree to submit to the jurisdiction of the courts located in the State of Wisconsin.</li>
                <li className="list-groups-item">THIS WEBSITE AND ALL SOFTWARE AND SYSTEMS USED OR PROVIDED AS PART OF THIS WEBSITE ARE PROVIDED "AS IS" AND "AS AVAILABLE." WE HEREBY SPECIFICALLY DISCLAIM ANY AND ALL REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE WEBSITE CONTENT, FUNCTIONALITY, OR MATERIALS PROVIDED BY US HEREUNDER, INCLUDING, WITHOUT LIMITATION OF THE FOREGOING, ANY WARRANTY REGARDING OWNERSHIP, ACCURACY, TIMELINESS, COMPLETENESS, AND AVAILABILITY, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THOSE ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE.</li>
                <li className="list-groups-item">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, WE DISCLAIM LIABILITY, AND YOU AGREE THAT WE ARE NOT LIABLE, FOR ANY LOSSES AND EXPENSES OF WHATEVER NATURE AND HOWSOEVER ARISING OUT OF YOUR USE OF THE WEBSITE OR ANY WEBSITE WITH WHICH IT IS LINKED, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSS OF USE, LOSS OF DATA, LOSS CAUSED BY A VIRUS, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES OF ANY KIND OR CHARACTER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF US, OUR LICENSORS, EMPLOYEES OR AGENTS TO YOU, OR ANY OTHER PARTY EXCEED, IN THE AGGREGATE, THE AVERAGE MONTHLY CHARGES ACTUALLY PAID BY YOU TO US OVER THE PRECEDING THREE (3) MONTHS. YOUR CLAIM FOR SUCH DAMAGES IS OUR SOLE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN THE EVENT OF THE BREACH BY US OF THIS AGREEMENT. THE FORGOING SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY YOU MIGHT HAVE. No action, regardless of form, arising out of the transactions under this agreement may be brought by you more than twelve (12) months after the cause of action first arose.</li>
                <li className="list-groups-item">You and we agree, pursuant to the E-Sign Act ('the Electronic Signatures in Global and National Commerce Act', as it may be amended from time to time), to treat electronic tokens of assent from you and from us, as binding expressions of assent by you and by us to contracts.</li>
                <li className="list-groups-item">We may revise the Terms of Use from time to time by posting changes to this Website. Any such revisions shall be effective when posted and made available for your review. Your continued use of the Website following such changes is your expression of assent to the modified terms.</li>
                <li className="list-groups-item">These Terms of Use and ancillary agreements you have signed in connection with the use of the Services provided through this Website contain the entire understanding between us with respect to any use of the Website. Should any part of the Terms of Use be declared invalid or unenforceable by a court of competent jurisdiction, this shall not affect the validity of any remaining portion and such remaining portion shall remain and such remaining portion shall remain in full force and effect as if the invalid portion of the Terms of Use had been eliminated.</li>
                <li className="list-groups-item">Unless otherwise specifically noted, images, trademarks, service marks, logos, and icons displayed on this Website are the property of Algence and may not be used without our prior written consent. We and our licensors retain all rights with respect to any of our respective trademarks, service marks, and logos appearing on this Website. Any unauthorized use of the images may violate copyright, trademark, privacy and publicity laws and regulations.</li>
            </ol>
           </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
