import React,{ useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import {UserContext} from '../contexts/UserContext';

const Footer = (props) => {
    const getCurrentYear = () => new Date().getFullYear();
    const { onboardingPlaybookDetail } = useContext(UserContext);
    const [socialLinks, setSocialLinks] = useState('');
    const [isArticlePage, setIsArticlePage] = useState(false);
    const location = useLocation();
    const curntUrl = location.pathname.split('/');
    const fetchData = async (id) => {
      const response = await onboardingPlaybookDetail(id,1);
      if(response){
         if(response.token_message){
            console.error(response.token_message);
         }else{
            if(response.data.social_media_links_on_footer != null){
               var links = JSON.parse(response.data.social_media_links_on_footer);
               setSocialLinks(links);
            }
         }
      }
      else {
         console.error(response.message);
      }
   }
  useEffect(() => {
    if(curntUrl[2] == 'article'){

      if(location.state != null){
        var playbookId = location.state.playbook_id;
      }else{
        var playbookId = curntUrl[3];
      }
      setIsArticlePage(true);
      
      fetchData(playbookId);
    }
    
  }, []);
  return (
    <div id="footer">
      <div className="container text-center">
        <div className="row d-sm-flex d-flex justify-content-xl-between justify-content-lg-between align-items-center">
          <div className="col-xl-5 col-lg-5 col-md-6">
            <p>
              &copy; {getCurrentYear()}{' '}
              <a href="/about-us" rel="nofollow">
                About Algence
              </a> - <a href ="/terms"> Terms</a> - <a href="/privacy-policy"> Privacy Policy</a>
            </p>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6">
            <div className="social">
              {(!isArticlePage) && (
              <ul>
                <li>
                  <a href={props.data ? props.data.facebook : '/'} target='_blank'>
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.twitter : '/'} target='_blank'>
                    <i className="fa fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                <li className="d-none">
                  <a href={props.data ? props.data.youtube : '/'} target='_blank'>
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href={props.data ? props.data.linkedin : '/'} target='_blank'>
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href={"/contact"}>
                    <i className="fa fa-comments"></i>
                  </a>
                </li>
                
              </ul>
              )}
              {(isArticlePage) && (socialLinks != '') && (
              <ul>
                {(socialLinks.facebook.link != '') && (
                <li>
                  <a href={socialLinks.facebook.link ? socialLinks.facebook.link : '/'} target='_blank'>
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>)}
                {(socialLinks.twitter.link != '') && (
                <li>
                  <a href={socialLinks.twitter.link ? socialLinks.twitter.link : '/'} target='_blank'>
                    <i className="fa fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                )}
                {(socialLinks.youtube.link != '') && (
                <li>
                  <a href={socialLinks.youtube.link ? socialLinks.youtube.link : '/'} target='_blank'>
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                )}
                {(socialLinks.instagram.link != '') && (
                <li>
                  <a href={socialLinks.instagram.link ? socialLinks.instagram.link : '/'} target='_blank'>
                    <i className="fa fa-brands fa-instagram"></i>
                  </a>
                </li>
                )}
                {(socialLinks.gmail.link != '') && (
                <li>
                  <a href={socialLinks.gmail.link ? socialLinks.gmail.link : '/'} target='_blank'>
                    <i className="fa fa-brands fa-google-plus-g"></i>
                  </a>
                </li>
                )}
                
              </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;