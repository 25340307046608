import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {UserContext} from '../contexts/UserContext';
import "react-pro-sidebar/dist/css/styles.css";

const StudyGroupAcceptRequest = () => {
  const navigate = useNavigate();
  const { invite_token } = useParams();
  const [loading, setLoading] = useState(true);
  const { getStudyGroupByToken, updateStudyGroupByToken, logout } = useContext(UserContext);
  
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');


  
  const submitInviteResult = async (status) => {
    const data = await updateStudyGroupByToken({invite_token:invite_token, status:status});
    if(data.status){
      setErrMsg(false);
      setSuccessMsg(data.message);

      setTimeout(
        () => {
          if(status === 1) {
            navigate(`/createstdgrp/chooseroadmaps/${data.group_id}`);
          } else if(status === 2) {
            navigate(-1)
          }
        }
        , 2000);

      
    }
    else if(!data.status && data.message){
      setErrMsg(data.message);
    }
  };


  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    
  const fetchData = async () => {
    setLoading(true);
    const response = await getStudyGroupByToken(invite_token);
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        if(response.status) {
          if(response.data.status === "0") {
            setLoading(false);
          } else {
            setErrMsg("Link is expired!");
          }
        } else {
          setErrMsg(response.message);
        }
      }
    }
    else {
      console.error(response);
    }
  };
  
    fetchData();
  }, []);

  return (
    <aside className="d-sm-none py-10 d-md-block border-end process-sidebar h-100">
      <div className="relative mr-2">
      {!loading && (
          <>
        <div className="container text-center">
          <h2 className="mb-1 text-2xl font-bold">Join Team</h2>
          <p className="mb-3 text-base leading-6 text-gray-600">You have been invited to join the team.</p>
          <div className="mx-auto w-full max-w-md">
            <div className="flex w-full items-center gap-2">
              <button type="button" className="btn btn-success" onClick={() => submitInviteResult(1)}>Accept</button>
              <button type="button" className="btn btn-danger" onClick={() => submitInviteResult(2)}>Reject</button>
            </div>

            <hr/>
          <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>Back to Teams</button>
          </div>
        </div>
        </>
      )}

      {successMsg && <h4 className="err-msg text-success text-center">{successMsg}</h4>}
      {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
      </div>
    </aside>
  );
};

export default StudyGroupAcceptRequest;