import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Row, Form, Button , Col, Modal, Container } from 'react-bootstrap';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
const TeamBoard = () => {

  const {logout, createUpdateTeamBoard, getTeamBoards, getBoards, getTeamDetails, updateBoardStatus, getTeamBoardDetails, deleteTeamBoard, deleteTeamBoardAttachment, showToast} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  // const [boards, setBoards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [saveEditLoadingBtn, setSaveEditLoadingBtn] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [singleBoard, setSingleBoard] = useState(null);
  const [isMasterAdmin, setMasterAdmin] = useState(0);
  const [boardId, setBoardId] = useState(0);
  const [modalBackgroundImage, setModalBackgroundImage] = useState({
    backgroundImage: null
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedFiles, setSelectedFiles] = useState([]);
   const [selectedEditFiles] = useState([]);
  const [mainImages, setMainImages] = useState({
    current : []
  });
  const [mainEditImages, setMainEditImages] = useState({
    current : []
  });
  const [dt, setDt] = useState(new DataTransfer());
  const inputFile = useRef(null);
  const [imageFileTypes] = useState(["image/png","image/jpg","image/jpeg"])
  const [formData, setFormData] = useState({
    team_id: id,
    title:'',
    description:''
  });

  const predefineBoards = [
    {
      id: "0",
      name: "To Do", 
      items: []
    },
    {
      id: "1",
      name: "In Progress",
      items: []
    },
    {
      id: "2",
      name: "Done",
      items: []
    },
  ]

  const [stores, setStores] = useState(predefineBoards);

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "align", "size",
  ];

  const handleProcedureContentChange = (content) => {
    setFormData({
      ...formData,
      ['description']:content
    });
  };

  const fetchData = async () => {

    setLoading(true);

    
    const team_response = await getTeamDetails(id, (user === null ? "" : user.id));
    if(team_response){
      if(team_response.token_message){
        console.error(team_response.token_message);
        logout();
      }else{
        setMasterAdmin(team_response.is_master_admin);
        setLoading(false);
      }
    }
    else {
        showToast(team_response.message, true);
    }
    setLoading(true);

    const response = await getBoards(id,'ORGANIZATION');
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        let temp = predefineBoards;
        temp[0].items = response.data.todo;
        temp[1].items = response.data.inprogress;
        temp[2].items = response.data.done;
        setStores(temp);
        setLoading(false);
      }
    }
    else {
        showToast(response.message, true);
    }
    
    setLoading(true);

    const response1 = await getTeamBoards(id,(user === null ? "" : user.id),'ORGANIZATION');
    if(response1){
      if(response1.token_message){
        console.error(response1.token_message);
        logout();
      }else{
        let temp = predefineBoards;
        temp[0].items = response1.data.todo;
        temp[1].items = response1.data.inprogress;
        temp[2].items = response1.data.done;
        setStores(temp);
        setLoading(false);
      }
    }
    else {
      console.error(response1.message);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

	const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    });
  };

  const onChangeInputFile = (e) => {
    const files = Object.values(e.currentTarget.files);
    const readAndPreview = (file) => {
      var reader = new FileReader();

      reader.onloadend = () => {
        var imgObj = {};
        imgObj.src = reader.result
        imgObj.alt = file.name
        imgObj.type = file.type
        selectedFiles.push(imgObj)
        setMainImages({
          ...mainImages,
          current : selectedFiles
        })
        
      }
      reader.readAsDataURL(file);
    }

    if (files) {
      files.forEach((f, i) => {
        readAndPreview(f);
        dt.items.add(files[i]);
      });
    }
      
  };
  
  const removeFile = (e) =>{
    e.preventDefault();
    var file = e.currentTarget.getAttribute('data-file');
    for(let i = 0; i < dt.items.length; i++){
        if(file === dt.items[i].getAsFile().name){
            dt.items.remove(i);
            continue;
        }
    }
    var id = e.currentTarget.getAttribute('data-id');
    selectedFiles.splice(id, 1);
    setMainImages({
          ...mainImages,
          current : selectedFiles
    });
    inputFile.current.files = dt.files;

  };

  const removeEditFile = async (e) =>{
    e.preventDefault();
    var board_id = e.currentTarget.getAttribute('data-board_id');
    var file = e.currentTarget.getAttribute('data-file');
    for(let i = 0; i < dt.items.length; i++){
        if(file === dt.items[i].getAsFile().name){
            dt.items.remove(i);
            continue;
        }
    }
    var id = e.currentTarget.getAttribute('data-id');
    selectedFiles.splice(id, 1);
    setMainImages({
          ...mainImages,
          current : selectedFiles
    });
    inputFile.current.files = dt.files;

    let data = await deleteTeamBoardAttachment(id);
    if(data.status){
        selectedEditFiles.splice(id, 1);
          setMainEditImages({
                ...mainEditImages,
                current : selectedEditFiles
        });
        
      handleEditAction(board_id);
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
    
    

  };

  const closeCreateModal = () => {
		setCreateModalShow(false);
		setFormData({
      team_id: id,
      title:'',
      description:''
    });
    setMainImages({
      current : []
    });
    setSelectedFiles([]);
    if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
    }
	}

  const closeEditModal = () => {
    setEditModalShow(false);
    setFormData({
      team_id: id,
      title:'',
      description:''
    });
    setMainImages({
      current : []
    });
    setMainEditImages({
      current : []
    });
    setSelectedFiles([]);
    if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
    }
  }

  const saveModal = async (e) => {
    e.preventDefault();
    if(formData['title'] === ''  || formData['description'] === '') {
      showToast('All fields are required', true); 
      return false;
    }

    setSaveEditLoadingBtn(true);

    formData['team_type'] = 'ORGANIZATION';
    formData['files'] = mainImages;
    let data = await createUpdateTeamBoard(formData);
    if(data.status){
        setFormData({
          team_id: id,
          title:'',
          description:''
        });
        
        setDt(new DataTransfer());
        setCreateModalShow(false);
        setSaveEditLoadingBtn(false);

        setTimeout(() => {
          setMainImages({
              current : []
          });
          setSelectedFiles([]);
          if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
          }
          fetchData();
        },1000);
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
	}

  const saveEditModal = async (board_id) => {
   
    if(formData['title'] === ''  || formData['description'] === '') {
      showToast('All fields are required', true); 
      return false;
    }
    setSaveEditLoadingBtn(true);
    formData['team_type'] = 'ORGANIZATION';
    formData['files'] = mainImages;
    formData['id'] = board_id;
    let data = await createUpdateTeamBoard(formData);
    if(data.status){
        setFormData({
          team_id: id,
          title:'',
          description:''
        });
        setMainImages({
          current : []
        });
        setDt(new DataTransfer());
        setEditModalShow(false);
        setSaveEditLoadingBtn(false);

        setTimeout(() => {
          fetchData();
        },1000);
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
  }

  
  const handleDelete = async (board_id) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
      let data = await deleteTeamBoard(board_id, 'ORGANIZATION');
      if(data.status){
        setModalShow(false);
        fetchData();
      }
      else if(!data.status && data.message){
        console.log(data.message);
      }
    }
	}

  const handleEdit = async (board_id) => {
    let data = await getTeamBoardDetails(board_id, 'ORGANIZATION');
    if(data.status){
      console.log(data.data)
      setSingleBoard(data.data);
      setModalBackgroundImage({
        backgroundImage: "url(" +data.data.header_image+ ")",
        backgroundSize: "cover"
      });
      setModalShow(true);
    }
    else if(!data.status && data.message){
      console.log(data.message);
    }
	}

  const handleEditAction = async (board_id) => {
    setBoardId(board_id);
    let data = await getTeamBoardDetails(board_id, 'ORGANIZATION');
      if(data.status){
        var record = data.data;
        setFormData({
          ...formData,
          description:record.description,
          team_id: record.team_id,
          title: record.title
        });
        let newatt = [];
        record.attachments.forEach((f, i) => {
          var imgObj = {};
          imgObj.id = f.id
          imgObj.src = f.src
          imgObj.alt = f.name
          imgObj.type = f.type
          imgObj.board_id = f.board_id
          newatt.push(imgObj)
        });
        console.log(newatt);
        setMainEditImages({
          current : newatt
        });
        setModalShow(false);
        setEditModalShow(true);
      }
      else if(!data.status && data.message){
        console.log(data.message);
      }
    
  }

	const closeModal = () => {
		setModalShow(false);
	}

  const handleDragAndDrop = (results) => {
    let tempUpdateData = [];
    tempUpdateData['id'] = results.draggableId;
    tempUpdateData['team_id'] = id;
    tempUpdateData['user_id'] = (user === null ? "" : user.id);
    tempUpdateData['status'] = results.destination.droppableId;
    tempUpdateData['team_type'] = 'ORGANIZATION';
    console.log(tempUpdateData);
    updateBoardStatus(tempUpdateData);

    const { source, destination, type } = results;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (type === "group") {
      const reorderedStores = [...stores];

      const storeSourceIndex = source.index;
      const storeDestinatonIndex = destination.index;

      const [removedStore] = reorderedStores.splice(storeSourceIndex, 1);
      reorderedStores.splice(storeDestinatonIndex, 0, removedStore);

      return setStores(reorderedStores);
    }
    const itemSourceIndex = source.index;
    const itemDestinationIndex = destination.index;

    const storeSourceIndex = stores.findIndex(
      (store) => store.id === source.droppableId
    );
    const storeDestinationIndex = stores.findIndex(
      (store) => store.id === destination.droppableId
    );

    const newSourceItems = [...stores[storeSourceIndex].items];
    const newDestinationItems =
      source.droppableId !== destination.droppableId
        ? [...stores[storeDestinationIndex].items]
        : newSourceItems;

    const [deletedItem] = newSourceItems.splice(itemSourceIndex, 1);
    newDestinationItems.splice(itemDestinationIndex, 0, deletedItem);

    const newStores = [...stores];

    newStores[storeSourceIndex] = {
      ...stores[storeSourceIndex],
      items: newSourceItems,
    };
    newStores[storeDestinationIndex] = {
      ...stores[storeDestinationIndex],
      items: newDestinationItems,
    };

    setStores(newStores);
  };

  const handleHrefRedirect = (href,id) => {
    navigate(href+id, {
        state: {
            tabIndex: 2
        },
    });
  };

  const StoreList = ({ name, items, id }, is_droppable = 1) => {
    return (
      (is_droppable === 1) ?
      <Col lg="4">
        <Droppable droppableId={id}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="list-wrapper">
                    <div className="list-header">
                      <div className="list-text">
                        <h2 className="list-name">{name}</h2>
                      </div>
                      <div className="list-header-bottom"></div>
                    </div>
                    <ul className="list-cards">
                      {items.map((item, index) => (
                      <Draggable draggableId={item.id} index={index} key={item.id}>
                        {(provided) => (
                          <li className="list-card" {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef} onClick={() => handleEdit(item.id)}>
                            <div className="list-card-box">
                              <div className="list-card-item">
                                <b><p className="card-name">{item.title}</p></b>
                                <div className="card-front-badges">
                                  {
                                    (item.description !== '' && item.description !== null) &&
                                    (
                                      <span className="badge-card badge-card-description">
                                          <span className="icon description-icon">
                                            <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10C21 9.44772 20.5523 9 20 9H4ZM3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H4C3.44772 15 3 14.5523 3 14ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H4Z" fill="currentColor"></path></svg>
                                          </span>
                                      </span>
                                    )
                                  }

                                  {
                                    (item.attachments.length > 0) &&
                                    (
                                      <span className="badge-card badge-card-description" style={{float:'right'}}>
                                          <span className="icon description-icon">
                                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                              <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path>
                                            </svg>
                                          </span>
                                        <span className="badge-card-text checklist-text">{item.attachments.length}</span>
                                      </span> 
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                  </ul>
                    {provided.placeholder}
                </div>
            </div>
          )}
        </Droppable>
      </Col>
      :
      <Col lg="4">
        <div className="list-wrapper">
            <div className="list-header">
              <div className="list-text">
                <h2 className="list-name">{name}</h2>
              </div>
              <div className="list-header-bottom"></div>
            </div>
            <ul className="list-cards">
              {items.map((item, index) => (
                  <li className="list-card" onClick={() => handleEdit(item.id)}>
                    <div className="list-card-box">
                      <div className="list-card-item">
                        <b><p className="card-name">{item.title}</p></b>
                        <div className="card-front-badges">
                          {
                            (item.description !== '' && item.description !== null) &&
                            (
                              <span className="badge-card badge-card-description">
                                  <span className="icon description-icon">
                                    <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10C21 9.44772 20.5523 9 20 9H4ZM3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H4C3.44772 15 3 14.5523 3 14ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H4Z" fill="currentColor"></path></svg>
                                  </span>
                              </span>
                            )
                          }

                          {
                            (item.attachments.length > 0) &&
                            (
                              <span className="badge-card badge-card-description" style={{float:'right'}}>
                                  <span className="icon description-icon">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                      <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path>
                                    </svg>
                                  </span>
                                <span className="badge-card-text checklist-text">{item.attachments.length}</span>
                              </span> 
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </li>
            ))}
          </ul>
        </div>
      </Col>
    );
  }

  

  return (
    <div>
     
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                    { (isMasterAdmin === 1) &&
                      (
                        <>
                        <div className="settings-card">
                        <div className="settings-card-header">Boards</div>
                        <div className="settings-card-body">
                          <Row>
                            <Col md={8} lg={9}>
                                <p>Progress for team members can be checked in the <a href="#" onClick={() => handleHrefRedirect("/organization/progress/" , id)}>Progress</a> menu option</p>
                            </Col>
                            <Col md={4} lg={3}>
                              <button className="btn btn-dark pull-right submit" type="buuton" onClick={() => { setCreateModalShow(true) }}>Create</button>
                            </Col>
                          </Row>
                        <br/>
                        <br/>
                        {(loading) ? 
                        <h6 className="text-center">Loading...</h6> :
                          <Row>
                            {stores.map((store, index) => (
                              StoreList(store, 0)
                            ))}
                          </Row>
                        }
                        </div>
                        </div>
                        </>
                      )
                    }   
                    {(isMasterAdmin === 0) &&
                      (
                        <>
                        <div className="settings-card">
                        <div className="settings-card-header">Onboard</div>
                        <div className="settings-card-body">
                        {(loading) ? 
                        <h6 className="text-center">Loading...</h6> :
                          <DragDropContext onDragEnd={handleDragAndDrop}>
                            <Droppable droppableId="ROOT" type="group">
                              {(provided) => (
                                <Row {...provided.droppableProps} ref={provided.innerRef}>
                                  {stores.map((store, index) => (
                                     StoreList(store, 1)
                                  ))}
                                </Row>
                              )}
                            </Droppable>
                          </DragDropContext>
                        }
                        </div>
                        </div>
                        </>
                      )
                    }
                    </div>
                  </div>
                </div>
              </Row>
            </main>
          </Container>

            
        <Modal show={createModalShow} centered size="lg">
            <Modal.Header>
              <Modal.Title>Create New Board</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={onChangeInput}
                    placeholder="Enter Title"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="delete-team" className="mb-3 sm-mb-3">
                  <Form.Label>Description</Form.Label> <br/>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="write your content ...."
                    onChange={handleProcedureContentChange}
                    style={{ height: "200px" }}
                  >
                  </ReactQuill>
                  {/* <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={formData.description}
                      placeholder="Enter Description"
                      onChange={onChangeInput}
                      required
                    /> */}
                </Form.Group>
                <br/><br/>
                <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Label>Attachments</Form.Label>
                  <label id="drop-area" forHtml="fileElem" className={`border rounded ${mainImages.current.length > 0 ? "d-block" : "d-flex"} justify-content-center w-100 align-items-center`} style={{height:"240px",cursor:"pointer",overflowX:"auto"}}>
                      <div id="image_preview" className="flex-row text-center">
                          {/* if file selected  */}
                          {mainImages.current.length > 0 ? (
                          mainImages.current.map((file, index) => (
                          <div className="img-bx" id={`addimage${index}`}>
                              {(imageFileTypes.includes(file.type) && (
                                <img key={index} src={file.src} alt={file.alt} />
                              ))}
                              {(!imageFileTypes.includes(file.type) && (
                                <img key={index} src="/img/file-icon.png" alt={file.alt} />
                              ))}
                              <span className="file-txt">{file.alt}</span>
                              <span className="btn btn-primary btn-flat remove_img" data-id={index}  data-file={file.alt} onClick={removeFile}><i className="fa fa-times"></i></span>
                          </div>
                          )) ) : (
                            <p className="mt-3">Drag and drop your image here or click to select a file.</p> ) }
                      </div>
                      <input type="file" id="fileElem" multiple name="fileElem" ref={inputFile} onChange={onChangeInputFile} className="d-none" />
                  </label>
                </Form.Group>
            </Modal.Body>
            

            <Modal.Footer className="justify-content-between">
              <div className="btn-left">
                <Button variant="outline-secondary" onClick={closeCreateModal}>Cancel</Button>
              </div>
              <div className="btn-right">
                <Button type="submit" variant="dark" onClick={saveModal} disabled={saveEditLoadingBtn}>{saveEditLoadingBtn === true ? 'Loading...' : 'Save'}</Button>
                {/* <Button variant="outline-secondary mx-2">Save as placeholder</Button>
                <Button variant="dark">Continue to Editor</Button> */}
              </div>
            </Modal.Footer>
        </Modal>

         <Modal show={editModalShow} centered size="lg">
            <Modal.Header>
              <Modal.Title>Edit Board</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={onChangeInput}
                    placeholder="Enter Title"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="delete-team" className="mb-3 sm-mb-3">
                  <Form.Label>Description</Form.Label> <br/>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="write your content ...."
                    onChange={handleProcedureContentChange}
                    style={{ height: "200px" }}
                    value={formData.description}
                  >
                  </ReactQuill>
                  {/* <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={formData.description}
                      placeholder="Enter Description"
                      onChange={onChangeInput}
                      required
                    /> */}
                </Form.Group>
                <br/><br/>
                <Form.Group controlId="delete-team" className="mb-3">
                  <Form.Label>Attachments</Form.Label>
                  <label id="drop-area" forHtml="fileElem" className={`border rounded ${mainEditImages.current.length > 0 ? "d-block" : "d-flex"} justify-content-center w-100 align-items-center`} style={{height:"240px",cursor:"pointer",overflowX:"auto"}}>
                      <div id="image_preview" className="flex-row text-center">
                          {/* if file selected  */}
                          {mainEditImages.current.length > 0 ? (
                          mainEditImages.current.map((file, index) => (
                          <div className="img-bx" id={`editimage${index}`}>
                              {(imageFileTypes.includes(file.type) && (
                                <img key={index} src={file.src} alt={file.alt} />
                              ))}
                              {(!imageFileTypes.includes(file.type) && (
                                <img key={index} src="/img/file-icon.png" alt={file.alt} />
                              ))}
                              <span className="file-txt">{file.alt}</span>
                              <span className="btn btn-primary btn-flat remove_img" data-board_id={file.board_id} data-id={file.id}  data-file={file.alt} onClick={removeEditFile}><i className="fa fa-times"></i></span>
                          </div>
                          )) ) : ''}
                      {/*</div>
                      <div id="image_preview" className="flex-row text-center">*/}
                          {/* if file selected  */}
                          {mainImages.current.length > 0 ? (
                          mainImages.current.map((file, index) => (
                          <div className="img-bx" id={`addimage${index}`}>
                              {(imageFileTypes.includes(file.type) && (
                                <img key={index} src={file.src} alt={file.alt} />
                              ))}
                              {(!imageFileTypes.includes(file.type) && (
                                <img key={index} src="/img/file-icon.png" alt={file.alt} />
                              ))}
                              <span className="file-txt">{file.alt}</span>
                              <span className="btn btn-primary btn-flat remove_img" data-id={index}  data-file={file.alt} onClick={removeFile}><i className="fa fa-times"></i></span>
                          </div>
                          )) ) : (
                            <p className="mt-3">Drag and drop your image here or click to select a file.</p> ) }
                      </div>
                      <input type="file" id="fileElem" multiple name="fileElem" ref={inputFile} onChange={onChangeInputFile} className="d-none" />
                  </label>
                </Form.Group>
            </Modal.Body>
            

            <Modal.Footer className="justify-content-between">
              <div className="btn-left">
                <Button variant="outline-secondary" onClick={closeEditModal}>Cancel</Button>
              </div>
              <div className="btn-right">
                <Button type="submit" variant="dark" onClick={() => saveEditModal(boardId)} disabled={saveEditLoadingBtn}>{saveEditLoadingBtn == true ? 'Loading...' : 'Save'}</Button>
                {/* <Button variant="outline-secondary mx-2">Save as placeholder</Button>
                <Button variant="dark">Continue to Editor</Button> */}
              </div>
            </Modal.Footer>
        </Modal>
          
          <Modal show={modalShow} size="lg" centered className="card-detail-modal">
          {
            
            (singleBoard != null) && (
              <>
              <Modal.Header className="card-cover" closeButton onClick={closeModal} style={modalBackgroundImage}
              >
              </Modal.Header>
              <Modal.Body>
                  <div className="card-detail-header">
                    <div className="d-flex align-items-center">
                      <span className="card-header-icon"><i className="fa solid fa-credit-card"></i></span>
                      <div className="modal-title">
                        <h2 className="card-detail-title">{singleBoard.title}</h2>
                      </div>
                    </div>
                    <div className="card-detail-inline-content">
                      {/* <p>in list </p> */}
                    </div>
                  </div>
                  <Row>
                    <Col lg="9">
                      <div className="card-detail-header">
                        <div className="d-flex align-items-center">
                          <span className="card-header-icon"><i className="fa solid fa-align-justify"></i></span>
                          <div className="modal-title">
                            <h4 className="card-detail-title card-detail-desc-title">Description</h4>
                          </div>
                        </div>
                        <div className="card-detail-inline-content">
                          <div dangerouslySetInnerHTML={ { __html: singleBoard.description}} />
                        </div>
                      </div>
                      {
                        (singleBoard.attachments.length > 0) && (
                          <div className="card-detail-header">
                            <div className="d-flex align-items-center">
                              <span className="card-header-icon"><i className="fa solid fa-paperclip"></i></span>
                              <div className="modal-title">
                                <h4 className="card-detail-title card-detail-desc-title">Attachments</h4>
                              </div>
                            </div>
                            <div className="card-detail-inline-content">
                                <div className="attachment-list">
                                  {
                                  singleBoard.attachments.map((row,index) => (
                                    <div className="attachment-thumbnail">
                                        <img src={row.src} className="attachment-thumbnail-preview"/>
                                        <p className="attachment-thumbnail-details">
                                            <span className="attachment-thumbnail-name">{row.name}</span>
                                            {/* <span className="attachment-thumbnail-details-title-options">Added <span className="date"></span></span> */}
                                        </p>
                                    </div>
                                  ))
                                  }
                                </div>
                            </div>
                          </div>
                        )
                      }
                    </Col>
                    {
                      (isMasterAdmin === 1) && (
                        <Col lg="3">
                        <div className="card-sidebar">
                            <div className="card-action-box">
                              <h4 className="action-title">Actions</h4>
                              <div className="action-button">
                                <button className="btn btn-outline-danger pull-right" type="button" onClick={() => handleDelete(singleBoard.id)}><i className="fa fa-trash"></i> Delete</button>
                                <button className="btn btn-outline-dark pull-right" onClick={() => handleEditAction(singleBoard.id)} style={{marginRight:'10px'}}><i className="fa fa-pencil"></i> Edit</button>
                              </div>
                            </div>
                        </div>
                      </Col>
                      )
                    }
                     
                  </Row>
                  
                
              </Modal.Body>
              
              </>
            )
          }
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TeamBoard;