
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {UserContext} from '../contexts/UserContext';
import { Container } from 'react-bootstrap';
import Moment from 'moment';
import * as myConstent from '../contexts/ConstContext';

export const OnboardingPlaybookPage = (props) => {
  const {onboardingPlaybookList, logout, showToast} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [onboardingPlaybook, setOnboardingPlaybook] = useState([]);

  const navigateToArtical = (id) => {
    navigate(`/onboarding-playbook/article/${id}`);
  }

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();    
  }, []);
  
  const fetchData = async () => {
    setLoading(true);
    const response = await onboardingPlaybookList();
    if(response){
    if(response.token_message){
        console.error(response.token_message);
        logout();
    }else{
        setOnboardingPlaybook(response.data);
        setLoading(false);
    }
    }
    else {
        showToast(response.message, true);
    }
  };
                        
  return (
      <div className="h-full padding100">
        <Container>
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-body">
                  <div className="page__content-wrapper">
                    <div className="flw-material-cards">
                      {
                        onboardingPlaybook.map((row,index) => (
                          <div className="flw-material-card">
                            <div className="card-preview">
                              <div className="flw-material-card__body">
                                <div className="flw-material-card__body-content">
                                  <div className="flw-material-card__body-heading">
                                    <a href="" className="flw-material-card__body-link" onClick={() => navigateToArtical(row.id)}>{row.name} </a>
                                  </div> 
                                  <div className="flw-material-card__body-text">{row.description}</div> 
                                  <div className="flw-material-card__body-footer flex-row-center"><span className="knb-date-info">{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
  );
};