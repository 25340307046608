import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
const ForgotPassword = () => {
  const {forgotPassword, wait, showToast} = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    site_url:window.location.origin+'/reset-password'
  });
  

  const submitForm = async (e) => {
    e.preventDefault();
    setRedirect('Loading...');
    const data = await forgotPassword(formData);
    // if(data.status){
    //     window.location.href = data.link;
    // }
    setRedirect(false);
    showToast(data.message, true);
};


  return (
    <div className="forgot-password-form padding100 h-full">
      <Container>
        <Row>
          <Col md={6}>
            <Image
              src="/img/about.jpg"
              alt="Login"
              fluid
              className="login-image"
            />
          </Col>
          <Col md={6}>
            <div className="login-form-container">
              <h2 className="text-center">Forgot Password</h2>
              <Form onSubmit={submitForm}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </Form.Group>

                <div className="col-xl-12 col-lg-12">
                  <div className="part-btn text-center">
                    {redirect ? redirect : <Button className="login-btn" type="submit" variant="primary" disabled={wait}>
                      Reset Password
                    </Button>}
                  </div>
                </div>
              </Form>

              <div className="signup-link">
                <p>
                  Don't have an account? <a href="/signup">Sign Up</a>
                </p>
                <p>
                  Already Have an Account? <a href="/login">Login</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default ForgotPassword;
