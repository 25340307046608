import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  Panel,
  Handle
} from 'reactflow';
import { Container, Row, Form } from 'react-bootstrap';
import 'reactflow/dist/style.css';
import Sidebar from './roadmapSidebar';
import { UserContext } from '../contexts/UserContext';
import CustomNode from './customNode';
// import DrawOutputNode from './DrawOutputNode';
// import DrawDefaultNode from './DrawDefaultNode';

let skillid = 0;
let questionid = 0;
let paragraphid = 0;
let buttonid = 0;
const getSkillId = () => `s_${skillid++}`;
const getQuestionId = () => `q_${questionid++}`;
const getParagraphId = () => `p_${paragraphid++}`;
const getButtonId = () => `b_${buttonid++}`;
const nodeTypes = {
    custom: CustomNode,
  };
 
const DrawRoadmapFlowchart = () => {
  const reactFlowWrapper = useRef(null);
  const {getSkills, logout, user, saveRoadmapSkillAndQuestions, showToast} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [roadmap, setRoadmap] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodeName, setNodeName] = useState('');
  const [nodeDesc, setNodeDesc] = useState('');
  const [roadmapType, setRoadmapType] = useState('');
  const [roadmapSubtype, setRoadmapSubtype] = useState('');
  const [deleteHoverClass, setDeleteHoverClass] = useState('');
  const [nodeId, setNodeId] = useState(1);
  const [isNodeSelected, setIsNodeSelected] = useState(false);
  const [skills, setSkills] = useNodesState([]);
  const location = useLocation();
  const [id] = useState(location.state.roadmapId);
  const [backpath] = useState(location.state.path);
  const [nodeHeight, setNodeHeight] = useState('100');
  const [nodeWidth, setNodeWidth] = useState('100');
  const [nodeLink, setNodeLink] = useState('#');
  
  
  const navigate = useNavigate();
  const [paragraphNodeStyle, setparagraphNodeStyle] = useState({
    background: 'rgb(195, 195, 255)',
    border:'#000000',
    color:'#000000'
  });
  const [nodePosition, setNodePosition] = useState({
    x: 250,
    y:5
  });
  const [nodeType, setNodeType] = useState('main_node');
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [],
  );
  const defaultViewport = { x: 0, y: 0, zoom: 12 };
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      setIsNodeSelected(true);
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: 400,
        y: 500,
      });
      setNodeType(type);
      let node_id = (type === 'Skill' ? getSkillId() : type === 'Paragraph' ? getParagraphId() : type === 'Button' ?  getButtonId() :  getQuestionId());
      const newNode = {
        id: node_id,
        type: (type === 'Skill' )  ? 'defalt' : type === 'Button' || type === 'Paragraph' ? 'custom' : 'output',
        position,
        data: { label: addLabelAndRemoveButton(node_id,`${type} node`,type,node_id) ,original_label:`${type} node`, description: '', link:'' },
        element: type+'@NEW',
        style: {
          background: type === 'Skill' ? "rgb(107, 207, 238)" : type === 'Paragraph' ? "rgb(195, 195, 255)" : type === 'Button' ? "rgb(42, 121, 228)" : "rgb(3, 64, 128)",
          color: (type === 'Skill' || type === 'Paragraph') ? "#000" :  "#fff",
          border: type === 'Paragraph' ? "1px solid transparent" : type === 'Button' ? "1px solid rgb(42, 121, 228)" : "1px solid #222138",
          width: type === 'Button' ? 160 : type === 'Paragraph' ? '100%' : 180,
          height: type === 'Button' ? 'initial' : type === 'Paragraph' ? 100 : 180,
          padding: type === 'Button' ? 15 : 'initial'
        }
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );

  const handleNodeClick = (e, node) => {
    setNodeName(node.data.original_label);
    setNodeDesc(node.data.description);
    setNodeHeight(node.height);
    setNodeLink(node.data.link);
    setNodeWidth(node.width);
    setNodeId(node.id);
    setNodePosition({
      x:node.position.x,
      y:node.position.y
    });
    if(node.id === "main_node") {
      setRoadmapType(node.data.roadmap_type);
      setRoadmapSubtype(node.data.roadmap_subtype);
    }

    var node_id = node.id;
    let isquestion = node_id.includes("skill_question");
    let isnewquestion = node_id.includes("q_");
    let isnewparagraph = node_id.includes("p_");
    let isnewbutton = node_id.includes("b_");
    if(isquestion || isnewquestion || isnewparagraph || isnewbutton){
      setNodeDesc('#DESCRIPTION#');
    }
    if(!isNodeSelected){
      setIsNodeSelected(true);
    }
    
  };

  const onSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    let tnodes = nodes.map((node) => {
      node.data = {
        ...node.data,
        label: ''
      }
      return node;
    });

    let data = await saveRoadmapSkillAndQuestions({roadmap_id:id,node_description:nodeDesc,nodes:(JSON.stringify(tnodes)),edges:(JSON.stringify(edges))});
    if(data.status){
      setIsNodeSelected(false);
      fetchData();
    }
    else if(!data.status && data.message){
      setLoading(false);
      showToast(data.message, true);
    }
  };

  const handlePaneClick = (e) =>{
    setIsNodeSelected(false);
  };

  const remNodeElement = useCallback((id, type, node_id) => {
    setNodes((nds) =>
        nds.filter(newVal => (newVal.id !== node_id)).map((node) => { 
          return node;
        }
    ));
    setIsNodeSelected(false);
  });

  const handleMouseOver = (classname) => {
    setDeleteHoverClass(classname);
  }

  const handleMouseOut = () => {
    setDeleteHoverClass('');
  }

  const backToPage = () => {
    if(backpath === '') {
      navigate(-1);
    } else {
      navigate(backpath);
    }
  }

  const addLabelAndRemoveButton = (id,name, type, node_id, link = "#") => {
    // return (link != null && link != "" && link != "#") ? <a href={link}>{addLabelAndRemoveButtonHtml(id,name, type, node_id) }</a> : 
    return addLabelAndRemoveButtonHtml(id,name, type, node_id);
  }

  const addLabelAndRemoveButtonHtml = (id,name, type, node_id) => {
    return (
    <>
    <div className="flow-node" onMouseOver={() => handleMouseOver('flow-node-'+id)} onMouseOut={() => handleMouseOut()}>
      {(type !== 'MAIN') &&(<button type='button' className="rounded-2 border-black delete-btn" style={{display:('flow-node-'+id === deleteHoverClass) ?  "block;" : "none;"}} onClick={() => remNodeElement(id, type, node_id)}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash"><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path></svg></button>)}

      {(type === 'PARAGRAPH') && (<>{name}</>)}
      {(type === 'Button') && (<>{name}</>)}
      {(type !== 'PARAGRAPH') && (type !== 'Button') && (<>{name} </>)}
    </div>
    </>
    )
  }

  const xpostion = [500,300];
  // Function to fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    const response = await getSkills(id);
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else if(response.data){
            var result = response.data;
            var roadmap = response.roadmap;
            setRoadmap(roadmap);
            setSkills(result);
            let temp = [
              {
                id: 'main_node',
                type: 'input',
                className: 'main_node',
                data: { original_label:roadmap.roadmap_name, label: addLabelAndRemoveButton(roadmap.roadmap_id,roadmap.roadmap_name,'MAIN', 'main_node'), description: roadmap.roadmap_description, 
                roadmap_type:roadmap.roadmap_type,
                roadmap_subtype:roadmap.roadmap_subtype },
                position: (roadmap.node_json.position !== '') ? roadmap.node_json.position : { x: 250, y: 5 },
                element: 'MAIN',
                style: (roadmap.node_json.style !== '') ? roadmap.node_json.style : {
                  background: "unset",
                  color: "#000",
                  border: "1px solid #222138",
                  fontSize:"15px",
                  fontWeight:"bold",
                  width: 100
                },
                width: (roadmap.node_json.width !== '') ? roadmap.node_json.width : nodeWidth,
                height: (roadmap.node_json.height !== '') ? roadmap.node_json.height : nodeHeight,
              }
            ];

            let tempEdges = [];

            let x = 0;
            let y = 100;
            let count = 1;
            let leftright = 1;
            let last_skill = null;
            let question_y = 0;
            let question_ay = 0;
            let question_ay_start_with = 0;
            let question_by = 0;
            result.forEach(element => {
              x = xpostion[count];
              if(element.node_type !== undefined && element.node_type === 'paragraph') {
                element.node_type = 'Paragraph';
                element.skill_id = 'p_'+element.id;
                element.skill_definition = '#DESCRIPTION#';
                element.skill_name = element.text;
              } else if(element.node_type !== undefined && element.node_type === 'button') {
                element.node_type = 'Button';
                element.skill_id = 'b_'+element.id;
                element.skill_definition = '#DESCRIPTION#';
                element.skill_name = element.text;
              } else {
                element.node_type = 'Skill';
                element.node_link = '#';
              }

              let lwr_node_type = (element.node_type).toLowerCase();

              temp.push({
                id: lwr_node_type+'-'+element.skill_id,
                type: (element.node_type === 'Skill') ? 'default' :'custom',
                className: 'single_'+lwr_node_type+' '+lwr_node_type+'_nodes hide '+lwr_node_type+'-'+element.skill_id,
                data: { original_label:element.skill_name, label: addLabelAndRemoveButton(element.skill_id,element.skill_name,lwr_node_type.toUpperCase(),lwr_node_type+'-'+element.skill_id,element.node_link), description: element.skill_definition, link:element.node_link },
                position: (element.node_json !== null && element.node_json.position !== '') ? element.node_json.position : { x: x, y: y },
                element: element.node_type+'@'+element.skill_id,
                style: (element.node_json !== null && element.node_json.style !== '') ? element.node_json.style : {
                background: "rgb(107, 207, 238)",
                color: "#000",
                border: "2px solid #000",
                width: 100}
              });

              let source_id = (last_skill === null) ? 'main_node' : last_skill;
              if(element.node_type !== undefined && element.node_type === 'Skill') {
                tempEdges.push({
                  id: 'id-'+source_id+'-'+element.skill_id, className: lwr_node_type+'_edges edges-single-'+lwr_node_type+'-'+element.skill_id+'_', source: source_id, target: lwr_node_type+'-'+element.skill_id,
                  animated: false,
                  style: {strokeWidth: 3}
                });
              }

              
              y += 100;
              
              count++;
              if(count >= 2) {
                count = 0;
              }

              if(leftright === 1) {
                question_ay_start_with = question_y;
              }


                question_y = (leftright === 0) ? question_ay_start_with + 50 : ((question_ay > question_by) ? question_ay : question_by) + 50;
                let question_x = (leftright === 1) ? x - x : x + x;
                element.questionDetails.forEach(question_element => {
                  temp.push({
                    id: 'skill_question-'+question_element.skills_question_id+'-'+element.skill_id,
                    type: 'output',
                    className: 'question_skill_nodes_skill-'+element.skill_id,
                    data: {  original_label:question_element.skill_question, label: addLabelAndRemoveButton(question_element.skills_question_id,question_element.skill_question,'QUESTION', 'skill_question-'+question_element.skills_question_id+'-'+element.skill_id),skill_id: element.skill_id },
                    position: (question_element.node_json !== null && question_element.node_json.position !== '') ? question_element.node_json.position : { x: question_x, y: question_y },
                    element: 'Question@'+question_element.skills_question_id,
                    style: (question_element.node_json !== null && question_element.node_json.style !== '') ? question_element.node_json.style :  {display:'block',
                    background: "rgb(3, 64, 128)",
                    color: "#fff",
                    fontSize:"10px",
                    border: "2px solid #fff",
                    width: 100}
                  });
                  tempEdges.push({
                    id: 'id-que-'+element.skill_id+'-'+question_element.skills_question_id, className: 'skill_question_edges que_edge_skill-'+element.skill_id, source: 'skill-'+element.skill_id, target: 'skill_question-'+question_element.skills_question_id+'-'+element.skill_id,
                    animated: true,
                    style: {display:'block'}
                  });

                  question_y += 60;
                });

                if(leftright === 1) {
                  question_ay = question_y;
                } else {
                  question_by = question_y;
                }
 
                y += element.questionDetails.length * 10;
                last_skill = lwr_node_type+'-'+element.skill_id;
                leftright = (leftright === 1) ? 0 : 1;
            });

            setNodes(temp);
            setEdges(tempEdges);


            console.log(temp);
            setLoading(false);
        }
  };
  const onChangeNodeStyle = (e) => {
      setparagraphNodeStyle({
        ...paragraphNodeStyle,
        [e.target.name]:e.target.value
      });
  };
  const onChangeNodePosition = (e) => {
      setNodePosition({
        ...nodePosition,
        [e.target.name]:parseFloat(e.target.value)
      });
  };
  
  // const nodeTypes = { output: DrawOutputNode,default: DrawDefaultNode };
  
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          node.style = {
            ...node.style,
            width:parseFloat(nodeWidth),
            height:parseFloat(nodeHeight)
          };
          node.position = {
            ...node.position,
            x: nodePosition.x,
            y: nodePosition.y
          };
          let isquestion = nodeId.includes("skill_question-");
          let isquestionNew = nodeId.includes("q_");
          let isskill = nodeId.includes("skill-");
          let isskillNew = nodeId.includes("s_");
          let isparagraph = nodeId.includes("paragraph-");
          let isparagraphNew = nodeId.includes("p_");
          let isbuttonNew = nodeId.includes("b_");
          var label = null;
          if(nodeId === "main_node") {
            setNodeType('main_node');
            label = addLabelAndRemoveButton(nodeId,nodeName,'MAIN', 'main_node');
          } else if(isquestion || isquestionNew) {
            setNodeType('Question');
            label = addLabelAndRemoveButton(nodeId,nodeName,'QUESTION', nodeId);
          } else if(isskill || isskillNew) {
            setNodeType('Skill');
            label = addLabelAndRemoveButton(nodeId,nodeName,'SKILL',nodeId);
          } else if(isparagraph || isparagraphNew) {
            setNodeType('Paragraph');
            label = addLabelAndRemoveButton(nodeId,nodeName,'PARAGRAPH',nodeId);
             node.style = {
              ...node.style,
              background:paragraphNodeStyle.background,
              border:'1px solid '+paragraphNodeStyle.border,
              color:paragraphNodeStyle.color
            };
          } else if(isbuttonNew) {
            setNodeType('Button');
            label = addLabelAndRemoveButton(nodeId,nodeName,'Button',nodeId,nodeLink);
            node.data = {
              ...node.data,
              link: nodeLink,
            };
          }

          node.data = {
            ...node.data,
            label: label
          }

          node.data = {
            ...node.data,
            original_label: nodeName,
          };
          node.data = {
            ...node.data,
            description: nodeDesc,
          };

          if(nodeId === "main_node") {
            node.data = {
              ...node.data,
              roadmap_type: roadmapType,
            };
            
            node.data = {
              ...node.data,
              roadmap_subtype: roadmapSubtype,
            };
          }
        }
        return node;
      })
    );
  }, [nodeName, nodeDesc, nodePosition, nodeHeight, nodeWidth, roadmapType, roadmapSubtype, setNodes, paragraphNodeStyle, nodeLink]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row h-full dndflow">
      <div className="col-md-12">
        <div className="flowchart-topbar">
          <a href="javascript:void(0)" onClick={() => backToPage()} className="d-flex align-items-center border-end px-4 py-3 pl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="position-relative left-[-2px] h-4 w-4"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
          </a>
          <span className="ms-4 inline-grid flex-grow-1 flex-column py-2 fw-normal">
            <span className="d-flex align-items-center">
              <span className="truncate">{roadmap.roadmap_name}</span>
              {/*<button className="ms-2 border-0 bg-transparent text-gray-500 hover:text-gray-900">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path></svg>
              </button>*/}
            </span>
            <span className="truncate text-sm font-normal text-gray-500">{roadmap.roadmap_description}</span>
          </span>
          <div className="d-flex min-w-0 d-flex-shrink-0">
            <button className="d-flex align-items-center justify-content-center bg-dark px-3 text-white" onClick={(e) => onSave(e)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-4 w-4"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
              Save Roadmap
            </button>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-2">
        <Sidebar />
      </div>

      <div className="col-xs-12 col-md-10">
        <Container fluid>
              {!loading && (nodes.length > 0) && (
              <>
              <Row>
              <ReactFlowProvider>
                <div className={`reactflow-wrapper ${isNodeSelected ? 'col-md-9' : 'col-md-12'} padding80 h-full`} ref={reactFlowWrapper} style={{height:'500px'}}>
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    nodeTypes={nodeTypes}
                    onNodeClick={(e, node) => handleNodeClick(e, node)}
                    onPaneClick = {handlePaneClick}
                    panOnScroll
                    selectionOnDrag={true}
                  >
                    <Controls 
                     position="top-left"
                    />
                    
                    <Background color="#ccc" variant="dots" />
                    <MiniMap nodeStrokeWidth={3} />

                    {/* <Panel position="top-right">
                      <div className="button-section">
                        <button type='button' className='button' onClick={(e) => onSave(e)} >save</button>
                      </div>
                    </Panel> */}
                  </ReactFlow>
                </div>
                
                <aside className={`updatenode__controls react-flow__sidebar node-sidebar  ${isNodeSelected ? 'd-flex w-25' : 'd-none'} shrink-0 flex-column align-items-stretch border-start border-gray-200 bg-white h-full`}>
                    <div className="d-flex flex-column">
                      <label className="text-xs uppercase text-gray-400">label</label>
                      <input className="mt-2 d-flex h-8 align-items-center rounded border px-2 py-3 " value={nodeName} onChange={(evt) => setNodeName(evt.target.value)}/>
                    </div>
                    {(nodeDesc !== '#DESCRIPTION#') && (
                    <div className="d-flex mt-2 flex-column">
                      <label className="text-xs uppercase text-gray-400">Description</label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="roadmap_description"
                        value={nodeDesc}
                        placeholder="Enter Description"
                        className="mt-2 d-flex align-items-center rounded border"
                        onChange={(evt) => setNodeDesc(evt.target.value)}
                      />
                    </div>
                    )}


                    {(nodeId === 'main_node') && (
                      <>
                      <div className="d-flex mt-2 flex-column">
                        <label className="text-xs uppercase text-gray-400">Roadmap Type</label>
                        <Form.Select name="roadmap_type" className="roadmap_type" onChange={(evt) => setRoadmapType(evt.target.value)}>
                            <option value="" selected={((roadmapType === "") ? 'selected' : '')}>Select</option>
                            <option value="Non Technical" selected={((roadmapType === "Non Technical") ? 'selected' : '')}>Non Technical</option>
                            <option value="Technical" selected={((roadmapType === "Technical") ? 'selected' : '')}>Technical</option>
                            <option value="Custom" selected={((roadmapType === "Custom") ? 'selected' : '')}>Custom</option>
                        </Form.Select>
                      </div>
                      
                      <div className="d-flex mt-2 flex-column">
                        <label className="text-xs uppercase text-gray-400">Roadmap Sub Type</label>
                        <Form.Select name="roadmap_subtype" className="roadmap_subtype" onChange={(evt) => setRoadmapSubtype(evt.target.value)}>
                          
                        <option value="" selected={((roadmapSubtype === "") ? 'selected' : '')}>Select</option>
                        {
                          (roadmapType === "Technical") &&
                          <>
                          <option value="Role Based" selected={((roadmapSubtype === "Role Based") ? 'selected' : '')}>Role Based</option>
                          <option value="Skill Based" selected={((roadmapSubtype === "Skill Based") ? 'selected' : '')}>Skill Based</option>
                          </>
                        }

                        {
                          (roadmapType === "Non Technical") &&
                          <>
                          <option value="Role Based" selected={((roadmapSubtype === "Role Based") ? 'selected' : '')}>Role Based</option>
                          <option value="Soft Skills" selected={((roadmapSubtype === "Soft Skills") ? 'selected' : '')}>Soft Skills</option>
                          </>
                        }

                        {
                          (roadmapType === "Custom") &&
                          <>
                          <option value="Custom" selected={((roadmapSubtype === "Custom") ? 'selected' : '')}>Custom</option>
                          </>
                        }
                        </Form.Select>
                      </div>
                      </>
                    )}

                    <div className="node-control-box">
                      <div className="mt-4 row">
                        <div className="col-md-6 pb-3">
                          <label htmlFor="x"></label>
                          <div className="d-flex align-items-center">
                            <span className="d-flex me-1 align-items-center justify-content-center text-xs uppercase text-gray-400">X</span>
                            <input type="number" name="x" id="x" className="d-flex  align-items-center p-1 text-sm" value={nodePosition.x} onChange={onChangeNodePosition} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="y"></label>
                          <div className="d-flex align-items-center">
                            <span className="d-flex me-1 align-items-center justify-content-center text-xs uppercase text-gray-400">Y</span>
                            <input type="number" name="y" id="y" className="d-flex  align-items-center p-1 text-sm" value={nodePosition.y} onChange={onChangeNodePosition} />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 row">
                        <div className="col-md-6 pb-3">
                          <label htmlFor="w"></label>
                          <div className="d-flex align-items-center">
                            <span className="d-flex me-1 align-items-center justify-content-center text-xs uppercase text-gray-400">W</span>
                            <input type="number" name="w" id="w" className="d-flex align-items-center p-1 text-sm" value={nodeWidth} onChange={(evt) => setNodeWidth(evt.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="h"></label>
                          <div className="d-flex align-items-center">
                            <span className="d-flex me-1 align-items-center justify-content-center text-xs uppercase text-gray-400">H</span>
                            <input type="number" name="h" id="h" className="d-flex align-items-center p-1 text-sm" value={nodeHeight} onChange={(evt) => setNodeHeight(evt.target.value)}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(nodeType === 'Paragraph') && (
                      <>
                      <hr className="my-5" />
                      <div className="node-control-box1">
                        <h3 className="text-xs uppercase text-gray-400">Paragraph Style</h3>
                        <div className="mt-3 space-y-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <label htmlFor="edge-color" className="text-sm text-dark fw-normal tracking-tight">Background</label>
                            <div className="d-flex align-items-center gap-1">
                              <div className="position-relative">
                                <span className="d-block h-4 w-4 rounded shadow" style={{background:'rgb(195, 195, 255)'}}></span>
                                <input id="background-color" name="background" type="color" className="position-absolute inset-0 h-full w-full cursor-pointer opacity-0" value={paragraphNodeStyle.background} onChange={onChangeNodeStyle} />
                              </div>
                              <input type="text" className="w-16 text-sm tabular-nums focus:border-none focus:outline-none" value={paragraphNodeStyle.background} onChange={(evt) => setparagraphNodeStyle(evt.target.value)} />
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-3">
                            <label htmlFor="edge-color" className="text-sm text-dark fw-normal tracking-tight">Border</label>
                            <div className="d-flex align-items-center gap-1">
                              <div className="position-relative">
                                <span className="d-block h-4 w-4 rounded shadow" style={{background:'rgb(195, 195, 255)'}}></span>
                                <input id="border-color" name="border" type="color" className="position-absolute inset-0 h-full w-full cursor-pointer opacity-0" value={paragraphNodeStyle.border} onChange={onChangeNodeStyle} />
                              </div>
                              <input type="text" className="w-16 text-sm tabular-nums focus:border-none focus:outline-none" value={paragraphNodeStyle.border} onChange={(evt) => setparagraphNodeStyle(evt.target.value)} />
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-3">
                            <label htmlFor="edge-color" className="text-sm text-dark fw-normal tracking-tight">Text</label>
                            <div className="d-flex align-items-center gap-1">
                              <div className="position-relative">
                                <span className="d-block h-4 w-4 rounded shadow" style={{background:'rgb(195, 195, 255)'}}></span>
                                <input id="text-color" name="color" type="color" className="position-absolute inset-0 h-full w-full cursor-pointer opacity-0" value={paragraphNodeStyle.color} onChange={onChangeNodeStyle} />
                              </div>
                              <input type="text" className="w-16 text-sm tabular-nums focus:border-none focus:outline-none" value={paragraphNodeStyle.color} onChange={(evt) => setparagraphNodeStyle(evt.target.value)} />
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-3">
                            <h6 className="text-sm text-dark fw-normal tracking-tight">Text Align</h6>
                            <div className="d-flex align-items-center gap-1">
                              <button disabled className="d-flex h-6 w-6 p-0 align-items-center justify-content-center btn-light rounded-3 bg-gray-200 text-sm fw-normal disabled:text-gray-600 disabled:opacity-60">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 stroke-2"><line x1="21" x2="3" y1="6" y2="6"></line><line x1="15" x2="3" y1="12" y2="12"></line><line x1="17" x2="3" y1="18" y2="18"></line></svg>
                              </button>
                              <button className="d-flex h-6 w-6 p-0 align-items-center justify-content-center btn-light rounded-3 bg-gray-200 text-sm fw-normal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 stroke-2"><line x1="21" x2="3" y1="6" y2="6"></line><line x1="17" x2="7" y1="12" y2="12"></line><line x1="19" x2="5" y1="18" y2="18"></line></svg>
                              </button>
                              <button className="d-flex h-6 w-6 p-0 align-items-center justify-content-center btn-light rounded-3 bg-gray-200 text-sm fw-normal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 stroke-2"><line x1="21" x2="3" y1="6" y2="6"></line><line x1="21" x2="9" y1="12" y2="12"></line><line x1="21" x2="7" y1="18" y2="18"></line></svg>
                              </button>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-3">
                            <h6 className="text-sm  text-dark fw-normal tracking-tight">Justification</h6>
                            <div className="d-flex align-items-center gap-1">
                              <button disabled className="d-flex h-6 w-6 p-0 align-items-center justify-content-center btn-light rounded-3 bg-gray-200 text-sm fw-normal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 stroke-2"><rect width="6" height="16" x="4" y="6" rx="2"></rect><rect width="6" height="9" x="14" y="6" rx="2"></rect><path d="M22 2H2"></path></svg>
                              </button>
                              <button className="d-flex h-6 w-6 p-0 align-items-center justify-content-center btn-light rounded-3 bg-gray-200 text-sm fw-normal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 stroke-2"><path d="M2 12h20"></path><path d="M10 16v4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-4"></path><path d="M10 8V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v4"></path><path d="M20 16v1a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-1"></path><path d="M14 8V7c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v1"></path></svg>
                              </button>
                              <button className="d-flex h-6 w-6 p-0 align-items-center justify-content-center btn-light rounded-3 bg-gray-200 text-sm fw-normal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 stroke-2"><rect width="6" height="16" x="4" y="2" rx="2"></rect><rect width="6" height="9" x="14" y="9" rx="2"></rect><path d="M22 22H2"></path></svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      </>
                      )}

                    {(nodeType === 'Button') && (
                      <>
                      <hr className="my-5" />
                      <div className="node-control-box1">
                        <div className="mt-3 space-y-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <label htmlFor="edge-color" className="text-sm text-dark fw-normal tracking-tight">Button Link</label>
                            <div className="d-flex align-items-center gap-1">
                              <input type="text" name="link" id="link" className="d-flex align-items-center p-1 text-sm" value={nodeLink} onChange={(evt) => (setNodeLink(evt.target.value))} />
                            </div>
                          </div>
                        </div>
                      </div>
                      </>
                      )}
                </aside>
              </ReactFlowProvider>
              </Row>
              </>
              )}

              {loading && (
              <>
              <h6 className="text-center">Loading...</h6>
              </>
              )}
        </Container>
      </div>
    </div>
  );
};

export default DrawRoadmapFlowchart;

