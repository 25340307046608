import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';

const RoadmapModal = ({ show, handleCloseModal }) => {
  const {createRoadmapAndAddToOrganizationOrStudyGroup, user, showToast} = useContext(UserContext);
  const [nextStep, setNextStep] = useState(false);
  const [roadmapSlug, setRoadmapSlug] = useState('');
  const [roadmapId, setRoadmapId] = useState(0);
  const { id } = useParams();
	const [formData, setFormData] = useState({
    team_id: id,
    user_id:(user === null) ? "" : user.id,
    roadmap_title:'',
    roadmap_description:''
  });

  const navigate = useNavigate();
  let path = window.location.pathname.split("/");
  path = path[1];
  
	const onChangeInput = (e) => {
    	setFormData({
        ...formData,
        [e.target.name]:e.target.value
    	});
    	
  };
	const closeModal = () => {
		handleCloseModal();
		setFormData({
      team_id: id,
      user_id:user.id,
      roadmap_title:'',
      roadmap_description:''
    });
	}

  const saveModal = async (e) => {
    e.preventDefault();
    formData['from'] = 'STUDYGROUP';
    if(path === "createorg") {
      formData['from'] = 'ORGANIZATION';
    }else if(path === "createhiring") {
      formData['from'] = 'HIRING';
    }
    let data = await createRoadmapAndAddToOrganizationOrStudyGroup(formData);
    if(data.status){
        setFormData({
          team_id: id,
          user_id:user.id,
          roadmap_title:'',
          roadmap_description:''
        });
        setRoadmapId(data.data.id);
        setRoadmapSlug(data.data.slug);

        if(path !== "createorg") {
            handleCloseModal();
            navigate(`/roadmap/draw/${data.data.slug}`, {
              state: {
                  roadmapId:data.data.id,
                  path: ''
              },
            });
        }else{
          setNextStep(true);
        }
        
        
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
	}
  
 const handleSkillFlow = (e) => {
    handleCloseModal();
    setNextStep(false);
    navigate(`/skills-flow/${roadmapSlug}/${roadmapId}`, {
        state: {
            roadmapId: roadmapId,
            teamId: id
        },
    });
  };

  const handleEditor = (e) => {
    handleCloseModal();
    setNextStep(false);
    navigate(`/roadmap/draw/${roadmapSlug}`, {
      state: {
          roadmapId:roadmapId,
          path: ''
      },
    });
  };

	return (
    <div>
    	<Modal show={show} centered>
	        <Modal.Header>
	          <Modal.Title>{(nextStep) ? 'Open Skill Roadmap in Editor or Skill Flow?' : 'Create Roadmap'}</Modal.Title>
	        </Modal.Header>
          {(!nextStep) && (
	        <Modal.Body>
	          <p>Add a title and description to your roadmap.</p>
          		<Form.Group controlId="delete-team" className="mb-3">
                <Form.Label>Roadmap Title</Form.Label>
                <Form.Control
                  type="text"
                  name="roadmap_title"
                  value={formData.roadmap_title}
                  onChange={onChangeInput}
                  placeholder="Enter Title"
                  
                />
              </Form.Group>
              <Form.Group controlId="delete-team" className="mb-3">
                <Form.Label>Roadmap Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name="roadmap_description"
                    value={formData.roadmap_description}
                    placeholder="Enter Description"
                    onChange={onChangeInput}
                  />
              </Form.Group>

	        </Modal.Body>
          )}

	        <Modal.Footer className="justify-content-between">
	        	<div className="btn-left">
	          	<Button type='button' variant="outline-secondary" onClick={closeModal}>Cancel</Button>
	          </div>
	          <div className="btn-right">
	          	{(!nextStep) && ( <Button type='button' variant="dark" onClick={saveModal}>Save</Button> )}
              {(nextStep) && ( <> <Button type='button' variant="dark" onClick={handleEditor}>Continue to Editor</Button>
                <Button variant="success mx-2" onClick={handleSkillFlow}>Skill Flow</Button></>
               )}
		          {/* <Button variant="outline-secondary mx-2">Save as placeholder</Button>
		          <Button variant="dark">Continue to Editor</Button> */}
		        </div>
	        </Modal.Footer>
      	</Modal>
    </div>
  );
};
export default RoadmapModal;