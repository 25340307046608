import React, { useState, useContext, useEffect } from 'react';
import { useNavigate , useParams} from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
const VerifyAccount = () => {
  const {accountVerification} = useContext(UserContext);
  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const navigate = useNavigate();
  
  const { id } = useParams();
  const { token } = useParams();
  const [formData, setFormData] = useState({
    user_id:id,
    token:token
  });
  
  useEffect(() => {
    const checkToken = async () => {
      const data = await accountVerification(formData);
      if(data.status){
        setSuccessMsg(data.message);
        setTimeout(() => {
          navigate(`/login`);
        }, 3000);
      } else {
        setErrMsg(data.message);
      }
    }
  checkToken();
  }, []);


  return (
    <div className="forgot-password-form padding100">
      <Container>
        <Row>
          <Col md={12}>
            <div className="login-form-container">
              {successMsg && <h4 className="success-msg text-primary text-center">{successMsg}</h4>}
              {errMsg && <div className="err-msg text-danger text-center">{errMsg}</div>}
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default VerifyAccount;
