
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import { Row, Col, Form, Modal, Container, Button, Dropdown } from 'react-bootstrap';
import Moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';
import * as myConstent from '../contexts/ConstContext';

const OnboardPlaybook = () => {
  const {onboardingPlaybookList, logout, onboardingPlaybookCreate, onboardingPlaybookDetail, onboardingPlaybookDelete, getMasterUserTeamsData, showToast} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  
  const navigate = useNavigate();
  
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [onboardingPlaybook, setOnboardingPlaybook] = useState([]);
  const [onboardingPlaybookId, setOnboardingPlaybookId] = useState(0);
  const [userTeamsData, setUserTeamsData] = useState([]);
  const [userTeamMemberData, setUserTeamMemberData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [showTab, setShowTab] = useState('tab-1');
  const [formData, setFormData] = useState({
      id:0,
      name:'',
      description:'',
      title_color:'#000000',
      custom_css:'',
      social_media_links_on_footer:'',
      header_background:'',
      header_logo:'',
      footer_logo:'',
      favicon:'',
      preview:'',
      hide_header:0,
      external_site_name:'',
      external_site_url:'',
      footer_logo_href:'',
      footer_logo_text:'',
      seo:0,
      seo_title:'',
      seo_description:'',
      social_links:[],
      user_can_edit:0,
      team_id:0,
      user_emails:[],
      user_id: (user.login_user_type === 'ADMIN') ? 0 : user.id
  });
  const [formValues, setFormValues] = useState([{
    name: 'facebook',
    link:''
  },
  {
    name: 'gmail',
    link:''
  },
  {
    name: 'instagram',
    link:''
  },
  {
    name: 'twitter',
    link:''
  },
  {
    name: 'youtube',
    link:''
  }
  ]);

  const openCreateModal = async (playbook_id = null) => {

    if(playbook_id !== null && playbook_id !== 0) {
      setOnboardingPlaybookId(playbook_id);
      const response = await onboardingPlaybookDetail(playbook_id);
      if(response){
          if(response.token_message){
              console.error(response.token_message);
              logout();
          }else{
            
              response.data.social_links = [];
              if(response.data.social_media_links_on_footer !== null){
               var links = JSON.parse(response.data.social_media_links_on_footer); 
                if(links.length > 0) {
                  var social_links_arr = [{
                    name: 'facebook',
                    link:links.facebook.link
                  },
                  {
                    name: 'gmail',
                    link:links.gmail.link
                  },
                  {
                    name: 'instagram',
                    link:links.instagram.link
                  },
                  {
                    name: 'twitter',
                    link:links.twitter.link
                  },
                  {
                    name: 'youtube',
                    link:links.youtube.link
                  }
                ];
                setFormValues(social_links_arr);
                response.data.social_links = social_links_arr;
              } 
              }

              setSelectedEmail(response.data.user_emails);
              if(user.login_user_type !== 'ADMIN') {
                userTeamsData.forEach(element => {
                  if(element.id === response.data.team_id && element.type === "ORGANIZATION") {
                    let tmpEmailList = [...new Set(element.accepted_members.map(Val => (Val.email)))];
                    setEmailList(tmpEmailList);
                    setUserTeamMemberData(element.accepted_members);
                    return true;
                  }
                });
              }

              /*setFormData(response.data);*/
              setFormData({
                  ...response.data,
                  last_updated_by:user.id
              });
            }
      }
      else {
          showToast(response.message, true);
      }
    } else {
      setOnboardingPlaybookId(0);
      setSelectedEmail([]);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
      setFormData({
        id:0,
        name:'',
        description:'',
        title_color:'#000000',
        custom_css:'',
        social_media_links_on_footer:'',
        header_background:'',
        header_logo:'',
        footer_logo:'',
        favicon:'',
        preview:'',
        hide_header:0,
        external_site_name:'',
        external_site_url:'',
        footer_logo_href:'',
        footer_logo_text:'',
        seo:0,
        seo_title:'',
        seo_description:'',
        social_links:[],
        user_can_edit:0,
        team_id:0,
        user_emails:[],
        user_id:(user.login_user_type === 'ADMIN') ? 0 : user.id
    });

    setShowTab('tab-1');
    setFormValues([{
        name: 'facebook',
        link:''
      },
      {
        name: 'gmail',
        link:''
      },
      {
        name: 'instagram',
        link:''
      },
      {
        name: 'twitter',
        link:''
      },
      {
        name: 'youtube',
        link:''
      }
    ]);
    setOnboardingPlaybookId(0);
    fetchData();
  };

  const activeTab = (tab) => {
    setShowTab(tab);
  }

  const onChangeInput = (e) => {
      if(e.target.name === 'hide_header' || e.target.name === 'seo' || e.target.name ===  'user_can_edit') {
          const { checked } = e.target;
          if (checked) {
              setFormData({
                  ...formData,
                  [e.target.name]:1
              });
          } else {
              setFormData({
                  ...formData,
                  [e.target.name]:0
              });
          }
      } else if(e.target.name === 'user_emails') {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        setFormData({
          ...formData,
          [e.target.name]:value
        });
      } else {
          setFormData({
              ...formData,
              [e.target.name]:e.target.value
          });
      }
      
  };

  let handleChange = (i, value, e) => {
      let newFormValues = [...formValues];
      newFormValues[i]['link'] = e.target.value;
      newFormValues[i]['name'] = value;
      setFormValues(newFormValues);
      setFormData({
        ...formData,
        social_links:newFormValues
      });
  }

  const onChangeInputFile = (e) => {
      if (e.target.files) {
          setFormData({
              ...formData,
              [e.target.name]:e.target.files[0]
            });
      }
    }

  const isValidUrl = (string) => {
    try {
      if(string !== '') {
        new URL(string);
        return true;
      } else {
        return true;
      }
    } catch (err) {
      return false;
    }
  }

  const saveModal = async (e) => {
      e.preventDefault();
      if(formData.name === '' || formData.description === '') {
        showToast('Please enter all mandatory fields!', true); return false;
      }

      var temp_social_plateform_name = '';
      (formData.social_links).forEach(element => {
        var res = isValidUrl(element.link);
        if(res === false) {
          temp_social_plateform_name = element.name; 
          return true;            
        }
      });

      if(temp_social_plateform_name !== '') {
        showToast('Please enter valid url in '+temp_social_plateform_name+'!', true);return false; 
      }
    
      let data = await onboardingPlaybookCreate(formData);
      if(data.status){
          showToast(data.message, false);
          
          setTimeout(
              () => {
              handleCloseModal();
          },1000);
      }
      else if(!data.status && data.message){
        showToast(data.message, true);
      }
    }

  const navigateToArtical = (d) => {
    navigate(`/onboard-playbook/article/${d.id}`);
    // if (d.user_can_edit == 1 || d.user_id == user.id || user.login_user_type == 'ADMIN') {
    // } else {
    //   navigate(`/view-onboard-playbook/article/${d.id}`);
    // }
  }

  const handleDelete = async (id) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
      const response = await onboardingPlaybookDelete(id);
      if(response){
          if(response.token_message){
              console.error(response.token_message);
              logout();
          }else{
            fetchData();    
          }
      }
      else {
          showToast(response.message, true);
      }
    }
  }

  const fetchData = async () => {
    setLoading(true);
    const response1 = await getMasterUserTeamsData(user.id);
    if(response1){
      if(response1.token_message){
          console.error(response1.token_message);
          logout();
      }else{
          setUserTeamsData(response1.data);
      }
    }
    else {
        showToast(response1.message, true);
    }


    const response = await onboardingPlaybookList(user.login_user_type !== 'ADMIN' ? user.id : 0);
    console.log(response);
    if(response){
    if(response.token_message){
        console.error(response.token_message);
        logout();
    }else{
        setOnboardingPlaybook(response.data);
        setLoading(false);
    }
    }
    else {
        showToast(response.message, true);
    }

    
  };

  let handleTeamsChange = (e) => {
    let team = (e.target.value).split("@");
    let id = team[0];
    let type = team[1];

    setFormData({
      ...formData,
      ['team_id']:id,
      ['user_emails']:[]
    });

    let tempSelected = false;
    userTeamsData.forEach(element => {
      if(element.id === id && element.type === type) {
        let tmpEmailList = [...new Set(element.accepted_members.map(Val => (Val.email)))];
        setEmailList(tmpEmailList);
        setUserTeamMemberData(element.accepted_members);
        tempSelected = true;
        return true;
      }
    });

    if(tempSelected == false) {
      setEmailList([]);
      setUserTeamMemberData([]);
    }
  }

  const onSelectEmail = (selectedList, selectedItem) =>{
    setSelectedEmail([...selectedEmail, selectedItem]);
    setFormData({
        ...formData,
        user_emails:selectedList
    });
  }
  const onRemoveEmail = (selectedList, removedItem) => {
    let tempSelectedEmail = selectedEmail;
    const index = tempSelectedEmail.indexOf(removedItem);
    if (index > -1) { 
      tempSelectedEmail.splice(index, 1);
    }
    setSelectedEmail(tempSelectedEmail);
    setFormData({
        ...formData,
        user_emails:selectedList
    });

  }

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
    // if (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.playbook.view == 1){
         
    // }else{
    //   navigate('/unauthorized');
    // }
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      {
                        (!loading) ? 
                        <>
                          <Row className="box-header">
                            <Col md={8} lg={9}>
                                <h3 className="box-title">Onboard Playbook List</h3> 
                            </Col>
                            {
                              (userTeamsData.length !== 0 || user.login_user_type === 'ADMIN') ? 
                              <Col md={4} lg={3}>
                                {/* { 
                                (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.playbook.add == 1) && ( */}
                                  <button className="btn btn-dark pull-right submit" type="buuton" onClick={() => openCreateModal(0)}><i className="fa fa-plus me-2"></i> Create</button> 
                                  {/* )
                                } */}
                              </Col>
                              :
                              <Col md={12} lg={12}>
                                <p className='text-center'>*Please create a team to use onboarding playbook.*</p>
                              </Col>
                            }
                          </Row>
                          <Row className="box-body">
                              <div className="page__content-wrapper">
                                {
                                  (onboardingPlaybook.length === 0) && (
                                    <p className='text-center'>No records found!</p>
                                  )
                                }
                                <div className="flw-material-cards">
                                  {
                                    onboardingPlaybook.map((row,index) => (
                                      <div className="flw-material-card">
                                        <div className="card-preview">
                                          <div className="flw-material-card__body">
                                          {
                                            (row.user_can_edit === 1 || row.user_id === user.id || user.login_user_type === 'ADMIN') && (
                                              <div className="flw-material-card__body-buttons">
                                                <div className="btn-group pull-right">
                                                  <Dropdown>
                                                    <Dropdown.Toggle variant='Secondary' className="btn icon-button dropdown-more" id="dropdown-basic">
                                                      <i className="fa fa-ellipsis-v"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="dropdown-menu bottom">
                                                      <Dropdown.Item className="edit-item-knowledgebase" href="#" onClick={() => openCreateModal(row.id)}><i translate="no" className="fa fa-pencil"></i> Edit</Dropdown.Item>
                                                      {
                                                        (row.user_id === user.id || user.login_user_type === 'ADMIN') && (
                                                          <Dropdown.Item id="remove-item-knowledgebase" data-id="1" className="remove-item-knowledgebase font-red-thunderbird" href="#" onClick={() => handleDelete(row.id)}><i translate="no" className="fa fa-trash"></i>Delete </Dropdown.Item>
                                                        )
                                                      }
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div> 
                                            )
                                          }
                                            <div className="flw-material-card__body-content">
                                              <div className="flw-material-card__body-heading">
                                                <a href="javascript:void(0);" className="flw-material-card__body-link" onClick={() => navigateToArtical(row)}>{row.name} </a>
                                              </div> 
                                              <div className="flw-material-card__body-text">{row.description}</div> 
                                              <div className="flw-material-card__body-footer flex-row-center"><span className="knb-date-info">{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</span></div>
                                              {
                                                (row.user_id !== user.id && user.login_user_type !== 'ADMIN') ? (
                                                  <>
                                                  <p style={{textAlign: 'right'}} className="mb-0"><span class="playbook-share-item">Shared</span><br/> Created by {row.fname +' '+ row.lname}</p>
                                                  </>
                                                ) : (user.login_user_type === 'ADMIN' && row.user_id !== user.id && row.user_id !== null) ? 
                                                  <p style={{textAlign: 'right'}}>Created by {row.fname +' '+ row.lname}</p>
                                                :
                                                ''
                                              }
                                              {
                                                (row.last_updated_by != null && row.last_updated_by != 0) ? (
                                                  <p style={{textAlign: 'right'}}>Last updated by {row.last_updated_user}</p>
                                                ) : (row.last_updated_by == 0) ? <p style={{textAlign: 'right'}}>Last updated by Admin</p> : ''
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>

                          </Row>
                        </>
                          :
                          <h6 className="text-center">Loading...</h6>
                      }
                      </div>
                    </div>
                </div>
                    
              </Row>
            </main>
             <Modal show={showModal} size={'lg'} className="come-from-modal right" id="largeModal" >
              <Modal.Header>
                  <button type="button" className="btn-close close" data-dismiss="modal" aria-hidden="true" onClick={handleCloseModal}>&times;</button>
                  <Modal.Title>{onboardingPlaybookId === 0 ? '' : ''} Onboarding Playbook</Modal.Title>
              </Modal.Header>

              <Modal.Body className="knowledge_modal">
                <ul className="tabs">
                    <li className={"tab tab-1 " + (showTab === 'tab-1' ? 'current' : '') } data-tab="tab-1" onClick={() => activeTab('tab-1')}>Main</li>
                    <li className={"tab tab-2 d-none" + (showTab === 'tab-2' ? 'current' : '') } data-tab="tab-2" onClick={() => activeTab('tab-2')}>External</li>
                    {
                      (formData.user_id == user.id && user.login_user_type !== 'ADMIN') && 
                      (<li className={"tab tab-3 " + (showTab === 'tab-3' ? 'current' : '') } data-tab="tab-3" onClick={() => activeTab('tab-3')}>Share</li>)
                    }
                </ul>
                <div id="tab-1" className={"tab-content " + (showTab === 'tab-1' ? 'current' : '') }>
                
                    <div className="form-group">
                        <label htmlFor="name" className="form__label">Name<sup style={{color:'red'}}>*</sup></label>
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name"  
                          value={formData.name}
                          onChange={onChangeInput} />
                       
                    </div>
                    <div className="form-group">
                        <label htmlFor="description" className="form__label">Description<sup style={{color:'red'}}>*</sup></label>
                        <textarea className="form-control" name="description" id="description" rows="4"  
                        value={formData.description}
                        onChange={onChangeInput} placeholder="Description"></textarea>
                        
                    </div>
                    <div className="form-group">
                        <h4>Design</h4>
                    </div>
                    <div className="form-group">
                        <label htmlFor="header_backgraound" className="form__label">Header Background</label>
                        <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                        <div className="input-file__inner">
                            <label htmlFor="fileUpload" className="fileupload-new thumbnail ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>
                                </label>

                                <div className="input-file__image-wrap">
                                    <div className="input-file__image-origin hidden"></div>
                                    <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                    {
                                        (formData.header_background !== '' && formData.header_background.name === undefined) && 
                                        <img src={formData.header_background} />  
                                    }
                                    </div>
                                </div>
                                <span className="input-file__action--upload">
                                    {
                                        (formData.header_background !== '' && formData.header_background.name !== '') ? 
                                        <span className="fileupload-exists">
                                            {formData.header_background.name}                        
                                        </span>
                                        :
                                        <span className="fileupload-new">
                                            Add                        
                                        </span>
                                    }
                                </span>
                                <input type="file" name="header_background" className="form-control" id="header_background" placeholder="Add"  accept="image/*" onChange={onChangeInputFile} />
                                
                            </div>
                        </div>
                    </div>
                    <div className="form-group color-picker">
                        <label htmlFor="header_backgraound" className="form__label">Title Font Color</label>
                        <input type="color" className="form-control" name="title_color" value={formData.title_color}
                        onChange={onChangeInput} />
                    </div>
                    
                </div>
                <div id="tab-2" className={"tab-content d-none" + (showTab === 'tab-2' ? 'current' : '') }>
                    <div className="form-group">
                        <h4 className="">Template Design</h4>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="header_backgraound" className="form__label">Header Logo</label>
                            <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                                <div className="input-file__inner">
                                    <label htmlFor="fileUpload" className="fileupload-new thumbnail ">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>

                                    </label>

                                    <div className="input-file__image-wrap">
                                        <div className="input-file__image-origin hidden"></div>
                                        <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                        {
                                            (formData.header_logo !== '' && formData.header_logo.name === undefined) && 
                                            <img src={formData.header_logo} />  
                                        }
                                        </div>
                                    </div>
                                    <span className="input-file__action--upload">
                                    {
                                        (formData.header_logo !== '' && formData.header_logo.name !== '') ? 
                                        <span className="fileupload-exists">
                                            {formData.header_logo.name}                        
                                        </span>
                                        :
                                        <span className="fileupload-new">
                                            Add                        
                                        </span>
                                    }
                                    </span>
                                    <input type="file" className="form-control" id="header_logo" name='header_logo' accept="image/*" placeholder="Add" onChange={onChangeInputFile} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="header_backgraound" className="form__label">Footer Logo</label>
                            <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                                <div className="input-file__inner">
                                    <label htmlFor="fileUpload" className="fileupload-new thumbnail ">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>

                                    </label>

                                    <div className="input-file__image-wrap">
                                        <div className="input-file__image-origin hidden"></div>
                                        <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                        {
                                            (formData.footer_logo !== '' && formData.footer_logo.name === undefined) && 
                                            <img src={formData.footer_logo} />  
                                        }
                                        </div>
                                    </div>
                                    <span className="input-file__action--upload">
                                    {
                                        (formData.footer_logo !== '' && formData.footer_logo.name !== '') ? 
                                        <span className="fileupload-exists">
                                            {formData.footer_logo.name}                        
                                        </span>
                                        :
                                        <span className="fileupload-new">
                                            Add                        
                                        </span>
                                    }
                                    </span>
                                    <input type="file" className="form-control" id="footer_logo" name='footer_logo' placeholder="Add" accept="image/*" onChange={onChangeInputFile} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="header_backgraound" className="form__label">Favicon</label>
                            <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                                <div className="input-file__inner">
                                    <label htmlFor="fileUpload" className="fileupload-new thumbnail ">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>

                                    </label>

                                    <div className="input-file__image-wrap">
                                        <div className="input-file__image-origin hidden"></div>
                                        <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                        {
                                            (formData.favicon !== '' && formData.favicon.name === undefined) && 
                                            <img src={formData.favicon} />  
                                        }
                                        </div>
                                    </div>
                                    <span className="input-file__action--upload">
                                    {
                                        (formData.favicon !== '' && formData.favicon.name !== '') ? 
                                        <span className="fileupload-exists">
                                            {formData.favicon.name}                        
                                        </span>
                                        :
                                        <span className="fileupload-new">
                                            Add                        
                                        </span>
                                    }
                                    </span>
                                    <input type="file" className="form-control" id="favicon" name='favicon' placeholder="Add" accept="image/*" onChange={onChangeInputFile} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="header_backgraound" className="form__label">Preview</label>
                            <div className="flw-portlet flw-portlet--light input-file fileupload  fileupload-new">
                                <div className="input-file__inner">
                                    <label htmlFor="fileUpload" className="fileupload-new thumbnail input-file__default">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="19px" fill="currentColor" className="bi bi-camera fileupload_label-icon" viewBox="0 0 16 16"> <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/> <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/> </svg>
                                    </label>

                                    <div className="input-file__image-wrap">
                                        <div className="input-file__image-origin hidden"></div>
                                        <div className="input-file__image fileupload-preview fileupload-exists thumbnail">
                                        {
                                            (formData.preview !== '' && formData.preview.name === undefined) && 
                                            <img src={formData.preview} />  
                                        }
                                        </div>
                                    </div>
                                    <span className="input-file__action--upload">
                                    {
                                        (formData.preview !== '' && formData.preview.name !== '') ? 
                                        <span className="fileupload-exists">
                                            {formData.preview.name}                        
                                        </span>
                                        :
                                        <span className="fileupload-new">
                                            Add                        
                                        </span>
                                    }
                                    </span>
                                    <input type="file" className="form-control" id="preview" name='preview' accept="image/*" onChange={onChangeInputFile} placeholder="Add" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-check">
                        <div className="checkbox-inline">
                            <input name="hide_header" type="checkbox" className="form-check-input" id="hide_header" value={formData.hide_header}
                        checked={formData.hide_header === 1 ? true : false } onChange={onChangeInput} />
                            <label className="form-check-label" htmlFor="hide_header">Hide Header</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="external_site_name" className="form__label">The name of your website</label>
                        <input className="form-control" id="external_site_name" placeholder="The name of your website" type="text" name="external_site_name"  value={formData.external_site_name}
                        onChange={onChangeInput} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="external_site_url" className="form__label">Your website address</label>
                        <input className="form-control" id="external_site_url" placeholder="Your website address" type="text" name="external_site_url"  value={formData.external_site_url}
                        onChange={onChangeInput}  />
                    </div>
                    <div className="form-group">
                        <label htmlFor="footer_logo_href" className="form__label">Footer: Logo Link</label>
                        <input className="form-control" id="footer_logo_href" placeholder="Footer: Logo Link" type="text" name="footer_logo_href"   
                        value={formData.footer_logo_href}
                        onChange={onChangeInput}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="footer_logo_text" className="form__label">Footer: Logo Text</label>
                        <input className="form-control" id="footer_logo_text" placeholder="Footer: Logo Text" type="text" name="footer_logo_text" 
                        value={formData.footer_logo_text}
                        onChange={onChangeInput} />
                    </div>

                    <div className="form-group">
                        <div className="checkbox-inline">
                            <input className="form-check-input" id="exampleCheck3" name="seo" type="checkbox"  value={formData.seo}
                        checked={formData.seo === 1 ? true : false } onChange={onChangeInput} >
                            </input>
                            <label className="form-check-label" htmlFor="exampleCheck3">
                                SEO
                            </label>
                        </div>
                    </div>
                    {
                        (formData.seo === 1) && (
                            <div className="seo-section">
                                <div className="form-group">
                                    <label className="form__label" htmlFor="seo_title">
                                        Title HTML-document
                                    </label>
                                    <input className="form-control" placeholder="Title HTML-document" type="text" name="seo_title"  value={formData.seo_title}
                                onChange={onChangeInput} />
                                </div>
                                <div className="form-group">
                                    <label className="form__label" htmlFor="seo_description">
                                        Meta Description
                                    </label>
                                    <textarea className="form-control"  placeholder="Meta Description" name="seo_description" value={formData.seo_description}
                                onChange={onChangeInput} >
                                    </textarea>
                                </div>
                            </div>
                        )
                    }
                    
                    <div className="form__divider"></div>
                     <label class="form__label" for="">Social Media Links on Footer</label>
                      <div class="form__input--fragment">
                          <div id="social-links-container" class="social-links">
                              <div class="social-links__item" data-social-link-id="0">
                                <div className="row">
                                  {
                                  formValues.map((element,index) => (
                                  <>
                                  <div className="col-md-3 mb-1 mt-1">
                                    <div class="social-links__item-name">
                                        <input type="text" class="form-control" placeholder="Item Name" id={`social_links_${element.name}`} value={element.name} readonly />
                                    </div>
                                  </div>
                                  <div className="col-md-8 mb-1 mt-1">
                                    <div class="social-links__item-url">
                                        <input type="text" class="form-control" placeholder="Item URL" id={`social_links_${element.name}`} name="link" value={element.link}  onChange={e => handleChange(index, element.name, e)}/>
                                    </div>
                                  </div>
                                  <div className="col-md-1 mt-1">
                                    <div class="social-links__item-icon">
                                        <div data-provides="fileupload" class="fileupload  fileupload-new ">
                                            <div class="" data-container=".sidepanel-wrapper">
                                                <div class="btn-group d-flex">
                                                    <div class="social-links__item-icon--preview fileupload-new thumbnail">
                                                        <img src={`/knowledge-asstes/image/${element.name}.png`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                  </>
                                  ))}
                                  
                                </div>
                              </div>
                              <br />
                          </div>
                      </div>
                    <div className="form-group">
                        <label htmlFor="css" className="form__label">Custom CSS</label>
                        <textarea className="form-control"  placeholder="Custom CSS" rows="4" name="custom_css" value={formData.custom_css}
                                onChange={onChangeInput}></textarea>
                    </div>
                </div>
                {
                      (formData.user_id === user.id && user.login_user_type !== 'ADMIN') && (
                      <div id="tab-3" className={"tab-content " + (showTab === 'tab-3' ? 'current' : '') }>
                          <div className="form-group form-check">
                              <div className="checkbox-inline">
                                  <input name="user_can_edit" type="checkbox" className="form-check-input" id="user_can_edit" value={formData.user_can_edit}
                              checked={formData.user_can_edit === 1 ? true : false } onChange={onChangeInput} />
                                  <label className="form-check-label" htmlFor="user_can_edit">User can edit?</label>
                              </div>
                          </div>

                          <div className="form-group">
                            <Form.Label>Select Team</Form.Label>
                            <Form.Select name="team_id" className="team_id" onChange={e => handleTeamsChange(e)}>
                              <option value="" >Select</option>
                              {userTeamsData.map((team) => (
                                <option value={team.id+"@"+team.type} selected={formData.team_id === team.id ? true : false} >{team.name} ({(team.type === 'ORGANIZATION') ? team.parent_name : 'Study Group'})</option>
                              ))}
                            </Form.Select>
                          </div>

                          {
                            (userTeamMemberData.length !== 0 || formData.id != 0) ? 
                            <>
                            <div className="form-group">
                              {/* <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="By default view access to all selected users."></i> */}
                            <Form.Label>Select Users </Form.Label>
                            <br/>
                            <br/>
                              <Multiselect showArrow options={emailList} selectedValues={selectedEmail} isObject={false} onSelect={onSelectEmail} onRemove={onRemoveEmail} /> 
                               {/*<Form.Select name="user_emails" className="user_emails" onChange={e => onChangeInput(e)} multiple>
                                {userTeamMemberData.map((member) => (
                                  <option value={member.email} selected={(formData.user_emails).includes(member.email) ? true : false}>{member.email}</option>
                                ))}
                              </Form.Select>*/}
                            </div>
                            </>
                            :
                            ''
                          }
                          
                      </div>
                      )
                }
              </Modal.Body>

              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button variant="outline-secondary" onClick={handleCloseModal}>Cancel</Button>
                </div>
                <div className="btn-right" onClick={saveModal}>
                  <Button variant="dark">Save</Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
  );
};
export default OnboardPlaybook;