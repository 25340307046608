import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import ReactFlow , { 
  ReactFlowProvider,
  Background,
  Controls
} from 'reactflow';
import { Container, Row, Col, Modal, Button, Form, Dropdown } from 'react-bootstrap';
import 'reactflow/dist/style.css';
import ProfileMenu from './profilemenu';
import { UserContext } from '../contexts/UserContext';
import TeamSidebar from "./teamSidebar";
import CustomNode from './customNode';

const SkillsFlow = () => {
  const reactFlowWrapper = useRef(null);
  const { getTeamsOfOrganizationByTeamId, getStudyTeams, getSkills, logout, user, getTeamDetails} = useContext(UserContext);

  if(user === null) {
    logout();
  }

  const [loading, setLoading] = useState(true);
  const [roadmap, setRoadmap] = useState({});
  const [showSkill, setShowSkill] = useState(true);
  const [displayNodes, setDisplayNodes] = useState([]);
  const [displayEdges, setDisplayEdges] = useState([]);
  const [cheight, setHeight] = useState('50vh');
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.roadmapId;
  const teamId = location.state.teamId;
  const path = location.state.path;
  const [errModalMsg, setErrModalMsg] = useState('');
  const [teams, setTeams] = useState([]);
  const [grpTeams, setGrpTeams] = useState([]);
  const [isNodeSelected, setIsNodeSelected] = useState(false);
  const [nodeName, setNodeName] = useState('');
  const [nodeDesc, setNodeDesc] = useState('');
  const [nodeId, setNodeId] = useState(1);

  const nodeTypes = {
    custom: CustomNode,
  };

  const [formValues, setFormValues] = useState([{
    email:'',
    user_id:''
  }]);
  
  const statusClassNames = [ 'pending','done','in_progress','skip'];

	const [formData, setFormData] = useState({
    team_id: 0,
    user_id:user.id,
    roadmap_title:'',
    roadmap_description:'',
    from:'',
    parent_roadmpa_id: 0,
  });
  
	const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    });
  };
	
  const addLabel = (name, link = "#") => {
    return (link != null && link != "" && link != "#") ? <a href={link}><div className={`title`}>{name}</div></a> : <div className={`title`}>{name}</div>;
  }

  const edgeOptions = {
    style: {
      stroke: '#000',
    },
  };

  
  const connectionLineStyle = { stroke: '#000' };

  const xpostion = [500,300];
  const ypostion = [0,100,200,300];

  const fetchTeamData = async () => {
      const team_response = await getTeamDetails(teamId);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setFormValues(team_response.data.accepted_members_results);
          if(team_response.data.accepted_members_results.length > 0) {
            fetchData(team_response.data.accepted_members_results[0]['user_id']);
          }
          setLoading(false);
        }
      }
      
    };

  let handleChange = (e) => {
      let user_id = e.target.value;
      fetchData(user_id);
  }

  // Function to fetch data from the API
  const fetchData = async (user_id = null, skill_id = null) => {
    setLoading(true);
    let response = await getSkills(id, user_id);
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else if(response.data){
            var result = response.data;
            var roadmap = response.roadmap;

            setRoadmap(roadmap);
            let temp = [
              {
                id: 'main_node',
                type: 'input',
                className: 'main_node',
                data: { label: roadmap.roadmap_name },
                position: (roadmap.node_json.position != '') ? roadmap.node_json.position : { x: xpostion[0], y: 0 },
                style: {
                background: "unset",
                color: "#000",
                border: "unset",
                fontSize:"15px",
                fontWeight:"bold",
                width: 100},
              },
            ];

            let tempEdges = [];

            let x = 0;
            let y = 100;
            let count = 1;
            let leftright = 1;
            let last_skill = null;
            let last_x = xpostion[0];
            let question_y = 0;
            let question_ay = 0;
            let question_ay_start_with = 0;
            let question_by = 0;
            let skill_child_y = 0;
            let skill_child_ay = 0;
            let skill_child_ay_start_with = 0;
            let skill_child_by = 0;
            result.forEach(element => {
              x = xpostion[count];

              if(element.node_type != undefined && element.node_type == 'paragraph') {
                element.node_type = 'Paragraph';
                element.skill_id = 'p_'+element.id;
                element.skill_definition = '#DESCRIPTION#';
                element.skill_name = element.text;
              } else if(element.node_type != undefined && element.node_type == 'button') {
                element.node_type = 'Button';
                element.skill_id = 'b_'+element.id;
                element.skill_definition = '#DESCRIPTION#';
                element.skill_name = element.text;
              } else {
                element.node_type = 'Skill';
                element.node_link = '#';
              }

              let lwr_node_type = (element.node_type).toLowerCase();
              temp.push({
                id: lwr_node_type+'-'+element.skill_id,
                type: (element.node_type == 'Skill') ? 'default' :'custom',
                className: 'single_'+lwr_node_type+' '+lwr_node_type+'_nodes hide '+lwr_node_type+'-'+element.skill_id+' '+statusClassNames[element.node_status],
                data: { original_label:element.skill_name, label: addLabel(element.skill_name,element.node_link), description: element.skill_definition, link:element.node_link, status: element.node_status },
                position: (element.node_json.position != '') ? element.node_json.position : { x: x, y: y },
                element: element.node_type+'@'+element.skill_id,
                // style: (element.node_json.style != '') ? element.node_json.style : {
                // background: "rgb(107, 207, 238)",
                // color: "#000",
                // border: "2px solid #000",
                // width: 100}
                style: (element.node_type == "Skill") ? {
                  display:(skill_id == null || skill_id == 'skill-'+element.skill_id || showSkill == false) ? 'block' : 'none',
                  background: "rgb(107, 207, 238)",
                  color: "#000",
                  border: "2px solid #000",
                  width: 100
                } : (element.node_json.style != '') ? element.node_json.style : {
                  background: "rgb(107, 207, 238)",
                  color: "#000",
                  border: "2px solid #000",
                  width: 100}
              });

              // temp.push({
              //   id: 'skill-'+element.skill_id,
              //   className: 'single_skill skill_nodes hide skill-'+element.skill_id+' '+statusClassNames[element.node_status],
              //   data: { original_label:element.skill_question, label: addLabel(element.skill_name), description: element.skill_definition, status: element.node_status },
              //   position: { x: x, y: y },
              //   style: {display:(skill_id == null || skill_id == 'skill-'+element.skill_id || showSkill == false) ? 'block' : 'none',
              //   background: "rgb(107, 207, 238)",
              //   color: "#000",
              //   border: "2px solid #000",
              //   width: 100},
              //   // sourcePosition: (leftright == 1) ? "left" : "right",
              // });

              let source_id = (last_skill == null) ? 'main_node' : last_skill;
              tempEdges.push({
                id: 'id-'+source_id+'-'+element.skill_id, className: 'skill_edges edges-single-skill-'+element.skill_id+'_', source: source_id, target: 'skill-'+element.skill_id,
                animated: false,
                style: {strokeWidth: 3}
              });

              
              y += 100;
              
              count++;
              if(count >= 2) {
                count = 0;
              }

              if(leftright == 1) {
                question_ay_start_with = question_y;
                skill_child_ay_start_with = skill_child_y;
              }


                question_y = (leftright == 0) ? question_ay_start_with + 50 : ((question_ay > question_by) ? question_ay : question_by) + 50;
                let question_x = element.skillChilds.length > 0 ? ((leftright == 1) ? x - x - x : x + x + x) : ((leftright == 1) ? x - x : x + x);
                element.questionDetails.forEach(question_element => {
                  temp.push({
                    id: 'skill_question-'+question_element.skills_question_id,
                    type: 'output',
                    className: 'question_skill_nodes_skill-'+element.skill_id+' '+statusClassNames[question_element.node_status],
                    data: { original_label:question_element.skill_question, label: addLabel(question_element.skill_question), description: '', status: question_element.node_status },
                    position: (question_element.node_json.position != '') ? question_element.node_json.position : { x: question_x, y: question_y },
                    style: {display:'block',
                    background: "rgb(3, 64, 128)",
                    color: "#fff",
                    fontSize:"10px",
                    border: "2px solid #fff",
                    width: 100}
                  });
                  tempEdges.push({
                    id: 'id-que-'+element.skill_id+'-'+question_element.skills_question_id, className: 'skill_question_edges que_edge_skill-'+element.skill_id, source: 'skill-'+element.skill_id, target: 'skill_question-'+question_element.skills_question_id,
                    animated: true,
                    style: {display:'block'}
                  });

                  question_y += 60;
                });

                if(element.skillChilds.length > 0) {
                  skill_child_y = (leftright == 0) ? skill_child_ay_start_with + 50 : ((skill_child_ay > skill_child_by) ? skill_child_ay : skill_child_by) + 50;
                  let skill_child_x = (leftright == 1) ? x - x : x + x;

                  element.skillChilds.forEach(child_element => {
                    temp.push({
                      id: 'skill_child-'+child_element.skill_id,
                      type: 'output',
                      className: 'child_skill_nodes_skill-'+element.skill_id+' '+statusClassNames[child_element.node_status],
                      data: { original_label:child_element.skill_name, label: addLabel(child_element.skill_name), description: '', status: child_element.node_status },
                      position: (child_element.node_json.position != '') ? child_element.node_json.position : { x: skill_child_x, y: skill_child_y },
                      style: {display:'block',
                      background: "rgb(107, 207, 238)",
                      color: "#000",
                      border: "2px solid #000",
                      width: 100}
                    });
                    tempEdges.push({
                      id: 'id-que-'+element.skill_id+'-'+child_element.skill_id, className: 'skill_child_edges que_edge_skill-'+element.skill_id, source: 'skill-'+element.skill_id, target: 'skill_child-'+child_element.skill_id,
                      animated: true,
                      style: {display:'block'}
                    });

                    skill_child_y += 60;
                  });
                }
 

                if(leftright == 1) {
                  question_ay = question_y;
                  skill_child_ay = skill_child_y;
                } else {
                  question_by = question_y;
                  skill_child_by = skill_child_y;
                }
 
                y += element.questionDetails.length * 10;
                last_skill = 'skill-'+element.skill_id;
                leftright = (leftright == 1) ? 0 : 1;
                last_x = x;
            });

            // console.log(JSON.stringify(temp));
            // console.log(JSON.stringify(tempEdges));
            if(temp.length >= 10 && temp.length <= 25){
                setHeight('100vh');
            }else if(temp.length >= 25 && temp.length <= 40){
                setHeight('180vh');
            }else if(temp.length >= 50){
              setHeight('250vh');
            }else if(temp.length >= 50 && temp.length <= 75){
              setHeight('300vh');
            }else if(temp.length >= 75){
              setHeight('400vh');
            }
            setDisplayNodes(temp);
            setDisplayEdges(tempEdges);

        }else{
        }
        setLoading(false);


        let team_id = 0;
      response = await getTeamsOfOrganizationByTeamId('undefined', user.id, 1);
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          let newarray = response.data.filter(
              (e) => e.is_admin === 1
          );
          setTeams(newarray);
          if(response.data.length > 0) {
            team_id = response.data[0].id + '@ORGANIZATION';
          }
        }
      }
      else {
        console.error(response);
      }
      
      let response2 = await getStudyTeams(user.id);
      if(response2){
        if(response2.token_message){
          console.error(response2.token_message);
          logout();
        }else{
          let newarray = response2.data.filter(
              (e) => e.user_id === user.id
          );
          setGrpTeams(newarray);
          if(team_id == 0 && response2.data.length > 0) {
            team_id = response2.data[0].group_id + '@STUDYGROUP';
          }
        }
      }
      else {
        console.error(response2);
      }

      setFormData({
        team_id: team_id,
        user_id:user.id,
        roadmap_title:roadmap.roadmap_name,
        roadmap_description:roadmap.roadmap_description,
        from:'',
        parent_roadmpa_id: roadmap.roadmap_id,
      });
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchTeamData();
  }, []);

  
  const handleNodeClick = (e, node) => {
    // let id = e.target.attributes['data-id'].nodeValue;
    // if ((e.target.className).includes('single_skill')) {
    //   fetchData(id);
    //   setShowSkill((showSkill == true) ? false : true);
    // }

    setNodeName(node.data.label);
    setNodeDesc(node.data.description);
    setNodeId(node.id);

    var node_id = node.id;
    let isquestion = node_id.includes("skill_question");
    let isnewquestion = node_id.includes("q_");
    let isnewparagraph = node_id.includes("p_");
    let isnewbutton = node_id.includes("b_");
    if(isquestion || isnewquestion || isnewparagraph || isnewbutton){
      setNodeDesc('#DESCRIPTION#');
    }

    setIsNodeSelected(true);
    
  };

  const closeSidebar = async (e) => {
    setIsNodeSelected(false);
  }

  return (
    <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                      <Row>
                        <Col lg="9">
                          <h1>{roadmap.roadmap_name} Flow Chart</h1>
                        </Col>
                         <Col lg="3">
                          <Form.Select name="member_id"  onChange={e => handleChange(e)}>
                            {formValues.length > 0 && formValues.map((element, index) => (
                              <option value={element.user_id} selected={(index===0)}>{element.email}</option>
                            ))}
                            {formValues.length === 0 && (
                              <option value="0" selected>Choose option</option>
                            ) }
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                      {!loading && (displayEdges.length > 0) && (
                      <>
                        {/* <div style={{height:cheight}}> */}
                        <Row>
                          <ReactFlowProvider>
                            <div className={`reactflow-wrapper ${isNodeSelected ? 'col-md-9' : 'col-md-12'} padding80 h-full`} ref={reactFlowWrapper} style={{height:cheight}}>
                              <ReactFlow
                                defaultNodes={displayNodes}
                                defaultEdges={displayEdges}
                                defaultEdgeOptions={edgeOptions}
                                onNodeClick={(e, node) => handleNodeClick(e, node)}
                                nodesDraggable={false}
                                connectionLineStyle={connectionLineStyle}
                                nodeTypes={nodeTypes}
                                fitView
                                attributionPosition="bottom-left"
                                preventScrolling={false}
                              >
                              <Controls
                                position="top-left"
                              />
                              <Background color="#ccc" variant="dots" />
                              </ReactFlow>
                            
                            </div>
                            <aside className={`updatenode__controls react-flow__sidebar node-sidebar  ${isNodeSelected ? 'd-flex w-25' : 'd-none'} shrink-0 flex-column align-items-stretch border-start border-gray-200 bg-white h-full`}>

                              <a href="javascript:void(0)" onClick={() => closeSidebar()} className="close_sidebar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="position-relative left-[-2px] h-4 w-4"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
                              </a>
                              
                              <h4>{nodeName}</h4>
                              {(nodeDesc != '#DESCRIPTION#') && (
                              <p>{nodeDesc}</p>
                              )}
                            </aside> 
                          </ReactFlowProvider>
                        </Row>
                        {/* </div> */}
                        </>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </Row>
            </main>
          </Container>
      </div>
    </div>
  );
};

export default SkillsFlow;
