import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import JsonMetaData from "../data/metaData.json";
export const AboutUs = (props) => {
  const [pageMetaData, setPageMetaData] = useState(JsonMetaData);
  useEffect(() => {
    // const script = document.createElement("script");
    //     script.src = "../js/metaTag.js";
    //     script.async = true;
    //     document.body.appendChild(script);
    }, []);
  return (
    <div id="aboutus" className="h-full">
      <Helmet>
          <title>{pageMetaData.about.title}</title>
          <meta name="description" content={pageMetaData.about.description} />
          <meta name="keywords" content={pageMetaData.about.keywords} />
          <meta name="author" content={pageMetaData.about.author} />
          <meta property="og:title" content={pageMetaData.about.og_title} />
          <meta property="og:description" content={pageMetaData.about.og_description} />
          <meta property="og:image" content={pageMetaData.about.og_image} />
          <meta property="og:url" content={pageMetaData.about.og_url} />
      </Helmet>
      <div className="container padding80">
        <div className="section-title text-center">
          <h2>About Us</h2>
          <p>
            
          </p>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="text-area">
            <section>
              <p>At Algence, we transcend traditional hiring processes by specializing in comprehensive skills assessments for both technical and non-technical roles. Our mission is to redefine talent acquisition, empowering organizations to build high-performance teams.</p>
              <p>With a dynamic fusion of precision assessments and personalized development plans, we guide individuals on a transformative journey to unleash their full potential. Whether you're navigating the complex terrain of technical proficiency or seeking excellence in non-technical realms, we are your strategic partner. </p>
              <p>Welcome to a realm where skills meet their true potential, and career development is not just a path; it's a tailored ascent to success. Discover the power of purposeful skill assessments and chart your course to professional excellence with Algence.</p>
              <p>Algence is owned by Quantum Ventures LLC.</p>

             <h3> Get in touch </h3>
             <p>Do you have product suggestions? Want to get in touch? Email us at QuantumVenturesLLC@proton.me and we’ll get back to you as soon as we can.</p>
           </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
