import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import {UserContext} from '../contexts/UserContext';
import "react-pro-sidebar/dist/css/styles.css";

const HiringAcceptRequest = () => {
  const navigate = useNavigate();
  const { invite_token } = useParams();
  const [loading, setLoading] = useState(true);
  const { getHiringByToken, updateHiringByToken, logout, showToast } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  
  const checkProfessionalEmail = async () => {
    // List of commonly used professional email providers
    const professionalProviders = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'protonmail.com',
      'yopmail.com'
      // Add more professional email providers as needed
    ];

    // Extract domain from email address
    const domain = (user.email).split('@')[1];

    // Check if the domain is a commonly used professional email provider
    if (professionalProviders.includes(domain)) {
      alert('To unlock Hiring feature, please register using your official company email address.');
      navigate(`/profile-teams`);
    }
  };
  
  const submitInviteResult = async (status) => {
    const data = await updateHiringByToken({invite_token:invite_token, status:status});
    if(data.status){
      showToast(data.message, false);

      setTimeout(
        () => {
          if(status === 1) {
            navigate(`/createhiring/chooseroadmaps/${data.hiring_id}`);
          } else if(status === 2) {
            navigate(-1)
          }
        }
        , 2000);

      
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
  };


  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    
  const fetchData = async () => {
    // checkProfessionalEmail();
    setLoading(true);
    const response = await getHiringByToken(invite_token);
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        if(response.status) {
          if(response.data.status === "0") {
            setLoading(false);
          } else {
            showToast("Link is expired!", true);
          }
        } else {
          showToast(response.message, true);
        }
      }
    }
    else {
      console.error(response);
    }
  };
  
    fetchData();
  }, []);

  return (
    <div className="row h-full">
      <aside className="padding80 py-10 d-md-block border-end process-sidebar">
        <Container fluid>
          <div className="relative mr-2">
          {!loading && (
              <>
            <div className="container text-center">
              <h2 className="mb-1 text-2xl font-bold">Join Team</h2>
              <p className="mb-3 text-base leading-6 text-gray-600">You have been invited to join the team.</p>
              <div className="w-full max-w-md" style={{display: 'inline-block'}}>
                <div className="flex w-full items-center gap-2">
                  <button type="button" className="btn btn-success mx-2" onClick={() => submitInviteResult(1)}>Accept</button>
                  <button type="button" className="btn btn-danger ms-2" onClick={() => submitInviteResult(2)}>Reject</button>
                </div>

                <hr/>

                
              <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>Back to Teams</button>
              </div>
            </div>
            </>
          )}
          </div>
        </Container>
      </aside>
    </div>
  );
};

export default HiringAcceptRequest;