
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import { Modal, Button, Form, Container, Dropdown} from 'react-bootstrap';
import { PlaybookUpdateModal } from "./playbookUpdateModal";
import { PlaybookArticleCreateModal } from "./playbookArticleCreateModal";
import ReactQuill from 'react-quill';

import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';

const PlaybookArticle = () => {
  const { onboardingPlaybookDetail, onboardingPlaybookDelete, logout, getPlaybookArticlesByKeyword } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const [mainPost, setMainPost] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [searchData, setSearhData] = useState([]);
  const [isOpenId, setIsOpenId] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  
  const openEditPlaybookModal = async (e) => {
    setShowEditModal(true);
  }

  const openCreateModal = async (e) => {
    setShowModal(true);
  }

  const handleEditCloseModal = () => {
    setShowEditModal(false);
  };
  const handleCloseModal  = () => {
      fetchData();
      setShowModal(false);
  };
  
  const navigateToArticalDetail = (id) => {
   navigate(`/onboard-playbook/article/details/${id}`);
  }

  const searchInputChange = async (e) => {
   if (e.code == "ArrowUp" || e.code == "ArrowDown")
       return;
    if (e.code == "Enter")
       return;
    setIsShowSpinner(true);
    let keyword = e.target.value;
   const response = await getPlaybookArticlesByKeyword({onboarding_playbook_id: id,keyword:keyword.toLowerCase()});
   if(response){
      if(response.token_message){
         console.error(response.token_message);
         logout();
      }else{
         if(response.status){
            setIsShowSpinner(false);
            setSearhData(response.data);

         }else{
            setIsShowSpinner(false);
            setSearhData([]);
         }
      }
   }
   else {
      console.error(response.message);
   }
  }

   const fetchData = async () => {
      setLoading(true);
      const response = await onboardingPlaybookDetail(id,0 ,user.login_user_type != 'ADMIN' ? user.id : 0);
      console.log(response);
      if(response){
         if(response.token_message){
            console.error(response.token_message);
            logout();
         }else{
            if (response.data.user_can_edit == 1 || response.data.user_id == user.id || user.login_user_type == 'ADMIN') {
               response.data.is_editable = 1;
            } else {
               response.data.is_editable = 0;
            }
            setMainPost(response.data);
            setLoading(false);
         }
      }
      else {
         console.error(response.message);
      }
   }

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
   fetchData();
  }, []);


  
 const handleDelete = async () => {
   const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
      const response = await onboardingPlaybookDelete(id);
      if(response){
          if(response.token_message){
              console.error(response.token_message);
              logout();
          }else{
            navigate(`/onboard-playbook`);   
          }
      }
      else {
         console.error(response.message);
      }
   }
 }
   
   const toggleOpen = (id) => {
      setIsOpenId(id);
    }                    
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 responsive-content">
          <Container fluid>
            {
               (loading) ?
               <h6 className="text-center">Loading...</h6>
               :
               (
               <div className="row">
                  <div className="col-xs-12">
                  <div className="box">
                        <div className="box-header d-flex">
                           <a href="javascript:void(0);" className="page-bar-back-url-btn" onClick={() => navigate(-1)}><i className="fa fa-long-arrow-left"></i></a> 
                           <h3 className="box-title">{mainPost.name}</h3>
                           {
                              (mainPost.is_editable == 1) && (
                                 <div className="btn-group pull-right d-flex justify-content-center">
                                    {
                                       (mainPost.user_id == user.id || user.login_user_type == 'ADMIN') && (
                                          <a data-target="#largeModal" data-toggle="modal" href="javascript:void(0)" className="btn btn-primary" onClick={openCreateModal}><i className="fa fa-plus me-2"></i> Create Article</a> 
                                       )
                                    }
                                 <div className="btn-group header__more-btn d-flex ms-2">
                                    <Dropdown>
                                       <Dropdown.Toggle variant='Secondary' className="btn icon-button btn__lead-link d-flex align-items-center" style={{fontSize:"20px"}} id="dropdown-basic">
                                       <i className="fa fa-ellipsis-v"></i>
                                       </Dropdown.Toggle>
      
                                       <Dropdown.Menu className="dropdown-menu bottom">
                                       <Dropdown.Item className="header__more-item" href="javascript:void(0);" onClick={openEditPlaybookModal}><i translate="no" className="fa fa-pencil me-2"></i> Edit</Dropdown.Item>
                                       {
                                          (mainPost.user_id == user.id || user.login_user_type == 'ADMIN') && (
                                          <Dropdown.Item id="remove-item-knowledgebase" data-id="1" className="header__more-item menu-item-remove font-red-thunderbird" href="javascript:void(0);" onClick={handleDelete}><i translate="no" className="fa fa-trash me-2"></i>Delete </Dropdown.Item>
                                          )
                                       }
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </div>
                                 </div>
                              )
                           }
                        </div>
                        <div className="box-body ">
                           <div className="row h-full">
                              <div id="sideleft" className="side-active sideleft_grey col-md-3">
                              <div id="knowledgebase-sidebar" className="flw-sideleft-wrapper has-border">
                                 <div id="knowledgebase_section__tree" className="tree-view">
                                    <div className="flw-knb-menu">
                                    <ul id="favouriteArticles" className="sortable-exception flw-knb-list">
                                       <li className="flw-knb-item" onClick={() => toggleOpen('fav_id')}>
                                          <div data-toggle="collapse" data-target="#favouriteArticles-list" aria-expanded={(isOpenId == 'fav_id') ? true : false} className={`collapse-trigger ${(isOpenId == 'fav_id') ? 'collapsed' : ''} d-flex align-items-center justify-content-between`}>
                                             <div className="flw-knb-handle margin-right-10 flw-knb-first-lvl flex d-flex align-items-center">
                                                <span id="ui-63ef56c5109675-34247950" className="margin-right-12 d-flex align-items-center">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#8899AE" viewBox="0 0 16 16" className="svgFillColor">
                                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                   </svg>
                                                </span>
                                                <span>Favourites</span>
                                             </div>
                                             {(mainPost.favorite_articles.length > 0) && (
                                                <i className="fas fa-angle-down rotate-icon"></i>
                                             )}
                                          </div>
                                          {
                                             (mainPost.favorite_articles.length > 0) && (
                                                <ul aria-expanded={(isOpenId == 'fav_id') ? true : false} id="36-childs" className={`flw-knb-list collapse-are collapse ${(isOpenId == 'fav_id') ? 'show in' : ''} row_position1 ui-sortable`}>
                                                   {
                                                      mainPost.favorite_articles.map((favorite,favindex) => (
                                                         <li id="44" data-order="1" data-id="44" data-parent-article-id="36" className="flw-knb-item js-knb-menu-item ui-sortable-handle">
                                                            <div data-toggle="collapse" data-target="#36-44-childs" aria-expanded="false" className="collapse-trigger collapsed d-flex align-items-start" >
                                                               <div data-action="drop" className="flw-knb-handle"><a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(favorite.id)} tabIndex="-1" data-sideview-url="false" data-id_article="44" data-id_parent_tree="0" data-toggle="sidecenter">
                                                                  {favorite.title}</a>
                                                               </div>
                                                            </div>
                                                         </li>
                                                      ))
                                                   }
                                                </ul>
                                             )
                                          }
                                       </li>
                                      
                                    </ul> 
                                       <ul className="flw-knb-list row_position ui-sortable">
                                       {
                                          mainPost.articles.map((row,index) => (
                                          <li id="36" data-order="1" data-id="36" data-parent-article-id="0" className="flw-knb-item js-knb-menu-item" onClick={() => toggleOpen(row.id)}>
                                             <div data-toggle="collapse" data-target="#36-childs" aria-expanded={(isOpenId == row.id) ? true : false} className={`collapse-trigger ${(isOpenId == row.id) ? 'collapsed' : ''} d-flex align-items-center justify-content-between`} >
                                                <div data-action="drop" className="flw-knb-handle flw-knb-first-lvl">
                                                   <div>
                                                      <span id="ui-63ef56c510c7e6-70955023 d-flex align-items-center">
                                                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#91A2B6" viewBox="0 0 16 16" className="bi bi-file-earmark-text margin-right-10">
                                                            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                                            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                                                         </svg>
                                                      </span>
                                                   </div>
                                                   <a href="javascript:void(0);" tabIndex="-1" data-sideview-url="false"  data-toggle="sidecenter"  onClick={() => navigateToArticalDetail(row.id)}>
                                                   {row.title} 
                                                   </a>
                                                </div>
                                                {(row.childs.length > 0) && (<i className="fas fa-angle-down rotate-icon"></i>)}
                                             </div>
                                             {
                                                (row.childs.length > 0) && (
                                                   <ul aria-expanded={(isOpenId == row.id) ? true : false} id="36-childs" className={`flw-knb-list collapse-are collapse ${(isOpenId == row.id) ? 'show in' : ''} row_position1 ui-sortable`}>
                                                      {
                                                         row.childs.map((child,childindex) => (
                                                            <li id="44" data-order="1" data-id="44" data-parent-article-id="36" className="flw-knb-item js-knb-menu-item ui-sortable-handle">
                                                               <div data-toggle="collapse" data-target="#36-44-childs" aria-expanded="false" className="collapse-trigger collapsed d-flex align-items-start" >
                                                                  <div data-action="drop" className="flw-knb-handle"><a href="javascript:void(0);"  onClick={() => navigateToArticalDetail(child.id)} tabIndex="-1" data-sideview-url="false" data-id_article="44" data-id_parent_tree="0" data-toggle="sidecenter">
                                                                     {child.title}</a>
                                                                  </div>
                                                               </div>
                                                            </li>
                                                         ))
                                                      }
                                                   </ul>
                                                )
                                             }
                                             
                                          </li>
                                          ))
                                       }

                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              </div>
                              <div id="sidecenter" className="col-md-9">
                              <div className="page__content-wrapper">
                                 <div className="main-header header-bg text-center" style={{background: (mainPost.header_background != '') ? "url("+mainPost.header_background+")" : "#0d6efd"}}>
                                    <div className="flw-kb-main-header-content">
                                       <h1 className="knowledgebase__base-title text-left" style={{color: (mainPost.title_color != '') ? mainPost.title_color : '#000000'}}>
                                       {mainPost.name}
                                       </h1>
                                       <h4 className="text-left margin-bottom-20 flw-kb-main-banner-description" style={{color: (mainPost.title_color != '') ? mainPost.title_color : '#000000'}}>
                                          <div>{mainPost.description}</div>
                                       </h4>
                                       <div className="search-page search-content-1 position-relative">
                                          <div id="searchBox" className="input-group input-group-lg text-left">
                                             <span className="input-group-btn">
                                                <button id="btn_knowledgebase_search" type="button" className="btn flw-search-addon-btn no-space rounded-4">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" fill="#91a2b6" viewBox="0 0 16 16" className="bi bi-search">
                                                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                                   </svg>
                                                </button>
                                             </span>
                                             <input id="tableSearchInput" placeholder=" Search" type="text" className="form-control" onKeyUp={searchInputChange} /> { (isShowSpinner) && (<i className="fa fa-circle-o-notch fa-spin"></i>)}
                                          </div>
                                          {(searchData.length > 0) && (
                                           <div className="autocomplete">
                                             {searchData.map((row,index) => (
                                                <ul  key={index} className="autocomplete-results" >
                                                        <li  className="knb-search-item"><a className="dropdown-item knb-search-link" href="javascript:void(0)" onClick={() => navigateToArticalDetail(row.id)}><h6 className="">{row.title}</h6><div className="no-margin knb-search-content" dangerouslySetInnerHTML={ { __html: row.content}} /></a>  
                                                    </li>
                                                </ul>
                                             ))}
                                         
                                            </div>
                                          )} 
                                       </div>
                                    </div>
                                 </div>
                                 <div className="container-fixed">
                                    <div className="item-base-content">
                                    {
                                       mainPost.articles.map((row,index) => (
                                       <div>
                                          <a href="javascript:void(0);" className="knowledgebase-articles-news"  onClick={() => navigateToArticalDetail(row.id)}>
                                             <h4>
                                                {row.title}  
                                             </h4>
                                          </a>
                                          {
                                             (row.childs.length > 0) && (
                                                <ul className="childs-list-container list-unstyled margin-top-10">
                                                   {
                                                      row.childs.map((child,childindex) => (
                                                         <li className="childs-list-item margin-bottom-5 padding-5 position-relative">
                                                            <a href="javascript:void(0);" onClick={() => navigateToArticalDetail(child.id)} className="childs-list-item-link">
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#91A2B6" viewBox="0 0 16 16" className="bi bi-file-earmark-text margin-right-12">
                                                                  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                                                                  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                                                               </svg>
                                                               {child.title}  
                                                            </a>
                                                         </li>
                                                      ))
                                                   }
                                                </ul>
                                             )
                                          }
                                       </div>
                                       ))
                                    }
                                    </div>
                                 </div>
                              </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               )
            }
            <PlaybookUpdateModal show={showEditModal} handleCloseModal={handleEditCloseModal} fetchData={fetchData} playbookModalId={id} userId={user.id} />
            
            {
               (!loading) && (
                  <PlaybookArticleCreateModal show={showModal} handleCloseModal={handleCloseModal} fetchData={fetchData} playbookModalId={mainPost.id} />
               )
            }
            
          </Container>
        </div>
      </div>
  );
};
export default PlaybookArticle;