import React,  { useContext } from "react";
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../contexts/theme-context';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from "react-router-dom";
import * as myConstent from '../contexts/ConstContext';

import { ToastContainer, toast, TypeOptions } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

export const Navigation = (props) => {
  const { theme, setTheme } = useContext(ThemeContext);
  const {logout} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const location = useLocation();
  const curntUrl = location.pathname.split('/');
  const current_path = curntUrl[1];

  const navigate = useNavigate();
  const hreflink = 'javascript:void(0)';
  const hrefHomelink = '/';

  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };
  
  const navigateToHref = (href) => {
    navigate(href);
  };
 

  const handleLogout = async () => {
    const res = await logout({user_id:user.id});
  }

  return (
    <nav id="menu" className={"navbar navbar-expand-lg navbar-light navbar-fixed-top " + (current_path === 'reviewer-assessment' ? "d-none" : "")  }>
      <div className="container">
          <div className="navbar-header d-xs-flex d-sm-flex d-md-flex">

            <a className="navbar-brand page-scroll" href={hrefHomelink}>
              <img src="/img/main-logo.png" alt="logo" className="img-fluid main_logo" />
            </a>{" "}
            <div className="toggle-btn-section d-xs-block d-sm-block d-md-block d-lg-none">
              <input type="checkbox" name="checkbox" id="toggle_checkbox" onChange={handleThemeChange} checked={theme === 'dark'} />
              <label htmlFor="toggle_checkbox">
                <div id="star">
                  <div className="star" id="star-1">🔆</div>
                  <div className="star" id="star-2">🔆</div>
                </div>
                <div id="moon"></div>
              </label>
            </div>
            <button className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#bs-example-navbar-collapse-1"            
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" 
            aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            
          </div>
          {(current_path !== 'onboarding-playbook') && (
            <>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="navbar-nav navbar-right">
              <li className={"nav-item " + (location.hash == '#features' ? 'activemenu' : '')}>
                <a href="/#features" className="nav-link page-scroll"> 
                  Features
                </a>
              </li>
              <li className={"nav-item " + (location.pathname == '/skills' ? 'activemenu' : '')}>
                <a href="javascript:void(0)" onClick={() => navigateToHref("/skills")} className="nav-link page-scroll">
                  Roadmaps
                </a>
              </li>
              <li className={"nav-item " + (location.pathname == '/pricing' ? 'activemenu' : '')}>
                <a href="javascript:void(0)" onClick={() => navigateToHref("/pricing")} className="nav-link page-scroll">
                  Pricing
                </a>
              </li>
              <li className={"nav-item " + (location.hash == '/blogs' ? 'activemenu' : '')}>
                <a href="/blogs" className="nav-link page-scroll">
                  Blogs
                </a>
              </li>
              {!user && (
              <li className={"nav-item " + (location.pathname == '/login' ? 'activemenu' : '')}>
                <a href="/login" className="nav-link page-scroll">
                  Login
                </a>
              </li>
              )}
              { user && (
              <>
                <li className={"nav-item dropdown " + ((location.pathname == '/profile' || location.pathname == '/profile-history') ? 'activemenu' : '')}>
                  <a href={hreflink}
                    id="navbarDropdown" 
                    className="nav-link page-scroll dropdown-toggle" 
                    data-bs-toggle="dropdown"
                    aria-expanded={false}
                    role="button"
                    >
                    Account
                  </a>
                    <ul className="dropdown-menu">
                      {/* <li className={((location.pathname == '/profile') ? 'activemenu' : '')}>
                        <a href={hreflink} onClick={navigateToProfile} className="dropdown-item page-scroll">
                          Profile
                        </a>
                      </li> */}
                      {(user.login_user_type !== 'ADMIN') && (<>
                      <li className={((location.pathname == '/profile') ? 'activemenu' : '')}>
                        <a href={hreflink} onClick={() => navigateToHref("/profile")} className="dropdown-item page-scroll">
                          Home
                        </a>
                      </li>
                      <li className={((location.pathname == '/track-progress') ? 'activemenu' : '')}>
                        <a href={hreflink} onClick={() => navigateToHref("/track-progress")} className="dropdown-item page-scroll">
                          Track Progress
                        </a>
                      </li>
                      <li className={((location.pathname == '/profile-favorite') ? 'activemenu' : '')}>
                        <a href={hreflink} onClick={() => navigateToHref("/profile-favorite")} className="dropdown-item page-scroll">
                          Favorite
                        </a>
                      </li>
                      <li className={((location.pathname == '/profile-history') ? 'activemenu' : '')}>
                        <a href={hreflink} onClick={() => navigateToHref("/profile-history")} className="dropdown-item page-scroll">
                          History
                        </a>
                      </li>
                      <li className={((location.pathname == '/profile-settings') ? 'activemenu' : '')}>
                        <a href={hreflink} onClick={() => navigateToHref("/profile-settings")} className="dropdown-item page-scroll">
                          Settings
                        </a>
                      </li></>)}
                      {(user.login_user_type === 'ADMIN') && (
                        <li className={((location.pathname == '/queries') ? 'activemenu' : '')}>
                          <a href={hreflink} onClick={() => navigateToHref("/queries")} className="dropdown-item page-scroll">
                          Support Tickets
                          </a>
                      </li>
                      )}
                      <li>
                        <a href={hreflink} className="dropdown-item page-scroll" onClick={handleLogout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                </li>
                {/* <li>
                  {
                    (teamSwitchLoading) ?
                    (<Dropdown data-bs-theme="primary" className="header_dropdown">
                      <Dropdown.Toggle variant="primary">
                        <span className="team_role">Loading...</span>
                      </Dropdown.Toggle>
                    </Dropdown>) 
                    :
                      (teams.length > 0) &&
                      (<Dropdown data-bs-theme="primary" className="header_dropdown">
                        <Dropdown.Toggle variant="primary">
                          {(currentTeams !== undefined) && <> <span className="team_role">{currentTeams.name}</span> </> }
                        </Dropdown.Toggle>
                        
                        <Dropdown.Menu className="bg-primary">
                           {teams.map((team, index) => (
                              <a className=" border-bottom text-white dropdown-item" href="javascript:void(0);" onClick={e => onChangeInput(e, team.id, 1)} data-rr-ui-dropdown-item=""><h6><strong>{team.name}</strong></h6> <span className="team_role">({team.parent_name}) 
                              <br/>({team.role_title})</span></a>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>) 
                    }
                </li> */}
              </>
              )}
            </ul>
          </div>
          </>
          )}
          <div className="toggle-btn-section d-xs-none d-sm-none d-md-none d-lg-block">
            <input type="checkbox" name="checkbox" id="toggle_checkbox" onChange={handleThemeChange} checked={theme === 'dark'} />
              <label htmlFor="toggle_checkbox">
                <div id="star">
                  <div className="star" id="star-1">🔆</div>
                  <div className="star" id="star-2">🔆</div>
                </div>
                <div id="moon"></div>
              </label>
          </div>
      </div>
      <ToastContainer position="top-right" autoClose={myConstent.TOASTTIMELIMIT} newestOnTop hideProgressBar={false} theme="colored" />
    </nav>
  );
};
