import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import {UserContext} from '../contexts/UserContext';
import "react-pro-sidebar/dist/css/styles.css";

const TeamSidebar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState(null);
  const [currentTeams, setCurrentTeams] = useState({});
  const [teams, setTeams] = useState([]);
  const [isMasterAdmin, setMasterAdmin] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);
  const [invitedUser, setInvitedUser] = useState(null);
  const { getTeamsOfOrganizationByTeamId, getTeamDetails, logout, removeMemberToOrganization} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const handleRedirectManageTeam = (id) => {
    navigate(`/createorg/addteams/${id}`);
  };


  const handleHrefRedirect = (href,id) => {
    navigate(href+id);
  };
  
  const location = useLocation();
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      setLoading(true);
      const response = await getTeamsOfOrganizationByTeamId(id, (user === null ? "" : user.id));
      if(response){
        if(response.token_message){
          console.error(response.token_message);
          logout();
        }else{
          setTeams(response.data);
          setOrganizationId(response.organization_id);
          setLoading(false);
        }
      }
      else {
        console.error(response);
      }
  
  
      const team_response = await getTeamDetails(id, (user === null ? "" : user.id));
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          setCurrentTeams(team_response.data);
          setMasterAdmin(team_response.is_master_admin);
          setIsAdmin(team_response.is_admin);
          setInvitedUser(team_response.is_invited_user);
        }
      }
      else {
        console.error(response);
      }
    };
    fetchData();
  }, []);


  const handleProfileTeamsClick = (e) => {
    navigate(`/profile-teams`);
  };

  
  const handleLeaveManageTeam = async () => {
    setLoading(true);
    const response = await removeMemberToOrganization(id, (user === null ? "" : user.email));
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        navigate(`/profile-teams`);        
      }
    }
    else {
      console.error(response);
    }
  };

  return (
    <aside className="py-10 d-md-block border-end process-sidebar team-sidebar h-100">
      {/* {
        (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.teams.add == 1) && ( */}
          <a className="btn btn-dark d-flex me-2 mb-3" href="javascript:void(0);" onClick={() => handleRedirectManageTeam(organizationId)}><span>Create a New Team</span></a>
        {/* )
      } */}
      
      {
        (invitedUser != null) && (
          <a className="btn btn-danger d-flex me-2" href="javascript:void(0);" onClick={() => handleLeaveManageTeam()}><span>Leave Team</span></a>
        )
      }

      <div className="relative mr-2 d-flex align-items-center">
        <div className="back-btn me-1">
          <span className="mb-2 d-flex align-items-center justify-content-between text-xs uppercase text-gray-400"><span>&nbsp;</span></span>
          <button onClick={() => handleProfileTeamsClick()} className="btn btn-secondary" title="back"><i className="fa fa-chevron-left"></i></button>
        </div>
        <div className="">
          {(!loading) && (
              (isMasterAdmin === 1) ? (
                <>

                <span className="mb-2 d-flex align-items-center justify-content-between text-xs uppercase text-gray-400"><span>Choose Team</span></span>
                <Dropdown data-bs-theme="dark">
                  <Dropdown.Toggle id="dropdown-button-dark-example1" className="d-flex align-items-center justify-content-between sidebar_team_name_btn" variant="secondary">
                    <div><img src="/img/default-avatar.png" alt="" className="h-4 w-4 rounded-full object-cover" /> <span className="">{currentTeams.team_name}</span></div>
                  </Dropdown.Toggle>
                  
                  <Dropdown.Menu className="bg-dark">
                    {teams.map((row) => (
                      row.id !== id && (
                        <a href="javascript:void(0);" onClick={() => handleHrefRedirect("/createorg/dashboard/" , row.id)} data-rr-ui-dropdown-item="" className="text-white dropdown-item">{row.team_name}</a>
                      )
                    ))}


                    <Dropdown.Divider />
                    {( (organizationId !== null)&& (
                    <Dropdown.Item href="javascript:void(0);" >
                    <a className="btn btn-light d-flex p-1" href="javascript:void(0);" onClick={() => handleRedirectManageTeam(organizationId)}><span>+</span><span>Create a New Team</span></a>
                    </Dropdown.Item>
                    ))}
                            
                  </Dropdown.Menu>
                </Dropdown>
                </>
              ) : (
                <>
                <span className="mb-2 d-flex align-items-center justify-content-between text-xs uppercase text-gray-400"><span>&nbsp;</span></span>
                <button type="button" className="d-flex align-items-center justify-content-between btn btn-secondary"><div><img src="/img/default-avatar.png" alt="" className="h-4 w-4 rounded-full object-cover" /> <span className="ms-2">{currentTeams.team_name}</span></div></button>                  
                </>
              )
          )}
        </div>
      </div>
      <hr className="my-4" />
      <ul className="nav flex-column">
        {/* <li className="nav-item">
          <Link className="nav-link " aria-current="page" href="javascript:void(0);"><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="M3 3v18h18"></path><path d="m19 9-5 5-4-4-3 3"></path></svg>Progress</span></Link>
        </li> */}
        <li className={"nav-item " + (location.pathname == '/createorg/chooseroadmaps/'+ id ? 'activemenu' : '')}>
          <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/createorg/chooseroadmaps/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"></polygon><line x1="9" x2="9" y1="3" y2="18"></line><line x1="15" x2="15" y1="6" y2="21"></line></svg>Roadmaps</span></a>
        </li>

        <li className={"nav-item " + (location.pathname == '/organization/onboard/'+ id ? 'activemenu' : '')}>
          <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/organization/onboard/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"></polygon><line x1="9" x2="9" y1="3" y2="18"></line><line x1="15" x2="15" y1="6" y2="21"></line></svg>Onboarding</span></a>
        </li>
        
        {( (isAdmin === 1) && (
          <>
          <li className={"nav-item " + (location.pathname == '/createorg/add-members/'+ id ? 'activemenu' : '')}>
            <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/createorg/add-members/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>Members</span></a>
          </li> 

          <li className={"nav-item " + (location.pathname == '/organization/progress/'+ id ? 'activemenu' : '')}>
            <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/organization/progress/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="M3 3v18h18"></path><path d="m19 9-5 5-4-4-3 3"></path></svg>Progress</span></a>
          </li> 

          {/* <li className={"nav-item " + (location.pathname == '/organization/onboard-playbook/'+ id ? 'activemenu' : '')}>
            <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/organization/onboard-playbook/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512" className="me-2 h-4 w-4"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg> Onboard Playbook</span></a>
          </li>  */}
          
          {/* <li className={"nav-item " + (location.pathname == '/organization/history/'+ id ? 'activemenu' : '')}>
            <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/organization/history/" , id)}><span className="d-flex align-items-center"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" className="me-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg"><path d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"></path></svg>History</span></a>
          </li>  */}

          {/* <li className="nav-item">
            <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/organization/chart_progress/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="M3 3v18h18"></path><path d="m19 9-5 5-4-4-3 3"></path></svg>Chart Progress</span></a>
          </li>  */}
        
          <li className={"nav-item " + (location.pathname == '/createorg/team-settings/'+ id ? 'activemenu' : '')}>
            <a className="nav-link" href="javascript:void(0);" onClick={() => handleHrefRedirect("/createorg/team-settings/" , id)}><span className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="me-2 h-4 w-4"><path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"></path><path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>Settings</span></a>
          </li>
          </>
        ))}
      </ul>
      {/* <hr className="my-4" /> */}
      {/* <Button variant="outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="me-2 h-4 w-4"><path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"></path></svg> Send Feedback</Button> */}
    </aside>
  );
};

export default TeamSidebar;