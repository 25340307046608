import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Container, Modal, Accordion } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";
import TeamSidebar from "./teamSidebar";
import RoadmapModal from "./roadmapModal";

const ChooseRoadmaps = () => {

  const {logout, getRoadmap, getRoadmapByTeamId, addRoadmapsToOrganization, getTeamDetails, deleteRoadmap, getSkillLevels, importRoadmaps, getSkills, bulkAddEditRoadmapSkillsAndQuestions, copyCustomRoadmap, showToast} = useContext(UserContext);
  
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [allroadmaps, setAllroadmaps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRoadmaps, setSelectedRoadmaps] = useState([]);
  const [customRoadmaps, setCustomRoadmaps] = useState([]);
  const [skillLevel, setSkillLevel] = useState([]);
  const [invitedUser, setInvitedUser] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);

  const [showRoadmapUploadModal, setShowRoadmapUploadModal] = useState(false);
  const [file, setFile] = useState(null);

  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [skills, setSkills] = useState([]);
  const [selectedRoadmapIdForBulkEdit, setSelectedRoadmapIdForBulkEdit] = useState(null);

  const [showCopyCustomRoadmapModal, setShowCopyCustomRoadmapModal] = useState(false);
  const [copyCustomRoadmapFormData, setCopyCustomRoadmapFormData] = useState({
    roadmap_name:'',
    roadmap_description:'',
    roadmap_id:0
  });


  const fetchData = async () => {

    setLoading(true);

    
    const team_response = await getTeamDetails(id, (user === null ? "" : user.id));
    if(team_response){
      if(team_response.token_message){
        console.error(team_response.token_message);
        logout();
      }else{
        setInvitedUser(team_response.is_invited_user);
        setSelectedRoadmaps(team_response.data.team_roadmaps);
        setLoading(false);
      }
    }
    else {
        showToast(team_response.message, true);
    }
    setLoading(true);

    var all_roadmap = [];

    const response = await getRoadmap();
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        all_roadmap = [...new Set(response.data.map((Val) => Val))];
        setAllroadmaps(all_roadmap);

        // let temcus = [];
        // all_roadmap.forEach((row, index) => {
        //   if(row.roadmap_type === "Custom")
        //   temcus.push(row);
        // });
        // setCustomRoadmaps(temcus);
        setLoading(false);
      }
    }
    else {
        showToast(response.message, true);
    }
    
    setLoading(true);
    const response1 = await getRoadmapByTeamId(id, "ORGANIZATION");
    if(response1){
      if(response1.token_message){
        console.error(response1.token_message);
        logout();
      }else{
        var res = [...new Set(response1.data.map((Val) => Val))];
        let arr = all_roadmap.concat(res);
        setAllroadmaps(arr);
        setCustomRoadmaps(res);
        setLoading(false);
      }
    }
    else {
        showToast(response1.message, true);
    }

    setLoading(true);
    let response2 = await getSkillLevels();
    if(response2){
      if(response2.token_message){
        console.error(response2.token_message);
        logout();
      }else{
        setSkillLevel(response2.data);
        setLoading(false);
      }
    }
    else {
        showToast(response2.message, true);
    }

    
    

  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
      const { value, checked } = e.target;
      let  tempSelectedRoadmaps  = selectedRoadmaps;

      if (checked) {
          setSelectedRoadmaps([...tempSelectedRoadmaps, value]);
      }
      else {
          let newarray = tempSelectedRoadmaps.filter(
              (e) => e !== value
          );
          setSelectedRoadmaps(newarray);
      }
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      let data = await addRoadmapsToOrganization({team_id:id,organization_roadmaps:(JSON.stringify(selectedRoadmaps))});
      if(data.status){
          e.target.reset();
          navigate(`/createorg/add-members/${id}`);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };

  
  const handleStartAssessment = (roadmap_slug, roadmapId) => {
    navigate(`/assessment/${roadmap_slug}`, {
        state: {
            roadmapId: roadmapId,
            teamId: id,
            teamType: 'ORGANIZATION'
        },
    });
  };

  const handleStartAssessmentBehalfTeam = (roadmap_slug, roadmapId) => {
    navigate(`/team-assessment/${roadmap_slug}`, {
        state: {
            roadmapId: roadmapId,
            teamId: id
        },
    });
  };

  const handleSkillsFlow = (roadmap_slug, roadmapId) => {
    navigate(`/skills-flow/${roadmap_slug}/${id}`, {
        state: {
            roadmapId: roadmapId,
            teamId: id
        },
    });
  };

  const openRoadmapModal = async (e) => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    fetchData();
  };
  const handleDeleteRoadmap = async (id) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this record?"
    )
    if (confirmBox === true) {
      let data = await deleteRoadmap(id);
      if(data.status){
          fetchData();
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
    }
  };


  const handleEditRoadmap = async (id, roadmap_slug) => {
    navigate(`/roadmap/draw/${roadmap_slug}`, {
      state: {
          roadmapId: id,
          path: ''
      },
  });
  };



  const openRoadmapUploadModal = async (e) => {
    setShowRoadmapUploadModal(true);
  }

  const handleUploadRoadmapCloseModal = () => {
    setFile(null);
    setShowRoadmapUploadModal(false);
  }

  
  const onChangeInputFile = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  const uploadRoadmapFile = async (e) => {
    e.preventDefault();
    if (file != null) {

      let path = window.location.pathname.split("/");
      path = path[1];

      const formFileData = new FormData();
      formFileData.append("file", file);

      var from = 'STUDYGROUP';
      if(path === "createorg") {
        from = 'ORGANIZATION';
      }
      formFileData.append("team_id", id);
      formFileData.append("user_id", user.id);
      formFileData.append("from", from);

      let data = await importRoadmaps(
        {
          file : formFileData
        }
      );
      if(data.status){
          showToast(data.message, false);
          setFile(null);
          setTimeout(
          () => {
            setShowRoadmapUploadModal(false);
            fetchData();
          },1000);
      }
      else if(!data.status && data.message){
        showToast(data.message, true);
      }
    } else {
      showToast('Please choose file!', true);
      return false;
    }
  }
  const handleBulkEdit = async (id) => {
    setShowBulkEditModal(true);
    setSelectedRoadmapIdForBulkEdit(id);

    const response = await getSkills(id);
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else if(response.data){
          var result = response.data;
          var skillResult = result.map((ele,index) => {
            if (ele.questionDetails.length === 0) {
              var questions = ele.questionDetails;
              questions.push({
                skills_question_id: 0,
                skill_question:''
              });
              return {
                ...ele,
                questionDetails: questions
              };
            }else{
              return ele;
            } 
          });
          setSkills(skillResult);
      } 
  };

  const handleCloseBulkEditModal = (e) => {
      setShowBulkEditModal(false);
      setSkills([]);
  }

  const saveBulkEditModal = async (e) => {
    e.preventDefault();

    let data = await bulkAddEditRoadmapSkillsAndQuestions(
      {
        skills:skills,
        roadmap_id:selectedRoadmapIdForBulkEdit
      }
    );
    if(data.status){
        showToast(data.message, false);
        setTimeout(() => {
          setSkills([]);
          handleBulkEdit(selectedRoadmapIdForBulkEdit);
        },2000);
    }
    else if(!data.status && data.message){
      showToast(data.message, true);
    }
  }

  const onChangeSkillInput = (e) => {
      var skill_id = e.target.attributes['data-skill_id'].value;

      const nextShapes = skills.map((ele,index) => {
        if (ele.skill_id === skill_id) {
          return {
            ...ele,
            [e.target.name]: e.target.value
          };
        } else if ('NEW@'+index === skill_id) {
          return {
            ...ele,
            [e.target.name]: e.target.value
          };
        } else {
          return ele;
        }
      });
      setSkills(nextShapes);
  };

  const onChangeSkillQuestionInput = (e) => {
      var skill_id = e.target.attributes['data-skill_id'].value;
      var question_id = e.target.attributes['data-question_id'].value;

      const nextShapes = skills.map((ele,index) => {
        if (ele.skill_id === skill_id) {
          var questions = ele.questionDetails.map((que,qindex) => {
            if (que.skills_question_id === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else if ('NEW@'+qindex === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else {
              return que;
            }
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else if ('NEW@'+index === skill_id) {
          var questions = ele.questionDetails.map((que,qindex) => {
            if (que.skills_question_id === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else if ('NEW@'+qindex === question_id) {
              return {
                ...que,
                [e.target.name]: e.target.value
              };
            } else {
              return que;
            }
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else {
          return ele;
        }
      });
      setSkills(nextShapes);
  };

  const addSkillRow = () => { 
      var temp = skills;
      setSkills([...temp, {
        skill_id:0,
        skill_name:'',
        questionDetails:[{
          skills_question_id: 0,
          skill_question:''
        }]
      }]);
  };

  const deleteNewAddedSkillRow = (main_index) => {
    if(skills.length > 1) {
      var next = [];
      skills.map((ele,index) => {
        if (index !== main_index) {
          next.push(ele);
        } 
      });
      setSkills(next);
    } else {
      setSkills([]);
    }
    
  };

  const addQuestionRow = (skill_id) => {

      const nextShapes = skills.map((ele,index) => {
        if (ele.skill_id === skill_id) {
          var questions = ele.questionDetails;
          questions.push({
            skills_question_id: 0,
            skill_question:''
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else if ('NEW@'+index === skill_id) {
          var questions = ele.questionDetails;
          questions.push({
            skills_question_id: 0,
            skill_question:''
          });
          return {
            ...ele,
            questionDetails: questions
          };
        } else {
          return ele;
        }
      });
      setSkills(nextShapes);
  }
  const removeQuestionRow = (skill_id, question_id) => {
    const nextShapes = skills.map((ele,index) => {
      if (ele.skill_id === skill_id) {
        var questions = [];
        ele.questionDetails.map((que,qindex) => {
          if (que.skills_question_id !== question_id && ('NEW@'+qindex) !== question_id) {
            questions.push(que);
          } 
        });
        return {
          ...ele,
          questionDetails: questions
        };
      } else if ('NEW@'+index === skill_id) {
        var questions = [];
        ele.questionDetails.map((que,qindex) => {
          if (que.skills_question_id !== question_id && ('NEW@'+qindex) !== question_id) {
            questions.push(que);
          } 
        });
        return {
          ...ele,
          questionDetails: questions
        };
      } else {
        return ele;
      }
    });
    setSkills(nextShapes);
  }

  const onChangeInput = (e) => {
    setCopyCustomRoadmapFormData({
      ...copyCustomRoadmapFormData,
      [e.target.name]:e.target.value
    });
  };

  const openCopyCustomRoadmapModal = async (roadmap_id,roadmap_name,roadmap_description) => {
    setShowCopyCustomRoadmapModal(true);
    setCopyCustomRoadmapFormData({
      roadmap_name:roadmap_name,
      roadmap_description:roadmap_description,
      roadmap_id:roadmap_id
    })
  }

  
  const closeCopyCustomRoadmapModal = async (e) => {
    setShowCopyCustomRoadmapModal(false);
    setCopyCustomRoadmapFormData({
      roadmap_name:'',
      roadmap_description:'',
      roadmap_id:0
    })
  }

  const saveCopyCustomRoadmapModal = async () => {
    if(copyCustomRoadmapFormData.roadmap_name === "") {
      showToast("Please enter roadmap name", true); return false;
    }

    let path = window.location.pathname.split("/");
    path = path[1];
    var team_type = 'STUDYGROUP';
    if(path === "createorg") {
      team_type = 'ORGANIZATION';
    }
    let response = await copyCustomRoadmap({id:copyCustomRoadmapFormData.roadmap_id,roadmap_name:copyCustomRoadmapFormData.roadmap_name,roadmap_description:copyCustomRoadmapFormData.roadmap_description,team_type:team_type,team_id:id,user_id:(user === null ? "" : user.id)});
    if(response.token_message){
        console.error(response.token_message);
        logout();
      }else if(response.data){
          var result = response.data;
          alert('The Roadmap is now available under Custom Roadmaps.');
          closeCopyCustomRoadmapModal();
          fetchData();
      } 
  }

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <Row>  
                <div className="settings-card">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-2">
                      <TeamSidebar />
                    </div>

                    <div className="col-sm-12 col-md-7 col-lg-8 col-xl-10">
                    {
                      (loading) ? <h6 className="text-center">Loading...</h6> :
                      <div className="settings-card">
                      <Form onSubmit={handleSubmit} >
                        <div className="settings-card-header">{(invitedUser === null) ? 'Choose Multiple Roadmaps' : 'Roadmaps'}</div>
                        <div className="settings-card-body roadmap-list">
                            <Accordion defaultActiveKey="1" flush>
                            {skillLevel.map((level) => (
                            <Accordion.Item eventKey={level.id}>
                            <Row>
                              <div className="col-12">
                              <Accordion.Header>{(level.roadmap_type === 'Custom') ? 'Custom Roadmaps' :  level.roadmap_type}</Accordion.Header>
                              </div>
                               <Accordion.Body className="row">
                              {
                              allroadmaps.filter(newVal => (newVal.roadmap_type === level.roadmap_type)).map((row,index) => (
                                (invitedUser === null) ? 
                                  <div className="col-sm-4 col-md-6 col-lg-4">
                                      <Form.Check
                                          inline
                                          name="roadmaps[]"
                                          value={row.roadmap_id}
                                          type="checkbox"
                                          id={"checkbox_"+row.roadmap_id}
                                          className="roadmap_checkboxs"
                                          onChange={
                                              handleChange
                                          }
                                          checked={selectedRoadmaps.includes(row.roadmap_id) ? 'checked' : ''}
                                          label={row.roadmap_name}
                                      /> 
                                  </div>
                                  : 
                                  ((selectedRoadmaps.includes(row.roadmap_id)) ?
                                  <Row className="pe-0">
                                  <div className="col-6">
                                      <Form.Label htmlFor={"checkbox_"+row.roadmap_id}>{row.roadmap_name}</Form.Label>
                                  </div>
                                  <div className="col-6 pe-0">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <button type='button' className='btn btn-sm btn-success' onClick={() => handleStartAssessment(row.roadmap_slug,row.roadmap_id)} >Start Assessment</button>
                                    </div>
                                  </div>
                                  </Row> : '' )
                              ))}
                              {
                              allroadmaps.filter(newVal => (newVal.roadmap_type === level.roadmap_type)).map((row,index) => (
                                (invitedUser === null) ? 
                                  ((selectedRoadmaps.includes(row.roadmap_id)) ?
                                  <Row className="pe-0 mb-sm-2">
                                  <div className="col-6">
                                      <Form.Label htmlFor={"checkbox_"+row.roadmap_id}>{row.roadmap_name}</Form.Label>
                                  </div>
                                  <div className="col-6 pe-0">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <button type='button' className='btn btn-sm btn-warning ms-2 mb-2' onClick={() => openCopyCustomRoadmapModal(row.roadmap_id,row.roadmap_name,row.roadmap_description)} >Copy to Edit</button>
                                      <button type='button' className='btn btn-sm btn-success mx-2 mb-2' onClick={() => handleStartAssessmentBehalfTeam(row.roadmap_slug,row.roadmap_id)} title='Start Assessment On Behalf Of Team Members' >Start Assessment</button>
                                      <button type='button' className='btn btn-sm btn-primary ms-2 mb-2' onClick={() => handleSkillsFlow(row.roadmap_slug,row.roadmap_id)} >Skills Flow</button>
                                    </div>
                                  </div>
                                  </Row> : '' )
                                  : 
                                  ''
                              ))}
                              </Accordion.Body>
                            </Row>
                            </Accordion.Item>
                            ))}
                            {
                              (invitedUser === null) ? <Button variant="primary mt-3" type="submit">Save</Button> : ''
                            }
                           <Accordion.Item eventKey={'001'} style={{marginTop:'10px'}}>
                            <Row>
                              <div className="col-12">
                              <Accordion.Header>Add/Edit Custom Roadmaps</Accordion.Header>
                              </div>
                               <Accordion.Body className="row">
                                <Row>
                                <div className="col-12">
                                {
                                (invitedUser === null) ? 
                                  <div>
                                    <div className="mt-5">
                                      <button type="button" className="add-btn d-block w-100 rounded-3 border border-dashed bg-transparent py-2 text-sm hover:border-gray-600 hover:bg-gray-50 focus:outline-0" onClick={openRoadmapModal}>+ Add new Roadmap</button>
                                      <Row className='mt-2'>
                                        <Col md={6}> 
                                        </Col>
                                        <Col md={6}> 
                                        <button className="btn btn-warning pull-right" style={{marginRight:'2px'}} type="button" onClick={openRoadmapUploadModal}>Import Roadmaps</button>
                                        </Col>
                                      </Row>
                                    </div> 
                                    {
                                    (customRoadmaps.length > 0) ? 
                                    <div className="mt-5">
                                      <div className="mb-2 d-flex align-items-center justify-content-between">
                                        <h3 className="d-flex w-100 align-items-center justify-content-between text-xs uppercase text-gray-400">
                                          <span className="d-flex">Custom Roadmaps</span>
                                          <span className="normal-case">Total {customRoadmaps.length} roadmap(s)</span>
                                        </h3>
                                      </div>
                                      {
                                        customRoadmaps.map((row,index) => (
                                        <div className="d-flex flex-column divide-y rounded-3 border">
                                          <div className="d-flex lign-items-center justify-content-between p-2">
                                            <div className="mb-3 d-grid mb-sm-0">
                                              <p className="mb-2 truncate text-base font-medium leading-tight text-black">{row.roadmap_name}</p>
                                            </div>
                                            <div className="me-1 d-flex align-items-center justify-content-start sm:justify-end">
                                              <button type="button" onClick={() => handleDeleteRoadmap(row.roadmap_id)} className="ms-2 d-flex align-items-center gap-2 rounded-3 border border-gray-300 bg-white px-2 py-2 text-xs text-dark hover:bg-gray-50 focus:outline-none">Delete</button>
                                              <button type="button" onClick={() => handleEditRoadmap(row.roadmap_id, row.roadmap_slug)} className="ms-2 d-flex align-items-center gap-2 rounded-3 border border-gray-300 bg-white px-2 py-2 text-xs text-dark hover:bg-gray-50 focus:outline-none">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="d-inline-block h-4 w-4"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path></svg>Skill Flow Edit</button>
                                              <button type="button"  className='ms-2 d-flex align-items-center gap-2 rounded-3 border border-gray-300 bg-white px-2 py-2 text-xs text-dark hover:bg-gray-50 focus:outline-none' style={{'marginBottom':'2px'}} onClick={() => handleBulkEdit(row.roadmap_id)}>
                                                Bulk Text Edit
                                              </button>
                                              {/* <a href="#" className="ms-2 d-flex align-items-center gap-2 rounded-3 border border-gray-300 bg-white px-2 py-2 text-decoration-none text-xs focus:outline-none" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="d-inline-block h-4 w-4"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" x2="21" y1="14" y2="3"></line></svg>
                                                Visit
                                              </a> */}

                                            </div>
                                          </div>
                                        </div> 
                                    ))}
                                    </div> : ""
                                    }
                                  </div>
                                  : 
                                  <>
                                  
                                    {
                                    (customRoadmaps.length > 0) ? 
                                    <>
                                      <h3 className="border-bottom py-3">Custom Roadmaps </h3>
                                      {
                                        customRoadmaps.map((row,index) => (
                                          <Row>
                                        <div className="col-6">
                                            <Form.Label htmlFor={"checkbox_"+row.roadmap_id}>{row.roadmap_name}</Form.Label>
                                        </div>
                                        <div className="col-6">
                                          <div className="d-flex align-items-center justify-content-end">
                                            <button type="button" className='btn btn-sm btn-success' onClick={() => handleStartAssessment(row.roadmap_slug,row.roadmap_id)} >Start Assessment</button>
                                          </div>
                                        </div>
                                        </Row>
                                      )) 
                                    } </>
                                    : ""
                                    }
                                  </>
                                }
                                </div>
                                </Row>
                              </Accordion.Body>
                            </Row>
                          </Accordion.Item>
                          </Accordion>  

                         
                        </div>
                        </Form>
                      </div>
                    }
                    </div>
                  </div>
                  <RoadmapModal show={showModal} handleCloseModal={handleCloseModal} /> 
                  <Modal show={showBulkEditModal} size='lg' className="bulkEditModal" centered onHide={handleCloseBulkEditModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add/Edit Skills</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <table className="table align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th style={{width:'50px'}}></th>
                            <th >Skill Name</th>
                            <th >Skill Questions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (skills.length > 0) && (
                            skills.map((skill, sindex) => (
                              skill.questionDetails.map((question, qindex) => (
                                <>
                                <tr key={qindex}>
                                    {(qindex === 0) &&
                                      (<>
                                      <td style={{width:'50px'}} rowSpan={skill.questionDetails.length}><div className="question_box">
                                          <button className='btn btn-danger rounded-circle' onClick={() => deleteNewAddedSkillRow(sindex)}><i className="fa fa-remove"></i></button></div>
                                      </td>
                                      <td  scope="row" rowSpan={skill.questionDetails.length}>
                                        <input name='skill_name' data-skill_id={(skill.skill_id === 0) ? ('NEW@'+sindex) : skill.skill_id} data-question_id={(question.skills_question_id === 0) ? ('NEW@'+qindex) : question.skills_question_id}  type='text' value={skill.skill_name} onChange={onChangeSkillInput} />
                                      </td></>)
                                    }
                                  
                                  <td className='question_box'>
                                    <input name='skill_question' data-skill_id={(skill.skill_id === 0) ? ('NEW@'+sindex) : skill.skill_id}  data-question_id={(question.skills_question_id === 0) ? ('NEW@'+qindex) : question.skills_question_id} type='text' value={question.skill_question} onChange={onChangeSkillQuestionInput}/>
                                      {
                                        (qindex !== 0) && (
                                          <div className="question_box">
                                            <button className='btn btn-danger pull-right rounded-circle' style={{marginLeft:'10px'}} onClick={() => removeQuestionRow((skill.skill_id === 0) ? ('NEW@'+sindex) : skill.skill_id, (question.skills_question_id === 0) ? ('NEW@'+qindex) : question.skills_question_id)}><i className="fa fa-remove"></i></button>
                                          </div>
                                        )
                                      }

                                      {
                                        (skill.questionDetails.length === (qindex + 1)) && (
                                          <div className="question_box">
                                            <button className='btn btn-success pull-right rounded-circle' style={{marginLeft:'10px'}} onClick={() => addQuestionRow((skill.skill_id === 0) ? ('NEW@'+sindex) : skill.skill_id)}><i className="fa fa-plus"></i></button>
                                          </div>
                                        )
                                      }
                                  </td>
                                </tr>
                                </>
                              ))
                            ))
                          )        
                          }
                          
                        </tbody>
                      </table>
                      <button className='btn btn-success pull-right' style={{marginTop:'10px'}} onClick={addSkillRow}>ADD SKILL</button>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                      <div className="btn-left">
                        <Button variant="outline-secondary" onClick={handleCloseBulkEditModal}>Cancel</Button>
                      </div>
                      <div className="btn-right">
                        <Button variant="dark" onClick={saveBulkEditModal}>Save</Button>
                      </div>
                    </Modal.Footer>
                  </Modal> 
                </div>
              </Row>
            </main>

            
            <Modal show={showRoadmapUploadModal} centered>
              <Modal.Header>
                <Modal.Title>Bulk Upload</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <Form.Group controlId="delete-team" className="mb-3">
                    <Form.Control
                      type="file"
                      name="roadmap_file"
                      onChange={onChangeInputFile}
                      
                    />
                  </Form.Group>
                  <p><a href="/import_sample/roadmap-demo-file-for-user.csv"><strong>Download Sample File</strong></a></p>
              </Modal.Body>
              

              <Modal.Footer className="justify-content-between">
                <div className="btn-left">
                  <Button variant="outline-secondary" onClick={handleUploadRoadmapCloseModal}>Cancel</Button>
                </div>
                <div className="btn-right">
                  <Button variant="dark" onClick={uploadRoadmapFile}>Upload</Button>
                </div>
              </Modal.Footer>
            </Modal>

            
            <Modal show={showCopyCustomRoadmapModal} onHide={closeCopyCustomRoadmapModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Copy Roadmap</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Add a title and description to your roadmap.</p>
                        <Form.Group controlId="delete-team" className="mb-3">
                          <Form.Label>Roadmap Title</Form.Label>
                          <Form.Control
                            type="text"
                            name="roadmap_name"
                            value={copyCustomRoadmapFormData.roadmap_name}
                            onChange={onChangeInput}
                            placeholder="Enter Title"
                            
                          />
                        </Form.Group>
                        <Form.Group controlId="delete-team" className="mb-3">
                          <Form.Label>Roadmap Description</Form.Label>
                          <Form.Control
                              as="textarea"
                              rows={3}
                              name="roadmap_description"
                              value={copyCustomRoadmapFormData.roadmap_description}
                              placeholder="Enter Description"
                              onChange={onChangeInput}
                            />
                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer className="justify-content-between">
                      <div className="btn-left">
                        <Button type='button' variant="outline-secondary" onClick={closeCopyCustomRoadmapModal}>Cancel</Button>
                      </div>
                      <div className="btn-right">
                        <Button type='button' variant="dark" onClick={saveCopyCustomRoadmapModal}>Save</Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
          </Container>
        </div>
      </div>
  );
};

export default ChooseRoadmaps;