import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import * as myConstent from '../contexts/ConstContext';
const ProfileHome = () => {
  const { getDashboardCounts, getPendingFeedbackAssessmentDetailByUserId, getPendingHiringAssessmentDetailByUserId, logout, checkFeedbackTokenInLocalStorage } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const [feedbackAssessments, setFeedbackAssessments] = useState([]);
  const [hiringAssessments, setHiringAssessments] = useState([]);
  const [widgetCounts, setWidgetCounts] = useState({
    assessment_count:0
  });
  const [isAuthorized, setIsAuthorized] = useState(true);
  const navigate = useNavigate();

   // Use useEffect to fetch data when the component mounts
   useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {

      const assessment_token = await checkFeedbackTokenInLocalStorage();
      if(assessment_token != 1){
        navigate(`/reviewer-assessment/${assessment_token}`)
      }
      
      setLoading(true);
      const res = await getPendingFeedbackAssessmentDetailByUserId({user_id:user.id});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){
          setFeedbackAssessments(res.data);
      }


      const res1 = await getPendingHiringAssessmentDetailByUserId({user_id:user.id});
      if(res1.token_message){
        console.error(res1.token_message);
        logout();
      }else if(res1){
          setHiringAssessments(res1.data);
      }

      

      // const res1 = await getDashboardCounts(user.id);
      // if(res1.token_message){
      //   console.error(res1.token_message);
      //   logout();
      // }else if(res1){
      //   setWidgetCounts(res1.data);
      // }
      setLoading(false);
    };

    fetchData();
    // if (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.teams.view == 1){
    //     setIsAuthorized(true);
    // }
  }, []);

  
  const handleStartAssessment = (token) => {
    navigate(`/reviewer-assessment/${token}`);
  };

  const handleStartHiringAssessment = (token) => {
    navigate(`/hiring-token-assessment/${token}`);
  };
  

  const navigateToHref = (href) => {
    navigate(href);
  };

  
  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <h1 className="mb-4">Welcome, {user.fname} {user.lname}</h1>
            {/* <p>Last Login: {user.created_at}</p> */}

            {
              (user.login_user_type !== 'ADMIN') && 
            (<Row className="profile_contents">
              <Col md="6" lg="3">
                <div className="card">
                  <div className="card-body">
                    <div className="icon-box">
                      <a href="javascript:void(0)"  onClick={() => navigateToHref('/profile-skills')}><img src="/img/requirements.png" /></a>
                    </div>
                    <div className="content-box">
                      <p><a href="javascript:void(0)"  onClick={() => navigateToHref('/profile-skills')}>Take skill assessment</a></p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="3">
                <div className="card">
                  <div className="card-body">
                    <div className="icon-box">
                      <a href="javascript:void(0)"  onClick={() => navigateToHref('/track-progress')}><img src="/img/mark.png" /></a>
                    </div>
                    <div className="content-box">
                      <p><a href="javascript:void(0)"  onClick={() => navigateToHref('/track-progress')}>See data for completed assessments</a></p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="3">
                <div className="card">
                  <div className="card-body">
                    <div className="icon-box">
                      <a href="javascript:void(0)"  onClick={() => navigateToHref((isAuthorized) ? '/profile-teams' : '/unauthorized')}><img src="/img/management.png" /></a>
                    </div>
                    <div className="content-box">
                      <p><a href="javascript:void(0)"  onClick={() => navigateToHref((isAuthorized) ? '/profile-teams' : '/unauthorized')}>Manage skills and onboarding for Teams</a></p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="3">
                <div className="card">
                  <div className="card-body">
                    <div className="icon-box">
                      <a href="javascript:void(0)"  onClick={() => navigateToHref('/support')}><img src="/img/support.png" /></a>
                    </div>
                    <div className="content-box">
                      <p><a href="javascript:void(0)"  onClick={() => navigateToHref('/support')}>Open a support ticket for Algence</a></p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>)
            }

            
            {
              (user.login_user_type !== 'ADMIN') ?
              <div>
                {
                  (feedbackAssessments.length > 0) ? 
                  <>
                  <center><h4>Feedback Assessments</h4></center>
                  {!loading && (
                  <>
                  <div className="table-responsive p-0 pb-2">
                    <table id="history_table" className="table align-items-center justify-content-center mb-0">
                      <thead>
                        <tr>
                          <th>Roadmap Name</th>
                          <th>Feedback Subject</th>
                          <th>Feedback Text</th>
                          <th>Reviewer Name</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feedbackAssessments.map((row, index) => (
                          <tr key={index}>
                            <td>{(row.roadmap !== null) ? row.roadmap.roadmap_name : ''}</td>
                            <td>{row.subject}</td>
                            <td width={'20%'}>{(row.feedback_text.length > 100) ? (row.feedback_text).substring(0, 100)+'...' : row.feedback_text}</td>
                            <td>{(row.user !== null) ? row.user.fname +' '+row.user.lname : ''}</td>
                            <td>{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</td>
                            <td>
                              <button className='btn btn-sm btn-primary mx-1' onClick={() => handleStartAssessment(row.token)} >
                                Start Assessment
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  </>
                  )}
                  </> : ''
                }


                {
                  (hiringAssessments.length > 0) ? 
                  <>
                  <center><h4>Hiring Assessments</h4></center>
                  {!loading && (
                  <>
                  <div className="table-responsive p-0 pb-2">
                    <table id="history_table" className="table align-items-center justify-content-center mb-0">
                      <thead>
                        <tr>
                          <th>Hiring Name</th>
                          <th>Subject</th>
                          <th>Text</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hiringAssessments.map((row, index) => (
                          <tr key={index}>
                            <td>{(row.hiring !== null) ? row.hiring.hiring_role_name : ''}</td>
                            <td>{row.subject}</td>
                            <td width={'20%'}>{(row.share_text.length > 100) ? (row.share_text).substring(0, 100)+'...' : row.share_text}</td>
                            <td>{Moment(row.created_at).format(myConstent.DATETIMEFORMAT)}</td>
                            <td>
                              <button className='btn btn-sm btn-primary mx-1' onClick={() => handleStartHiringAssessment(row.token)} >
                                Start Assessment
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  </>
                  )}
                  </> : ''
                }
                
              </div>
              : ''
            }
          </Container>
        </div>
      </div>
  );
};

export default ProfileHome;
