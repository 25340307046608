import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
const SignUp = () => {

  const {registerUser} = useContext(UserContext);
  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
        firstName:'',
        lastName:'',
        email: '',
        password:'',
        confirmPassword: '',
        termsAndConditions: false,
        site_url:window.location.origin+'/verify-account'
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        });
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
        
        const data = await registerUser(formData);
        if(data.status){
          setFormData({
              firstName:'',
              lastName:'',
              email: '',
              password:'',
              confirmPassword: '',
              termsAndConditions: false,
              site_url:window.location.origin+'/verify-account'
          });
    
            setSuccessMsg(data.message);
            setErrMsg(false);
            setTimeout(() => {
              navigate(`/login`);
            }, 5000);
        }
        else if(!data.status && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
  };

  return (
      <div className="login-form padding100">
        <Container>
          <Row>
            <Col md={6}>
              <Image
                src="/img/about.jpg"
                alt="Login"
                fluid
                className="login-image"
              />
            </Col>
            <Col md={6}>
                <div className="login-form-container signup-form">
                   <h2>Signup For Your Success!</h2>
                   <Form onSubmit={handleSubmit} className="mt-5">
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="firstName" className="mb-3">
                            <Form.Label>First Name *</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter your first name"
                              name="firstName"
                              value={formData.firstName}
                              onChange={onChangeInput}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="lastName" className="mb-3">
                            <Form.Label>Last Name *</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter your last name"
                              name="lastName"
                              value={formData.lastName}
                              onChange={onChangeInput}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                    <Form.Group controlId="email" className="mb-3">
                      <Form.Label>Email *</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                        name="email"
                        value={formData.email}
                        onChange={onChangeInput}
                        required
                      />
                    </Form.Group>
                    <Row>
                        <Col md={6}>
                          <Form.Group controlId="password" className="mb-3">
                            <Form.Label>Password *</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter your password"
                              name="password"
                              value={formData.password}
                              onChange={onChangeInput}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="confirmPassword" className="mb-3">
                            <Form.Label>Confirm Password *</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter your Confirm password"
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={onChangeInput}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                    <Form.Group controlId="termsAndConditions" className="terms-and-conditions">
                      <Form.Check
                        type="checkbox"
                        value={formData.termsAndConditions}
                        name="termsAndConditions"
                        onChange={onChangeInput}
                        id="termsAndConditions_label"
                        required
                      />
                      <div className="signup-link">
                        <label for="termsAndConditions_label">I agree to the <a href ="/terms-and-conditions">terms and conditions</a></label>
                      </div>
                    </Form.Group>
                     {successMsg && <h4 className="success-msg text-primary text-center">{successMsg}</h4>}
                     {errMsg && <h4 className="err-msg text-danger text-center">{errMsg}</h4>}
                    <Button variant="primary" type="submit">
                      Signup
                    </Button>
                    <div className="signup-link">
                      <p>
                        Already have an account? <a href="/login">Login</a>
                      </p>
                    </div>
                   </Form>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
  );
};

export default SignUp;
