import React, { useState, useContext } from 'react';
import { useNavigate , useParams} from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
const ResetPassword = () => {
  const {resetPassword, wait, showToast} = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  
  const { id } = useParams();
  const { token } = useParams();
  const [formData, setFormData] = useState({
    user_id:id,
    token:token,
    password: '',
    cpassword: ''
  });
  

  const submitForm = async (e) => {
    e.preventDefault();

    if(formData['password'] == '') {
      showToast("Please enter password", true); return false;
    }

    if(formData['cpassword'] == '') {
      showToast("Please enter confirm password", true); return false;
    }

    if(formData['password'].length < 6) {
      showToast("Password must be at least 6 characters.", true); return false;
    }

    if(formData['password'] != formData['cpassword']) {
      showToast("Password and confirm password are not some", true); return false;
    }

    setRedirect('Loading...');
    const data = await resetPassword(formData);
    if(data.status){
      navigate('/login');
    }
    setRedirect(false);
    showToast(data.message, true);
};


  return (
    <div className="forgot-password-form padding100">
      <Container>
        <Row>
          <Col md={6}>
            <Image
              src="/img/about.jpg"
              alt="Login"
              fluid
              className="login-image"
            />
          </Col>
          <Col md={6}>
            <div className="login-form-container">
              <h2 className="text-center">Reset Password</h2>
              <Form onSubmit={submitForm}>

                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail1" className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your confirm password"
                    value={formData.cpassword}
                    onChange={(e) =>
                      setFormData({ ...formData, cpassword: e.target.value })
                    }
                  />
                </Form.Group>
                <div className="col-xl-12 col-lg-12">
                  <div className="part-btn text-center">
                    {redirect ? redirect : <Button className="login-btn" type="submit" variant="primary" disabled={wait}>
                      Submit
                    </Button>}
                  </div>
                </div>
              </Form>

              <div className="signup-link">
                <p>
                  Don't have an account? <a href="/signup">Sign Up</a>
                </p>
                <p>
                  Already Have an Account? <a href="/login">Login</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default ResetPassword;
