
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ProfileMenu from './profilemenu';
import {UserContext} from '../contexts/UserContext';
import Moment from 'moment';
import { CSVLink } from "react-csv";
import { Container} from 'react-bootstrap';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import * as myConstent from '../contexts/ConstContext';
const History = () => {
  const {getAllAssessmentHistoryOfUser, logout } = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const res = await getAllAssessmentHistoryOfUser({user_id:user.id});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){
          setHistory(res.data);
          setLoading(false);
      }else{
      }

      $("#history_table").DataTable({
        pagingType: "full_numbers",
        pageLength: myConstent.DATATABLERECORDLIMIT,
        processing: true,
        scrollX: true,
        dom: "Bfrtip",
        select: {
          style: "single",
        },
        order: [[4, 'desc']],
        buttons: [
          {
            extend: "pageLength",
            className: "btn btn-secondary bg-secondary",
          }
        ],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
    };

    fetchData();
    // if (user.last_login_team == undefined || user.last_login_team.access_rights == undefined || user.last_login_team.access_rights.history.view == 1){
         
    // }else{
    //   navigate('/unauthorized');
    // }
  }, []);

  // Define a function to handle the "Re-assess skills" button click
  const handleAssessmentHistorySkills = (id) => {
    // Perform your action here when the button is clicked
    navigate(`/check-assessment-result/${id}`);
  };

  const handleReassessSkills = (roadmap_slug, roadmapId) => {
    // Perform your action here when the button is clicked
    navigate(`/assessment/${roadmap_slug}`, {
      state: {
          roadmapId: roadmapId,
      },
    });
  };

    // Define a function to handle the "Re-assess skills" button click
  const handleAssessmentHistory = (roadmapId) => {
    // Perform your action here when the button is clicked
    navigate(`/user-assessment-history/${roadmapId}`);
  };

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <h1>History</h1>
            {!loading && (
            <>
              <div className="p-0 pb-2">
              {(history.export_data !== undefined && history.export_data.length > 1) && (
              <CSVLink filename={"history-export.csv"} data={history.export_data}><button type="button" style={{'float':'right', 'marginRight': '10px'}} className="btn btn-sm btn-success" >Export All Data</button></CSVLink>)}

                <table id="history_table" className="table align-items-center justify-content-center mb-0 display wrap" style={{width:'100%'}}>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Who</th>
                      <th>Roadmap Name</th>
                      <th>Score</th>
                      <th>Assessment Date</th>
                      <th>Action</th> 
                      {/* <th>Level</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {history.self_assessments.map((row, index) => (
                      <tr key={index}>
                        <td><p>{(history.user !== null) ? history.user.fname +' '+ history.user.lname  : ''}</p>
                        <p>{(history.user !== null) ? history.user.email  : ''}</p>
                        </td>
                        <td>{row.stakeholder_name}</td>
                        <td>{row.roadmap_name}</td>
                        <td>{row.total_score}</td>
                        <td>{Moment(row.assessment_created_at).format(myConstent.DATETIMEFORMAT)}</td>
                        <td>
                            <button className='btn btn-sm btn-success btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleAssessmentHistorySkills((row.stakeholder_name == 'Self' ? 'self/' : 'team-admin/')+row.id)}>
                              Check Results
                            </button>
                          {
                            (row.team_admin_id == 0 || row.team_admin_id == null) ? 
                            <>
                            <button className='btn btn-sm btn-primary mx-1' onClick={() => handleReassessSkills(row.roadmap_slug, row.roadmap_id)} >
                              Reassess
                            </button>
                            </> : ''
                          }
                        </td>
                        {/* <td>
                          <button className='btn btn-sm btn-success btn-history mx-1' onClick={() => handleAssessmentHistorySkills(row.roadmap.roadmap_id)}>
                            Check Results
                          </button>

                          <button className='btn btn-sm btn-primary mx-1' onClick={() => handleReassessSkills(row.roadmap.roadmap_slug, row.roadmap.roadmap_id)} >
                            Reassess
                          </button>
                          <button className='btn btn-sm btn-secondary mx-1' onClick={() => handleAssessmentHistory(row.roadmap.roadmap_id)}>
                            History
                          </button>
                        </td> */}
                      </tr>
                    ))}

                    {history.reviewer_assessments.map((row, index) => (
                      <tr key={index}>
                        <td><p>{row.fname +' '+ row.lname}</p>
                        <p>{row.user_email}</p>
                        </td>
                        <td>Stakeholder</td>
                        <td>{row.roadmap_name}</td>
                        <td>{row.total_score}</td>
                        <td>{Moment(row.assessment_created_at).format(myConstent.DATETIMEFORMAT)}</td>
                        <td>
                        <button className='btn btn-sm btn-success btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleAssessmentHistorySkills('stakeholder/'+row.id)}>
                              Check Results
                            </button>
                        </td>
                      </tr>
                    ))}

                    {history.team_assessments.map((row, index) => (
                      <tr key={index}>
                        <td><p>{row.fname +' '+ row.lname}</p>
                        <p>{row.user_email}</p>
                        </td>
                        <td>{row.role_title}</td>{/* {((row.team_type == 'ORGANIZATION' ? 'Team' : 'Study Group') + ' ( '+row.team_name+' ) ')} */}
                        <td>{row.roadmap_name}</td>
                        <td>{row.total_score}</td>
                        <td>{Moment(row.assessment_created_at).format(myConstent.DATETIMEFORMAT)}</td>
                        <td>
                        <button className='btn btn-sm btn-success btn-history mx-1' style={{'marginBottom':'2px'}} onClick={() => handleAssessmentHistorySkills('team/'+row.id)}>
                              Check Results
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
            )}
          </Container>
        </div>
      </div>
  );
};

export default History;
