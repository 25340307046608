import React, {useContext, useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';

const AutoLogin = () => {
    
  const { id,token } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const {loginUser, wait, loggedInCheck} = useContext(UserContext);
  const navigate = useNavigate();

 // Use useEffect to fetch data when the component mounts
 useEffect(() => {
  // Function to fetch data from the API
  const fetchData = async () => {
    setRedirect('Redirecting...');
    setErrMsg('');
    const data = await loginUser({email:"",password:"",type:"", credential:"",user_id:id, token:token});
      if(data.success){
          await loggedInCheck();
          setRedirect('');
          navigate("/profile");
      }
      else {
        setErrMsg(data.message);
      }
  };
  fetchData();
  }, []);

  return (
    <div className="login-form padding100">
      <Container>
        <Row>
          <Col md={12}>
            {redirect && <div className="text-center">{redirect}</div>}
            {errMsg && <div className="err-msg text-danger text-center">{errMsg}</div>}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AutoLogin;
