import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Form, Container } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";

const CreateStdGrp = () => {

  const { user, createStudyGroup, showToast} = useContext(UserContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_id:user.id,
    group_name:'',
    group_description:''
  });

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.group_name === "") {
        showToast("Please enter group name", true); return false;
      } 

      const data = await createStudyGroup(formData);
      if(data.status){
          e.target.reset();
          setFormData({
            user_id:user.id,
            group_name:'',
            group_description:''
          });
          navigate(`/createstdgrp/chooseroadmaps/${data.id}`);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <div className="button-section">
                  <button onClick={() => navigate(-1)} className="button" title="back"><i className="fa fa-chevron-left"></i> Back</button>
              </div>
              <div className="settings-card">
                <div className="settings-card-header">Enter Study Group Information</div>
                <div className="settings-card-body">

                <Form onSubmit={handleSubmit} >
                  <Form.Group controlId="group_name" className="mb-3">
                    <Form.Label>Group Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="group_name"
                      value={formData.group_name}
                      placeholder="Group Name"
                      onChange={onChangeInput}
                    />
                  </Form.Group>

                  <Form.Group controlId="group_description" className="mb-3">
                    <Form.Label>Group Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="group_description"
                      value={formData.group_description}
                      placeholder="Group Description"
                      onChange={onChangeInput}
                    />
                  </Form.Group>
                  <div className="button-section">
                      <button className="button submit" type="submit">Save</button>
                  </div>
                </Form>
                </div>
              </div>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default CreateStdGrp;