import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Form, Container } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";

const CreateOrg = () => {

  const {createOrganization, showToast} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_id:user.id,
    organization_name:'',
    organization_website:'',
    organization_size: ''
  });

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  const isValidUrl = urlString=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.organization_name === "") {
        showToast("Please enter company name", true); return false;
      } else if(formData.organization_website === "") {
        showToast("Please enter company website", true); return false;
      } else if(!isValidUrl(formData.organization_website)) {
        showToast("Please enter valid company website url", true); return false;
      } else if(formData.organization_size === "") {
        showToast("Please select company size", true); return false;
      } 

      const data = await createOrganization(formData);
      if(data.status){
          e.target.reset();
          setFormData({
            user_id:user.id,
            organization_name:'',
            organization_website:'',
            organization_size: ''
          });
         
          navigate(`/createorg/addteams/${data.id}`);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <div className="button-section">
                  <button onClick={() => navigate(-1)} className="button" title="back"><i className="fa fa-chevron-left"></i> Back</button>
              </div>
              <div className="settings-card">
                <div className="settings-card-header">Enter Organization Information</div>
                <div className="settings-card-body">
                <Form onSubmit={handleSubmit} >
                  <Form.Group controlId="organization_name" className="mb-3">
                    <Form.Label>Company Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="organization_name"
                      value={formData.organization_name}
                      placeholder="Company Name"
                      onChange={onChangeInput}
                    />
                  </Form.Group>

                  <Form.Group controlId="organization_website" className="mb-3">
                    <Form.Label>Website*</Form.Label>
                    <Form.Control
                      type="text"
                      name="organization_website"
                      value={formData.organization_website}
                      placeholder="Website"
                      onChange={onChangeInput}
                    />
                  </Form.Group>

                  <Form.Group controlId="organization_size" className="mb-3">
                    <Form.Label>Tech Team Size*</Form.Label>
                    <Form.Select name="organization_size" onChange={onChangeInput}>
                      <option value="">Select team size</option>
                      <option value="1-5">1-5 people</option>
                      <option value="6-10">6-10 people</option>
                      <option value="11-25">11-25 people</option>
                      <option value="26-50">26-50 people</option>
                      <option value="51-100">51-100 people</option>
                      <option value="101-200">101-200 people</option>
                      <option value="201-500">201-500 people</option>
                      <option value="501-1000">501-1000 people</option>
                      <option value="1000+">1000+ people</option>
                    </Form.Select>
                  </Form.Group>
                  <div className="button-section">
                      <button className="button submit" type="submit">Save</button>
                  </div>
                </Form>
                </div>
              </div>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default CreateOrg;