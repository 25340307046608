import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Row, Form, Container } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";

const AddTeams = () => {

  const {logout, getTeamsOfOrganization, addTeamInOrganization, showToast} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [teams, setTemas] = useState(['just']);
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    organization_id:id,
    team_name:''
  });

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.team_name === "") {
      showToast("Please enter team name", true); return false;
    } 
    const data = await addTeamInOrganization(formData);
    if(data.status){
        e.target.reset();
        setFormData({
            organization_id:id,
            team_name:''
        });

        fetchData();
    }
    else if(!data.status && data.message){
        showToast(data.message, false);
    }
};

  const fetchData = async () => {
    setLoading(true);
    const response = await getTeamsOfOrganization(id);
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        setTemas(response.data);
        setLoading(false);
      }
    }
    else {
      console.error(response);
    }
  };

  const handleRedirect = (id) => {
      navigate(`/createorg/chooseroadmaps/${id}`);
  };


  // Use useEffect to fetch data when the component mounts
  useEffect(async () => {
    setLoading(true);
    const response = await getTeamsOfOrganization(id);
    if(response){
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else{
        setTemas(response.data);
        setLoading(false);
      }
    }
    else {
      console.error(response);
    }
  }, []);



  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              {!loading && (
              <>
                <Row>
                  <div className="settings-card">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="settings-card">
                          <div className="settings-card-header">Add Teams</div>
                          <div className="settings-card-body">
                          <Form onSubmit={handleSubmit} >
                              <Form.Group controlId="team_name" className="mb-3">
                              <Form.Label>Team Name*</Form.Label>
                              <Form.Control
                                  type="text"
                                  name="team_name"
                                  value={formData.team_name}
                                  placeholder="Team Name"
                                  onChange={onChangeInput}
                              />
                              </Form.Group>
                              <div className="button-section">
                                  <button className="button submit" type="submit">Save</button>
                              </div>
                          </Form>

                           {teams.length > 0 && (
                          <>
                         <table className="history-table">
                              <thead>
                              <tr>
                                  <th>Team Name</th>
                                  <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {teams.map((row, index) => (
                                  <tr key={index} className="history-row">
                                  <td>{row.team_name}</td>
                                  <td>
                                      <button className='btn btn-sm btn-primary' onClick={() => handleRedirect(row.id)} >
                                      Manage
                                      </button>
                                  </td>
                                  </tr>
                              ))}
                              </tbody>
                          </table>
                          </>
                          )}
                          </div>
                        </div>
                      </div>
                    </div>
                        
                  </div>
                </Row>
                </>
                )}
            </main>
          </Container>
        </div>
      </div>
  );
};

export default AddTeams;