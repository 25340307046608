import React from 'react';

const CreateFlowchart = () => {
  

  return (
    <div className="row h-full dndflow">
1111
    </div>
  );
};

export default CreateFlowchart;

