import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';

const TeamAssessment = () => {
  const {logout, getRoadmapById, postAssessment, user, getSkills, getTeamDetails, showToast} = useContext(UserContext);

  if(user === null) {
    logout();
  }

  const location = useLocation();
  const navigate = useNavigate();
  const [id] = useState(location.state.roadmapId);
  const [teamId] = useState(location.state.teamId);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isQuestions, setIsQuestions] = useState(1);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [roadmap, setRoadmaps] = useState({});
  const [loadingRoadmap, setLoadingRoadmap] = useState(true);
  const [myQuestions, setMyQuestions] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedQueData, setSelectedQueData] = useState({result:[]});
  const [selectedArrData, setSelectedArrData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);


  const handleSelectedUserCheckBox = (e) => {
    const { value, checked } = e.target;
    let  temp  = selectedUsers;

    if (checked) {
        setSelectedUsers([...temp, value]);
    }
    else {
        let newarray = temp.filter(
            (e) => e !== value
        );
        setSelectedUsers(newarray);
    }
};
  

  const handleSelectedAnswer = (question_id,skill_id, answer,email) => {
    if(selectedQueData.result.length > 0){
        const array = selectedQueData.result[0].questions;

        array.forEach((element, index) => {
          if(element.email === email && element.skill_question_id === question_id) {
            array.splice(index, 1);
          }
        });

        let itemsres2 = [];
          itemsres2.push({
            questions:array
        });
        let qData1 = {result:itemsres2};
        setSelectedQueData(qData1);
    }

    let queRes = myQuestions;
    queRes.push({
      email:email,
      skill_question_id:question_id,
      answer:answer,
      skill_id:skill_id
    });
    let itemsres = [];
      itemsres.push({
         questions:queRes
    });
    let qData = {result:itemsres};

    setSelectedQueData(qData);
    let selectedArrData = [];
    (itemsres[0].questions).forEach(element1 => {
      selectedArrData.push(element1.email+"@"+element1.skill_question_id);
    });
    console.log(selectedArrData);
    setSelectedArrData(selectedArrData);
  };

  

  const fetchRoadMapData = async () => {
    setLoadingRoadmap(true);
    const resRoadmap = await getRoadmapById({id:id});
    if(resRoadmap.token_message){
      console.error(resRoadmap.token_message);
      logout();
    }else if(resRoadmap){
        setRoadmaps(resRoadmap.data);
        setLoadingRoadmap(false);
    }else{
    }
  };

  const handleSubmit = async (e) => {
    // Submit the user's answers
    // Display a message
    if(selectedUsers.length == 0){
      showToast('There are not have any users selected', true); 
      return false;
    } else if(selectedQueData.result.length == 0){
      showToast('Please rate all skill questions', true); 
      return false;
    } else if(selectedQueData.result[0] != undefined && selectedQueData.result[0].questions.length != (selectedUsers.length * questionsCount)){
      showToast('Please rate all skill questions', true); 
      return false;
    } 

    let formData = {
      user_id:user.id,
      roadmap_id:id,
      question_results:JSON.stringify(selectedQueData),
      feedback_id:0,
      in_process:0,
      assessment_id:0,
      admin_id:user.id,
      team_id:teamId
    };
    var result = await postAssessment(formData);
    if(result.status){
        alert(result.message);
        navigate(-1);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
    // Function to fetch data from the API
    const fetchData = async () => {
      setLoading(true);
      const response = await getSkills(id);
      console.log(response);
      if(response.token_message){
        console.error(response.token_message);
        logout();
      }else if(response.data){
          var result = response.data;
          setIsQuestions(response.is_questions);
          setQuestionsCount(response.question_count);
          setSkills(result);
          setLoading(false);
      }


      const team_response = await getTeamDetails(teamId);
      if(team_response){
        if(team_response.token_message){
          console.error(team_response.token_message);
          logout();
        }else{
          // if(team_response.data.accepted_members_results.length  ==  0) {
          //   team_response.data.accepted_members_results[0] = {
          //     name:'',
          //     email:'',
          //     role_id:''
          //   };
          // }

          // let roles_response = team_response.roles;

          // let role_member_id = null;
          // roles_response.forEach(element => {
          //   if(element.title === "Member") {
          //     role_member_id = element.id;
          //   }
          // });

          let  tempSelectedUsers  = [];
          team_response.accepted_members.forEach((element, index) => {
            tempSelectedUsers.push(element.email);
            // if(role_member_id !== element.role_id) {
            //     team_response.data.accepted_members_results.splice(index, 1);
            // } else {
            //   tempSelectedUsers.push(element.email);
            // }
          }); 
          setSelectedUsers(tempSelectedUsers);
          setTeamMembers(team_response.accepted_members);
          setLoading(false);
        }
      }

      fetchRoadMapData();
    };
    fetchData();
  }, []);
  const handleBackBtnClick = (e) => {
    navigate(`/createorg/chooseroadmaps/${teamId}`);
  };
  return (

    <div className="padding100 h-full">
      <div className="container-fluid">
      {!loadingRoadmap && (
        <>
         <div className="d-flex align-items-center justify-content-start">
            <div>
              <div className="back-btn me-4">
                <button onClick={() => handleBackBtnClick()} className="btn btn-secondary" title="back"><i className="fa fa-chevron-left"></i></button>
              </div>
            </div>
            <div>
              <h1>{(roadmap.roadmap_name).split(' ').map(function(word) {return word[0].toUpperCase() + word.substr(1);}).join(' ')} Roadmap</h1>
            </div>
          </div>
        </>
      )}
        <p>{roadmap.roadmap_description}</p>
           {!loadingRoadmap && (teamMembers.length === 0) && (
          <>
            <h6 className="text-danger"><center>Please add at least one team member to start assessment.</center></h6>
          </>
        )}

        {
          (loading) ? <h6 className="text-center">Loading...</h6> :
          (isQuestions == 0)  ? 
          <h6 className="text-center">Data not found</h6> 
          :
          (teamMembers.length > 0) && (
          <>
            <p><b>Novice</b> - I've never used or applied the skills and I'm completely new to the concept</p>
            <p><b>Advanced Beginner</b> - I've heard of this and understand what it is and when it's used. I might have started using and/or applying the skills, but I need a lot of hands-on assistance and guidance to do anything.</p>
            <p><b>Competent</b> -  I can access, use and apply the skill and I'm self-sufficient in its application, but I need time and access to reference materials or experts in some cases to apply the skill.</p>
            <p><b>Proficient</b> - I can do this independently and effectively with little to no dependence on experts or reference materials.</p>
            <p><b>Expert</b> - I am confident in this and can teach it effectively to others. I may be considered a SME or go-to expert and thought leader in this area</p>
          <div>
            <div className="scrollable-container">
              <div className="scrollable-body">
                <div className=" text-nowrap">
                  <table className="table new_assessment_table align-middle">
                    <thead className="sticky-header">
                      <tr>
                        <th scope="col" className="tbl_header sticky-col bg-color-gray" width="20%">Skill Name</th>
                        <th scope="col" className="tbl_header sticky-col bg-color-gray" width="20%">Skill Question</th>  
                        {teamMembers.map((element, index) => (     
                          <th scope="col" className="tbl_header">
                             
                            <Form.Check 
                                type="checkbox"
                                name={`user_access[${element.email}]`}
                                onChange={
                                  handleSelectedUserCheckBox
                                }
                                value={element.email}
                                label={element.name}
                                checked={selectedUsers.includes(element.email) ? 'checked' : ''}
                              />
                          </th> 
                        ))}  
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (loading) ? <tr><td colspan="7" className="text-center">Loading...</td></tr> : ''
                      }
                      {!loading && (skills.length > 0) && (
                        skills.map((skill, sindex) => (
                            skill.questionDetails.map((question, qindex) => (
                          <>
                          <tr>
                            { (qindex === 0) ?
                            <td scope="row" className="sticky-col  bg-color-gray" width="20%" rowSpan={skill.questionDetails.length}>{skill.skill_name} - {skill.skill_definition}</td> :
                            '' }
                            
                            <td width="20%" className="sticky-col  bg-color-gray" >
                                {question.skill_question}  
                            </td>
                          {teamMembers.map((element, index) => (
                              <td 
                              className={(selectedArrData.length > 0 && !selectedArrData.includes(element.email+"@"+question.skills_question_id) && selectedUsers.includes(element.email)) ? 'redboard_td' : ''}>
                                <Form.Check 
                                  type="radio"
                                  name={`user[${element.email}][answer-${question.skills_question_id}]`} 
                                  id={`${element.email}-${question.skills_question_id}-1]`} 
                                  value="1" 
                                  onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,1,element.email)}
                                  label="Novice"
                                  disabled={selectedUsers.includes(element.email) ? false : true}
                                />
                                <Form.Check 
                                  type="radio"
                                  name={`user[${element.email}][answer-${question.skills_question_id}]`} 
                                  id={`${element.email}-${question.skills_question_id}-2]`} 
                                  value="2" 
                                  onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,2,element.email)}
                                  label="Advanced Beginner"
                                  disabled={selectedUsers.includes(element.email) ? false : true}
                                  />
                                <Form.Check 
                                  type="radio"
                                  name={`user[${element.email}][answer-${question.skills_question_id}]`} 
                                  id={`${element.email}-${question.skills_question_id}-3]`} 
                                  value="3" 
                                  onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,3,element.email)}                                                       
                                  label="Competent"
                                  disabled={selectedUsers.includes(element.email) ? false : true}
                                />
                                <Form.Check 
                                  type="radio"
                                  name={`user[${element.email}][answer-${question.skills_question_id}]`} 
                                  id={`${element.email}-${question.skills_question_id}-4]`} 
                                  value="4" 
                                  onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,4,element.email)}                                                       
                                  label="Proficient"
                                  disabled={selectedUsers.includes(element.email) ? false : true}
                                />
                                <Form.Check 
                                  type="radio"
                                  name={`user[${element.email}][answer-${question.skills_question_id}]`} 
                                  id={`${element.email}-${question.skills_question_id}-5]`} 
                                  value="5" 
                                  onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,5,element.email)}  
                                  label="Expert"   
                                  disabled={selectedUsers.includes(element.email) ? false : true}                                     
                                />
                              </td>
                            ))}
                            
                          </tr>
                          </>
                        ))
                      ))
                      )}
                    {!loading && (skills.length === 0) && (
                      <>
                        <tr><td className="text-center" colspan="7">Data not found</td></tr>
                      </>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
            </div>
            <div className="button-section justify-content-end">
              {/* <button onClick={handleCancel} className="reset-button">Cancel</button>   */}

              {
                (teamMembers.length > 0) ? <button className="btn submit-button" onClick={handleSubmit}>Submit</button>  : ''
              }
              
            </div>
          </div>
          </>
          )
        }
      </div>
    </div>
  );
};

export default TeamAssessment;