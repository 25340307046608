
import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Header } from "../components/header";
import { Features } from "../components/features";
import { About } from "../components/about";
import { Services } from "../components/services";
import { UserContext } from '../contexts/UserContext';
//import { Gallery } from "../components/gallery";
/*import { Testimonials } from "../components/testimonials";*/
//import { Team } from "../components/Team";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import {Helmet} from "react-helmet";
import JsonMetaData from "../data/metaData.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
 
const Home = () => {
    const [landingPageData, setLandingPageData] = useState(JsonData);
    const [pageMetaData, setPageMetaData] = useState(JsonMetaData);
    const {logout} = useContext(UserContext); 
    const user =  JSON.parse(localStorage.getItem('userData'));
    
    const navigate = useNavigate();

    const handleHrefRedirect = (href) => {
        navigate(href);
    };

    const { hash } = useLocation();
    console.log(hash);
    
    if(hash != '#features') {
        if(hash == '#logout') {
            if(user != null) {
                const res = logout({user_id:user.id});
            }
            setTimeout(
                () => {
                    window.location.href = '/login';              
               },1000);
        } else if(hash != ''){
            navigate(hash.replace("#", ""));
        } else if (hash == '') { 
            window.scrollTo(0, 0); 
        }
    } 
    

  
    return (
        <div>
            <Helmet>
                <title>{pageMetaData.home.title}</title>
                <meta name="description" content={pageMetaData.home.description} />
                <meta name="keywords" content={pageMetaData.home.keywords} />
                <meta name="author" content={pageMetaData.home.author} />
                <meta property="og:title" content={pageMetaData.home.og_title} />
                <meta property="og:description" content={pageMetaData.home.og_description} />
                <meta property="og:image" content={pageMetaData.home.og_image} />
                <meta property="og:url" content={pageMetaData.home.og_url} />
            </Helmet>
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <section id="features" className="features2 padding80">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="about-text">
                              <h2>{landingPageData.AnalyticsSection.title}</h2>
                              <p>{landingPageData.AnalyticsSection.text}</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                      <img src="/img/slide3.png" className="d-block" alt="slide3.png" />
                                    </div>
                                    <div className="carousel-item">
                                      <img src="/img/slide1.png" className="d-block" alt="slide1.png" />
                                    </div>
                                    <div className="carousel-item">
                                      <img src="/img/slide2.png" className="d-block" alt="slide2.png" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            <div id="about">
                <div className="container home-roadmap-section">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            {" "}
                            <img src="img/roadmaps.jpg" className="img-responsive" alt="" />{" "}
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="about-text">
                                <h2>{landingPageData.SkillRoadmaps.title}</h2>
                                <p>{landingPageData.SkillRoadmaps.text1}</p>
                                <p>{landingPageData.SkillRoadmaps.text2}</p>
                            </div>
                            <div className="sqs-block button-block sqs-block-button text-center">
                                <a href="javascript:void(0);" onClick={() => handleHrefRedirect("/skills")}  className="btn btn-custom btn-lg">
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Services data={landingPageData.Services} />
         {/* <Gallery data={landingPageData.Gallery} /> 
            <Testimonials data={landingPageData.Testimonials} />
           {/*  <Team data={landingPageData.Team} /> */}

            <section className="onboarding-section">
                <div className="container">
                    <div className="row">
                        <div className="content-column col-xl-6 col-lg-7 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="section-title">
                                    <h2>{landingPageData.Onboarding.title}</h2>
                                </div>
                                <ul className="list-style-two">
                                    <li><i className="fa fa-check-circle"></i>{landingPageData.Onboarding.text1}</li>
                                    <li><i className="fa fa-check-circle"></i> {landingPageData.Onboarding.text2}</li>
                                </ul>
               
                            </div>
                        </div>

                        <div className="image-column col-xl-6 col-lg-5 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <figure className="image-1 overlay-anim"><img src="img/onboard-img-1.jpg" alt="" /></figure>
                                <figure className="image-2 overlay-anim"><img src="img/onboard-img-2.jpg" alt="" /></figure>
                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="product-demo-cta" className="Index-page">    
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text text-center">
                                <h2>{landingPageData.GetStarted.title}</h2>
                                <p className="p-white-banner"><strong>{landingPageData.GetStarted.text}</strong></p>
                            </div>
                            <div className="col-md-12">
                                <div className="sqs-block button-block sqs-block-button text-center">
                                    <a href="javascript:void(0);" onClick={() => handleHrefRedirect("/login")}  className="btn btn-custom btn-lg">
                                        Get started →
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
      </div>
    );
};
 
export default Home;

