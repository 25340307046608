import React from "react";

export const Plans = (props) => {

  return (
    <div id="pricing" className="text-center h-full">
      <div className="container pricing_container">
        <div className="section-title">
          <h2>Pricing</h2>
          <p>
            Choose the plan that's right for you. 
          </p>
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-0"></div>
          {props.data
            ? props.data.map((d, i) => (

              <div  key={`${d.name}-${i}`}  className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" >
                <div className="pricing_design">
                  <div className="single-pricing">
                    <div className="price-head">		
                      <h2>{d.name}</h2>
                      <h1>{d.amount}</h1>
                      <span>{d.duration}</span>
                    </div>
                    <ul>
                    {d.text
                      ? d.text.map((t, ti) => (
                          <li key={`${t}-${ti}`}>{t}</li>
                        ))
                      : "loading"}
                    </ul>
                    <div className="pricing-price">
						          <a href={"/" + d.redirect} className="price_btn">{d.button_text}</a>
                    </div>
                  </div>
                </div>
              </div>
              ))
            : "loading"}
            <div className="col-lg-2 col-md-0"></div>
        </div>
      </div>
    </div>
  );
};
