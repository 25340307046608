import React from 'react';
import { Form } from 'react-bootstrap';

const FilterButtons = ({ filterItem, filterSubItem, setItem, menuItems, menuSubItems }) => {
  
  return (
    <>
    <div className="row">
      <div className="col-md-6">
        <Form.Label>Select Roadmap Type</Form.Label>
        <Form.Select aria-label="All" id="roadmap_type" onChange={filterItem}>
          <option value="all">All</option>
          {menuItems.filter(newVal => (newVal != "" && newVal != null)).map((Val, id) => {
             return ( <option key={id} value={Val}>{Val}</option>
              );
          })}
        </Form.Select>
      </div>
      <div className="col-md-6">
        <Form.Label>Select Roadmap Sub Type</Form.Label>
        <Form.Select aria-label="All" id="roadmap_sub_type" onChange={filterSubItem}>
          <option data-color="primary" value="all">All</option>
          {menuSubItems.filter(newVal => (newVal != "" && newVal != null)).map((SubVal, id) => {

             return ( <option key={id} value={SubVal}>{SubVal}</option>
              );
          })}
        </Form.Select>
      </div>
    </div>
    </>
  );
};

export default FilterButtons;
