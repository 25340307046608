// Import ChartJS correctly
import ChartJS from 'chart.js/auto'; 
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Radar, Line, Bar, getElementAtEvent } from 'react-chartjs-2';
import { Row, Col, Form, Container } from 'react-bootstrap';
import ReactSpeedometer from "react-d3-speedometer";
import { CSVLink } from "react-csv";
import ProfileMenu from "./profilemenu";
import {UserContext} from '../contexts/UserContext';

const TrackProgressPage = () => {
  
  const navigate = useNavigate();
  const { getRoadmapAssessmentHistory, getLoggedInUser,getRoadmapById, getChartDataByUser, getInnerLevelChartDataByUser, logout, getUserTeamsData, getRoadmapsSpiderChartData, getTrackProgressSelfData, getTrackProgressTeamData, getStakeholderUserListWithRoadmapsChartData, getAllTeamsRoadmapsByUserId, getOrganizationRoadmapsSpiderChartData, getRoadmapsWithPercentileData, getTeamBoards } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(true);

  const [thirdChartLoading, setThirdChartLoading] = useState(true);
  var tempThirdChartSkillData = [];
  tempThirdChartSkillData['label'] = [];
  tempThirdChartSkillData['data'] = [];
  const [thirdChartSkillData, setThirdChartSkillData] = useState(tempThirdChartSkillData);
  const [userChartLabels, setUserChartLabels] = useState([]);
  const [userChartData, setUserChartData] = useState([]);
  const [customSegmentLabels] = useState([
    {
      text: "0 To 20",
      position: "INSIDE",
      color: "#fff",
    },
    {
      text: "20 To 40",
      position: "INSIDE",
      color: "#fff",
    },
    {
      text: "40 To 60",
      position: "INSIDE",
      color: "#fff",
      fontSize: "16px",
    },
    {
      text: "60 To 80",
      position: "INSIDE",
      color: "#fff",
    },
    {
      text: "80 To 100",
      position: "INSIDE",
      color: "#fff",
    },
  ]);
  const [SelfToalScore, setSelftToalScore] = useState(5);
  const [allRoadmap, setAllRoadmap] = useState({});
  const [lineLabelsChart, setLineLabelsChart] = useState([]);
  const [lineChart, setLineChart] = useState([]);

  const [organizationRoadmaps, setOrganizationRoadmaps] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [organizationLoading, setOrganizationLoading] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const user =  JSON.parse(localStorage.getItem('userData'));

  const [roadmap, setRoadmap] = useState({});
  const [userTeams, setUserTeams] = useState([]);
  const [userTeamId, setUserTeamId] = useState(0);
  const [userTeamType, setUserTeamType] = useState('ORGANIZATION');
  const [userTeamRoadmaps, setUserTeamRoadmaps] = useState([]);
  const [acceptedMembers, setAcceptedMembers] = useState([]);
  const [theLoginUser, setLoginUser] = useState({email:""});
  var tempUserTeamRoadmapSpiderData = [];
  tempUserTeamRoadmapSpiderData['label'] = [];
  tempUserTeamRoadmapSpiderData['data'] = [];
  const [userTeamRoadmapSpiderData, setUserTeamRoadmapSpiderData] = useState(tempUserTeamRoadmapSpiderData);

  const [stakeholderUserListWithRoadmapsChart, setStakeholderUserListWithRoadmapsChart] = useState([]);
  const [stakeholderUser, setStakeholderUser] = useState([]);
  const [stakeholderRoadmapsChart, setStakeholderRoadmapsChart] = useState([]);
  const [stakeholderRoadmapSkillChartLoading, setStakeholderRoadmapSkillChartLoading] = useState(true);
  var temp = [];
  temp['label'] = [];
  temp['data'] = [];
  const [stakeholderRoadmapSkillChart, setStakeholderRoadmapSkillChart] = useState(temp);
  
  const [selfExportData, setSelfExportData] = useState([]);
  const [teamExportData, setTeamExportData] = useState([]);
  const [stakeholderExportData, setStakeholderExportData] = useState([]);

  var staticInnerStakeholderSkillData = [];
  staticInnerStakeholderSkillData['label'] = [];
  staticInnerStakeholderSkillData['data'] = [];
  const [innerStakeholderSkillData, setInnerStakeholderSkillData] = useState(staticInnerStakeholderSkillData);

  const [customStakeholderSkillLabels, setCustomStakeholderSkillLabels] = useState(["Entry Level Developer","Junior Developer","Mid Level Developer","Senior Developer","Principal Developer"]);

  const { roadmap_id } = useParams();

  const { user_id } = useParams();

  var staticInnerSkillData = [];
  staticInnerSkillData['label'] = [];
  staticInnerSkillData['data'] = [];
  const [innerSkillData, setInnerSkillData] = useState(staticInnerSkillData);

  var staticUserSkillData = [];
  staticUserSkillData['label'] = [];
  staticUserSkillData['data'] = [];
  const [userSkillData, setUserSkillData] = useState(staticUserSkillData);

  const [customSkillLabels, setCustomSkillLabels] = useState(["Entry Level Developer","Junior Developer","Mid Level Developer","Senior Developer","Principal Developer"]);


  const chartRef = useRef();
  const stakeholderChartRef = useRef();
  const innerChartRef = useRef();
 
  const teamChartRef = useRef();

  const predefineBoards = [
    {
      id: "0",
      name: "To Do", 
      items: []
    },
    {
      id: "1",
      name: "In Progress",
      items: []
    },
    {
      id: "2",
      name: "Done",
      items: []
    },
  ]

  const [storeLoading, setStoreLoading] = useState(true);
  const [stores, setStores] = useState(predefineBoards);
  const [teamOnlyMembers, setTeamOnlyMembers] = useState([]);


  const [thirdChartAssessment, setThirdChartAssessment] = useState({});
  const [technicalLabels] = useState(["Entry Level Developer", "Junior Developer", "Mid Level Developer", "Senior Developer", "Principal Developer"]);
  const [nonTechnicalLabels] = useState(["Level-1", "Level-2", "Level-3", "Level-4", "Level-5"]);


  const fetchInnerLevelData = async (name) => {
    handleRoadmapsChange(name);
    setInnerLoading(true);
    setThirdChartLoading(true);
    const res = await getInnerLevelChartDataByUser({name:name, user_id:(user_id ? user_id : user.id)});
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){ 
      
      if(res.data.skill_level.length > 0) {  
        setCustomSkillLabels(res.data.skill_level);
      } else {
        setCustomSkillLabels(customSkillLabels);
      }

      var tempData = [];
      tempData['label'] = res.data.labels;
      tempData['data'] = Object.values(res.data.data);
      setInnerSkillData(tempData);
      setRoadmap(res.data.roadmap);


      setInnerLoading(false);
    }
  };

  const fetchThirdChartData = async (date) => {
    setThirdChartLoading(true);
    const res = await getRoadmapAssessmentHistory({roadmap_id:roadmap.roadmap_id, user_id:(user_id ? user_id : user.id), date:date});
    if(res.token_message){
      console.error(res.token_message);
      logout();
    }else if(res){ 
      setThirdChartAssessment(res.data.assessment_history);
      var tempData = [];
      tempData['label'] = res.data.assessment_history.skill_labels;
      tempData['data'] = Object.values(res.data.assessment_history.skill_data);
      setThirdChartSkillData(tempData);
      setThirdChartLoading(false);
    }
  };

  let handleRoadmapsChange = (name) => {
    allRoadmap.forEach(element => {
      if(element.roadmap_name == name) {
        setSelftToalScore(element.percentile_rank);
        return false;
      }
    });
  }

  const random_rgba = () => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
  };
  
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
     // Function to fetch data from the API
    const fetchData = async () => {
      const user_response = await getLoggedInUser();
      setLoginUser(user_response);

      setLoading(true);
      const resSelf = await getRoadmapsWithPercentileData(user.id);
      if(resSelf.token_message){
        console.error(resSelf.token_message);
        logout();
      }else if(resSelf){
        setAllRoadmap(resSelf.data.roadmap_labels);

        if(resSelf.data.roadmap_labels.length > 0) {
          setSelftToalScore(resSelf.data.roadmap_labels[0].percentile_rank);
        }

        
        let tdata = [];
        // setLineLabelsChart(resSelf.data.line_roadmap_labels);
        // let colors = ['rgb(60, 179, 113)','rgb(255, 165, 0)','rgb(238, 130, 238)','rgb(106, 90, 205)','rgb(255, 0, 0)'];
        // let index = 0;
        // (resSelf.data.line_roadmap_data).forEach(element => {
        //   let color_code = (colors[index] != undefined) ? colors[index] : random_rgba();
        //   tdata.push({
        //     label: element.name,
        //     data: element.score,
        //     borderColor: color_code,
        //     backgroundColor: color_code
        //   })
        //   index++;
        // });

        setLineChart(tdata);

      }else{
      }


      const res2 = await getUserTeamsData({user_id:user.id});
      if(res2.token_message){
        console.error(res2.token_message);
        logout();
      }else if(res2){ 
        setUserTeams(res2.data);
        if(res2.data.length != 0) {
          setUserTeamId(res2.data[0].id);
          setUserTeamType(res2.data[0].type);
          setUserTeamRoadmaps(res2.data[0].roadmaps);
          setAcceptedMembers(res2.data[0].accepted_members);

          if(res2.data[0].roadmaps.length != 0) {
            fetchSpiderData(res2.data[0].roadmaps[0].roadmap_id, res2.data[0].id, res2.data[0].type);
          }

          
          if(res2.data[0].accepted_members.length != 0) {
            fetchTeamBoardsData(res2.data[0].accepted_members[0].user_id, res2.data[0].id);
          }

        }
      }

      const res = await getChartDataByUser({user_id:(user_id ? user_id : user.id)});
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else if(res){ 
        var tempStaticUserSkillData = [];
        tempStaticUserSkillData['label'] = res.data.labels;
        tempStaticUserSkillData['data'] = res.data.data;
        setUserSkillData(tempStaticUserSkillData);
      }else{
      }

      setOrganizationLoading(true);
      const res4 = await getAllTeamsRoadmapsByUserId(user.id);
      if(res4.token_message){
        console.error(res4.token_message);
        logout();
      }else if(res4){
          setOrganizationRoadmaps(res4.data);
          if(res4.data.length > 0) {
              fetchOrganizationSpiderData(res4.data[0].roadmap_id);
          }
          setOrganizationLoading(false);
      }else{
      }

      
      setLoading(false);
      const export_res1 = await getTrackProgressSelfData({user_id:(user_id ? user_id : user.id)});
      if(export_res1.token_message){
        console.error(export_res1.token_message);
      }else if(export_res1){ 
        setSelfExportData(export_res1.data);
      }

      const export_res2 = await getTrackProgressTeamData({user_id:(user_id ? user_id : user.id)});
      if(export_res2.token_message){
        console.error(export_res2.token_message);
      }else if(export_res2){ 
        setTeamExportData(export_res2.data);
      }

      if(roadmap_id !== undefined && roadmap_id !== 'undefined') {
        const res1 = await getRoadmapById({id:roadmap_id});
        if(res1.token_message){
          console.error(res1.token_message);
          logout();
        }else if(res1){ 
          setRoadmap(res1.data);
          setTimeout(() => {
            fetchInnerLevelData(res1.data.roadmap_name)
          }, 1000);
        }
      }

      const res3 = await getStakeholderUserListWithRoadmapsChartData({user_id:user.id});
      if(res3.token_message){
        console.error(res3.token_message);
        logout();
      }else if(res3){ 
        setStakeholderUserListWithRoadmapsChart(res3.data.users);
        setStakeholderExportData(res3.data.export_data);
        if(res3.data.users.length != 0){
          var temp = [];
          temp['label'] = res3.data.users[0].roadmaps;
          temp['data'] = res3.data.users[0].score;
          setStakeholderRoadmapsChart(temp);
          setStakeholderUser(res3.data.users[0].user_email);
          setStakeholderRoadmapSkillChartLoading(true);
        }
      }

      
      
    };
    fetchData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleUserChange = (e) => {
    fetchTeamBoardsData(e.target.value);
  }

  const fetchTeamBoardsData = async (user_id, team_id = '') => {
    setStoreLoading(true);
    if(team_id == '') {
      team_id = userTeamId;
    }
    const response1 = await getTeamBoards(team_id,user_id,'ORGANIZATION');
    if(response1){
      if(response1.token_message){
        console.error(response1.token_message);
        logout();
      }else{
        let temp = predefineBoards;
        temp[0].items = response1.data.todo;
        temp[1].items = response1.data.inprogress;
        temp[2].items = response1.data.done;
        setStores(temp);
      }
    }
    else {
      console.error(response1.message);
    }
    setStoreLoading(false);
  }

  let handleOrganizationChange = (e) => {
    fetchOrganizationSpiderData(e.target.value);
  }

  const fetchOrganizationSpiderData = async (roadmap_id) => {
    setOrganizationLoading(true);
    const res = await getOrganizationRoadmapsSpiderChartData({roadmap_id:roadmap_id,user_id:user.id});
    if(res){
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else{
        let labels = res.data.labels;
        let data = res.data.data;
        if(res.data.labels.length == 2) {
          labels = [""].concat(labels);
          data = [""].concat(data);
        }
        setChartLabels(labels);
        setChartData(data);
        setOrganizationLoading(false);
      }
    }
    else {
        setErrMsg(res.message);
    }
  };

  let handleStakeholderUserChange = (e) => {
    let email = e.target.value;
    stakeholderUserListWithRoadmapsChart.forEach(element => {
      if(element.user_email == email) {
        var temp = [];
        temp['label'] = element.roadmaps;
        temp['data'] = element.score;
        setStakeholderRoadmapsChart(temp);
        setStakeholderUser(element.user_email);
        setStakeholderRoadmapSkillChartLoading(true);
        return true;
      }
    });
  }

  const handleStakeholderRoadmapChange = (event) => {
    setStakeholderRoadmapSkillChartLoading(true);
    var index = getElementAtEvent(stakeholderChartRef.current, event);
    if(index.length > 0) {
      index = index[0].index; 
      stakeholderUserListWithRoadmapsChart.forEach(element => {
        if(element.user_email == stakeholderUser) {
          (element.roadmap_chart_data).forEach(ele => {
            if(ele.roadmap_name == stakeholderRoadmapsChart['label'][index]) {
                var temp = [];
                temp['label'] = ele.history.skill_labels;
                temp['data'] = ele.history.skill_data;
                setStakeholderRoadmapSkillChart(temp);

                var temp = [];
                temp['label'] = ele.history.level_skill_label;
                temp['data'] = Object.values(ele.history.level_data);
                console.log(temp);
                setInnerStakeholderSkillData(temp);
                
                setCustomStakeholderSkillLabels(ele.history.level_skill_levels);
                
                setStakeholderRoadmapSkillChartLoading(false);
                return true;
            }
          });
        }
      });
    }
  };
  
  const fetchSpiderData = async (roadmap_id, team_id = '', team_type = '') => {
    if(team_id == '') {
      team_id = userTeamId;
    }

    if(team_type == '') {
      team_type = userTeamType;
    }
    let res = await getRoadmapsSpiderChartData({roadmap_id:roadmap_id,team_id:team_id,type:team_type,user_id:''});
    if(res){
      if(res.token_message){
        console.error(res.token_message);
        logout();
      }else{
        var tempData = [];
        tempData['label'] = res.data.labels;
        tempData['data'] = res.data.data;
        setUserTeamRoadmapSpiderData(tempData);

        let tdata = [];
        let colors = ['rgb(60, 179, 113)','rgb(255, 165, 0)','rgb(238, 130, 238)','rgb(106, 90, 205)','rgb(255, 0, 0)'];
        let index = 0;
        (res.data.user_skill_scores).forEach(element => {
          let color_code = (colors[index] != undefined) ? colors[index] : random_rgba();
          tdata.push({
            label: element.name,
            data: element.score,
            borderColor: color_code,
            backgroundColor: color_code
          })
          index++;
        });

        setUserChartLabels(res.data.bar_labels);
        setUserChartData(tdata);
      }
    }
    else {
      console.error(res.message);
    }

  };

  const handleProceed = (event) => {
    var index = getElementAtEvent(chartRef.current, event);
    if(index.length > 0) {
      index = index[0].index;  
      fetchInnerLevelData(userSkillData['label'][index]);
    }
  };

  const handleInnerChartProceed = (event) => {
    var index = getElementAtEvent(innerChartRef.current, event);
    if(index.length > 0) {
      index = index[0].index;  
      fetchThirdChartData(innerSkillData['label'][index]);
    }
  };

  let handleTeamsChange = (e) => {
    let team = (e.target.value).split("@");
    let id = team[0];
    let type = team[1];
    setUserTeamId(id);
    setUserTeamType(type);

    userTeams.forEach(element => {
      if(element.id == id && element.type == type) {
        setUserTeamRoadmaps(element.roadmaps);
        setAcceptedMembers(element.accepted_members);
        if(element.roadmaps.length != 0) {
          fetchSpiderData(element.roadmaps[0].roadmap_id, id, type)
        }

        if(element.accepted_members.length != 0) {
          fetchTeamBoardsData(element.accepted_members[0].user_id, id);
        }
        return true;
      }
    });
  }

  let handleTeamRoadmapChange = (e) => {
    fetchSpiderData(e.target.value)
  }

  // Sample data for the horizontal bar chart
  const barChartData = {
    labels: userSkillData['label'],
    datasets: [
      {
        label: 'Roadmaps',
        data: userSkillData['data'],
        backgroundColor: "rgba(53, 162, 235, 0.5)"
      }
    ],
  };
  
  const barChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Click on individual bars to expand'
      }
    },
    indexAxis: "x", // Make the bars horizontal
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const horizontalBarChartLabels = ["Post Skill Assessment Rating","Pre Skill Assessment Rating"];
  const total_score_arr = [0,0];
  let total_score = Math.ceil(thirdChartAssessment.total_score);
  for (let i = 0; i <= 5; i++) {
      if(i == total_score) {
        total_score_arr[0] = thirdChartAssessment.total_score;
           break;
        }
  }
  const assume_score_arr = [0,0];
  let assume_score = thirdChartAssessment.assume_score;
  for (let i = 0; i <= 5; i++) {
      if(i == assume_score) {
          assume_score_arr[1] = assume_score;
           break;
        }
  }

  const horizontalBarChartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
          display: true,
          scaleLabel: {
          display: true,
          labelString: 'Value'
          },
          ticks: {
          min: 0,
          max: 5,
          stepSize: 1,
          suggestedMin: 0.5,
          suggestedMax: 5.0,
          callback: function(label, index, labels) {
              switch (label) {
              case 0:
                return '';
              case 1:
                  return (thirdChartAssessment.roadmap_type == "Technical") ? technicalLabels[0] : nonTechnicalLabels[0];
              case 2:
                  return (thirdChartAssessment.roadmap_type == "Technical") ? technicalLabels[1] : nonTechnicalLabels[1];
              case 3:
                  return (thirdChartAssessment.roadmap_type == "Technical") ? technicalLabels[2] : nonTechnicalLabels[2];
              case 4:
                  return (thirdChartAssessment.roadmap_type == "Technical") ? technicalLabels[3] : nonTechnicalLabels[3];
              case 5:
                  return (thirdChartAssessment.roadmap_type == "Technical") ? technicalLabels[4] : nonTechnicalLabels[4];
             default:
                return '';
              }
          }
          },
          gridLines: {
          display: false
          }
      }
    },
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          labels: {
              font: {
                  size: window.innerWidth > 1024 ? 14 : window.innerWidth > 768 ? 9 : 6
              },
              boxWidth: 10,
              boxHeight: 10

          }
        },
        title: {
          display: false,
          text: '',
        },
      },
  };
  
  const horizontalBarChartData = {
      labels: horizontalBarChartLabels,
      datasets: [
        {
          label: 'Post Skill Assessment Rating', 
          data: total_score_arr,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: 'Pre Skill Assessment Rating',
          data: assume_score_arr,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    };

  // Sample data for the horizontal bar chart
  const levelBarChartData = {
    labels: innerSkillData['label'],
    datasets: [
      {
        label: customSkillLabels[0],
        data: innerSkillData['data'][0],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: customSkillLabels[1],
        data: innerSkillData['data'][1],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: customSkillLabels[2],
        data: innerSkillData['data'][2],
        backgroundColor: 'rgba(255, 99, 71, 0.5)',
      },
      {
        label: customSkillLabels[3],
        data: innerSkillData['data'][3],
        backgroundColor: 'rgba(205, 174, 71, 0.5)',
      },
      {
        label: customSkillLabels[4],
        data: innerSkillData['data'][4],
        backgroundColor: 'rgba(205, 174, 187, 0.5)',
      },
    ],
  };
  
  const levelBarChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Click on individual bars to expand'
      }
    },
    scales: {
       y: {
         display: true,
         scaleLabel: {
           display: true,
           labelString: 'Value'
         },
         ticks: {
           min: 0,
           max: 5,
           stepSize: 1,
           suggestedMin: 0.5,
           suggestedMax: 5.0,
           callback: function(label, index, labels) {
             switch (label) {
               case 0:
                 return '';
               case 1:
                 return customSkillLabels[0];
               case 2:
                 return customSkillLabels[1];
               case 3:
                 return customSkillLabels[2];
               case 4:
                 return customSkillLabels[3];
               case 5:
                 return customSkillLabels[4];
                 default:
                  return '';
             }
           }
         },
         gridLines: {
           display: false
         }
       }
     },
  };

  
  const thirdBarChartOptions = {
    indexAxis: "y", // Make the bars horizontal
    scales: {
        x: {
            display: true,
            scaleLabel: {
            display: true,
            labelString: 'Value'
            },
            ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
            suggestedMin: 0.5,
            suggestedMax: 5.0,
            callback: function(label, index, labels) {
                switch (label) {
                case 0:
                  return '';
                case 1:
                    return customSkillLabels[0];
                case 2:
                    return customSkillLabels[1];
                case 3:
                    return customSkillLabels[2];
                case 4:
                    return customSkillLabels[3];
                case 5:
                    return customSkillLabels[4];
               default:
                  return '';
                }
            }
            },
            gridLines: {
            display: false
            }
        }
    },
  };

  const teamSpiderdata = {
    labels: userTeamRoadmapSpiderData['label'],
    datasets: [
      {
        label: '',
        data: userTeamRoadmapSpiderData['data'],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const stakeholderBarChartData = {
    labels: stakeholderRoadmapsChart['label'],
    datasets: [
      {
        label: 'Roadmaps',
        data: stakeholderRoadmapsChart['data'],
        backgroundColor: "rgba(53, 162, 235, 0.5)"
      }
    ],
  };

  const stakeholderSkillBarChartData = {
    labels: stakeholderRoadmapSkillChart['label'],
    datasets: [
      {
        label: 'Skills',
        data: stakeholderRoadmapSkillChart['data'],
        backgroundColor: "rgba(53, 162, 235, 0.5)"
      }
    ],
  };

  const stakeholderSkillBarChartOptions = {
    indexAxis: "y", // Make the bars horizontal
    scales: {
        x: {
            display: true,
            scaleLabel: {
            display: true,
            labelString: 'Value'
            },
            ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
            suggestedMin: 0.5,
            suggestedMax: 5.0,
            callback: function(label, index, labels) {
                switch (label) {
                case 0:
                  return '';
                case 1:
                    return customStakeholderSkillLabels[0];
                case 2:
                    return customStakeholderSkillLabels[1];
                case 3:
                    return customStakeholderSkillLabels[2];
                case 4:
                    return customStakeholderSkillLabels[3];
                case 5:
                    return customStakeholderSkillLabels[4];
               default:
                  return '';
                }
            }
            },
            gridLines: {
            display: false
            }
        }
    },
  };


  const stakeholderSkillLevelBarChartData = {
    labels: innerStakeholderSkillData['label'],
    datasets: [
      {
        label: customStakeholderSkillLabels[0],
        data: innerStakeholderSkillData['data'][0],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: customStakeholderSkillLabels[1],
        data: innerStakeholderSkillData['data'][1],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: customStakeholderSkillLabels[2],
        data: innerStakeholderSkillData['data'][2],
        backgroundColor: 'rgba(255, 99, 71, 0.5)',
      },
      {
        label: customStakeholderSkillLabels[3],
        data: innerStakeholderSkillData['data'][3],
        backgroundColor: 'rgba(205, 174, 71, 0.5)',
      },
      {
        label: customStakeholderSkillLabels[4],
        data: innerStakeholderSkillData['data'][4],
        backgroundColor: 'rgba(205, 174, 187, 0.5)',
      },
    ],
  };
  
  const stakeholderSkillLevelBarChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: ''
      }
    },
    scales: {
       y: {
         display: true,
         scaleLabel: {
           display: true,
           labelString: 'Value'
         },
         ticks: {
           min: 0,
           max: 5,
           stepSize: 1,
           suggestedMin: 0.5,
           suggestedMax: 5.0,
           callback: function(label, index, labels) {
             switch (label) {
               case 0:
                 return '';
               case 1:
                 return customStakeholderSkillLabels[0];
               case 2:
                 return customStakeholderSkillLabels[1];
               case 3:
                 return customStakeholderSkillLabels[2];
               case 4:
                 return customStakeholderSkillLabels[3];
               case 5:
                 return customStakeholderSkillLabels[4];
                 default:
                  return '';
             }
           }
         },
         gridLines: {
           display: false
         }
       }
     },
  };

  const dataOrganization = {
    labels: chartLabels,
    datasets: [
      {
        label: '',
        data: chartData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const optionsOrganizationLine = {
    plugins: {
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };
  
  /*const labels = lineLabelsChart;
  
  const dataOrganizationLine = {
    labels,
    datasets: lineChart,
  };*/

  const options = {
    plugins: {
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };
  
  const labels = userChartLabels;
  
  const data1 = {
    labels,
    datasets: userChartData,
  };

  const StoreList = ({ name, items, id }) => {
    return (
      <Col sm={12} lg={6} xl={4}>
          <div>
              <div className="list-wrapper">
                  <div className="list-header">
                    <div className="list-text">
                      <h2 className="list-name">{name}</h2>
                    </div>
                    <div className="list-header-bottom"></div>
                  </div>
                  <ul className="list-cards">
                    {items.map((item, index) => (
                        <li className="list-card" style={{marginTop:'10px'}}>
                          <div className="list-card-box">
                            <div className="list-card-item">
                              <b><p className="card-name">{item.title}</p></b>
                              {/* <p className="card-name">{(item.description.length > 90) ? (item.description).slice(0, 90)+'...' : item.description}</p> */}
                              <div className="card-front-badges">
                                {
                                  (item.description !== '' && item.description !== null) &&
                                  (
                                    <span className="badge-card badge-card-description">
                                        <span className="icon description-icon">
                                          <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10C21 9.44772 20.5523 9 20 9H4ZM3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H4C3.44772 15 3 14.5523 3 14ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H4Z" fill="currentColor"></path></svg>
                                        </span>
                                    </span>
                                  )
                                }

                                {
                                  (item.attachments.length > 0) &&
                                  (
                                    <span className="badge-card badge-card-description" style={{float:'right'}}>
                                        <span className="icon description-icon">
                                          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd"></path>
                                          </svg>
                                        </span>
                                      <span className="badge-card-text checklist-text">{item.attachments.length}</span>
                                    </span> 
                                  )
                                }
                                  
                                  {/* <span className="badge-card checklist-badge">
                                    <span className="icon checklist-icon">
                                      <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V18H6V6L16 6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H6ZM8.73534 10.3223C8.36105 9.91618 7.72841 9.89038 7.3223 10.2647C6.91619 10.639 6.89039 11.2716 7.26467 11.6777L10.8768 15.597C11.4143 16.1231 12.2145 16.1231 12.7111 15.6264L13.0754 15.2683C13.3699 14.9785 13.6981 14.6556 14.0516 14.3075C15.0614 13.313 16.0713 12.3169 17.014 11.3848L17.0543 11.3449C18.7291 9.68869 20.0004 8.42365 20.712 7.70223C21.0998 7.30904 21.0954 6.67589 20.7022 6.28805C20.309 5.90022 19.6759 5.90457 19.2881 6.29777C18.5843 7.01131 17.3169 8.27244 15.648 9.92281L15.6077 9.96263C14.6662 10.8937 13.6572 11.8889 12.6483 12.8825L11.8329 13.6851L8.73534 10.3223Z" fill="currentColor"></path></svg>
                                    </span>
                                    <span className="badge-card-text checklist-text">0/4</span>
                                  </span> */}
                              </div>
                            </div>
                          </div>
                        </li>
                  ))}
                </ul>
              </div>
          </div>
      </Col>
    );
  }

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <h1>Track Progress</h1>
            {
              (!loading) ? (
              (userTeamId != 0) ? 
                (
                  <div className="tract-process-container-xl">
                    <ul className="settings-nav settings-nav-borders nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="settings-nav-link active ms-0" href="#self" data-bs-toggle="tab">Self</a>
                        </li>
                        <li className="nav-item">
                            <a className="settings-nav-link" href="#teams" data-bs-toggle="tab">Team</a>
                        </li>
                        <li className="nav-item">
                            <a className="settings-nav-link" href="#stakeholder" data-bs-toggle="tab">Stakeholder</a>
                        </li>
                        <li className="nav-item">
                            <a className="settings-nav-link" href="#organization" data-bs-toggle="tab">Organization</a>
                        </li>
                        <li className="nav-item">
                            <a className="settings-nav-link" href="#onboarding" data-bs-toggle="tab">Onboarding</a>
                        </li>
                    </ul>
                    <hr className="settings-hr mt-0 mb-4"/>
                    <div className="tab-content">
                        <div className="tab-pane active" id="self">
                            <div className="row">
                              {
                                (userSkillData['label'].length > 0) ? 
                                <>
                                  <CSVLink filename={"self-export.csv"} data={selfExportData}><button type="button" style={{'float':'right', 'marginRight': '10px'}} className="btn btn-sm btn-success" >Export All Data</button></CSVLink>
                                  
                                  <h2 className='text-center'>Roadmaps</h2>
                                  <Bar ref={chartRef} data={barChartData} options={barChartOptions} onClick={handleProceed} />
                                  <hr className='mb-4'/>
                        
                                  {!innerLoading && (
                                  <>
                                    <h2 className='text-center'>Percentile Rank</h2>
                                      {/* <Form.Label>Select Roadmap</Form.Label>
                                      <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleRoadmapsChange(e)}>
                                          {allRoadmap.map((roadmap) => (
                                          <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                                          ))}
                                      </Form.Select> */}

                                      <div style={{height:"500px",width:"800px",margin:"auto"}} className="proccessChart" >
                                      <ReactSpeedometer
                                        fluidWidth={true}
                                        minValue={0}
                                        maxValue={100}
                                        value={SelfToalScore} 
                                        currentValueText={"Percentile Rank - " + SelfToalScore}
                                        needleColor="steelblue"
                                        segmentColors={[
                                          "rgb(0, 192, 192)",
                                          "rgb(0, 179, 143)",
                                          "rgb(0, 166, 100)",
                                          "rgb(0, 153, 61)",
                                          "rgb(0, 141, 28)",
                                        ]}
                                        customSegmentLabels={customSegmentLabels}
                                      />
                                      </div>
                                      <hr className='mb-4'/>
                                      
                                      {/* <Line options={optionsOrganizationLine} data={dataOrganizationLine} /> */}
                                    <h2 className='text-center'>Skills by Levels</h2>
                                    <Bar ref={innerChartRef} data={levelBarChartData} options={levelBarChartOptions} onClick={handleInnerChartProceed} />
                                    <hr className='mb-4'/>
                                    </>
                                  )}

                                  {!thirdChartLoading && (
                                  <>
                                    <div className="col-md-12 mt-4 mb-4">
                                      <div className="chart-container">
                                        <h2 className='text-center'>Rattings</h2>
                                        <Bar options={horizontalBarChartOptions} data={horizontalBarChartData} />
                                      </div>
                                    </div>
                                    <Row className="mt-5">
                                      <h2 className='text-center mt-4'>History of Skills</h2>
                                      
                                        <Bar data={{
                                            labels: thirdChartSkillData.label,
                                            datasets: [
                                            {
                                                label: 'Skills',
                                                data: thirdChartSkillData.data,
                                                backgroundColor: "rgba(53, 162, 235, 0.5)"
                                            }
                                            ],
                                        }} options={thirdBarChartOptions} />
                                    </Row>


                                    </>
                                  )}
                                </> :
                                <p className="text-center">No assessment taken</p>
                              }
                            </div>
                        </div>
                        <div className="tab-pane fade" id="teams">
                          <div className="row">
                              <Col md={12}>
                                <CSVLink filename={"team-export.csv"} data={teamExportData}><button type="button" style={{'float':'right', 'marginRight': '10px'}} className="btn btn-sm btn-success" >Export All Data</button></CSVLink>
                              </Col>
                              <Col md={6}>
                              <Form.Label>Select Team</Form.Label>
                              <Form.Select name="team_id" className="team_id" onChange={e => handleTeamsChange(e)}>
                                {userTeams.map((team) => (
                                  <option value={team.id+"@"+team.type} data-id={team.id} data-type={team.type} >{team.name} ({(team.type == 'ORGANIZATION') ? team.parent_name : 'Study Group'})</option>
                                ))}
                                {
                                  (userTeams.length == 0) ? <option value="" >Select</option> : ''
                                }
                              </Form.Select>
                              </Col>

                              {
                                (userTeamRoadmaps.length != 0) ? 
                                <>
                                <Col md={6}>
                                <Form.Label>Select Roadmap</Form.Label>
                                <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleTeamRoadmapChange(e)}>
                                  {userTeamRoadmaps.map((roadmap) => (
                                    <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                                  ))}
                                  {
                                    (userTeamRoadmaps.length == 0) ? <option value="" >Select</option> : ''
                                  }
                                </Form.Select>
                                </Col>

                                <Row style={{marginBottom:'20px'}}>
                                  <Col md={12}>
                                  {
                                    (userTeamRoadmapSpiderData['data'].length > 0) ? 
                                      (userTeamRoadmapSpiderData['data'].reduce((a, b) => a + b, 0) != 0) ? 
                                      <div><div style={{height:"600px"}} ><Radar data={teamSpiderdata} /></div><Line options={options} data={data1} /></div>
                                      : <div style={{textAlign: "center", marginTop: "50px"}}>Reporting will be done when users take the skill assessment.</div>           
                                    : <div style={{textAlign: "center", marginTop: "50px"}}>No skill found</div>
                                  }
                                  </Col>
                                </Row>
                                <Row>
                                  {acceptedMembers.map((element, index) => (
                                  <Col md={6} lg={4}>
                                      <div className="h-full min-h-270 overflow-hidden rounded-3 border">
                                        <div className="position-relative d-flex gap-3 border-bottom p-3">
                                          <img src="/img/default-avatar.png" alt="Dev" className="h-8 min-h-32 w-8 min-w-32 rounded-circle"/>
                                          <div className="inline-grid w-100 profile">
                                            <div className="d-flex justify-content-between items-center ">
                                              <h3 className="truncate font-medium">{element.fname + " " + element.lname}</h3>
                                              {
                                                (element.email  ===  theLoginUser.email) ? <span className="rounded-3 bg-danger px-1 py-0.5 text-xs text-white">You</span> : ''
                                              }
                                            </div>
                                            <p className="truncate fs-6">{element.email}</p>
                                          </div>
                                        </div>
                                        <div className="position-relative d-flex grow flex-column p-3">
                                        <span>Roadmaps</span>
                                        {element.roadmaps.map((roadmap, roadmap_index) => (
                                          <button className="group position-relative overflow-hidden rounded-3 border p-2 hover:border-gray-300 hover:text-black focus:outline-none btn btn-outline-secondary" >
                                            <span className="position-relative z-10 d-flex align-items-center justify-content-between text-sm">
                                              <span className="inline-grid">
                                                <span className="truncate">{roadmap.roadmap_name}</span>
                                              </span>
                                              <span className="ml-1.5 shrink-0 text-xs text-gray-400 fn-12">{roadmap.score} / 5</span>
                                            </span>
                                            <span className="position-absolute inset-0 bg-gray-100 group-hover:bg-gray-200" style={{ width: '0%' }}></span>
                                          </button>
                                        ))}
                                        {/* <button className="text-sm text-gray-400 border-0 text-decoration-underline bg-transparent">+ 3 more</button> */}
                                        </div>
                                      </div>
                                  </Col>          
                                  ))}
                              </Row>
                                
                                </> : ''
                              }
                              
                            {/* <Bar ref={teamChartRef} data={teamBarChartData} options={barChartOptions} /> */}
                  
                          </div>
                        </div>
                        <div className="tab-pane fade" id="stakeholder">
                          <div className="row">
                              {
                                (stakeholderUserListWithRoadmapsChart.length != 0) ? 
                                <>
                                <Col md={12}>
                                  <CSVLink filename={"stakeholder-export.csv"} data={stakeholderExportData}><button type="button" style={{'float':'right', 'marginRight': '10px'}} className="btn btn-sm btn-success" >Export All Data</button></CSVLink>
                                </Col>
                                <Col md={6}>
                                <Form.Label>Select User</Form.Label>
                                <Form.Select name="stakeholder_user_id" className="stakeholder_user_id" onChange={e => handleStakeholderUserChange(e)}>
                                  {stakeholderUserListWithRoadmapsChart.map((val) => (
                                    <option value={val.user_email} >{val.user_email}</option>
                                  ))}

                                  {
                                    (stakeholderUserListWithRoadmapsChart.length == 0) ? <option value="" >Select</option> : ''
                                  }
                                </Form.Select>
                                </Col>


                                <Col md={12}>
                                <Bar ref={stakeholderChartRef} data={stakeholderBarChartData} options={barChartOptions} onClick={handleStakeholderRoadmapChange} />
                                </Col>

                                  {!stakeholderRoadmapSkillChartLoading && (
                                    <>
                                    <Col md={12}>
                                      <h2 className='text-center'>Skills by Levels</h2>
                                      <Bar data={stakeholderSkillLevelBarChartData} options={stakeholderSkillLevelBarChartOptions} />
                                      <hr className='mb-4'/>
                                    </Col> 
                                    <Col md={12}>
                                      <Bar data={stakeholderSkillBarChartData} options={stakeholderSkillBarChartOptions}  />
                                    </Col> 
                                  </>
                                  )}
                                </> : 
                                <p className="text-center">No assessment taken</p>
                              }
                          </div>
                        </div>
                        <div className="tab-pane fade" id="organization">
                        <div className="row">
                          {
                              (!organizationLoading) ? 
                              <Col md={12}>
                                  <Form.Label>Select Roadmap</Form.Label>
                                  <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleOrganizationChange(e)}>
                                      {organizationRoadmaps.map((roadmap) => (
                                      <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                                      ))}
                                  </Form.Select>

                                  {
                                      (chartLabels.length > 0) ? 
                                      <div style={{height:"800px",width:"800px",margin:"auto"}} className="proccessChart" ><Radar data={dataOrganization} /></div> : <div style={{textAlign: "center", marginTop: "50px"}}>No skill found</div>
                                  }
                              </Col>
                              : ''
                          }
                          </div>
                        </div>
                        <div className="tab-pane fade" id="onboarding">
                          <div className="row" style={{marginBottom:'20px'}}>
                              <Col md={6}>
                              <Form.Label>Select Team</Form.Label>
                              <Form.Select name="team_id" className="team_id" onChange={e => handleTeamsChange(e)}>
                                {userTeams.filter(newVal => (newVal.type == 'ORGANIZATION')).map((team) => (
                                  <option value={team.id+"@"+team.type} data-id={team.id} data-type={team.type} >{team.name} ({(team.type == 'ORGANIZATION') ? team.parent_name : 'Study Group'})</option>
                                ))}
                                {
                                  (userTeams.length == 0) ? <option value="" >Select</option> : ''
                                }
                              </Form.Select>
                              </Col>

                              {
                                (acceptedMembers.length != 0) ? 
                                <>
                                <Col md={6}>
                                <Form.Label>Select User</Form.Label>
                                  <Form.Select name="user_id" className="user_id" onChange={e => handleUserChange(e)}>
                                    {acceptedMembers.map((element, index) => (
                                      <option value={element.user_id} >{element.fname+' '+element.lname} ({element.email})</option>
                                    ))}
                                  </Form.Select>
                                </Col>

                                
                                </> : ''
                              }
                              
                          </div>
                          
                          {!storeLoading ? (
                              (stores.length > 0) && (
                                <Row>
                                  {stores.map((store, index) => (
                                    StoreList(store)
                                  ))}
                                </Row>
                              )
                            ) : <h6 className="text-center">Loading...</h6>
                          }
                        </div>
                    </div>
                  </div> 
                )
              : 
               (
                <div className="row">
                  {
                    (userSkillData['label'].length > 0) ? 
                    <>
                      <h2 className='text-center'>Roadmaps</h2>
                      <Bar ref={chartRef} data={barChartData} options={barChartOptions} onClick={handleProceed} />
                      <hr className='mb-4'/>

                      {!innerLoading && (
                      <>
                          <h2 className='text-center'>Percentile Rank</h2>
                          {/* <Form.Label>Select Roadmap</Form.Label>
                          <Form.Select name="roadmap_id" className="roadmap_id" onChange={e => handleRoadmapsChange(e)}>
                              {allRoadmap.map((roadmap) => (
                              <option value={roadmap.roadmap_id} >{roadmap.roadmap_name}</option>
                              ))}
                          </Form.Select> */}
                                
                            <div style={{height:"500px",width:"800px",margin:"auto"}} className="proccessChart" >
                            <ReactSpeedometer
                              fluidWidth={true}
                              minValue={0}
                              maxValue={100}
                              value={SelfToalScore} 
                              currentValueText={"Percentile Rank - " + SelfToalScore}
                              needleColor="steelblue"
                              segmentColors={[
                                "rgb(0, 192, 192)",
                                "rgb(0, 179, 143)",
                                "rgb(0, 166, 100)",
                                "rgb(0, 153, 61)",
                                "rgb(0, 141, 28)",
                              ]}
                              customSegmentLabels={customSegmentLabels}
                            />
                            </div>
                            
                            <hr className='mb-4'/>
                                      
                              {/* <Line options={optionsOrganizationLine} data={dataOrganizationLine} /> */}
                            <h2 className='text-center'>Skills by Levels</h2>
                            <Bar ref={innerChartRef} data={levelBarChartData} options={levelBarChartOptions} onClick={handleInnerChartProceed} />
                            <hr className='mb-4'/>
                        </>
                      )}

                      {!thirdChartLoading && (
                      <>
                        <div className="col-md-12 mt-4 mb-4">
                          <div className="chart-container">
                            <h2 className='text-center'>Rattings</h2>
                            <Bar options={horizontalBarChartOptions} data={horizontalBarChartData} />
                          </div>
                        </div>
                        <Row className="mt-5">
                          <h2 className='text-center mt-4'>History of Skills</h2>
                          
                            <Bar data={{
                              labels: thirdChartSkillData.label,
                              datasets: [
                              {
                                  label: 'Skills',
                                  data: thirdChartSkillData.data,
                                  backgroundColor: "rgba(53, 162, 235, 0.5)"
                              }
                              ],
                          }} options={thirdBarChartOptions} />
                          </Row>
                          </>
                      )}
                    </>
                    :
                    <p className="text-center">No assessment taken</p>
                  }
                </div>
              )
              ) : <h6 className="text-center">Loading...</h6>
            }
          </Container>
        </div>
      </div>
  );
};

export default TrackProgressPage;