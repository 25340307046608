import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UnAuthorized = () => {
  const navigate = useNavigate();
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);    
  }, []);

  return (
    <aside className="d-sm-none py-10 d-md-block border-end process-sidebar h-full mt-5 mb-5">
      <div className="relative mr-2">
        <div className="container text-center">
          <h2 className="mb-1 text-2xl font-bold">Unauthorize</h2>
          <p className="mb-3 text-base leading-6 text-gray-600">You are Unauthorized.</p>
          <div className="mx-auto w-full max-w-md">
            <hr/>
          <button type="button" className="btn btn-primary" onClick={() => navigate('profile')}>Back to Home</button>
          </div>
        </div>
      </div>
    </aside>
  );
}; 

export default UnAuthorized;