import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext';
import { inArray } from "jquery";

const ReviewerAssessment = () => {
  const [saveBtnDisable, setSaveBtnDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [id, setRoadmapId] = useState(0);
  const [feedback_id, setFeedbackId] = useState(0);
  const [skills, setSkills] = useState([]);
  
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname.split('/');
  const url_code = pathName[2];

  const {getRoadmapById, postAssessment, getSkills, getFeedbackByToken} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [loadingRoadmap, setLoadingRoadmap] = useState(true);
  const [isQuestions, setIsQuestions] = useState(1);
  const [roadmap, setRoadmaps] = useState({});
   const [myQuestions, setMyQuestions] = useState([]);
   const [selectedQueData, setSelectedQueData] = useState({result:[]});
   const [selectedQueDataForDisplay, setSelectedQueDataForDisplay] = useState([]);
   const [assessmentId, setAssessmentId] = useState(0);
   const [questionsCount, setQuestionsCount] = useState(0);
   const [errValidationMsg, setErrValidationMsg] = useState('');
   const [selectedArrData, setSelectedArrData] = useState([]);
  
  

  const handleSubmit = async (in_process) => {

    if(selectedQueData.result.length == 0){
      setErrValidationMsg('Please rate all skill questions'); 
      return false;
    } else if(selectedQueData.result[0] != undefined && selectedQueData.result[0].questions.length != questionsCount){
      setErrValidationMsg('Please rate all skill questions'); 
      return false;
    } else {
      setErrValidationMsg(''); 
    }

    // Submit the user's answers
    // Display a message
    let formData = {
      user_id:0,
      roadmap_id:id,
      feedback_id: feedback_id,
      question_results:JSON.stringify(selectedQueData),
      in_process:in_process,
      assessment_id:assessmentId
    };
    
    var result = await postAssessment(formData);
    if(result.status){
      if(in_process == 0) {
        alert('Thank you for completing the assessment.');
      } else {
        alert('Assessment saved.');
      }
      localStorage.removeItem('feedbackData');
      navigate(`/login`);
    }
    else if(!result.status && result.message){
    }
    
  };

  const fetchRoadMapData = async (id) => {
    setLoadingRoadmap(true);
    const resRoadmap = await getRoadmapById({id:id});
    if(resRoadmap.token_message){
      console.error(resRoadmap.token_message);
    }else if(resRoadmap){
        setRoadmaps(resRoadmap.data);
        setLoadingRoadmap(false);
    }else{
    }
  };

  const fetchFeedbackData = async (userData) => {
    const resFeedback = await getFeedbackByToken({url_code:url_code});
    if(resFeedback){
      if(resFeedback.status){
        // if(!userData){
        //   logout();
        //   navigate(`/login`);
        // }else if((userData.email !== resFeedback.data.email) || (resFeedback.data.status === 1)){
        //    setErrorMsg('You are not access this page');
        // }else{
          setRoadmapId(resFeedback.data.roadmap_id);
          setFeedbackId(resFeedback.data.id);
          fetchData(resFeedback.data.roadmap_id, resFeedback.data.assessment);
        // }
      }
      else{
        setErrorMsg('You are not access this page');
      }
    }
  };

   const handleSelectedAnswer = (question_id,skill_id, answer) => {
    let array = [];
    if(selectedQueData.result.length > 0){
        array = selectedQueData.result[0].questions;
        array.forEach((element, index) => {
          if(element.skill_question_id == question_id && element.skill_id == skill_id) {
            array.splice(index, 1);
            return true;
          }
        });
    }

    let queRes = (selectedQueData.result.length > 0) ? array : myQuestions;

    queRes.push({
      skill_question_id:question_id,
      answer:answer,
      skill_id:skill_id
    });

    let itemsres = [];
      itemsres.push({
         questions:queRes
    });
    let qData = {result:itemsres};
    setSelectedQueData(qData);
    setSaveBtnDisable(false);
    
    let tempSelectedQueDataForDisplay = selectedQueDataForDisplay;
    tempSelectedQueDataForDisplay[skill_id+"#"+question_id] = answer;
    setSelectedQueDataForDisplay(tempSelectedQueDataForDisplay);

    let selectedArrData = [];
    (itemsres[0].questions).forEach(element1 => {
      selectedArrData.push(element1.skill_question_id);
    });
    setSelectedArrData(selectedArrData);
  };

  // Function to fetch data from the API
  const fetchData = async (id, assessment) => {
    
    fetchRoadMapData(id);

    setLoading(true);
    const response = await getSkills(id);
    if(response.token_message){
      console.error(response.token_message);
    }else if(response.data){
        setIsQuestions(response.is_questions);
        setQuestionsCount(response.question_count);
        let result = response.data;
        let tempSelectedQueDataForDisplay = [];
        let abc = [];
        let selected_result = [];
        if(assessment !== null)
        {
          let temp = JSON.parse(assessment.question_results);
          setSelectedQueData(temp);
          setAssessmentId(assessment.id);
          setSaveBtnDisable(false);
          selected_result = temp.result[0].questions;
        }


        result.forEach((skill, skill_index) => {
          skill.questionDetails.forEach((question, question_index) => {
            selected_result.forEach(element => {
              if(element.skill_id == skill.skill_id && element.skill_question_id == question.skills_question_id) {
                tempSelectedQueDataForDisplay[skill.skill_id+"#"+question.skills_question_id] = element.answer;
              }
            });

            if (tempSelectedQueDataForDisplay[skill.skill_id+"#"+question.skills_question_id] === undefined) {
              tempSelectedQueDataForDisplay[skill.skill_id+"#"+question.skills_question_id] = null;
            }
            var answer = false;
            result[skill_index].questionDetails[question_index].answer = answer;
          });
        });
        setSelectedQueDataForDisplay(tempSelectedQueDataForDisplay);

        setSkills(result);
        
        setLoading(false);
    }else{
    }
  };
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // let userData = user;
    let userData = null;
    setTimeout(
      () => {
      fetchFeedbackData(userData);
    },1000);

    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
    
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    const confirmBox = window.confirm(
      "Unsaved progress will be lost. Are you sure?"
    )
    if (confirmBox === true) {
        navigate(`/`);
    }
  }

  return (

    <div className="padding100 skills-rating h-full">
      <div className="container-fluid">
        {errorMsg !== '' && (
            <> <center><h1>{errorMsg}</h1></center> </>
        )}
        {errorMsg === '' && 
        !loadingRoadmap && (
          <><h1>{(roadmap.roadmap_name).split(' ').map(function(word) {return word[0].toUpperCase() + word.substr(1);}).join(' ')} Roadmap</h1>
        </>
        )}
        <p>{roadmap.roadmap_description}</p>
        {
          (loading) ? <h6 className="text-center">Loading...</h6> :
          (isQuestions == 0)  ? 
            <h6 className="text-center">Data not found</h6> 
            :
          <div className="table-responsive-sm table-responsive-md">
            <table className="table new_assessment_table align-middle table-sticky">
              <thead>
                <tr>
                  <th scope="col" className="tbl_header">Skill Name</th>
                  <th scope="col" className="tbl_header">Skill Question</th>
                  <th scope="col" className="tbl_header">Novice - I've never used or applied the skills and I'm completely new to the concept</th>
                  <th scope="col" className="tbl_header">Advanced Beginner - I've heard of this and understand what it is and when it's used. I might have started using and/or applying the skills, but I need a lot of hands-on assistance and guidance to do anything.</th>
                  <th scope="col" className="tbl_header">Competent -  I can access, use and apply the skill and I'm self-sufficient in its application, but I need time and access to reference materials or experts in some cases to apply the skill.</th>
                  <th scope="col" className="tbl_header">Proficient - I can do this independently and effectively with little to no dependence on experts or reference materials.</th>
                  <th scope="col" className="tbl_header">Expert - I am confident in this and can teach it effectively to others. I may be considered a SME or go-to expert and thought leader in this area</th>
                </tr>
              </thead>
              <tbody>
                {
                  (loading) ? <tr><td colSpan="7" className="text-center">Loading...</td></tr> : ''
                }
                {!loading && (skills.length > 0) && (
                  skills.map((skill, index) => (
                      skill.questionDetails.map((question, index) => (
                    <>
                    <tr>
                      { (index == 0) ?
                      <td scope="row" rowSpan={skill.questionDetails.length}>{skill.skill_name} - {skill.skill_definition}</td> :
                      '' }
                      
                    <td>
                        {question.skill_question}  
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="1"
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,1)}
                        className={(errValidationMsg != '' && selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''} 
                        checked={(selectedQueDataForDisplay[question.skill_id+"#"+question.skills_question_id] == 1) ? 'checked' : ''}
                      />
                      
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="2" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,2)}
                        className={(errValidationMsg != '' && selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}
                        checked={(selectedQueDataForDisplay[question.skill_id+"#"+question.skills_question_id] == 2) ? 'checked' : ''}
                        />
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="3" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,3)} 
                        className={(errValidationMsg != '' && selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}
                        checked={(selectedQueDataForDisplay[question.skill_id+"#"+question.skills_question_id] == 3) ? 'checked' : ''}
                        />
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="4" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,4)} 
                        className={(errValidationMsg != '' && selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}
                        checked={(selectedQueDataForDisplay[question.skill_id+"#"+question.skills_question_id] == 4) ? 'checked' : ''}
                        />
                    </td>
                    <td className="text-center">
                      <Form.Check 
                        type="radio"
                        name={`answer-${question.skills_question_id}`} 
                        value="5" 
                        onChange={() => handleSelectedAnswer(question.skills_question_id,question.skill_id,5)}
                        className={(errValidationMsg != '' && selectedArrData.length > 0 && !selectedArrData.includes(question.skills_question_id)) ? 'redboard' : ''}
                        checked={(selectedQueDataForDisplay[question.skill_id+"#"+question.skills_question_id] == 5) ? 'checked' : ''}
                        />
                    </td>
                  </tr>
                    </>
                  ))
                ))
                )}
              {!loading && (skills.length === 0) && (
                <>
                  <tr><td className="text-center" colSpan="7">Data not found</td></tr>
                </>
              )}
              </tbody>
            </table>
            <div className="row">
              {errValidationMsg && <h4 className="err-msg text-danger text-center">{errValidationMsg}</h4>}
            </div>
            <div className="button-section justify-content-end">
              <button onClick={handleCancel} className="reset-button">Cancel</button>   
              <button onClick={() => handleSubmit(1)} disabled={saveBtnDisable} className="btn back-button">Save</button>
              <button onClick={() => handleSubmit(0)} className="btn submit-button">Submit</button> 
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ReviewerAssessment;