import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Form, Container } from "react-bootstrap";
import { UserContext } from '../contexts/UserContext';
import ProfileMenu from "./profilemenu";

const CreateHiring = () => {

  const { createHiring, showToast} = useContext(UserContext);
  const user =  JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_id:user.id,
    hiring_role_name:'',
    hiring_description:''
  });

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    });
  };

  const checkProfessionalEmail = () => {
    // List of commonly used professional email providers
    const professionalProviders = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'protonmail.com',
      'yopmail.com'
      // Add more professional email providers as needed
    ];

    // Extract domain from email address
    const domain = (user.email).split('@')[1];

    // Check if the domain is a commonly used professional email provider
    if (professionalProviders.includes(domain)) {
      navigate(`/profile-teams`);
    } 
  };

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      if(formData.hiring_role_name === "") {
        showToast("Please enter hiring role name", true); return false;
      }

      const data = await createHiring(formData);
      if(data.status){
          e.target.reset();
          setFormData({
            user_id:user.id,
            hiring_role_name:'',
            hiring_description:''
          });
          navigate(`/createhiring/add-hiring-members/${data.id}`);
      }
      else if(!data.status && data.message){
          showToast(data.message, true);
      }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    checkProfessionalEmail();
  }, []);

  return (
      <div className="row h-full">
        <div className="col-xs-12 col-md-4 col-lg-3 col-xl-2 responsive-sidebar">
          <ProfileMenu />
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10 padding80 responsive-content">
          <Container fluid>
            <main>
              <div className="button-section">
                  <button onClick={() => navigate(-1)} className="button" title="back"><i className="fa fa-chevron-left"></i> Back</button>
              </div>
              <div className="settings-card">
                <div className="settings-card-header">Enter Hiring Information</div>
                <div className="settings-card-body">

                <Form onSubmit={handleSubmit} >
                  <Form.Group controlId="hiring_role_name" className="mb-3">
                    <Form.Label>Hiring Role Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="hiring_role_name"
                      value={formData.hiring_role_name}
                      placeholder="Hiring Role Name"
                      onChange={onChangeInput}
                    />
                  </Form.Group>

                  <Form.Group controlId="hiring_description" className="mb-3">
                    <Form.Label>Hiring Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="hiring_description"
                      value={formData.hiring_description}
                      placeholder="Hiring Description"
                      onChange={onChangeInput}
                    />
                  </Form.Group>
                  <div className="button-section">
                      <button className="button submit" type="submit">Save</button>
                  </div>
                </Form>
                </div>
              </div>
            </main>
          </Container>
        </div>
      </div>
  );
};

export default CreateHiring;